import * as React from 'react'
import { withRouter, RouteComponentProps, NavLink, NavLinkProps } from 'react-router-dom'
import { t } from '../../../i18n'
import { icons } from '../../../assets'
import { useStatuses } from '../../../hooks/current-user'
import * as SvgIcons from './svg-icons'

const MenuLink: React.FC<NavLinkProps & { disabled?: boolean }> = (props) => {
  if (props.disabled)
    return (
      <a href="javascript:void(0)" className={(props.className || '') + ' disabled'}>
        {props.children}
      </a>
    )
  return <NavLink {...props}>{props.children}</NavLink>
}

type Props = RouteComponentProps

const Footer: React.FunctionComponent<Props> = () => {
  const closeMobileMenu = () => {
    document.body.classList.remove('menu-mobile-open')
  }
  const toggleMobileMenu = () => {
    document.body.classList.toggle('menu-mobile-open')
  }

  const { statuses } = useStatuses()

  const pendingTerms = !!statuses && (!statuses.verifiedPrivacyPolicyEmailAcceptance || !statuses.verifiedTermsOfUsageEmailAcceptance)

  const onClick = () => {
    closeMobileMenu()
  }

  return (
    <footer>
      <div className="box-footer">
        <MenuLink to="/" exact>
          <div>
            <SvgIcons.Home />
            <p>{t('menu:home')}</p>
          </div>
        </MenuLink>
        {/* <!-- products --> */}
        <MenuLink to="/products" disabled={pendingTerms}>
          <div>
            <SvgIcons.Products />
            <p>{t('menu:products')}</p>
          </div>
        </MenuLink>
        <MenuLink to="/network" disabled={pendingTerms}>
          <div>
            <SvgIcons.Network />
            <p>{t('menu:network')}</p>
            {/* <div className="badge-menu badge-success">65</div> */}
          </div>
        </MenuLink>
        {/* <!-- financial --> */}
        <MenuLink to="/financial" disabled={pendingTerms}>
          <div>
            <SvgIcons.Financial />
            <p>{t('menu:financial')}</p>
            {/* <div className="badge-menu badge-danger">6</div> */}
          </div>
        </MenuLink>
        {/* <!-- file --> */}
        <MenuLink to="/wallet" disabled={pendingTerms}>
          <div>
            <SvgIcons.Wallet />
            <p>{t('menu:wallet')}</p>
            {/* <div className="badge-menu badge-info">!</div> */}
          </div>
        </MenuLink>
        <MenuLink to="/profile">
          <div>
            <SvgIcons.Profile />
            <p>{t('menu:profile')}</p>
          </div>
        </MenuLink>
        {/* <!-- file --> */}
        {/* <MenuLink to="/files" disabled={pendingTerms}>
          <div>
            <SvgIcons.Files />
            <p>{t('menu:files')}</p>
          </div>
        </MenuLink> */}
        {/* <!-- marketing --> */}
        <MenuLink to="/marketing" disabled={pendingTerms}>
          <div>
            <SvgIcons.Marketing />
            <p>{t('menu:marketing')}</p>
          </div>
        </MenuLink>
        {/* <!-- tutorial --> */}
        {/* <MenuLink to="/tutorials" disabled={pendingTerms}>
          <div>
            <SvgIcons.Tutorials />
            <p>{t('menu:tutorials')}</p> */}
        {/* <div className="badge-menu badge-primary">65</div> */}
        {/* </div>
        </MenuLink> */}
        <MenuLink to="/support">
          <div>
            <SvgIcons.Support />
            <p>{t('menu:support')}</p>
          </div>
        </MenuLink>
      </div>
      {/* seletor mobile */}
      <div className="box-footer-mobile">
        <div className="selector" onClick={toggleMobileMenu}>
          <div>
            <img src={icons.menuMobile} />
          </div>
          <p>{t('menu:menu')}</p>
        </div>
      </div>
      {/* menu mobile */}
      <div className="menu-mobile">
        <div>
          <MenuLink to="/" exact onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Home />
                <p>{t('menu:home')}</p>
                {/* <div className="badge-menu badge-success">15</div> */}
              </div>
            </div>
          </MenuLink>
          <MenuLink to="/products" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Products />
                <p>{t('menu:products')}</p>
              </div>
            </div>
          </MenuLink>
          <MenuLink to="/network" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Network />
                <p>{t('menu:network')}</p>
              </div>
            </div>
          </MenuLink>
          <MenuLink to="/financial" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Financial />
                <p>{t('menu:financial')}</p>
                {/* <div className="badge-menu badge-danger">6</div> */}
              </div>
            </div>
          </MenuLink>
          <MenuLink to="/wallet" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Wallet />
                <p>{t('menu:wallet')}</p>
              </div>
            </div>
          </MenuLink>
          <MenuLink to="/profile" onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Profile />
                <p>{t('menu:profile')}</p>
              </div>
            </div>
          </MenuLink>
          {/* <MenuLink to="/files" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Files />
                <p>{t('menu:files')}</p>
     ink to="/files" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Files />
                <p>{t('menu:files')}</p>
              </div>
            </div>
          </MenuL         </div>
            </div>
          </MenuLink> */}
          <MenuLink to="/marketing" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Marketing />
                <p>{t('menu:marketing')}</p>
              </div>
            </div>
          </MenuLink>
          {/* <MenuLink to="/tutorials" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Tutorials />
                <p>{t('menu:tutorials')}</p> */}
          {/* <div className="badge-menu badge-info">!</div> */}
          {/* </div>
            </div>
          </MenuLink> */}
          <MenuLink to="/support" disabled={pendingTerms} onClick={onClick}>
            <div>
              <div>
                <SvgIcons.Support />
                <p>{t('menu:support')}</p>
              </div>
            </div>
          </MenuLink>
        </div>
      </div>
    </footer>
  )
}

export default withRouter(Footer)
