import { db } from '../services/firebase'
import { useUid } from './current-user'
import { useDatabaseObject } from './database'
import { MemberSupportTickets } from '@allcoinwallet/inkryptus-db-schema'

export function useMemberSupportTickets({
  dateStart,
  dateEnd,
}: {
  dateStart: string
  dateEnd: string
}): [MemberSupportTickets | null, boolean, object | undefined] {
  const [uid] = useUid()
  const ticketsRef = uid
    ? db
        .child('membersSupportTickets')
        .child(uid)
        .orderByChild('openingTime')
        .startAt(dateStart.length > 0 ? new Date(dateStart).getTime() : '0000000000000')
        .endAt(dateEnd.length > 0 ? new Date(dateEnd).getTime() : '9999999999999')
    : undefined
  const tickets = useDatabaseObject(ticketsRef)
  return [tickets.value || null, tickets.loading, tickets.error]
}
