import * as React from 'react'

import { signupAcceptTerms } from '../../services/api'
import SignupAgreement from '../../components/auth/signup-agreement'

const SignupTermsPage: React.FunctionComponent = () => {
  return <SignupAgreement onAccept={signupAcceptTerms} />
}

export default SignupTermsPage
