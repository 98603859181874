import React from 'react'
import { ProductContract, ProductsContracts } from '@allcoinwallet/inkryptus-db-schema'
import { Decimal, decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'
import { db } from '../services/firebase'
import { useUid } from './current-user'
import { useDatabaseObject } from './database'

export function useMemberProductContractsRunningCakeAmount(productId: string): [decimal | undefined, boolean, object | undefined] {
  const [userId, userIdLoading] = useUid()
  const [contracts, contractsLoading, contractsError] = useOtherMemberProductContractsRunning(productId, userId || undefined)
  const cakeAmount = React.useMemo(() => {
    let totalAmount = Decimal(0)
    if (!contracts) return totalAmount
    for (const contractId in contracts) {
      const contract = contracts[contractId]
      totalAmount = add(totalAmount, contract.startNetAmount)
    }
    return totalAmount
  }, [contracts])
  return [cakeAmount, contractsLoading || userIdLoading, contractsError]
}

export function useMemberProductContractsRunning(productId?: string): [ProductsContracts | undefined, boolean, object | undefined] {
  const [uid, uidLoading] = useUid()
  const queryRef =
    uid && productId
      ? db.child('productsContracts').orderByChild('_indexes/userId__productId__running').equalTo(`${uid}__${productId}__true`)
      : undefined
  const v = useDatabaseObject(queryRef)
  const loading = v.loading || uidLoading
  return [v.value, loading, v.error]
}

export function useMemberProductContractsStopped(productId?: string): [ProductsContracts | undefined, boolean, object | undefined] {
  const [uid, uidLoading] = useUid()
  const queryRef =
    uid && productId
      ? db.child('productsContracts').orderByChild('_indexes/userId__productId__running').equalTo(`${uid}__${productId}__false`)
      : undefined
  const v = useDatabaseObject(queryRef)
  const loading = v.loading || uidLoading
  return [v.value, loading, v.error]
}

export function useMemberProductContract(contractId?: string): [ProductContract | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(contractId ? db.child('productsContracts').child(contractId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useOtherMemberProductContractsRunning(
  productId: string,
  username?: string
): [ProductsContracts | undefined, boolean, object | undefined] {
  let queryRef = username ? db.child('productsContracts').orderByChild('_indexes/username__running').equalTo(`${username}__true`) : undefined
  const v = useDatabaseObject(queryRef)
  const loading = v.loading
  return [v.value, loading, v.error]
}

export function useOtherMemberProductContractsRunningCakeAmount(
  productId: string,
  username?: string
): [decimal | undefined, boolean, object | undefined] {
  const [contracts, contractsLoading, contractsError] = useOtherMemberProductContractsRunning(productId, username)
  const cakeAmount = React.useMemo(() => {
    let totalAmount = Decimal(0)
    if (!contracts) return totalAmount
    for (const contractId in contracts) {
      const contract = contracts[contractId]
      totalAmount = add(totalAmount, contract.startNetAmount)
    }
    return totalAmount
  }, [contracts])
  return [cakeAmount, contractsLoading, contractsError]
}
