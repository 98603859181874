import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { map } from 'lodash'
import { t } from '../../i18n'
import { icons } from '../../assets'
import { ProductDetails } from '../../hooks/database'
import UsageLearningTutorials from './UsageLearningTutorials.component'
import PresentationDownload from './PresentationDownload.component'
import MarketplaceProductWidget from '../../components/marketplace-product-widget'

interface Props {
  licensePrice: decimal
  licenseDurationDays: number
  productId: string
  available?: boolean
  hasVariants?: boolean
  freeTrialDays?: number
  onBuyClick: (productVariant: string) => () => void | Promise<void>
  onCloseClick: () => void
  details: ProductDetails
}
const ProductOverview: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <div className="menu">
        <img src={props.details.headerLogoUrl} />
        <img src={icons.close} onClick={props.onCloseClick} />
      </div>
      <div className="info">
        <div className="info-main">
          <img src={props.details.overviewBannerUrl} />
          <div className="details">
            {props.details.trading && (
              <div className="cards-details">
                <div className="profit">
                  <p>{t('desk:product_overview_modal.info.trading.average_annual_profit_title')}</p>
                  <p>{props.details.trading.averageAnnualProfit}</p>
                </div>
                <div className={`risk ${props.details.trading.riskLevel}`}>
                  <p>{t('desk:product_overview_modal.info.trading.risk_level_title')}</p>
                  <p>{t(`fields:risk_level.options.${props.details.trading.riskLevel}`)}</p>
                </div>
                <div className="investment">
                  <p>{t('desk:product_overview_modal.info.trading.minimum_investment_title')}</p>
                  <p>{props.details.trading.minimumInvestment}</p>
                </div>
              </div>
            )}
            <div className="description">
              <p>{t('desk:product_overview_modal.info.about_title')}</p>
              {props.details.about.split('\n').map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
            {props.details.benefits && (
              <div className="requirements">
                <p>{t('desk:product_overview_modal.info.benefits_title')}</p>
                <ul>
                  {map(props.details.benefits, (text, index) => (
                    <li key={index}>{text}</li>
                  ))}
                </ul>
              </div>
            )}
            {props.details.requirements && (
              <div className="requirements">
                <p>{t('desk:product_overview_modal.info.requirements_title')}</p>
                <ul>
                  {map(props.details.requirements, (text, index) => (
                    <li key={index}>{text}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="info-sidebar">
          {props.details.openingLink && (
            <a href={props.details.openingLink} target="_blank">
              {t('desk:product_overview_modal.open_product_button_label')}
            </a>
          )}
          <MarketplaceProductWidget
            productId={props.productId}
            hasVariants={props.hasVariants}
            available={props.available}
            freeTrialDays={props.freeTrialDays}
            onClose={props.onCloseClick}
          />

          {/* <div>
            <p>{t('common:license')}</p>
            <p>{formatCurrency(props.licensePrice)}*</p>
            <p>
              <small>*</small>
              {t('common:first')} {props.licenseDurationDays} {t('common:days')}
            </p>
          </div>
          {platform !== 'ios' && (
            <div>
              <MarketplaceProductActionButton productId={props.productId} hasVariants={props.hasVariants} available={props.available} />
            </div>
          )}
          <div>
            <p>{t('desk:product_overview_modal.license.status.title')}</p>
            <div>
              <p>-</p>
            </div>
          </div>
          */}
          <div className="details">
            <p className="title">{t('desk:product_overview_modal.license_details.title')}</p>
            <p>{t('desk:product_overview_modal.license_details.disclaimer')}</p>
            <p>{props.details.licenseDetails}</p>
          </div>
          {props.details.presentationResourceId && <PresentationDownload resourceId={props.details.presentationResourceId} />}
        </div>
      </div>
      {props.details.usageLearningResourcesIds && <UsageLearningTutorials tutorialsIds={props.details.usageLearningResourcesIds} />}
    </>
  )
}

export default ProductOverview
