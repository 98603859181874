import * as React from 'react'
import MemberActiveProductItem from './MemberActiveProductItem'

const MemberActiveProducts: React.FC = () => {
  return (
    <div className="products-active">
      <p>Active Products</p>
      <MemberActiveProductItem productId="cake-smart-farm" />
      <MemberActiveProductItem productId="banana-smart-farm" />
      <MemberActiveProductItem productId="biswap-smart-farm" />
    </div>
  )
}

export default MemberActiveProducts
