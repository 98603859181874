import { SwapRates } from '@allcoinwallet/inkryptus-db-schema'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'

export function useForexRates(currency?: string): [{ [currency: string]: decimal } | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(currency ? db.child('forexRates').child(currency) : undefined)
  const forexRates = v.value?.rates
  return [forexRates, v.loading, v.error]
}

export function useSwapRates(): [SwapRates | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(db.child('swapRates'))
  return [v.value, v.loading, v.error]
}
