import React from 'react'

import ProfilePicture from './ProfilePicture.container'
import ProfileInfoForm from './ProfileInfoForm.container'

const ProfileInfoTab: React.SFC = () => {
  return (
    <div className="data-container">
      <div className="picture">
        <ProfilePicture />
      </div>
      <div className="data-user">
        <ProfileInfoForm />
      </div>
    </div>
  )
}

export default ProfileInfoTab
