import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { t } from '../../../i18n'
import { formatDatePlain, formatMsToDays, formatNumber, formatCurrency } from '../../../utils/format'

interface Props {
  activationTime?: number | null
  expirationTime?: number | null
  totalProfit?: decimal | null
  sponsoreds?: number | null
}
const StatusBar: React.SFC<Props> = (props) => {
  return (
    <>
      <div className="information-menu">
        {t('fields:productLicense.activationTime.label')}
        {': '}
        {props.activationTime ? formatDatePlain(props.activationTime) : '-'}
      </div>
      <div className="information-menu">
        {t('fields:productLicense.remainingTime.label')}
        {': '}
        {props.expirationTime ? formatMsToDays(props.expirationTime - Date.now()) : '-'}
      </div>
      <div className="information-menu">
        {t('fields:productMember.totalProfit.label')}
        {': '}
        {formatCurrency(props.totalProfit)}
      </div>
      <div className="information-menu">
        {t('fields:productMember.sponsoreds.label')}
        {': '}
        {formatNumber(props.sponsoreds)}
      </div>
    </>
  )
}

export default StatusBar
