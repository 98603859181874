import React from 'react'
import { useOtherMemberProductContractsRunning } from '../../hooks/product-contracts'
import CompanyStatus from './CompanyStatus.component'

interface Props {
  username: string
}

const CompanyMemberStatus: React.FunctionComponent<Props> = (props) => {
  const [contracts, contractsLoading] = useOtherMemberProductContractsRunning('cake-smart-farm', props.username)
  const loading = contractsLoading
  const status = contracts ? 'ACTIVE' : undefined
  return <CompanyStatus status={status} loading={loading} />
}

export default CompanyMemberStatus
