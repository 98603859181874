import React from 'react'
import { useUsername } from '../../hooks/current-user'
import ReferralLinkWidget from './ReferralLinkWidget.component'
import { usePlatform } from '../../hooks/capacitor'
import { useShareInvitationLinkModal } from '../../hooks/social-networks'
import { getReferralLink } from '../../utils/url'

const MemberReferralLink: React.FunctionComponent = () => {
  const platform = usePlatform()
  const { username } = useUsername()
  const { showModal: showShareInvitationLinkModal } = useShareInvitationLinkModal()

  const href = getReferralLink(platform, username)

  const onShare = React.useCallback(() => {
    if (!username) return
    showShareInvitationLinkModal(username)
  }, [username, showShareInvitationLinkModal])

  return <ReferralLinkWidget link={href} onShare={onShare} />
}

export default MemberReferralLink
