import React from 'react'
import BoxView, { BoxViewTab } from '../../components/box-view'
import { icons } from '../../assets'
import MemberWalletBalance from './MemberWalletBalance.container'
import MemberWalletTable from './MemberWalletTable.container'

const WalletPage: React.FunctionComponent = () => {
  return (
    <BoxView label="Wallet" icon={icons.iconWallet} className="box-financial-wallet">
      <BoxViewTab tabKey="balance" label="Balance">
        <MemberWalletBalance symbol="CAKE" />
        <MemberWalletBalance symbol="BANANA" />
        <MemberWalletBalance symbol="BSW" />
        <MemberWalletBalance symbol="USDT" />
        <MemberWalletBalance symbol="BUSD" />
        <MemberWalletBalance symbol="SHIB" />
      </BoxViewTab>
      <BoxViewTab tabKey="historic" label="Historic">
        <MemberWalletTable />
      </BoxViewTab>
    </BoxView>
  )
}

export default WalletPage
