import React from 'react'
import FinishProductContractModal from './FinishProductContractModal.container'

export interface FinishProductContractModalContextValues {
  contractId?: string | null
  showModal: (contractId: string) => void
  closeModal: () => void
}
export const FinishProductContractModalContext = React.createContext<FinishProductContractModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const FinishProductContractModalProvider: React.FunctionComponent = (props) => {
  const [showingFinishProductContractId, setShowingFinishProductContractId] = React.useState<string | null>(null)
  const showModal = (contractId: string) => {
    setShowingFinishProductContractId(contractId)
  }
  const closeModal = () => {
    setShowingFinishProductContractId(null)
  }

  return (
    <FinishProductContractModalContext.Provider value={{ contractId: showingFinishProductContractId, showModal, closeModal }}>
      <FinishProductContractModal contractId={showingFinishProductContractId} onClose={closeModal} />
      {props.children}
    </FinishProductContractModalContext.Provider>
  )
}

export default FinishProductContractModalContext
