import React from 'react'
import { t } from '../../i18n'
import { useUsername } from '../../hooks/current-user'
import { useMemberNetwork, useMemberNetworkSummary } from '../../hooks/network'
import { icons } from '../../assets/'

import LastInvitationsTable from './LastInvitationsTable.container'
import MemberNetworkActivityChart from './MemberNetworkActivityChart.container'

const MemberNetworkHistory: React.FunctionComponent = () => {
  const { username, loading: usernameLoading } = useUsername()
  const [memberNetwork, memberNetworkLoading] = useMemberNetwork(8, username)
  const networkSummary = useMemberNetworkSummary(username, memberNetwork)

  const loading = usernameLoading || memberNetworkLoading
  if (loading) return null

  const referrals = networkSummary ? networkSummary.directsCount + networkSummary.indirectsCount : 0
  if (referrals === 0)
    return (
      <div className="empty-referrals">
        <div>
          <div>
            <img src={icons.emptyReferral} />
          </div>
          <div>
            <p>{t('desk:network_page.empty_referral')}</p>
          </div>
        </div>
      </div>
    )

  return (
    <div className="history-referral">
      <div className="chart-referral">
        <MemberNetworkActivityChart />
      </div>
      <div className="last-registers">
        <div>{t('desk:network_page.tab_overview.last_invitations_widget.title')}</div>
        <LastInvitationsTable />
      </div>

    </div>
  )
}

export default MemberNetworkHistory
