import { sha3_256 } from 'js-sha3'

export function isSimpleEthAddress(address: string) {
  return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address)
}

export function isEthAddress(address: string) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    // check if it has the basic requirements of an address
    console.log('fails here')
    return false
  } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
    // If it's all small caps or all all caps, return true
    return true
  } else {
    // Otherwise check each case
    console.log('checksum checking')
    return isChecksumAddress(address)
  }
}

function isChecksumAddress(address: string) {
  // Check each case
  address = address.replace('0x', '')
  var addressHash = sha3_256(address.toLowerCase())
  for (var i = 0; i < 40; i++) {
    // the nth letter should be uppercase if the nth digit of casemap is 1
    if (
      (parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])
    ) {
      console.log('fails here at checksum')
      return false
    }
  }
  return true
}
