import React from 'react'
import SwapSymbolSelectorModal from './SwapSymbolSelectorModal.container'

export interface SwapSymbolSelectorModalContextValues {
  currentSymbol?: string | null
  showModal: (currentSymbol: string, onSelect: (symbol: string) => void) => void
  closeModal: () => void
}
export const SwapSymbolSelectorModalContext = React.createContext<SwapSymbolSelectorModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const SwapSymbolSelectorModalProvider: React.FunctionComponent = (props) => {
  const [showingCurrentSymbol, setShowingCurrentSymbol] = React.useState<string | null>(null)
  const [onSelectCallback, setOnSelectCallback] = React.useState<{ cb: (symbol: string) => void }>({
    cb: (symbol) => {
      console.warn('initial empty onSelect callback', { symbol })
    },
  })
  const showModal = React.useCallback(
    (currentSymbol: string, onSelect: (symbol: string) => void) => {
      setShowingCurrentSymbol(currentSymbol)
      setOnSelectCallback({ cb: onSelect })
    },
    [setShowingCurrentSymbol, setOnSelectCallback]
  )
  const closeModal = React.useCallback(() => {
    setShowingCurrentSymbol(null)
    setOnSelectCallback({
      cb: (symbol) => {
        console.warn('empty onSelect callback', { symbol })
      },
    })
  }, [setShowingCurrentSymbol, setOnSelectCallback])

  return (
    <SwapSymbolSelectorModalContext.Provider value={{ currentSymbol: showingCurrentSymbol, showModal, closeModal }}>
      <SwapSymbolSelectorModal currentSymbol={showingCurrentSymbol} onClose={closeModal} onSelect={onSelectCallback.cb} />
      {props.children}
    </SwapSymbolSelectorModalContext.Provider>
  )
}

export default SwapSymbolSelectorModalContext
