import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, AuthPersistence } from '../../services/firebase'
import { t } from '../../i18n'
import alrt from '../../utils/alrt'

import { resetPassword } from '../../services/api'
import FormPasswordReset from './FormPasswordReset.component'

const ResetPassword: React.FunctionComponent<RouteComponentProps> = props => {
  const [jwt, setJwt] = React.useState<string | null>(null)
  const { initialising, user } = useAuthState(auth)
  const [loading, setLoading] = React.useState<boolean>(false)

  // persist jwt from query params
  const urlParamJwt = new URLSearchParams(window.location.search).get('reset_pwd_jwt')
  React.useEffect(() => {
    if (!urlParamJwt) return
    setJwt(urlParamJwt)
  }, [urlParamJwt])

  React.useEffect(() => {
    if (!jwt) return
    if (!loading) setLoading(true)
    if (initialising) return
    ;(async () => {
      try {
        await auth.setPersistence(AuthPersistence.NONE)
        await auth.signInWithCustomToken(jwt)
      } catch (error) {
        console.error(error)
        if (error.humanizedMessage) alrt.error({ body: error.humanizedMessage })
        else alrt.error({ body: t('errors:auth_invalid_jwt') as string })
      }
      setLoading(false)
    })()
    setJwt(null)
  }, [jwt, user, initialising])

  const onReset = async (password: string) => {
    const idToken = user && (await user.getIdToken())
    if (!idToken) return
    await resetPassword(idToken, password)
  }
  const onClickBack = () => {
    auth.signOut()
    props.history.push('/')
  }
  return <FormPasswordReset onReset={onReset} onClickBack={onClickBack} />
}

export default withRouter(ResetPassword)
