import React from 'react'
import { reduce } from 'lodash'
import { decimal, Decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'
import { MemberEarningsSummary } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useDatabaseObject, EarningType, Query, MemberEarningsAmountStats } from './database'
import { useUid } from './current-user'

export function useMemberEarningsStats(uid: string | null, type: EarningType, sponsoredLevel?: number): decimal | null {
  let query: Query<MemberEarningsAmountStats> | undefined
  if (uid) {
    const statsRef = db.child('stats').child('memberEarningsAmount')
    let indexPath = '_indexes/uid__type'
    let indexVal = `${uid}__${type}`
    if (sponsoredLevel !== undefined) {
      indexPath += '__sponsoredLevel'
      indexVal += `__${sponsoredLevel}`
    }
    query = statsRef.orderByChild(`${indexPath}__time`).startAt(`${indexVal}__0000000000000`).endAt(`${indexVal}__9999999999999`)
  }
  const stats = useDatabaseObject(query)
  const sum = React.useMemo(() => {
    if (stats.loading) return null
    if (!stats.value) return Decimal(0)
    const s = reduce(stats.value, (acc, curr) => add(acc, curr.value), Decimal(0))
    return s
  }, [stats.value, stats.loading])
  return sum
}

export function useMemberEarningsStatsBySymbol(uid: string | null, type: EarningType, sponsoredLevel?: number): { [symbol: string]: decimal } {
  let query: Query<MemberEarningsAmountStats> | undefined
  if (uid) {
    const statsRef = db.child('stats').child('memberEarningsAmount')
    let indexPath = '_indexes/uid__type'
    let indexVal = `${uid}__${type}`
    if (sponsoredLevel !== undefined) {
      indexPath += '__sponsoredLevel'
      indexVal += `__${sponsoredLevel}`
    }
    query = statsRef.orderByChild(`${indexPath}__time`).startAt(`${indexVal}__0000000000000`).endAt(`${indexVal}__9999999999999`)
  }
  const stats = useDatabaseObject(query)
  const sum: { [symbol: string]: decimal } = React.useMemo(() => {
    if (stats.loading) return {}
    if (!stats.value) return {}
    const acc: { [symbol: string]: decimal } = {}
    if (stats.value) {
      for (const key in stats.value) {
        const curr = stats.value[key]
        const symbol = curr.symbol || 'CAKE'
        acc[symbol] = add(acc[symbol] || Decimal(0), curr.value)
      }
    }
    return acc
  }, [stats.value, stats.loading])
  return sum
}

export function useMemberTotalProfit(): decimal | null {
  const [uid] = useUid()
  const totalSalesProfit = useMemberEarningsStats(uid, 'sale')
  const totalBonusProfit = useMemberEarningsStats(uid, 'bonus')
  if (!totalSalesProfit) return totalBonusProfit || null
  if (!totalBonusProfit) return totalSalesProfit || null
  return add(totalSalesProfit, totalBonusProfit)
}

export function useMemberEarningsSummary(
  username?: string,
  symbol?: string,
  earningType?: EarningType
): [MemberEarningsSummary | undefined, boolean] {
  const { value, loading } = useDatabaseObject(
    username && earningType && symbol
      ? db.child('stats').child('membersEarningsSummary').child(username).child(symbol).child(earningType)
      : undefined
  )
  return [value, loading]
}
