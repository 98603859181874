import React from 'react'
import ProductPlansModal from './ProductPlansModal.container'

export interface ProductPlansModalContextValues {
  productId?: string | null
  showModal: (productId: string) => void
  closeModal: () => void
}
export const ProductPlansModalContext = React.createContext<ProductPlansModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const ProductPlansModalProvider: React.FunctionComponent = props => {
  const [showingProductPlansProductId, setShowingProductPlansProductId] = React.useState<string | null>(null)
  const showModal = (productId: string) => setShowingProductPlansProductId(productId)
  const closeModal = () => setShowingProductPlansProductId(null)

  return (
    <ProductPlansModalContext.Provider value={{ productId: showingProductPlansProductId, showModal, closeModal }}>
      <ProductPlansModal productId={showingProductPlansProductId} onClose={closeModal} />
      {props.children}
    </ProductPlansModalContext.Provider>
  )
}

export default ProductPlansModalContext
