import React from 'react'
import ProductOverviewModal from './ProductOverviewModal.container'

export interface ProductOverviewModalContextValues {
  productId?: string | null
  showModal: (productId: string) => void
  closeModal: () => void
}
export const ProductOverviewModalContext = React.createContext<ProductOverviewModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const ProductOverviewModalProvider: React.FunctionComponent = props => {
  const [showingProductOverviewProductId, setShowingProductOverviewProductId] = React.useState<string | null>(null)
  const showModal = (productId: string) => setShowingProductOverviewProductId(productId)
  const closeModal = () => setShowingProductOverviewProductId(null)

  return (
    <ProductOverviewModalContext.Provider value={{ productId: showingProductOverviewProductId, showModal, closeModal }}>
      <ProductOverviewModal productId={showingProductOverviewProductId} onClose={closeModal} />
      {props.children}
    </ProductOverviewModalContext.Provider>
  )
}

export default ProductOverviewModalContext
