import * as React from 'react'
import { useState } from 'react'

import formatDate from '../../utils/format-date'

import Input from '../shared/input'

interface Props {
  columns: string[]
  rows: {}[]
  searchable?: boolean
  tableTitle?: string
}

const Table: React.FunctionComponent<Props> = props => {
  const [filteredResult, setFilteredResult] = useState(props.rows)

  const rows = props.searchable ? filteredResult : props.rows

  const filterResult = (term: string) => {
    const result = props.rows.filter(row => {
      return props.columns.find(column => row[column].toLowerCase().includes(term))
    })
    setFilteredResult(result)
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterResult(formatDate(e.target.value))
  }

  return (
    <>
      {props.searchable ? (
        <div>
          <div className="title">
            <p>{props.tableTitle}</p>
          </div>
          <div className="filter">
            <div>
              <div className="input-small-icon i-stroke">
                <select>
                  <option value="volvo">Volvo</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
              </div>
            </div>
            <div>
              <p>De:</p>
              <div className="input-small i-stroke">
                <Input type="date" onChange={e => handleDateChange(e)} />
              </div>
            </div>
            <div>
              <p>Até:</p>
              <div className="input-small i-stroke">
                <Input type="date" onChange={e => handleDateChange(e)} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <> </>
      ) //precisei fazer esse if ternario devido ao layout que o lucas fez, se não tiver uma div aqui a tabela irá quebrar
      }
      <div>
        <table>
          <thead>
            <tr>
              {props.columns.map((header, index) => {
                return <th key={index}>{header}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              return (
                <tr key={index}>
                  {props.columns.map((column, index) => {
                    return <td key={index}>{row[column]}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Table
