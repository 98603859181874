import copy from 'copy-to-clipboard'

const isFirefox = navigator.userAgent.indexOf('Firefox') > -1

export function copyToClipboard(text: string): void {
  copy(text)
  if (isFirefox) {
    const input = document.createElement('input')
    input.setAttribute('id', 'copy_to_clipboard_input')
    input.setAttribute('type', 'text')
    input.setAttribute('value', text)
    input.setAttribute('style', 'opacity: 0;')
    document.body.append(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }
}
