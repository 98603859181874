import React from 'react'
import Modal from 'react-modal'
import { useUid } from '../../hooks/current-user'
import { useSwapWalletTransaction } from '../../hooks/swap-wallets'
import { processSwapOfSwapWallet } from '../../services/api'
import SwapProcessModal, { SwapNewProcessFormState, SwapProcessStep } from './SwapProcess.component'

export interface SwapProcessModalContextValues {
  showing: boolean
  step?: SwapProcessStep
  showNewProcessModal: (newFormState: SwapNewProcessFormState) => void
  showModal: (transactionId: string) => void
  closeModal: () => void
}
export const SwapProcessModalContext = React.createContext<SwapProcessModalContextValues>({
  showing: false,
  showNewProcessModal: () => {
    console.warn('initial empty showNewProcessModal fn')
  },
  showModal: () => {
    console.warn('initial empty showModal fn')
  },
  closeModal: () => {
    console.warn('initial empty closeModal fn')
  },
})

export const SwapProcessModalProvider: React.FunctionComponent = (props) => {
  const [modalState, setModalState] = React.useState<[string | undefined, SwapNewProcessFormState | undefined]>([undefined, undefined])
  const [succeded, setSucceded] = React.useState(false)
  const [failureReason, setFailureReason] = React.useState('')

  const showNewProcessModal = React.useCallback(
    (newFormState: SwapNewProcessFormState) => {
      setModalState([undefined, newFormState])
    },
    [setModalState]
  )
  const showModal = React.useCallback(
    (transactionId: string) => {
      setModalState([transactionId, undefined])
    },
    [setModalState]
  )
  const closeModal = React.useCallback(() => {
    setModalState([undefined, undefined])
    setSucceded(false)
    setFailureReason('')
  }, [setModalState, setSucceded, setFailureReason])

  const [transactionId, newFormState] = modalState
  const showing = !!transactionId || !!newFormState

  const [uid, uidLoading] = useUid()
  const [transaction, transactionLoading] = useSwapWalletTransaction(uid ? transactionId : undefined)
  const loading = transactionId ? uidLoading || transactionLoading : false
  console.log('loading', loading)

  const fromSymbol = transaction?.inSymbol || newFormState?.fromSymbol
  const toSymbol = transaction?.symbol || newFormState?.toSymbol
  const activeField = newFormState?.activeField
  const fromAmount = transaction?.inAmount || newFormState?.fromAmount
  const toAmount = transaction?.amount || newFormState?.toAmount

  const [processing, setProcessing] = React.useState(false)
  const confirmProcess = React.useCallback(async () => {
    if (processing) return
    if (!fromSymbol || !toSymbol || !activeField || !fromAmount || !toAmount) return
    setProcessing(true)
    try {
      await processSwapOfSwapWallet(fromSymbol, toSymbol, {
        activeField,
        fromAmount,
        toAmount,
      })
      setSucceded(true)
    } catch (error) {
      console.error('Failed to process swap', error)
      setFailureReason('Falha desconhecida')
    }
    setProcessing(false)
  }, [fromSymbol, toSymbol, activeField, fromAmount, toAmount, processing, setProcessing, setFailureReason, setSucceded])

  const step: SwapProcessStep | undefined = succeded
    ? 'FINISHED'
    : newFormState
    ? processing
      ? 'PROCESSING'
      : failureReason
      ? 'FAILED'
      : 'CONFIRMING'
    : undefined

  return (
    <SwapProcessModalContext.Provider value={{ showing, showNewProcessModal, showModal, closeModal }}>
      <Modal isOpen={showing} onRequestClose={closeModal} portalClassName="modal-portal" overlayClassName="overlay" className="box-swap-state">
        {!!step && <SwapProcessModal {...newFormState} step={step} onConfirmClick={confirmProcess} onCloseClick={closeModal} />}
      </Modal>
      {props.children}
    </SwapProcessModalContext.Provider>
  )
}

export default SwapProcessModalContext
