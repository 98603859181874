import React from 'react'
import { useUsername } from '../../../hooks/current-user'
import { useActiveProductLicense } from '../../../hooks/products'
import { useMemberNetwork, useMemberNetworkSummary } from '../../../hooks/network'
import { useMemberTotalProfit } from '../../../hooks/earnings'
import StatusBar from './StatusBar.component'

const InkryptusStatusBar: React.FunctionComponent = () => {
  const { username, loading: usernameLoading } = useUsername()
  const [productLicense, productLicenseLoading] = useActiveProductLicense('tradesoftware')
  const [memberNetwork, memberNetworkLoading] = useMemberNetwork(7, username)
  const networkSummary = useMemberNetworkSummary(username, memberNetwork)
  const totalProfit = useMemberTotalProfit()
  const loading = usernameLoading || productLicenseLoading || memberNetworkLoading
  if (loading) return null
  if (!productLicense) return null
  return (
    <StatusBar
      activationTime={productLicense.activationTime}
      expirationTime={productLicense.expirationTime}
      totalProfit={totalProfit}
      sponsoreds={networkSummary && networkSummary.directsCount + networkSummary.indirectsCount}
    />
  )
}

export default InkryptusStatusBar
