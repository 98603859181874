import React from 'react'
import { t } from '../../i18n'
import { logo } from '../../assets'
import { useFormErrors } from '../../hooks/form-errors'
import { Input, FormErrorsList, Button } from '../../components/shared'
import FinishedPasswordReset from './FinishedPasswordReset.component'

interface Props {
  onReset: (password: string) => Promise<void> | void
  onClickBack: () => void
}
const FormPasswordReset: React.FunctionComponent<Props> = props => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [finished, setFinished] = React.useState<boolean>(false)
  const [password, setPassword] = React.useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = React.useState<string>('')

  const [errorMessages, setErrorMessages] = React.useState<string[]>([])
  const [formErrors, setFormErrors, solveFieldError] = useFormErrors()
  const withOnBlur = (fieldPath: string) => () => solveFieldError(fieldPath)

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }
  const handlePasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.target.value)
  }

  const onRequest = async () => {
    if (loading || !password || !passwordConfirmation || finished) return
    setFormErrors({})
    if (password !== passwordConfirmation) return setErrorMessages([t('auth:reset_password.password_not_matches_confirmation')])
    setLoading(true)
    setErrorMessages([])
    try {
      await props.onReset(password)
      setFinished(true)
    } catch (error) {
      if (error.formErrors) setFormErrors(error.formErrors)
      else if (error.humanizedMessage) setErrorMessages([error.humanizedMessage])
      else setErrorMessages([t('alrt:common.error_body')])
    }
    setLoading(false)
  }

  const disabledButton = loading || !password || !passwordConfirmation || finished

  return (
    <div className="box-password-recovery">
        {finished ? (
          <FinishedPasswordReset />
        ) : (
          <div className="password-recovery-change">
            <div>
              <img src={logo.inkryptus.regularDark} />
            </div>
            <div>
              <p>{t('auth:reset_password.page_title')}</p>
              <p>{t('auth:reset_password.instruction')}</p>
              <div className="input-large i-stroke">
                <Input
                  type="password"
                  placeholder={t('auth:reset_password.password_field.placeholder')}
                  value={password}
                  onChange={handlePasswordChange}
                  onBlur={withOnBlur('password')}
                />
              </div>
              <div className="input-large i-stroke">
                <Input
                  type="password"
                  placeholder={t('auth:reset_password.password_confirmation_field.placeholder')}
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  onBlur={withOnBlur('password')}
                />
              </div>

              <Button size="large" type="primary" onClick={onRequest} disabled={disabledButton} loading={loading}>
                {t('auth:reset_password.button.label')}
              </Button>
              <a onClick={props.onClickBack} href="javascript:void(0)">
                {t('auth:request_reset_password.back_link.label')}
              </a>
              <FormErrorsList messages={errorMessages} formErrors={formErrors} itemClassName="response-signup" />
            </div>
          </div>
        )}
    </div>
  )
}

export default FormPasswordReset
