import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import TreeChart from '../../components/tree-chart'
// import { formatNumber } from '../../utils/format'
import { t } from '../../i18n'
import * as SvgIcons from '../../../src/components/shared/svg-icons'
// import { DecimalFormatter } from '../../components/shared'

export interface TreeItem {
  name: string
  children: TreeItem[]
}

interface Props {
  directReferralsCount?: number | null
  indirectReferralsCount?: number | null
  stakedAmount?: decimal | null
  tree: TreeItem
  onClickNode: (item: TreeItem) => void
}

const NetworkGraph: React.FunctionComponent<Props> = (props) => {
  const [fullscreen, setFullscreen] = React.useState<boolean>(false)
  const openFullscreen = React.useCallback(() => {
    if (fullscreen) return
    setFullscreen(true)
  }, [fullscreen, setFullscreen])
  const closeFullscreen = React.useCallback(() => {
    if (!fullscreen) return
    setFullscreen(false)
  }, [fullscreen, setFullscreen])

  return (
    <div className={`graph ${fullscreen ? 'fullscreen' : ''}`}>
      <div>
        <div>
          <p>{t('desk:network_page.tab_graph.graph_widget.title')}</p>
          <div>
            <span>Beta</span>
          </div>
        </div>
        <div>
          {/* <div>
            <p>Direct</p>
            <p>{formatNumber(props.directReferralsCount)}</p>
          </div>
          <div>
            <p>Indirect</p>
            <p>{formatNumber(props.indirectReferralsCount)}</p>
          </div>
          <div>
            <p>{t('common:networkCakeAmount', 'Network CAKE')}</p>
            <p>{props.stakedAmount ? <DecimalFormatter value={props.stakedAmount} /> : '-'}</p>
          </div> */}
        </div>
      </div>
      <TreeChart
        tree={props.tree}
        id="tree-chart"
        size={2000}
        aspectRatio={1}
        isSorted={true}
        widthBetweenNodesCoeff={2.5}
        heightBetweenNodesCoeff={5}
        onClickText={props.onClickNode}
        margin={{
          top: 10,
          left: 10,
          right: 10,
          bottom: 10,
        }}
        style={{
          border: 'none',
          node: {
            colors: {
              default: '#FF4F4F',
              parent: '#F6891E',
              collapsed: '#27AE60',
            },
            radius: 10,
          },
          text: {
            colors: {
              default: 'white',
              hover: '#ccc',
            },
          },
          link: {
            stroke: '#777',
            fill: 'none',
          },
        }}
        tooltipOptions={{ disabled: true }}
      />
      <div className="open-fullscreen" onClick={openFullscreen}>
        <p>{t('desk:network_page.tab_graph.graph_widget.open_fullscreen_button_label')}</p>
        <SvgIcons.FullscreenIcon />
      </div>
      <div className="close-fullscreen" onClick={closeFullscreen}>
        <SvgIcons.CloseIcon />
        <p>{t('desk:network_page.tab_graph.graph_widget.close_fullscreen_button_label')}</p>
      </div>
    </div>
  )
}

export default NetworkGraph
