import { useState, useEffect, useContext } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, AuthPersistence } from '../services/firebase'
import { reportTokenRecognition } from '../services/api'
import alrt from '../utils/alrt'
import { t } from '../i18n'
import { WalletWithdrawalRequestModalContext } from '../modals/wallet-withdrawal-request'
import { SwapWalletWithdrawalRequestModalContext } from '../modals/swap-wallet-withdrawal-request'

export function useReportTokenRecognition(): boolean {
  const [jwt, setJwt] = useState<string | null>(null)
  const { initialising, user } = useAuthState(auth)
  const [loading, setLoading] = useState<boolean>(false)

  const { showModal: showWithdrawalRequestModal } = useContext(WalletWithdrawalRequestModalContext)
  const { showModal: showSwapWithdrawalRequestModal } = useContext(SwapWalletWithdrawalRequestModalContext)

  // persist jwt from query params
  const urlParamJwt = new URLSearchParams(window.location.search).get('jwt')
  useEffect(() => {
    if (!urlParamJwt) return
    setJwt(urlParamJwt)
  }, [urlParamJwt])

  useEffect(() => {
    if (!jwt) return
    if (!loading) setLoading(true)
    if (initialising) return
    ;(async () => {
      try {
        await auth.setPersistence(AuthPersistence.SESSION)
        const decodedToken = await auth.signInWithCustomToken(jwt)
        if (!decodedToken.user) return
        const idToken = await decodedToken.user.getIdToken()
        const { actions } = await reportTokenRecognition(idToken)
        if (actions && actions.confirmWithdrawal) {
          const transactionId: string = actions.confirmWithdrawal
          showWithdrawalRequestModal(transactionId)
        }
        if (actions && actions.confirmSwapWithdrawal) {
          const transactionId: string = actions.confirmSwapWithdrawal
          showSwapWithdrawalRequestModal(transactionId)
        }
      } catch (error) {
        console.error(error)
        if ((error as any).humanizedMessage) alrt.error({ body: (error as any).humanizedMessage })
        else alrt.error({ body: t('errors:auth_invalid_jwt') as string })
      }
      setLoading(false)
    })()
    setJwt(null)
  }, [jwt, user, initialising, showWithdrawalRequestModal])
  return loading
}
