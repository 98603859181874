import React from 'react'
import ShareInvitationLink from './ShareInvitationLink.component'

export interface ShareInvitationLinkModalContextValues {
  username?: string | null
  showModal: (username: string) => void
  closeModal: () => void
}
export const ShareInvitationLinkModalContext = React.createContext<ShareInvitationLinkModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const ShareInvitationLinkModalProvider: React.FunctionComponent = props => {
  const [showingShareInvitationLinkUsername, setShowingShareInvitationLinkUsername] = React.useState<string | null>(null)
  const showModal = (username: string) => setShowingShareInvitationLinkUsername(username)
  const closeModal = () => setShowingShareInvitationLinkUsername(null)

  return (
    <ShareInvitationLinkModalContext.Provider value={{ username: showingShareInvitationLinkUsername, showModal, closeModal }}>
      {showingShareInvitationLinkUsername && <ShareInvitationLink username={showingShareInvitationLinkUsername} onClose={closeModal} />}
      {props.children}
    </ShareInvitationLinkModalContext.Provider>
  )
}

export default ShareInvitationLinkModalContext
