import * as React from 'react'
import { icons } from '../../assets'
import { useNotificationBody } from '../../hooks/notifications'
import { humanizeTimeFromNow } from '../../utils/format'
import { markNotificationAsRead } from '../../services/api'

export interface NotificationListItemProps {
  notificationId: string
  memberNotificationId: string
  read?: boolean
  expanded?: boolean
  onClick?: (notificationId: string) => any
}

export const NotificationListItem: React.FC<NotificationListItemProps> = React.memo(
  ({ notificationId, memberNotificationId, expanded, read, onClick }) => {
    const propagateClick = React.useCallback(() => {
      if (onClick) onClick(notificationId)
    }, [onClick, notificationId])

    const [notification] = useNotificationBody(notificationId)
    const notificationBody = notification?.body

    React.useEffect(() => {
      if (!notificationBody) return
      if (!memberNotificationId) return
      if (!expanded) return
      if (read) return
      markNotificationAsRead(memberNotificationId).catch((error) => {
        console.error('Failed to mark notification as readed', notificationId, error)
      })
    }, [notificationBody, memberNotificationId, notificationId, expanded, read, memberNotificationId])

    if (!notification) return null
    return (
      <div className={`notification ${expanded ? 'active' : ''}`} onClick={propagateClick}>
        {expanded && <img src={icons.closeNotification} alt="close" />}
        {!expanded && <img src={icons.openNotification} alt="open" />}
        <div>
          <p>{notification.title}</p>
          <p>{humanizeTimeFromNow(notification.deliveryTime)}</p>
          <div dangerouslySetInnerHTML={{ __html: notification.body }} />
        </div>
      </div>
    )
  }
)
