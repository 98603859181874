import * as React from 'react'

import { t } from '../../../i18n'
import { logo, icons } from '../../../assets/'
import { Button, FormErrorsList, Input } from '../../shared'
import { useMemberProfile } from '../../../hooks/profile'

interface Props {
  resendEnabled?: boolean
  onResend: () => Promise<void>
  onChangeEmail: (email: string) => Promise<void>
  onClickExit: () => Promise<void>
}
const SignupEmailVerification: React.FunctionComponent<Props> = (props) => {
  const [resending, setResending] = React.useState<boolean>(false)
  const [editing, setEditing] = React.useState<boolean>(false)
  const [errorMessages, setErrorMessages] = React.useState<string[]>([])

  const [email, setEmail] = React.useState<string>('')
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const startEmailEditing = () => setEditing(true)
  const [profile] = useMemberProfile()
  const profileEmail = profile ? profile.email : null
  React.useEffect(() => {
    if (!profileEmail) return
    if (editing) setEditing(false)
    setEmail(profileEmail)
  }, [profileEmail])

  const finishEditing = async () => {
    if (profileEmail === email) return
    await props.onChangeEmail(email)
  }

  const [reportNotReceive, setReportNotReceive] = React.useState<boolean>(false)
  const onReportNotReceive = () => {
    setReportNotReceive(true)
  }

  const onResend = async () => {
    if (resending) return
    setResending(true)
    setErrorMessages([])
    try {
      await finishEditing()
      await props.onResend()
      setReportNotReceive(false)
    } catch (error) {
      if (error.humanizedMessage) setErrorMessages([error.humanizedMessage])
    }
    if (editing) setEditing(false)
    setResending(false)
  }

  return (
    <div className={reportNotReceive ? 'box-signup-resend' : 'box-signup-end'}>
        <div>
          <div>
            <img src={logo.inkryptus.regularDark} />
          </div>
          {reportNotReceive ? (
            <div>
              <p>{t('auth:sign_up_email_resend.page_title')}</p>
              <p>{t('auth:sign_up_email_resend.email_instruction')}</p>
              <div className="input-large-icon i-stroke">
                <Input type="email" value={email} onChange={onChangeEmail} disabled={!editing || resending} />
                {!editing && <img src={icons.edit} onClick={startEmailEditing} />}
              </div>
              <p>{t('auth:sign_up_email_resend.resend_instruction')}</p>
              <Button size="large" type="primary" onClick={onResend} disabled={resending} loading={resending}>
                {t('auth:sign_up_email_resend.resend_button.label')}
              </Button>
              <a onClick={props.onClickExit} href="javascript:void(0)">
                {t('auth:sign_up_email_resend.exit_link.label')}
              </a>
            </div>
          ) : (
            <>
              <div>
                <p>{t('auth:sign_up_email_verification.page_title')}</p>
                <div>
                  <img src={icons.emailSend} />
                  <p>{t('auth:sign_up_email_verification.instruction')}</p>
                </div>
              </div>
              <div>
                <a onClick={onReportNotReceive} href="javascript:void(0)">
                  {t('auth:sign_up_email_verification.resend_link.label')}
                </a>
              </div>
            </>
          )}
          <FormErrorsList messages={errorMessages} itemClassName="response-signup" />
        </div>
    </div>
  )
}

export default SignupEmailVerification
