const icons = {
  // header menu
  arrowDownOLD: require('./images/icons/header/arrow-down.png'),
  arrowDown2xOLD: require('./images/icons/header/arrow-down@2x.png'),
  arrowDown3xOLD: require('./images/icons/header/arrow-down@3x.png'),
  dayTheme: require('./images/icons/header/day-theme.png'),
  dayTheme2x: require('./images/icons/header/day-theme@2x.png'),
  dayTheme3x: require('./images/icons/header/day-theme@3x.png'),
  nightTheme: require('./images/icons/header/night-theme.png'),
  nightTheme2x: require('./images/icons/header/night-theme@2x.png'),
  nightTheme3x: require('./images/icons/header/night-theme@3x.png'),
  logout: require('./images/icons/header/logout.svg'),
  signout: require('./images/icons/header/logout.png'),
  signout2x: require('./images/icons/header/logout@2x.png'),
  signout3x: require('./images/icons/header/logout@3x.png'),
  perfil: require('./images/icons/header/perfil.png'),
  bscNetwork: require('./images/icons/desk/shared/bsc-network.svg'),
  historicSwap: require('./images/icons/header/historic-swap.svg'),
  notification: require('./images/icons/header/notification.svg'),
  menuOpen: require('./images/icons/header/menu-open.svg'),
  menuClose: require('./images/icons/header/menu-close.svg'),

  // country flags
  flagEn: require('./images/icons/flags/en.png'),
  flagEn2x: require('./images/icons/flags/en@2x.png'),
  flagEn3x: require('./images/icons/flags/en@3x.png'),
  flagBr: require('./images/icons/flags/br.png'),
  flagBr2x: require('./images/icons/flags/br@2x.png'),
  flagBr3x: require('./images/icons/flags/br@3x.png'),
  flagEs: require('./images/icons/flags/es.png'),
  flagEs2x: require('./images/icons/flags/es@2x.png'),
  flagEs3x: require('./images/icons/flags/es@3x.png'),
  flagRu: require('./images/icons/flags/ru.png'),
  flagRu2x: require('./images/icons/flags/ru@2x.png'),
  flagRu3x: require('./images/icons/flags/ru@3x.png'),

  //icons products
  fxchrome: require('./images/icons/products/fxchrome.png'),
  tradesoftware: require('./images/icons/products/tradesoftware.png'),
  ignisight: require('./images/icons/products/ignisight.png'),
  mvts: require('./images/icons/products/mvts.png'),
  sevencapital: require('./images/icons/products/sevencapital.png'),

  //shared
  downloadOld: require('./images/icons/desk/shared/download.png'),
  error: require('./images/icons/desk/shared/error.png'),
  info: require('./images/icons/desk/shared/info.png'),
  menuMobile: require('./images/icons/desk/shared/menu-mobile.png'),
  play: require('./images/icons/desk/shared/play.png'),
  success: require('./images/icons/desk/shared/success.png'),
  successPrimary: require('./images/icons/desk/shared/success-primary.svg'),
  edit: require('./images/icons/desk/shared/edit.png'),
  close: require('./images/icons/desk/shared/close.png'),
  profitfee: require('./images/icons/desk/shared/profitfee.png'),
  warning: require('./images/icons/desk/shared/warning.svg'),
  message: require('./images/icons/desk/shared/message.svg'),
  arrowDown: require('./images/icons/desk/shared/arrow-down.svg'),
  arrowLeft: require('./images/icons/desk/shared/arrow-left.svg'),
  attentionRed: require('./images/icons/desk/shared/attention-red.svg'),
  arrowTable: require('./images/icons/desk/shared/arrow-table.svg'),
  download: require('./images/icons/desk/shared/download.svg'),
  iconContract: require('./images/icons/desk/shared/icon-contract.svg'),
  iconProduct: require('./images/icons/desk/shared/icon-product.svg'),
  iconWallet: require('./images/icons/desk/shared/icon-wallet.svg'),
  iconFinancial: require('./images/icons/desk/shared/icon-financial.svg'),
  iconNetwork: require('./images/icons/desk/shared/icon-network.svg'),
  iconProfile: require('./images/icons/desk/shared/icon-profile.svg'),
  iconMarketing: require('./images/icons/desk/shared/icon-marketing.svg'),
  iconSupport: require('./images/icons/desk/shared/icon-support.svg'),
  iconNotification: require('./images/icons/desk/shared/icon-notification.svg'),

  //pages
  emailSend: require('./images/icons/desk/pages/email-send-signup.png'),
  emptyReferral: require('./images/icons/desk/pages/empty-referral.png'),
  pdfLicense: require('./images/icons/desk/pages/pdf-license.png'),
  profile: require('./images/icons/desk/pages/profile.png'),
  qrWalletAcwPayment: require('./images/icons/desk/pages/qr-wallet-payment.png'),
  terms: require('./images/icons/desk/pages/terms.png'),
  walletAcwPending: require('./images/icons/desk/pages/wallet-acw-pending.png'),
  walletAcw: require('./images/icons/desk/pages/wallet-acw.png'),
  editPhoto: require('./images/icons/desk/pages/photo-edit-profile.png'),
  profitFee: require('./images/icons/desk/pages/profit-fee.png'),
  bscIcon: require('./images/icons/desk/pages/bsc.svg'),
  qrCode: require('./images/icons/desk/pages/qr-code.svg'),
  changeSwap: require('./images/icons/desk/pages/change-swap.svg'),
  successSwap: require('./images/icons/desk/pages/success-swap.svg'),
  errorSwap: require('./images/icons/desk/pages/error-swap.svg'),
  openNotification: require('./images/icons/desk/pages/open-notification.svg'),
  closeNotification: require('./images/icons/desk/pages/close-notification.svg'),
}

const iconsMenu = {
  productIcon: require('./images/icons/desk/products.png'),
}

const tokens = {
  cakeLogo: require('./images/products/cake-logo.svg'),
  usdtLogo: require('./images/products/usdt-logo.svg'),
  bananaLogo: require('./images/products/banana-logo.svg'),
  bnbLogo: require('./images/products/bnb-logo.svg'),
  busdLogo: require('./images/products/busd-logo.svg'),
  shibLogo: require('./images/products/shib-logo.svg'),
  biswapLogo: require('./images/products/biswap-logo.svg'),
  product: require('./images/products/product.png'),
  cryptoSignalsLogo: require('./images/products/logo-cryptosignals.png'),
  forexTradeLogo: require('./images/products/logo-fxchrome.png'),
  cryptoSchoolLogo: require('./images/products/logo-cryptoschool.png'),
  mockingjaybotLogo: require('./images/products/logo-mockingjaybot.png'),
  ignisightLogo: require('./images/products/logo-ignisight.svg'),
  mvtsLogo: require('./images/products/logo-mvts.png'),
  sevencapitalLogo: require('./images/products/logo-sevencapital.png'),
  bestChoice: require('./images/products/bestchoice.png'),
  banners: {
    ignisight: require('./images/products/banners/ignisight.png'),
  },
  presentations: {
    ignisight: require('./images/products/presentation/ignisight.png'),
  },
}

const logo = {
  tradesoftware: {
    regular: require('./images/main/logo.png'),
    regular2x: require('./images/main/logo@2x.png'),
    regular3x: require('./images/main/logo@3x.png'),
    extended: require('./images/main/logo-extend.png'),
    extended2x: require('./images/main/logo-extend@2x.png'),
    extended3x: require('./images/main/logo-extend@3x.png'),
    extendWhite: require('./images/main/logo-white-extend.png'),
    extendWhite2x: require('./images/main/logo-white-extend@2x.png'),
    extendWhite3x: require('./images/main/logo-white-extend@3x.png'),
  },
  inkryptus: {
    regularLight: require('./images/main/logo-inkryptus-light.svg'),
    regularDark: require('./images/main/logo-inkryptus-dark.svg'),
    regularMini: require('./images/main/mini-logo-ink.svg'),
  },
  allcoinwallet: {
    regular: require('./images/partners/allcoinwallet/logo-white-extend.png'),
  },
  fxchrome: {
    regular: require('./images/partners/fxchrome/logo-regular.png'),
  },
  ignisight: {
    regular: require('./images/products/logo-ignisight.svg'),
    extend: require('./images/partners/ignisight/logo-extend.png'),
  },
  mvts: {
    regular: require('./images/products/logo-mvts.png'),
  },
  sevencapital: {
    regular: require('./images/products/logo-sevencapital.png'),
  },
}

const loading = {
  loadingRoute: require('./images/main/loading-route.gif'),
}

export { icons, logo, iconsMenu, tokens, loading }
