import * as React from 'react'
import MaskedPhoneInput from 'react-phone-number-input/basic-input'

type SizeTypes = 'small' | 'medium' | 'large' | 'small-icon' | 'medium-icon' | 'large-icon' | ''
type InputStyle = 'stroke' | 'fill' | 'disable' | 'focus' | 'success' | 'wrong' | ''

interface Props {
  size?: SizeTypes
  style?: InputStyle
  type?: string
  disabled?: boolean
  value?: string
  inputRef?: string | ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined
  id?: string
  className?: string
  placeholder?: string
  onBlur?: () => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined
}

const addTargetValue = (fn: (e: React.ChangeEvent<HTMLInputElement>) => void) => (v: string) => fn({ target: { value: v } } as any)

const Input: React.FunctionComponent<Props> = (props) => {
  const style: InputStyle | undefined = props.disabled ? 'disable' : props.style

  if (props.type === 'phone') {
    return (
      <MaskedPhoneInput
        id={props.id}
        ref={props.inputRef}
        value={props.value}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={props.onChange ? addTargetValue(props.onChange) : undefined}
        onBlur={props.onBlur}
        className={`input-${props.size}
        ${props.className}
        ${style ? `i-${style}` : ''}`}
        type={props.type ? props.type : 'text'}
        onKeyPress={props.onKeyPress}
      />
    )
  }

  return (
    <input
      id={props.id}
      ref={props.inputRef}
      value={props.value}
      disabled={props.disabled}
      placeholder={props.placeholder || ''}
      onChange={props.onChange}
      onBlur={props.onBlur}
      className={`${props.size ? `input-${props.size}` : ''}
        ${props.className || ''}
        ${style ? `i-${style}` : ''}`}
      type={props.type ? props.type : 'text'}
      onKeyPress={props.onKeyPress}
    />
  )
}

export default Input
