import React from 'react'
const FacebookIcon: React.SFC<{ className?: string }> = props => {
  return (
    <svg className={props.className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0833 0L2.91667 0C1.28516 0 0 1.28516 0 2.91667L0 32.0833C0 33.7148 1.28516 35 2.91667 35L32.0833 35C33.7148 35 35 33.7148 35 32.0833L35 2.91667C35 1.28516 33.7148 0 32.0833 0ZM17.5 33.8333V22.1667L12.8333 22.1667L12.8333 17.5L17.5 17.5L17.5 13.4167C17.5 10.0898 20.0703 7.29167 23.3333 7L28.5833 7V11.6667H23.9167C22.9232 11.6667 22.1667 12.4232 22.1667 13.4167L22.1667 17.5H28V22.1667H22.1667V33.8333H17.5ZM33.8333 32.0833C33.8333 33.0768 33.0768 33.8333 32.0833 33.8333L23.3333 33.8333V23.3333H29.1667V16.3333H23.3333L23.3333 13.4167C23.3333 13.0703 23.5703 12.8333 23.9167 12.8333H29.75V5.83333L23.2786 5.83333C19.3685 6.17969 16.3333 9.50651 16.3333 13.4167L16.3333 16.3333L11.6667 16.3333L11.6667 23.3333L16.3333 23.3333V33.8333H2.91667C1.92318 33.8333 1.16667 33.0768 1.16667 32.0833L1.16667 2.91667C1.16667 1.92318 1.92318 1.16667 2.91667 1.16667L32.0833 1.16667C33.0768 1.16667 33.8333 1.92318 33.8333 2.91667L33.8333 32.0833Z"
        fill="white"
      />
    </svg>
  )
}

export default FacebookIcon
