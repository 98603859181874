import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { t } from '../../i18n'
import CloudImage, { Transformation } from '../../components/cloud-image'
import { DecimalFormatter } from '../../components/shared'

interface Props {
  username?: string | null
  fullName?: string | null
  email?: string | null
  pictureVersion?: string | null
  stakedCakeAmount?: decimal | null
  networkCakeAmount?: decimal | null
  stakedBananaAmount?: decimal | null
  networkBananaAmount?: decimal | null
  stakedBiswapAmount?: decimal | null
  networkBiswapAmount?: decimal | null
  customerActivationTime?: number | null
  distributorActivationTime?: number | null
  directSponsoreds?: number | null
  indirectSponsoreds?: number | null
}

const AffiliateInfo: React.FC<Props> = (props) => {
  return (
    <div className="info-affiliate">
      <div>
        <CloudImage
          name={props.username ? `usernames_pictures/${props.username}` : undefined}
          fallbackAvatar
          version={props.pictureVersion || undefined}
        >
          <Transformation crop="thumb" gravity="face" width="120" height="120" />
        </CloudImage>
        <p>
          {props.username ? `@${props.username}` : ''}
          {props.fullName ? ` - ${props.fullName}` : ''}
        </p>
      </div>
      <div>
        <div>
          <p>{t('fields:username.label')}</p>
          <p>{props.username && `@${props.username}`}</p>
        </div>
        {props.email && (
          <div>
            <p>{t('fields:email.label')}</p>
            <p>{props.email}</p>
          </div>
        )}
        <div>
          <p>{t('common:stakedCakeAmount', 'Staked CAKE')}</p>
          <p>{props.stakedCakeAmount ? <DecimalFormatter value={props.stakedCakeAmount} /> : '-'}</p>
        </div>
        <div>
          <p>{t('common:networkCakeAmount', 'Network CAKE')}</p>
          <p>{props.networkCakeAmount ? <DecimalFormatter value={props.networkCakeAmount} /> : '-'}</p>
        </div>
        <div>
          <p>{t('common:stakedBananaAmount', 'Staked BANANA')}</p>
          <p>{props.stakedBananaAmount ? <DecimalFormatter value={props.stakedBananaAmount} /> : '-'}</p>
        </div>
        <div>
          <p>{t('common:networkBananaAmount', 'Network BANANA')}</p>
          <p>{props.networkBananaAmount ? <DecimalFormatter value={props.networkBananaAmount} /> : '-'}</p>
        </div>
        <div>
          <p>{t('common:stakedBiswapAmount', 'Staked BSW')}</p>
          <p>{props.stakedBiswapAmount ? <DecimalFormatter value={props.stakedBiswapAmount} /> : '-'}</p>
        </div>
        <div>
          <p>{t('common:networkBiswapAmount', 'Network BSW')}</p>
          <p>{props.networkBiswapAmount ? <DecimalFormatter value={props.networkBiswapAmount} /> : '-'}</p>
        </div>
        {/* // <div>
        //   <p>
        //     {t('fields:productLicense.activationTime.label')}
        //     {` (${t('common:customer')})`}
        //   </p>
        //   <p>{props.customerActivationTime && formatDate(props.customerActivationTime)}</p>
        // </div>
        // <div>
        //   <p>
        //     {t('fields:productLicense.activationTime.label')}
        //     {` (${t('common:distributor')})`}
        //   </p>
        //   <p>{props.distributorActivationTime && formatDate(props.distributorActivationTime)}</p>
        // </div> */}
        <div>
          <p>{t('fields:productMember.directSponsoreds.label')}</p>
          <p>{props.directSponsoreds}</p>
        </div>
        <div>
          <p>{t('fields:productMember.indirectSponsoreds.label')}</p>
          <p>{props.indirectSponsoreds}</p>
        </div>
      </div>
    </div>
  )
}

export default AffiliateInfo
