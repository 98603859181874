import * as React from 'react'
import { signOut } from '../../../utils/firebase-auth'
import { useUid, useUsername } from '../../../hooks/current-user'
import { icons, logo } from '../../../assets'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
// import LanguageSelector from '../../language-selector'
import InkryptusStatusBar from './TradesoftwareStatusBar.container'
import CloudImage, { Transformation } from '../../cloud-image'
import { usePublicProfile } from '../../../hooks/profile'
import { usePlatform } from '../../../hooks/capacitor'
import DebitsAlertBar from './DebitsAlertBar.component'
import { useMemberProductContractsRunning, useMemberProductContractsStopped } from '../../../hooks/product-contracts'
import { useMemberEarningsSummary } from '../../../hooks/earnings'
import {
  useMemberCakeSmartFarmLastProfitCycleStats,
  useMemberNetworkCakeSmartFarmLastProfitCycleStats,
  useCompanyLastOverviewStats,
  useMemberBananaSmartFarmLastProfitCycleStats,
  useMemberNetworkBananaSmartFarmLastProfitCycleStats,
  useMemberBiswapSmartFarmLastProfitCycleStats,
  useMemberNetworkBiswapSmartFarmLastProfitCycleStats,
} from '../../../hooks/stats'
import { useMemberWallet } from '../../../hooks/wallets'
import { useMemberNetwork } from '../../../hooks/network'
import { useAppState } from '../../../AppState'
import AppModeSwitchModalContext from '../../../modals/app-mode-switch'
import { MaintenanceBar } from './MaintenanceBar'
import { useSwapRates } from '../../../hooks/rates'
import { useMemberNotifications, useMemberUnreadNotifications } from '../../../hooks/notifications'
import { NotificationsHeaderBadge } from './NotificationsHeaderBadge'

const Header: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [appState, setAppState] = useAppState()
  const platform = usePlatform()
  const { username } = useUsername()
  const [publicProfile] = usePublicProfile(username)
  const profilePictureVersion = publicProfile ? publicProfile.pictureVersion : undefined

  const { showModal: openAppModeSwitchModal } = React.useContext(AppModeSwitchModalContext)

  const [switchMenuShowing, setSwitchMenuShowing] = React.useState(false)
  const openSwitchMenu = React.useCallback(() => {
    setSwitchMenuShowing(true)
  }, [setSwitchMenuShowing])
  const closeSwitchMenu = React.useCallback(() => {
    setSwitchMenuShowing(false)
  }, [setSwitchMenuShowing])

  const goToHome = React.useCallback(() => {
    closeSwitchMenu()
    props.history.push('/')
  }, [props.history, closeSwitchMenu])

  const { rootMode, rootModeTransitioning } = appState
  const history = props.history
  const switchToStakeRootMode = React.useCallback(() => {
    if (rootModeTransitioning) return
    if (rootMode === 'stake') return
    setAppState((c) => ({ ...c, rootModeTransitioning: true }))
    closeSwitchMenu()
    history.push('/')
    setTimeout(() => {
      setAppState((c) => ({ ...c, rootModeTransitioning: false, rootMode: 'stake' }))
    }, 2000)
  }, [setAppState, history, closeSwitchMenu])
  const switchToSwapRootMode = React.useCallback(() => {
    if (rootModeTransitioning) return
    if (rootMode === 'swap') return
    setAppState((c) => ({ ...c, rootModeTransitioning: true }))
    closeSwitchMenu()
    setTimeout(() => {
      history.push('/')
    }, 500)
    setTimeout(() => {
      setAppState((c) => ({ ...c, rootModeTransitioning: false, rootMode: 'swap' }))
    }, 2000)
  }, [setAppState, history, closeSwitchMenu])
  const confirmSwitchToStakeRootMode = React.useCallback(() => {
    if (rootModeTransitioning) return
    if (rootMode === 'stake') return
    openAppModeSwitchModal('stake', () => {
      setAppState((c) => ({ ...c, rootModeTransitioning: true }))
      closeSwitchMenu()
      history.push('/')
      setTimeout(() => {
        setAppState((c) => ({ ...c, rootModeTransitioning: false, rootMode: 'stake' }))
      }, 2000)
    })
  }, [setAppState, history, closeSwitchMenu, openAppModeSwitchModal])
  const confirmSwitchToSwapRootMode = React.useCallback(() => {
    if (rootModeTransitioning) return
    if (rootMode === 'swap') return
    openAppModeSwitchModal('swap', () => {
      setAppState((c) => ({ ...c, rootModeTransitioning: true }))
      closeSwitchMenu()
      setTimeout(() => {
        history.push('/')
      }, 500)
      setTimeout(() => {
        setAppState((c) => ({ ...c, rootModeTransitioning: false, rootMode: 'swap' }))
      }, 2000)
    })
  }, [setAppState, history, closeSwitchMenu, openAppModeSwitchModal])

  const goToTransactionsPage = React.useCallback(() => {
    closeSwitchMenu()
    history.push(rootMode === 'swap' ? '/transactions' : '/swap/transactions')
  }, [history, rootMode, closeSwitchMenu])

  // cache
  const [uid] = useUid()
  useMemberProductContractsRunning('cake-smart-farm')
  useMemberProductContractsStopped('cake-smart-farm')

  useMemberProductContractsRunning('banana-smart-farm')
  useMemberProductContractsStopped('banana-smart-farm')

  useMemberProductContractsRunning('biswap-smart-farm')
  useMemberProductContractsStopped('biswap-smart-farm')

  useMemberEarningsSummary(username, 'CAKE', 'profit_fee')
  useMemberEarningsSummary(username, 'BANANA', 'profit_fee')
  useMemberEarningsSummary(username, 'BSW', 'profit_fee')

  useMemberNotifications({ read: true })
  // useMemberReadNotifications()
  useMemberUnreadNotifications()

  useMemberCakeSmartFarmLastProfitCycleStats()
  useMemberNetworkCakeSmartFarmLastProfitCycleStats(username)
  useMemberBananaSmartFarmLastProfitCycleStats()
  useMemberNetworkBananaSmartFarmLastProfitCycleStats(username)
  useMemberBiswapSmartFarmLastProfitCycleStats()
  useMemberNetworkBiswapSmartFarmLastProfitCycleStats(username)

  useCompanyLastOverviewStats()
  useMemberWallet(uid, 'CAKE')
  useMemberWallet(uid, 'BANANA')
  useMemberWallet(uid, 'BSW')
  useMemberNetwork(8, username)

  useSwapRates()

  return (
    <header className={platform}>
      <MaintenanceBar rootMode={rootMode} />
      <div className={`header-bar ${rootMode}`}>
        {/* <!--Left menu--> */}
        <div>
          <div className="logo" onClick={goToHome}>
            <img src={logo.inkryptus.regularLight} />
          </div>
          <div className="logo-mobile" onClick={goToHome}>
            <img src={logo.inkryptus.regularLight} />
          </div>
          <InkryptusStatusBar />
          <div className="change-platform">
            <div className={`stake ${rootMode === 'stake' ? 'active' : ''}`} onClick={confirmSwitchToStakeRootMode}>
              Stake
            </div>
            <div className={`swap ${rootMode === 'swap' ? 'active' : ''}`} onClick={confirmSwitchToSwapRootMode}>
              Swap
            </div>
          </div>
        </div>
        {/* <!--Right menu--> */}
        <div>
          {appState.rootMode === 'swap' && (
            <div className="historic-swap" onClick={goToTransactionsPage}>
              <img src={icons.historicSwap} alt="Historic swap" />
              <p>Historic</p>
            </div>
          )}
          <div className="bsc-network">
            <img src={icons.bscNetwork} alt="Binance Smart Chain" />
            <p>BSC Mainnet</p>
          </div>
          <NotificationsHeaderBadge />
          <div className="profile">
            <NavLink to="/profile">
              <CloudImage name={username ? `usernames_pictures/${username}` : undefined} fallbackAvatar version={profilePictureVersion}>
                <Transformation crop="thumb" gravity="face" width="69" height="69" />
              </CloudImage>
            </NavLink>
          </div>
          <div className="signout" onClick={signOut}>
            <img src={icons.logout} alt="Log out" />
          </div>
          <div className="menu-mobile">
            <img src={icons.menuOpen} alt="menu" onClick={openSwitchMenu} style={{ cursor: 'pointer' }} />
            <div className={`menu-opened ${switchMenuShowing ? 'active' : ''}`}>
              <div>
                <img src={logo.inkryptus.regularLight} alt="logo" />
                <img src={icons.menuClose} alt="close" onClick={closeSwitchMenu} style={{ cursor: 'pointer' }} />
              </div>
              <div>
                <CloudImage name={username ? `usernames_pictures/${username}` : undefined} fallbackAvatar version={profilePictureVersion}>
                  <Transformation crop="thumb" gravity="face" width="40" height="40" />
                </CloudImage>
                <p>@{username}</p>
              </div>
              <div onClick={switchToStakeRootMode} style={{ cursor: 'pointer' }}>
                <div className={`stake ${rootMode === 'stake' ? 'active' : ''}`}>
                  <div></div>
                </div>
                <p>Inkryptus Stake</p>
              </div>
              <div onClick={switchToSwapRootMode} style={{ cursor: 'pointer' }}>
                <div className={`swap ${rootMode === 'swap' ? 'active' : ''}`}>
                  <div></div>
                </div>
                <p>Inkryptus Swap</p>
              </div>
              {appState.rootMode === 'swap' && (
                <div onClick={goToTransactionsPage} style={{ cursor: 'pointer' }}>
                  <img src={icons.historicSwap} alt="Historic swap" />
                  <p>Historic</p>
                </div>
              )}
              <div onClick={signOut} style={{ cursor: 'pointer' }}>
                <img
                  src={icons.signout}
                  srcSet={`${icons.signout} 1x,
                              ${icons.signout2x} 2x,
                              ${icons.signout3x} 3x`}
                />
                <p>Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DebitsAlertBar />
    </header>
  )
}

export default withRouter(Header)
