import React from 'react'
import { findDOMNode } from 'react-dom'
import { tree } from 'd3-state-visualizer'

interface TreeItem {
  name: string
  children: TreeItem[]
}

interface Props {
  state?: any
  rootKeyName?: string
  pushMethod?: string
  tree: TreeItem
  id: string
  style?: {
    node?: {
      colors?: {
        default?: string
        parent?: string
        collapsed?: string
      }
      radius?: number
    }
    text?: {
      colors?: {
        default?: string
        hover?: string
      }
    }
    link?: any
    border?: any
  }
  size: number
  aspectRatio: number
  margin?: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }
  onClickText?: (item: TreeItem) => void
  isSorted: boolean
  heightBetweenNodesCoeff: number
  widthBetweenNodesCoeff: number
  transitionDuration?: number
  tooltipOptions?: {
    disabled?: boolean
    left?: number
    top?: number
    offset?: {
      left: number
      top: number
    }
    indentationSize?: number
  }
}

class TreeChart extends React.Component<Props> {
  private _renderChart: (tree?: TreeItem) => void = () => {}

  componentDidMount() {
    this._renderChart = tree(findDOMNode(this), this.props)
    this._renderChart()
  }

  componentWillReceiveProps(nextProps: Props) {
    this._renderChart(nextProps.tree || nextProps.state)
  }

  render() {
    return <div />
  }
}

export default TreeChart
