import * as React from 'react'
import { useState } from 'react'

import { SignupCreateAccountProps } from '../../../services/api'
import { logo } from '../../../assets/'
import { Input, Button, Select, SelectItem, FormErrorsList } from '../../shared'
import { useFormErrors } from '../../../hooks/form-errors'
import { t } from '../../../i18n'
import { countriesByName } from '../../../utils/countries'

const countries = countriesByName()
const withTargetValue = (setFn: (value: string) => void) => (e: any) => setFn(e.target.value)

interface Props {
  sponsorUsername?: string
  onSubmit: (data: SignupCreateAccountProps) => Promise<void>
}
const SignupForm: React.FunctionComponent<Props> = props => {
  const [sponsorUsername, setSponsorUsername] = useState<string>(props.sponsorUsername || '')
  const [username, setUsername] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [birthDate, setBirthDate] = useState<string>('')

  const [creating, setCreating] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [formErrors, setFormErrors, solveFieldError] = useFormErrors()

  const withOnBlur = (fieldPath: string) => () => solveFieldError(fieldPath)

  const onSubmit = async () => {
    function getGender(): 'male' | 'female' | 'other' | 'undefined' {
      switch (gender) {
        case 'male':
        case 'female':
        case 'other':
          return gender
        default:
          return 'undefined'
      }
    }
    if (creating) return
    setCreating(true)
    setFormErrors({})
    setErrorMessages([])
    try {
      const gender = getGender()
      await props.onSubmit({ name, lastName, sponsorUsername, username, email, password, country, phone, birthDate, gender })
    } catch (error) {
      if (error.formErrors) setFormErrors(error.formErrors)
      else if (error.humanizedMessage) setErrorMessages([error.humanizedMessage])
    }
    setCreating(false)
  }

  return (
    <div className="box-signup">
        <div>
          <div>
            <img src={logo.inkryptus.regularDark} />
          </div>
          <div>
            <div>
              <p>{t('auth:sign_up.page_title')}</p>
            </div>
            <div className="box-input">
              <p>{t('fields:sponsorUsername.label')}</p>
              <div className={`input-medium ${!!props.sponsorUsername ? 'i-disable' : ''}`}>
                <Input disabled={!!props.sponsorUsername} type="text" value={sponsorUsername} onChange={withTargetValue(setSponsorUsername)} />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:username.label')}</p>
              <div className={'input-medium ' + (formErrors['username'] ? 'i-wrong' : 'i-stroke')}>
                <Input type="text" value={username} onBlur={withOnBlur('username')} onChange={withTargetValue(setUsername)} />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:email.label')}</p>
              <div className={'input-medium ' + (formErrors['email'] ? 'i-wrong' : 'i-stroke')}>
                <Input type="email" value={email} onBlur={withOnBlur('email')} onChange={withTargetValue(setEmail)} />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:password.label')}</p>
              <div className={'input-medium ' + (formErrors['password'] ? 'i-wrong' : 'i-stroke')}>
                <Input type="password" value={password} onBlur={withOnBlur('password')} onChange={withTargetValue(setPassword)} />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:name.label')}</p>
              <div className={'input-medium ' + (formErrors['name'] ? 'i-wrong' : 'i-stroke')}>
                <Input type="text" value={name} onBlur={withOnBlur('name')} onChange={withTargetValue(setName)} />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:lastName.label')}</p>
              <div className={'input-medium ' + (formErrors['lastName'] ? 'i-wrong' : 'i-stroke')}>
                <Input type="text" value={lastName} onBlur={withOnBlur('lastName')} onChange={withTargetValue(setLastName)} />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:phone.label')}</p>
              <div className={'input-medium ' + (formErrors['phone'] ? 'i-wrong' : 'i-stroke')}>
                <Input
                  placeholder={t('fields:phone.mask_placeholder')}
                  type="phone"
                  value={phone}
                  onBlur={withOnBlur('phone')}
                  onChange={withTargetValue(setPhone)}
                />
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:country.label')}</p>
              <div className={'input-medium ' + (formErrors['country'] ? 'i-wrong' : 'i-stroke')}>
                <Select value={country} onBlur={withOnBlur('country')} onChange={withTargetValue(setCountry)}>
                  <SelectItem value="" hidden>
                    {''}
                  </SelectItem>
                  {countries.map(({ code, name }) => (
                    <SelectItem key={code} value={code}>
                      {name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:gender.label')}</p>
              <div className={'input-medium ' + (formErrors['gender'] ? 'i-wrong' : 'i-stroke')}>
                <Select value={gender} onBlur={withOnBlur('gender')} onChange={withTargetValue(setGender)}>
                  <SelectItem value="" hidden>
                    {''}
                  </SelectItem>
                  {['undefined', 'female', 'male', 'other'].map(k => (
                    <SelectItem key={k} value={k}>
                      {t('fields:gender.options.' + k)}
                    </SelectItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="box-input">
              <p>{t('fields:birthDate.label')}</p>
              <div className={'input-medium ' + (formErrors['birthDate'] ? 'i-wrong' : 'i-stroke')}>
                <Input type="date" value={birthDate} onChange={withTargetValue(setBirthDate)} />
              </div>
            </div>
            <Button onClick={onSubmit} loading={creating} disabled={creating} size="medium" type="primary">
              {t('auth:sign_up:button_sign_up.label')}
            </Button>
            <FormErrorsList messages={errorMessages} formErrors={formErrors} itemClassName="response-signup" />

          </div>
        </div>
    </div>
  )
}

export default SignupForm
