import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { Invoice } from '@allcoinwallet/inkryptus-db-schema'
import moment from 'moment'
import { formatCurrency, formatDate, humanizeTimeFromNow } from '../../utils/format'
import { t } from '../../i18n'

interface Props {
  invoice?: (Invoice & { invoiceId: string }) | null
  licensePrice?: decimal
  licenseActivationTime?: number
  licenseExpirationTime?: number
  licenseRenewalTime?: number
  freeTrialDays?: number | null
  hasDebits?: boolean
  debitDueDate?: number
  enabledAutomaticRenewal?: boolean
  loading?: boolean
}

const ProductStatus: React.SFC<Props> = (props) => {
  const {
    loading,
    licenseActivationTime,
    licenseExpirationTime,
    licenseRenewalTime,
    hasDebits,
    debitDueDate,
    licensePrice,
    invoice,
    freeTrialDays,
  } = props

  const licenseStatus: 'active' | 'expired' | null = React.useMemo(() => {
    if (loading) return null
    if (!licenseExpirationTime) return null
    const isActive = moment(licenseExpirationTime).isAfter(moment())
    if (isActive) return 'active'
    return 'expired'
  }, [loading, licenseExpirationTime])

  const hasOverdueDebits = React.useMemo(() => !!debitDueDate && debitDueDate < Date.now(), [debitDueDate])

  const formattedActivationTime = React.useMemo(() => (licenseActivationTime ? formatDate(licenseActivationTime) : null), [
    licenseActivationTime,
  ])
  const formattedExpirationTime = React.useMemo(() => (licenseExpirationTime ? humanizeTimeFromNow(licenseExpirationTime) : null), [
    licenseExpirationTime,
  ])
  const formattedRenewalTime = React.useMemo(() => (licenseRenewalTime ? formatDate(licenseRenewalTime) : null), [licenseRenewalTime])
  const formattedDebitDueDateTime = React.useMemo(() => (debitDueDate ? humanizeTimeFromNow(debitDueDate) : null), [debitDueDate])

  const [licenseProgress, setLicenseProgress] = React.useState<number>(0)
  React.useEffect(() => {
    const expired = licenseStatus === 'expired'
    if (!licenseActivationTime || !licenseExpirationTime || expired) {
      const timeout = setTimeout(() => setLicenseProgress(100), 600)
      return () => clearTimeout(timeout)
    }
    try {
      if (hasDebits && debitDueDate) {
        const total = 7
        const remaining = moment(debitDueDate).diff(moment(), 'days', true)
        const percent = 100 - (remaining / total) * 100
        const timeout = setTimeout(() => setLicenseProgress(percent > 100 ? 100 : percent), 600)
        return () => clearTimeout(timeout)
      }
      const total = licenseExpirationTime - (licenseRenewalTime || licenseActivationTime)
      const remaining = licenseExpirationTime - Date.now()
      const percent = 100 - (remaining / total) * 100
      const timeout = setTimeout(() => setLicenseProgress(percent), 600)
      return () => clearTimeout(timeout)
    } catch (error) {
      console.error(`Cannot calculate progress of license`, error)
      const timeout = setTimeout(() => setLicenseProgress(100), 600)
      return () => clearTimeout(timeout)
    }
  }, [licenseStatus, licenseActivationTime, licenseRenewalTime, licenseExpirationTime, hasDebits, debitDueDate])

  if (loading) {
    return (
      <div className="status-pre-purchase">
        <div>
          <p />
          <p />
        </div>
        <div>
          <p />
        </div>
      </div>
    )
  }

  const progressDiv = <div style={{ width: `${licenseProgress}%` }} />

  if (hasDebits) {
    return (
      <div className="status-pending-payment">
        <p>{t('components:marketplace_product_widget.status_section.pending_payment')}</p>
        <p>
          {t(
            hasOverdueDebits
              ? 'components:marketplace_product_widget.status_section.suspended_since'
              : 'components:marketplace_product_widget.status_section.will_be_suspended_on',
            { dueDateTime: formattedDebitDueDateTime }
          )}
        </p>
        {/* {licenseStatus === 'expired' ? (
          <p>{t('components:marketplace_product_widget.status_section.expired_since', { licenseExpirationTime: formattedExpirationTime })}</p>
        ) : licenseStatus === 'active' ? (
          <p>
            {t(
              enabledAutomaticRenewal
                ? 'components:marketplace_product_widget.status_section.will_be_renewed_on'
                : 'components:marketplace_product_widget.status_section.will_expire_on',
              { licenseExpirationTime: formattedExpirationTime }
            )}
          </p>
        ) : (
              <p>{t('components:marketplace_product_widget.status_section.available_for_purchase_after_payment')}</p>
            )} */}
        <div>{progressDiv}</div>
        <p>
          {formattedRenewalTime
            ? t('components:marketplace_product_widget.status_section.renewed_at', { licenseRenewalTime: formattedRenewalTime })
            : formattedActivationTime
            ? t('components:marketplace_product_widget.status_section.activated_at', { licenseActivationTime: formattedActivationTime })
            : t('components:marketplace_product_widget.status_section.no_active_licenses')}
        </p>
      </div>
    )
  }

  if (!licenseExpirationTime) {
    return (
      <div className="status-pre-purchase">
        <div>
          <p>
            {invoice
              ? t(`fields:invoice.statuses.${invoice.status}`)
              : freeTrialDays
              ? t('components:marketplace_product_widget.status_section.available_for_free_trial')
              : t('components:marketplace_product_widget.status_section.available_for_purchase')}
          </p>
          <p>{t('components:marketplace_product_widget.status_section.release_of_immediate_access')}</p>
        </div>
        <div>
          <p>{formatCurrency(licensePrice)}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="status-active">
      <p>{t('components:marketplace_product_widget.status_section.active_license')}</p>
      <div>{progressDiv}</div>
      <p>{formattedExpirationTime ? `${t('fields:productLicense.expirationTime.label')}: ${formattedExpirationTime}` : null}</p>
    </div>
  )
}

export default ProductStatus
