import React from 'react'
import { map, reverse, sortBy, reduce } from 'lodash'
import { Decimal, mul, add } from '@allcoinwallet/invest-bitcoin-decimal'
import { useHistory } from 'react-router'

import { tokens, icons } from '../../assets'
import { useSwapRates } from '../../hooks/rates'
import { formatCurrency, formatDecimal, formatPercent } from '../../utils/format'
import { useMemberProductContractsRunning } from '../../hooks/product-contracts'
import { ProductContractListItem } from './ProductContractListItem'
import { useCompanyLastOverviewStats } from '../../hooks/stats'
import { Button } from '../../components/shared'
import { ProductCreateContractWidget } from './ProductCreateContractWidget'

const ProductSmartFarm: React.FC<{ productId: string; symbol: string; label: string }> = ({ productId, symbol, label }) => {
  const history = useHistory()

  const [swapRates, swapRatesLoading] = useSwapRates()
  const price = swapRates && swapRates[symbol] ? swapRates[symbol].rates?.USDT : undefined

  const [companyOverviewStats, companyOverviewStatsLoading] = useCompanyLastOverviewStats()
  const lastProfit = companyOverviewStats
    ? (symbol === 'BANANA'
        ? companyOverviewStats?.bananaSmartFarmProfit
        : symbol === 'BSW'
        ? companyOverviewStats?.biswapSmartFarmProfit
        : companyOverviewStats?.cakeSmartFarmProfit) || Decimal(0)
    : undefined
  const last7DaysProfit = lastProfit ? mul(lastProfit, Decimal(7)) : undefined
  const last14DaysProfit = lastProfit ? mul(lastProfit, Decimal(14)) : undefined
  const last30DaysProfit = lastProfit ? mul(lastProfit, Decimal(30)) : undefined

  const [productsContracts, productsContractsLoading] = useMemberProductContractsRunning(productId)
  const contracts = React.useMemo(
    () =>
      reverse(
        sortBy(
          map(productsContracts, (c, contractId) => ({ ...c, contractId })),
          'paymentStartTime'
        )
      ),
    [productsContracts]
  )
  const contractsTotalNetAmount = React.useMemo(
    () =>
      reduce(
        map(contracts, (c) => c.netAmount),
        add
      ),
    [contracts]
  )

  const openContractsPage = React.useCallback(() => {
    history.push('/products/contracts/')
  }, [history])

  const loading = swapRatesLoading || productsContractsLoading || companyOverviewStatsLoading

  return (
    <>
      <div className={`box-contract ${loading ? 'loading' : ''}`}>
        <div className="contract-container">
          <div className="logo">
            {symbol === 'CAKE' && <img src={tokens.cakeLogo} alt="Logo cake" />}
            {symbol === 'BSW' && <img src={tokens.biswapLogo} alt="Logo biswap" />}
            {symbol === 'BANANA' && <img src={tokens.bananaLogo} alt="Logo banana" />}
            <div className="cake-price">
              <p>{label}</p>
              <p>{swapRatesLoading ? '...' : formatCurrency(price)}</p>
            </div>
          </div>
          <div className="description">
            <p>Inkryptus {label} Staking</p>
            <p>
              Make more crypto with your crypto.
              <br />
              We’ll make it easy for you.
              <br />
              Lend your funds and earn fees on a daily basis.
            </p>
          </div>
          <div className="avarage-profit">
            <p>Daily profit</p>
            <div className="timeframe">
              <div className="title">
                <p>Time</p>
                <p>Roi*</p>
              </div>
              <div>
                <p>1 day</p>
                <p>{formatPercent(lastProfit, { precision: 2 })}</p>
              </div>
              <div>
                <p>7 days</p>
                <p>{formatPercent(last7DaysProfit, { precision: 2 })}</p>
              </div>
              <div>
                <p>14 days</p>
                <p>{formatPercent(last14DaysProfit, { precision: 2 })}</p>
              </div>
              <div>
                <p>30 days</p>
                <p>{formatPercent(last30DaysProfit, { precision: 2 })}</p>
              </div>
              <div className="obs">
                <p>*It varies according market liquidity.</p>
              </div>
            </div>
            <div className="fee">
              <div>
                <p>Profit Fee**</p>
                <p>25%</p>
              </div>
              <p>**Fee charged over daily profits.</p>
            </div>
          </div>
        </div>
        <div className="start-contract-container">
          <div className="adress-start">
            <div className="info">
              <p>Start Staking Now</p>
              <ul>
                <li>Each deposit made to your personal address will automatically generate a contract.</li>
                <li>Contracts are unique and can not be modified once is generated.</li>
                <li>There is no fee to create a contract.</li>
                <li>Contracts can be canceled at any time and funds will be available to withdraw within 24 hours.</li>
                <li>Daily profits are paid after 24 hours cycle.</li>
                <li>Staking earnings may vary from 0.2% to 0.5% a day.</li>
                <li>Inkryptus charge a 25% fee from Staking earnings.</li>
                <li>Refer a friend and earn more with our referral program.</li>
              </ul>
              <p>{symbol === 'CAKE' ? 'Let’s bake some cake…' : 'Let’s profit...'}</p>
            </div>
            <ProductCreateContractWidget symbol={symbol} productId={productId} />
          </div>
          <div className="alert" style={{ display: 'none' }}>
            <img src={icons.attentionRed} alt="Attention" />
            <div>
              <div>
                <li>
                  <span>ONLY SEND CAKE, BSW OR BANANA TO THIS ADDRESS.</span>
                </li>
                <p>If you send other coins to this address Inkryptus will not be responsible for the transaction.</p>
              </div>
              <div>
                <li>
                  <span>BINANCE SMART CHAIN (BEP 20)</span>
                </li>
                <p>Make sure you are using the Binance Smart Chain Network (BEP20). Deposits made through other networks could be lost.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-contracts">
          <div className="title">
            <p>My Contracts{contractsTotalNetAmount ? ` (${formatDecimal(contractsTotalNetAmount, 8)})` : ''}</p>
            <Button size="medium" type="success" onClick={openContractsPage}>
              View all contracts
            </Button>
          </div>
          {map(contracts, (contract) => (
            <ProductContractListItem key={contract.contractId} contractId={contract.contractId} contract={contract} />
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductSmartFarm
