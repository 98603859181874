import * as React from 'react'
import { match } from 'react-router'

import { signupCreateAccount, SignupCreateAccountProps } from '../../services/api'
import { signInWithUsernameAndPassword } from '../../utils/firebase-auth'
import SignupForm from '../../components/auth/signup'

interface Props {
  match: match<{ sponsorUsername?: string }>
}

const SignupPage: React.FunctionComponent<Props> = props => {
  const { sponsorUsername } = props.match.params
  const onSubmit = async (params: SignupCreateAccountProps): Promise<void> => {
    await signupCreateAccount(params)
    await signInWithUsernameAndPassword(params.username, params.password)
  }
  return <SignupForm onSubmit={onSubmit} sponsorUsername={sponsorUsername} />
}

export default SignupPage
