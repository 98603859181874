import React from 'react'
import { t } from '../../i18n'
import { icons } from '../../assets'

interface Props {
  title: string
  description: string
  downloadLink: string
  thumbnailUrl: string
}

const openExternalLink = (link: string) => () => window.open(link, '_blank')

const MarketingResourceItem: React.FunctionComponent<Props> = props => {
  const onDownloadClick = React.useCallback(openExternalLink(props.downloadLink), [props.downloadLink])
  return (
    <div className="item">
      <div style={{ backgroundImage: `url("${props.thumbnailUrl}")` }} />
      <div>
        <div>
          <p>{props.title}</p>
        </div>
        <div>
          <p>{props.description} </p>
        </div>
        <div>
          <p onClick={onDownloadClick}>
            <img src={icons.download} /> {t('common:download')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default MarketingResourceItem
