import * as React from 'react'
import { icons, tokens } from '../assets'
import Button from '../components/shared/button'
import SwapSymbolSelectorModalContext from '../modals/swap-symbol-selector'

export const SwapHomeScreen: React.FC = () => {
  const { showModal: showSwapSymbolSelector } = React.useContext(SwapSymbolSelectorModalContext)
  const openSwapSymbolSelector = React.useCallback(() => {
    showSwapSymbolSelector('CAKE', () => {})
  }, [showSwapSymbolSelector])
  return (
    <div className="container">
      <div className="box-swap">
        <div className="wallets">
          <div className="adress-container">
            <p>Wallets</p>
            <div className="adress">
              <div className="qr-code">
                <img src={icons.qrCode} alt="Qr-code" />
              </div>
              <div className="content">
                <div>
                  <p>Wallet Adress</p>
                  <p>0xc1FFa54BcF15e198c19E8Be68c6524cf1f0F59Bd</p>
                </div>
                <div>
                  <p>Rede</p>
                  <p>
                    <img src={icons.bscNetwork} alt="Binance Smart Chain" />
                    Binance Smart chain (BEP 20)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="assets-container">
            <p>Assets</p>
            <div className="assets">
              <div>
                <img src={tokens.usdtLogo} alt="USDT" />
                <p>USD Theter</p>
                <p>Balance: 0,00</p>
                <Button size="small" type="primary">
                  Withdraw
                </Button>
              </div>
              <div>
                <img src={tokens.cakeLogo} alt="CAKE" />
                <p>Cake</p>
                <p>Balance: 0,00</p>
                <Button size="small" type="primary">
                  Withdraw
                </Button>
              </div>
              <div>
                <img src={tokens.biswapLogo} alt="BISWAP" />
                <p>Biswap</p>
                <p>Balance: 0,00</p>
                <Button size="small" type="primary">
                  Withdraw
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="swap">
          <p className="title">Swap</p>
          <div className="swap-container">
            <div className="asset-swap">
              <div>
                <p>To</p>
                <p>Balance: 0,00</p>
              </div>
              <div>
                <div onClick={openSwapSymbolSelector}>
                  <img src={tokens.usdtLogo} alt="USDT" />
                  <p>USD Theter</p>
                  <img src={icons.arrowDown} alt="Arrow Down" />
                </div>
                <input type="text" placeholder="0" />
              </div>
            </div>
            <div className="change-swap">
              <div>
                <img src={icons.changeSwap} alt="Change Swap" />
              </div>
            </div>
            <div className="asset-swap">
              <div>
                <p>From</p>
                <p>Balance: 0,00</p>
              </div>
              <div>
                <div onClick={openSwapSymbolSelector}>
                  <img src={tokens.cakeLogo} alt="CAKE" />
                  <p>Cake</p>
                  <img src={icons.arrowDown} alt="Arrow Down" />
                </div>
                <input type="text" placeholder="0" />
              </div>
            </div>
            <div className="estimate-price">
              <p>Price:</p>
              <p>11.43 USDT per CAKE</p>
            </div>
            <Button size="large" type="primary">
              Swap
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
