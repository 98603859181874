import { ProductContract } from '@allcoinwallet/inkryptus-db-schema'
import { add, Decimal, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import React from 'react'
import { tokens, icons } from '../../assets'
import Button from '../../components/shared/button'
import { formatDate } from '../../utils/format'
// import Modal from 'react-modal'

const MAX_DECIMAL_PLACES = 18

interface Props {
  contractId: string
  contract: ProductContract
  onFinishClick?: () => any
  onCloseClick?: () => any
}

const FinishProductContract: React.FC<Props> = ({ contractId, contract, onFinishClick, onCloseClick }) => {
  const [agreementChecked, setAgreementChecked] = React.useState(false)
  const onChangeAgreementChecked = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreementChecked(event.target.checked)
    },
    [setAgreementChecked]
  )

  const [finishing, setFinishing] = React.useState(false)
  const finishContract = React.useCallback(async () => {
    if (finishing) return
    setFinishing(true)
    try {
      if (onFinishClick) await onFinishClick()
      setAgreementChecked(false)
    } catch (error) {}
    setFinishing(false)
  }, [finishing, setFinishing, onFinishClick, setAgreementChecked])

  const close = React.useCallback(() => {
    setAgreementChecked(false)
    if (onCloseClick) onCloseClick()
  }, [onCloseClick, setAgreementChecked])

  const activationTime = contract.activationTime
  const daysCount = React.useMemo(() => {
    if (!activationTime) return 0
    return Math.floor((Date.now() - activationTime) / 1000 / 60 / 60 / 24)
  }, [activationTime])

  if (contract.status === 'PENDING_DEACTIVATION')
    return (
      <div className="modal-notification">
        <img src={icons.successPrimary} alt="Success" />
        <p>Contract termination successfully requested !</p>
        <Button size="medium" type="primary" onClick={close}>
          Fechar
        </Button>
      </div>
    )
  return (
    <div className="modal-close-contract">
      <div className="title">
        <img
          src={contract.symbol === 'BANANA' ? tokens.bananaLogo : contract.symbol === 'BSW' ? tokens.biswapLogo : tokens.cakeLogo}
          alt={contract.symbol}
        />
        <p>Terminate Contract</p>
      </div>
      <div className="single-item">
        <p>Contract Number</p>
        <div>
          <p>{contractId}</p>
        </div>
      </div>
      <div className="single-item">
        <p>Total {contract.symbol} blocked in contract </p>
        <div>
          <p>
            {trunc(contract.startNetAmount, MAX_DECIMAL_PLACES)} {contract.symbol}
          </p>
        </div>
      </div>
      <div className="multiple-item">
        <p>Contract Details</p>
        <div>
          <p>Gross Profit</p>
          <p>{trunc(add(contract.totalProfitFeeAmount || Decimal(0), contract.totalProfitNetAmount || Decimal(0)), MAX_DECIMAL_PLACES)}</p>
          <p>Gross Profit Fee </p>
          <p>{trunc(contract.totalProfitFeeAmount || Decimal(0), MAX_DECIMAL_PLACES)}</p>
          <p>Net Profit </p>
          <p>{trunc(contract.totalProfitNetAmount || Decimal(0), MAX_DECIMAL_PLACES)}</p>
          <p>Active Cycle</p>
          {!!activationTime && (
            <p>
              {daysCount} day{daysCount === 1 ? '' : 's'} ({formatDate(activationTime)} - {formatDate(Date.now())})
            </p>
          )}
          {!activationTime && <p>-</p>}
        </div>
      </div>
      <div className="check-term">
        <input id="check" type="checkbox" checked={agreementChecked} onChange={onChangeAgreementChecked} />
        <label htmlFor="check">
          I agree that the current cycle will be canceled and will not generate any more profit. This operation is irreversible and your balance
          will be available for withdrawal in your wallet within 24 hours.
        </label>
      </div>
      <div className="button-action">
        <Button size="medium" type="primary" disabled={!agreementChecked} loading={finishing} onClick={finishContract}>
          Cancel Contract
        </Button>
      </div>
    </div>
  )
}

export default FinishProductContract
