import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { icons } from '../../assets'
import { useMemberDebits } from '../../hooks/debits'
import BoxView, { BoxViewTab } from '../../components/box-view'
import MemberEarningsTable from './MemberEarningsTable.container'
import MemberEarningsSummary from './MemberEarningsSummary.container'

const FinancialPage: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [debits] = useMemberDebits()
  const hasDebits = !!debits
  console.log({ hasDebits, debits })
  return (
    <BoxView label="Financial" icon={icons.iconFinancial} className="box-financial-comission">
      <BoxViewTab tabKey="comission" label="Comission">
        <MemberEarningsSummary />
      </BoxViewTab>
      <BoxViewTab tabKey="historic" label="Historic">
        <MemberEarningsTable />
      </BoxViewTab>

      {/*
        <div className="box-financial-payments">
          <div className="history-payments">
            <MemberInvoicesTable />
          </div>
        </div>
      */}
    </BoxView>
  )
}

export default FinancialPage
