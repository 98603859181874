import * as React from 'react'
import Input from '../shared/input'
import { Reference, Query } from '../../hooks/database'
import { t } from '../../i18n'

export interface SelectSmartTableFilter<T = any> {
  functions: {
    ChangeSelectFilter: (e: any) => void
  }
  fields: {
    name: string
    onQuery: (ref: Reference<T>, uid: string, dateStartFilter: string, dateEndFilter: string) => Reference<T> | Query<T>
    onFilter: (v: T) => T
  }[]
}

interface Props {
  columns: string[]
  rows: any[][]
  tableTitle?: string
  rowClassName?: (obj: any) => string | undefined
  filter: {
    dateStart: string
    dateEnd: string
    setDateStart: React.Dispatch<React.SetStateAction<string>>
    setDateEnd: React.Dispatch<React.SetStateAction<string>>
    select?: SelectSmartTableFilter
  }
}

const SmartTable: React.FunctionComponent<Props> = props => {
  const changeDateStart = React.useCallback(
    e => {
      props.filter.setDateStart(e.target.value)
    },
    [props.filter.dateStart]
  )
  const changeDateEnd = React.useCallback(
    e => {
      props.filter.setDateEnd(e.target.value)
    },
    [props.filter.dateEnd]
  )
  return (
    <>
      <div>
        <div className="title">
          <p>{props.tableTitle}</p>
        </div>
        <div className="filter">
          <div>
            <div className="input-small-icon i-stroke">
              {props.filter.select ? (
                <select defaultValue="" onChange={props.filter.select.functions.ChangeSelectFilter}>
                  <option value="">{t('components:smart_table.select_filter.label')}</option>
                  {props.filter.select.fields.map((field, index) => (
                    <option key={index} value={index}>
                      {' '}
                      {field.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          </div>
          <div>
            <p>{t('components:smart_table.date_filter.from_label')}:</p>
            <div className="input-small i-stroke">
              <Input value={props.filter.dateStart} type="date" onChange={changeDateStart} />
            </div>
          </div>
          <div>
            <p>{t('components:smart_table.date_filter.to_label')}:</p>
            <div className="input-small i-stroke">
              <Input value={props.filter.dateEnd} type="date" onChange={changeDateEnd} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              {props.columns.map((header, index) => {
                return <th key={index}>{header}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rows.map((row, rowIndex) => {
              return (
                <tr key={rowIndex} className={props.rowClassName ? props.rowClassName(row) : undefined}>
                  {props.columns.map((_, columnIndex) => {
                    if (!props.columns[columnIndex]) return null
                    return <td key={columnIndex}>{row[columnIndex]}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SmartTable
