import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { icons } from '../../assets'
import BoxView, { BoxViewTab } from '../../components/box-view'

import MemberNetworkGraph from './MemberNetworkGraph.container'
import MemberReferralLink from './MemberReferralLink.container'
import MemberEmailInvitationDispatcher from './MemberEmailInvitationDispatcher.container'
import MemberNetworkHistory from './MemberNetworkHistory.container'
import MemberActiveProducts from './MemberActiveProducts'

const Network: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <BoxView label="Network" icon={icons.iconNetwork} className="box-network">
      <BoxViewTab tabKey="overview" label="Overview">
        <div className="referral-container">
          <div>
            <div className="referral">
              <p>Invitation</p>
              <MemberReferralLink />
              <MemberEmailInvitationDispatcher />
            </div>
            <MemberActiveProducts />
          </div>
          <MemberNetworkHistory />
        </div>
      </BoxViewTab>
      <BoxViewTab tabKey="network" label="Network">
        <div className="box-network-graph">
          <MemberNetworkGraph />
        </div>
      </BoxViewTab>
    </BoxView>
  )
}

export default Network
