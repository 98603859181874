import React from 'react'
import Modal from 'react-modal'
import { add } from '@allcoinwallet/invest-bitcoin-decimal'
import { useActiveCompanyDistributorLicense, useActiveCompanyCustomerLicense } from '../../hooks/products'
import { useMemberNetworkSummary, useMemberNetwork } from '../../hooks/network'
import {
  useMemberBananaSmartFarmLastProfitCycleStats,
  useMemberBiswapSmartFarmLastProfitCycleStats,
  useMemberCakeSmartFarmLastProfitCycleStats,
  useMemberNetworkBananaSmartFarmLastProfitCycleStats,
  useMemberNetworkBiswapSmartFarmLastProfitCycleStats,
  useMemberNetworkCakeSmartFarmLastProfitCycleStats,
} from '../../hooks/stats'
import { usePublicProfile, useMemberProfile } from '../../hooks/profile'
import AffiliateInfo from './AffiliateInfo.component'
import { useStatuses } from '../../hooks/current-user'

interface Props {
  username: string
  onClose: () => void
}

const AffiliateOverview: React.FunctionComponent<Props> = (props) => {
  // const [products] = useLocalizedProducts()
  // const [licenses] = useActiveLicenses(props.username)
  const [distributorLicense] = useActiveCompanyDistributorLicense(props.username)
  const [customerLicense] = useActiveCompanyCustomerLicense(props.username)
  const [memberNetwork] = useMemberNetwork(7, props.username)
  const networkSummary = useMemberNetworkSummary(props.username, memberNetwork)
  const [publicProfile] = usePublicProfile(props.username)
  const { statuses } = useStatuses()
  const [profileForInsiders] = useMemberProfile(statuses && statuses.insider ? props.username : null)
  const profilePictureVersion = publicProfile ? publicProfile.pictureVersion : undefined

  const [cakeLastProfitCycle] = useMemberCakeSmartFarmLastProfitCycleStats(props.username)
  const cakeStakedAmount = React.useMemo(
    () => (cakeLastProfitCycle ? add(cakeLastProfitCycle.firstCycleAmount, cakeLastProfitCycle.startNetAmount) : null),
    [cakeLastProfitCycle]
  )
  const [cakeNetworkLastProfitCycle] = useMemberNetworkCakeSmartFarmLastProfitCycleStats(props.username)
  const cakeNetworkStakedAmount = React.useMemo(
    () => (cakeNetworkLastProfitCycle ? add(cakeNetworkLastProfitCycle.firstCycleAmount, cakeNetworkLastProfitCycle.startNetAmount) : null),
    [cakeNetworkLastProfitCycle]
  )

  const [bananaLastProfitCycle] = useMemberBananaSmartFarmLastProfitCycleStats(props.username)
  const bananaStakedAmount = React.useMemo(
    () => (bananaLastProfitCycle ? add(bananaLastProfitCycle.firstCycleAmount, bananaLastProfitCycle.startNetAmount) : null),
    [bananaLastProfitCycle]
  )
  const [bananaNetworkLastProfitCycle] = useMemberNetworkBananaSmartFarmLastProfitCycleStats(props.username)
  const bananaNetworkStakedAmount = React.useMemo(
    () =>
      bananaNetworkLastProfitCycle ? add(bananaNetworkLastProfitCycle.firstCycleAmount, bananaNetworkLastProfitCycle.startNetAmount) : null,
    [bananaNetworkLastProfitCycle]
  )

  const [biswapLastProfitCycle] = useMemberBiswapSmartFarmLastProfitCycleStats(props.username)
  const biswapStakedAmount = React.useMemo(
    () => (biswapLastProfitCycle ? add(biswapLastProfitCycle.firstCycleAmount, biswapLastProfitCycle.startNetAmount) : null),
    [biswapLastProfitCycle]
  )
  const [biswapNetworkLastProfitCycle] = useMemberNetworkBiswapSmartFarmLastProfitCycleStats(props.username)
  const biswapNetworkStakedAmount = React.useMemo(
    () =>
      biswapNetworkLastProfitCycle ? add(biswapNetworkLastProfitCycle.firstCycleAmount, biswapNetworkLastProfitCycle.startNetAmount) : null,
    [biswapNetworkLastProfitCycle]
  )

  // const orderedProducts = React.useMemo(() => {
  //   if (!products || !licenses) return []
  //   const list = map(licenses, ({ productId }, licenseKey) => ({
  //     name: products[productId] ? products[productId].name : '',
  //     productId,
  //     licenseKey,
  //     iconUrl: products[productId] ? products[productId].networkIconUrl : undefined,
  //   }))
  //   return orderBy(list, ['name'], ['asc'])
  // }, [products, licenses])

  const customerActivationTime = customerLicense ? customerLicense.activationTime : undefined
  const distributorActivationTime = distributorLicense ? distributorLicense.activationTime : undefined

  const [fullName, email]: [string | undefined, string | undefined] = React.useMemo(() => {
    if (!profileForInsiders) return [undefined, undefined]
    const fullName = `${profileForInsiders.name} ${profileForInsiders.lastName}`
    return [fullName, profileForInsiders.email]
  }, [profileForInsiders])

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.onClose}
      portalClassName="modal-portal"
      overlayClassName="overlay fullscreen-network"
      className="box-affiliate-overview"
    >
      <AffiliateInfo
        username={props.username}
        fullName={fullName}
        email={email}
        customerActivationTime={customerActivationTime}
        distributorActivationTime={distributorActivationTime}
        pictureVersion={profilePictureVersion}
        directSponsoreds={networkSummary && networkSummary.directsCount}
        indirectSponsoreds={networkSummary && networkSummary.indirectsCount}
        stakedCakeAmount={cakeStakedAmount}
        networkCakeAmount={cakeNetworkStakedAmount}
        stakedBananaAmount={bananaStakedAmount}
        networkBananaAmount={bananaNetworkStakedAmount}
        stakedBiswapAmount={biswapStakedAmount}
        networkBiswapAmount={biswapNetworkStakedAmount}
      />
      {/* <ProductsSection onClose={props.onClose}>
        {orderedProducts.map(({ productId, licenseKey, name, iconUrl }) => (
          <ProductItem key={licenseKey} productId={productId} name={name} iconUrl={iconUrl} />
        ))}
      </ProductsSection> */}
    </Modal>
  )
}

export default AffiliateOverview
