import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { InvoiceModalContext } from '../modals/invoice'
import { auth, db } from '../services/firebase'
import { useDatabaseObject, Invoice } from './database'

export type UseInvoiceHook = [Invoice | null, boolean, object | undefined]
export function useInvoice(invoiceId?: string | null): UseInvoiceHook {
  const invoiceRef = invoiceId ? db.child('invoices').child(invoiceId) : undefined
  const invoiceData = useDatabaseObject(invoiceRef)
  return [invoiceData.value || null, invoiceData.loading, invoiceData.error]
}

export interface UseInvoiceModalHook {
  showingInvoiceId?: string | null
  showModal: (invoiceId: string) => void
  closeModal: () => void
}
export const useInvoiceModal = (): UseInvoiceModalHook => {
  const { showModal, closeModal, invoiceId: showingInvoiceId } = React.useContext(InvoiceModalContext)
  return { showModal, closeModal, showingInvoiceId }
}

export type UseActiveProductInvoiceHook = [(Invoice & { invoiceId: string }) | null, boolean, object | undefined]
export function useActiveProductInvoice(productId?: string | null): UseActiveProductInvoiceHook {
  const { user, initialising } = useAuthState(auth)
  const invoicesQuery =
    !productId || initialising || !user
      ? undefined
      : db
        .child('invoices')
        .orderByChild('_indexes/payerUid__itemProductId__active')
        .equalTo(`${user.uid}__${productId}__${true}`)
        .limitToFirst(1)
  const invoices = useDatabaseObject(invoicesQuery)
  const invoice: (Invoice & { invoiceId: string }) | null = React.useMemo(() => {
    if (!invoices.value) return null
    const invoiceId = Object.keys(invoices.value)[0]
    return { ...invoices.value[invoiceId], invoiceId }
  }, [invoices.value])
  return [invoice, invoices.loading, invoices.error]
}

export type UseActiveDebitsInvoiceHook = [(Invoice & { invoiceId: string }) | null, boolean, object | undefined]
export function useActiveDebitsInvoice(): UseActiveDebitsInvoiceHook {
  const { user, initialising } = useAuthState(auth)
  const invoicesQuery =
    initialising || !user
      ? undefined
      : db
        .child('invoices')
        .orderByChild('_indexes/payerUid__itemType__active')
        .equalTo(`${user.uid}__debits__true`)
        .limitToFirst(1)
  const invoices = useDatabaseObject(invoicesQuery)
  const invoice: (Invoice & { invoiceId: string }) | null = React.useMemo(() => {
    if (!invoices.value) return null
    const invoiceId = Object.keys(invoices.value)[0]
    return { ...invoices.value[invoiceId], invoiceId }
  }, [invoices.value])
  return [invoice, invoices.loading, invoices.error]
}
