import { tokens } from '../assets'

export const swapTokensMetadata: { [symbol: string]: { name: string; icon: string } } = {
  USDT: { name: 'USD Tether', icon: tokens.usdtLogo },
  CAKE: { name: 'Cake', icon: tokens.cakeLogo },
  BUSD: { name: 'Binance USD', icon: tokens.busdLogo },
  BNB: { name: 'Binance Coin', icon: tokens.bnbLogo },
  BANANA: { name: 'Banana', icon: tokens.bananaLogo },
  SHIB: { name: 'Shiba Inu', icon: tokens.shibLogo },
  BSW: { name: 'Biswap', icon: tokens.biswapLogo },
}

export function getSwapTokenMetadata(symbol: string) {
  if (swapTokensMetadata[symbol]) return swapTokensMetadata[symbol]
  return { name: `Unsupported Token(${symbol})`, icon: String(tokens.biswapLogo) }
}

export function isValidSwapToken(symbol: string | undefined | null) {
  return (
    symbol === 'CAKE' ||
    symbol === 'USDT' ||
    symbol === 'BNB' ||
    symbol === 'BUSD' ||
    symbol === 'BANANA' ||
    symbol === 'SHIB' ||
    symbol === 'BSW'
  )
}
