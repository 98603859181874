import * as React from 'react'
import moment from 'moment'
import { MemberNotifications, Notification, Query } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useUid } from './current-user'
import { useDatabaseObject } from './database'

const MIN_TIMESTAMP = '0000000000000'
const MAX_TIMESTAMP = '9999999999999'

export function useNotificationBody(notificationId?: string): [Notification | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(notificationId ? db.child('notifications').child(notificationId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useMemberNotifications(
  filter: { read?: boolean; first?: number; last?: number; startTime?: number; endTime?: number } = {}
): [MemberNotifications | undefined, boolean, object | undefined] {
  const { read, first, last, startTime, endTime } = filter
  const [uid] = useUid()
  const queryRef = React.useMemo(() => {
    if (!uid) return
    let query: Query<MemberNotifications> = db.child('memberNotifications')
    if (first) query = query.limitToFirst(first)
    if (last) query = query.limitToLast(last)
    if (read === undefined) {
      if (startTime || endTime)
        query = query
          .orderByChild('_indexes/uid__deliveryTime')
          .startAt(`${uid}__${startTime || MIN_TIMESTAMP}`)
          .endAt(`${uid}__${endTime || MAX_TIMESTAMP}`)
      else query = query.orderByChild('uid').equalTo(uid)
    } else {
      if (read === true)
        query = query
          .orderByChild('_indexes/uid__readingTime')
          .startAt(`${uid}__${startTime || MIN_TIMESTAMP}`)
          .endAt(`${uid}__${endTime || MAX_TIMESTAMP}`)
      else query = query.orderByChild('_indexes/uid__readingTime').equalTo(`${uid}__undefined`)
    }
    return query
  }, [uid, read, first, last, startTime, endTime])
  const v = useDatabaseObject(queryRef)
  return [v.value, v.loading, v.error]
}

const getNotificationsInverval = () => ({
  startTime: moment().subtract(30, 'days').startOf('day').valueOf(),
  endTime: moment().add(1, 'day').endOf('day').valueOf(),
})

export function useMemberReadNotifications(): [MemberNotifications | undefined, boolean, object | undefined] {
  const [notificationsInterval, setNotificationsInterval] = React.useState(getNotificationsInverval)
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setNotificationsInterval(getNotificationsInverval())
    }, 60 * 60 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [setNotificationsInterval])
  const { startTime, endTime } = notificationsInterval

  return useMemberNotifications({ read: true, startTime, endTime })
}

export function useMemberUnreadNotifications(): [MemberNotifications | undefined, boolean, object | undefined] {
  return useMemberNotifications({ read: false })
}
