import React from 'react'
import { loading } from '../../assets'

const LoadingSpinner: React.SFC = () => (
  <div className="overlay-route">
    <img src={loading.loadingRoute} />
  </div>
)

export default LoadingSpinner
