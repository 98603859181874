import React from 'react'
import { t } from '../../i18n'
// import { SupportTicketType } from '../../hooks/database'

import Input from '../../components/shared/input'
import MemberContacts from './MemberContacts.container'
import { Button } from '../../components/shared'
import { openTicket } from '../../services/api'
import { SupportTicketType } from '../../hooks/database'

interface Props {
  // onOpenTicket: (params: { type: SupportTicketType; subject: string; description: string }) => Promise<void> | void
}

const OpeningTicketForm: React.FunctionComponent<Props> = () => {
  const [type, setType] = React.useState<string>('')
  const [subject, setSubject] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')

  const [loading, setLoading] = React.useState<boolean>(false)

  const handleChange = (changerFn: (v: string) => void) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    changerFn(e.target.value)
  }

  const disabled = loading || !type || !subject || !description

  const onOpenTicket = async () => {
    if (disabled) return
    setLoading(true)
    try {
      await openTicket({ type: type as SupportTicketType, subject, description })
      setType('')
      setSubject('')
      setDescription('')
    } catch (error) {}
    setLoading(false)
  }

  return (
    <div className="form-support">
      <p>{t('desk:support_page.ticket_opening_form.title')}</p>
      <MemberContacts />
      <div>
        <div className="input-medium i-stroke">
          <select value={type} onChange={handleChange(setType)}>
            <option value="">{`- ${t('desk:support_page.ticket_opening_form.type_select.label')} -`}</option>
            <option value="incident">{t('fields:ticket.type.options.incident')}</option>
            <option value="problem">{t('fields:ticket.type.options.problem')}</option>
            <option value="question">{t('fields:ticket.type.options.question')}</option>
            <option value="task">{t('fields:ticket.type.options.task')}</option>
          </select>
        </div>
        <div className="input-medium i-stroke">
          <Input
            placeholder={t('desk:support_page.ticket_opening_form.subject_input.label')}
            value={subject}
            onChange={handleChange(setSubject)}
          />
        </div>
        <div className="textarea-main i-stroke">
          <textarea
            placeholder={t('desk:support_page.ticket_opening_form.description_textfield.label')}
            value={description}
            onChange={handleChange(setDescription)}
          />
        </div>
        <div>
          <Button size="medium" type="primary" onClick={onOpenTicket} loading={loading} disabled={disabled}>
            {t('desk:support_page.ticket_opening_form.open_button.label')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OpeningTicketForm
