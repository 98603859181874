import React from 'react'
import { t } from '../../i18n'

interface Props {
  title: string
  onSeeAllClick?: () => void
}

const MarketingResourcesSectionGrid: React.SFC<Props> = props => {
  return (
    <div className="section-resources">
      <div className="title">
        <p>{props.title}</p>
        {props.onSeeAllClick && (
          <button className="btn-medium btn-primary" onClick={props.onSeeAllClick}>
            {t('common:see_all')}
          </button>
        )}
      </div>
      <div className="resources">{props.children}</div>
    </div>
  )
}

export default MarketingResourcesSectionGrid
