import React from 'react'
import { InvoiceItem } from '@allcoinwallet/inkryptus-db-schema'
import { t } from '../../i18n'
import { getWalletAddressExplorerUrl } from '../../utils/testnet'

import { Button } from '../../components/shared'
import InvoiceItemDetails from './InvoiceItemDetails.component';
import InvoiceItemPostPaymentInstruction from './InvoiceItemPostPaymentInstruction.component';

interface Props {
  item: InvoiceItem
  depositWalletAddress: string
  onCloseClick: () => void
}
const InvoiceExecuted: React.FunctionComponent<Props> = props => {
  return (
    <>
      <div>
        <p>{t('desk:invoice_modal.executed.menu_title')}</p>
      </div>
      <div>
        <p>{t('desk:invoice_modal.transaction_wallet_address')}</p>
        <a href={getWalletAddressExplorerUrl(props.depositWalletAddress)} target="_blank">
          {props.depositWalletAddress}
        </a>
      </div>
      <div>
        <InvoiceItemDetails item={props.item} paid />
      </div>

      <InvoiceItemPostPaymentInstruction item={props.item} />

      <Button size="medium" type="primary" onClick={props.onCloseClick}>
        {t('common:close')}
      </Button>
    </>
  )
}

export default InvoiceExecuted
