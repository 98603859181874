import React from 'react'
import { add } from '@allcoinwallet/invest-bitcoin-decimal'
import { useUsername } from '../../hooks/current-user'
import { useMemberNetwork, useMemberNetworkSummary } from '../../hooks/network'
// import { useMemberProductContractsRunning } from '../../hooks/product-contracts'
import {
  useMemberNetworkBananaSmartFarmLastProfitCycleStats,
  useMemberNetworkBiswapSmartFarmLastProfitCycleStats,
  useMemberNetworkCakeSmartFarmLastProfitCycleStats,
} from '../../hooks/stats'
import { tokens } from '../../assets'
import { formatNumber } from '../../utils/format'
import { DecimalFormatter } from '../../components/shared'

const MemberActiveProductItem: React.FC<{ productId: string }> = ({ productId }) => {
  const { username, loading: usernameLoading } = useUsername()

  // const [contracts, contractsLoading] = useMemberProductContractsRunning(productId)
  // const status = contractsLoading ? undefined : contracts ? 'ACTIVE' : 'INACTIVE'

  const [memberNetwork, memberNetworkLoading] = useMemberNetwork(8, username)
  const networkSummary = useMemberNetworkSummary(username, memberNetwork)
  const [cakeNetworkLastProfitCycle] = useMemberNetworkCakeSmartFarmLastProfitCycleStats(productId === 'cake-smart-farm' ? username : undefined)
  const [bananaNetworkLastProfitCycle] = useMemberNetworkBananaSmartFarmLastProfitCycleStats(
    productId === 'banana-smart-farm' ? username : undefined
  )
  const [biswapNetworkLastProfitCycle] = useMemberNetworkBiswapSmartFarmLastProfitCycleStats(
    productId === 'biswap-smart-farm' ? username : undefined
  )
  const networkLastProfitCycle = cakeNetworkLastProfitCycle || bananaNetworkLastProfitCycle || biswapNetworkLastProfitCycle
  const stakedAmount = React.useMemo(
    () => (networkLastProfitCycle ? add(networkLastProfitCycle.firstCycleAmount, networkLastProfitCycle.startNetAmount) : null),
    [networkLastProfitCycle]
  )

  const loading = usernameLoading || memberNetworkLoading // || contractsLoading

  const imgSrc = productId === 'banana-smart-farm' ? tokens.bananaLogo : productId === 'biswap-smart-farm' ? tokens.biswapLogo : tokens.cakeLogo
  const label = productId === 'banana-smart-farm' ? 'Banana' : productId === 'biswap-smart-farm' ? 'Biswap' : 'Cake'

  return (
    <div className="product">
      <div>
        <img src={imgSrc} alt={`${label} logo`} />
        <p>{label}</p>
      </div>
      <div>
        <p>Direct</p>
        <p>{loading ? '-' : formatNumber(networkSummary?.directsCount)}</p>
      </div>
      <div>
        <p>Indirect</p>
        <p>{loading ? '-' : formatNumber(networkSummary?.indirectsCount)}</p>
      </div>
      <div>
        <p>Network {label}</p>
        <p>{loading ? '-' : stakedAmount ? <DecimalFormatter value={stakedAmount} decimalPlaces={8} /> : '-'}</p>
      </div>
    </div>
  )
}

export default MemberActiveProductItem
