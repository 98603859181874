import React from 'react'
const FullscreenIcon: React.SFC<{ className?: string }> = props => {
  return (
    <svg className={props.className} width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1667 0H2.83333C1.29524 0 0 1.31378 0 2.8739V11.085C0 12.6862 1.29524 13.9589 2.83333 13.9589H14.1667C15.7452 13.9589 17 12.6452 17 11.085V2.8739C17 1.31378 15.7452 0 14.1667 0ZM14.1667 13.1378H2.83333C1.74048 13.1378 0.809524 12.2346 0.809524 11.085V2.8739C0.809524 1.7654 1.7 0.821114 2.83333 0.821114H14.1667C15.2595 0.821114 16.1905 1.72434 16.1905 2.8739V11.085C16.1905 12.2346 15.3 13.1378 14.1667 13.1378Z" fill="white" />
      <path d="M13.5595 11.0848C13.6404 11.1669 13.7214 11.208 13.8428 11.208C13.9642 11.208 14.0452 11.1669 14.1262 11.0848L14.1666 11.0437C14.2476 10.9616 14.2881 10.8795 14.2881 10.7563C14.2881 10.6332 14.2476 10.5511 14.1666 10.4689L11.1309 7.38977C10.969 7.22555 10.7262 7.22555 10.5642 7.38977L10.5238 7.43082C10.4428 7.51294 10.4023 7.59505 10.4023 7.71821C10.4023 7.84138 10.4428 7.92349 10.5238 8.0056L13.5595 11.0848Z" fill="white" />
      <path d="M15.1381 7.10254H15.0572C14.8548 7.10254 14.6524 7.26676 14.6524 7.5131V11.126C14.6524 11.3723 14.45 11.5776 14.2072 11.5776H10.4834C10.281 11.5776 10.0786 11.7418 10.0786 11.9882V12.0703C10.0786 12.2756 10.2405 12.4808 10.4834 12.4808H14.2072C14.9358 12.4808 15.5024 11.9061 15.5024 11.1671V7.55415C15.5024 7.30782 15.3405 7.10254 15.1381 7.10254Z" fill="white" />
      <path d="M3.44049 2.87414C3.27858 2.70992 3.03572 2.70992 2.87382 2.87414L2.83334 2.9152C2.75239 2.99731 2.71191 3.12048 2.71191 3.20259C2.71191 3.32576 2.75239 3.40787 2.83334 3.48998L5.90953 6.61022C5.99049 6.69233 6.07144 6.73338 6.19287 6.73338C6.31429 6.73338 6.39525 6.69233 6.4762 6.61022L6.51668 6.56916C6.67858 6.40494 6.67858 6.1586 6.51668 5.99438L3.44049 2.87414Z" fill="white" />
      <path d="M6.51661 1.51953H2.83327C2.1047 1.51953 1.49756 2.13537 1.49756 2.87437V6.48727C1.49756 6.69255 1.65946 6.89783 1.90232 6.89783H1.98327C2.18565 6.89783 2.38803 6.73361 2.38803 6.48727V2.87437C2.38803 2.62804 2.59042 2.42276 2.83327 2.42276H6.55708C6.75946 2.42276 6.96184 2.25853 6.96184 2.0122V1.93009C6.92137 1.72481 6.75946 1.51953 6.51661 1.51953Z" fill="white" />
    </svg>

  )
}

export default FullscreenIcon
