import React from 'react'
import { map, reverse, filter, sortBy } from 'lodash'
import moment from 'moment'
import { t } from '../../i18n'
import { db } from '../../services/firebase'
import { useUid } from '../../hooks/current-user'
import { useDatabaseObject, MemberEarnings, MemberEarning, Query, Reference } from '../../hooks/database'

import SmartTable, { SmartTableColumn, SmartTableSelectOption, useSmartTableSort } from '../../components/smart-table-new'
import { formatDateAndHours, formatUpperProductId } from '../../utils/format'
import { DecimalFormatter } from '../../components/shared'
import { tokens } from '../../assets'

const earningsRef = db.child('earnings').child('member')

type Row = MemberEarning & { id: string }

const selectButtonsOptions: SmartTableSelectOption[] = [
  { value: '', label: 'All' },
  { value: 'cake-smart-farm', label: 'Cake' },
  { value: 'banana-smart-farm', label: 'Banana' },
  { value: 'biswap-smart-farm', label: 'Biswap' },
]

const columns: SmartTableColumn<Row>[] = [
  {
    key: 'id',
    label: t('fields:earning.id.label'),
    render: (row) => <p>{row.id.substr(row.id.length - 9)}</p>,
  },
  {
    key: 'symbol',
    label: '#',
    render: (row) => {
      if (row.productId === 'banana-smart-farm') return <img src={tokens.bananaLogo} alt="Banana" />
      if (row.productId === 'biswap-smart-farm') return <img src={tokens.biswapLogo} alt="Biswap" />
      return <img src={tokens.cakeLogo} alt="Cake" />
    },
  },
  {
    key: 'productId',
    label: 'Product', // t('fields:earning.productId.label'),
    render: (row) => <p>{formatUpperProductId(row.productId)}</p>,
  },
  {
    key: 'type',
    label: 'Operation Type', // t('fields:earning.type.label'),
    render: (row) => <p>{`${t(`fields:earning.type.options.${row.type}`)} - @${row.sponsoredUsername}`}</p>,
  },
  {
    key: 'sponsoredLevel',
    label: 'Affiliate Level', // t('fields:earning.ofType.member.sponsoredLevel.label'),
    render: (row) => (
      <p>
        {row.director || row.sponsoredLevel === null || row.sponsoredLevel === undefined
          ? t('fields:earning.ofType.member.director')
          : t(`fields:earning.ofType.member.sponsoredLevel.${row.sponsoredLevel === 0 ? 'direct_value' : 'indirect_value'}`, {
              level: row.sponsoredLevel,
            })}
      </p>
    ),
  },
  {
    key: 'date',
    label: t('common:date'),
    sortable: true,
    render: (row) => <p>{formatDateAndHours(row.time)}</p>,
  },
  {
    key: 'amount',
    label: t('common:amount'),
    sortable: true,
    render: (row) => <p>{<DecimalFormatter value={row.amount} decimalPlaces={8} />}</p>,
  },
  {
    key: 'status',
    label: t('common:status'),
    // sortable: true,
    render: (row) => <p>{row.ineligible ? t('common:paid_not') : t('common:paid')}</p>,
  },
]

interface MemberEarningSelectOption {
  label: string
  onQuery: (
    ref: Reference<MemberEarnings>,
    uid: string,
    dateStartFilter: string,
    dateEndFilter: string
  ) => Reference<MemberEarnings> | Query<MemberEarnings>
  onFilter: (v: Row[]) => Row[]
}

function filterBySponsoredLevel(v: Row[], level: number): Row[] {
  return filter(v, (item) => item.sponsoredLevel === level)
}
const selectFilterOptions: MemberEarningSelectOption[] = [
  {
    label: 'Filter...',
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => v,
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.direct_value'),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 0),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 1 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 1),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 2 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 2),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 3 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 3),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 4 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 4),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 5 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 5),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 6 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 6),
  },
  {
    label: t('fields:earning.ofType.member.sponsoredLevel.indirect_value', { level: 7 }),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref.orderByChild('_indexes/uid__time').startAt(`${filterUid}__${filterDtStart}`).endAt(`${filterUid}__${filterDtEnd}`),
    onFilter: (v) => filterBySponsoredLevel(v, 7),
  },
  {
    label: t('common:paid'),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref
        .orderByChild('_indexes/uid__ineligible__time')
        .startAt(`${filterUid}__false__${filterDtStart}`)
        .endAt(`${filterUid}__false__${filterDtEnd}`),
    onFilter: (v) => v,
  },
  {
    label: t('common:paid_not'),
    onQuery: (ref, filterUid, filterDtStart, filterDtEnd) =>
      ref
        .orderByChild('_indexes/uid__ineligible__time')
        .startAt(`${filterUid}__true__${filterDtStart}`)
        .endAt(`${filterUid}__true__${filterDtEnd}`),
    onFilter: (v) => v,
  },
]
const selectFilterOptionsSimple = map(selectFilterOptions, (option, key) => ({ value: String(key), label: option.label }))

const MemberEarningsTable: React.FunctionComponent = () => {
  const [uid] = useUid()
  const [dateStart, setDateStart] = React.useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [dateEnd, setDateEnd] = React.useState(moment().format('YYYY-MM-DD'))

  const { activeSortColumnKey, activeSortDirection, toggleColumnSort } = useSmartTableSort({
    columnKey: 'date',
    direction: 'desc',
  })

  const [productIdFilter, setProductIdFilter] = React.useState('')
  const [selectedFilter, setSelectedFilter] = React.useState('0')

  const dateStartFilter = React.useMemo(() => (dateStart.length > 0 ? String(moment(dateStart).valueOf()) : '0000000000000'), [dateStart])
  const dateEndFilter = React.useMemo(() => (dateEnd.length > 0 ? String(moment(dateEnd).add(1, 'day').valueOf()) : '9999999999999'), [dateEnd])

  const earningsQuery = React.useMemo(() => {
    if (!uid) return
    const selectedFilterOption: MemberEarningSelectOption | undefined = selectedFilter ? selectFilterOptions[selectedFilter] : undefined
    if (selectedFilterOption) {
      return selectedFilterOption.onQuery(earningsRef, uid, dateStartFilter, dateEndFilter)
    }
    return earningsRef.orderByChild('_indexes/uid__time').startAt(`${uid}__${dateStartFilter}`).endAt(`${uid}__${dateEndFilter}`)
  }, [uid, dateStartFilter, dateEndFilter, selectedFilter])

  const { value: earnings } = useDatabaseObject(earningsQuery)

  const earningsList = React.useMemo(() => {
    if (!earnings) return []
    let list = map(earnings, (earning, id) => ({ ...earning, id }))
    const selectedFilterOption: MemberEarningSelectOption | undefined = selectedFilter ? selectFilterOptions[selectedFilter] : undefined
    if (selectedFilterOption) list = selectedFilterOption.onFilter(list)
    if (productIdFilter) list = filter(list, (row) => row.productId === productIdFilter)
    list = sortBy(list, (row) => {
      if (activeSortColumnKey === 'status') return row.ineligible ? 0 : 1
      if (activeSortColumnKey === 'amount') return Number(row.amount)
      return row.time
    })
    return activeSortDirection === 'asc' ? list : reverse(list)
  }, [earnings, selectedFilter, activeSortColumnKey, activeSortDirection, productIdFilter])

  return (
    <SmartTable
      columns={columns}
      rowKey="id"
      rows={earningsList}
      rowClassName={(row) => (row.ineligible ? 'warning' : undefined)}
      sort={{
        activeSortColumnKey,
        activeSortDirection,
        onSort: toggleColumnSort,
      }}
      filter={{
        selectButtonsOptions,
        selectedButtonOption: productIdFilter,
        setSelectedButtonOption: setProductIdFilter,
        dateStart,
        dateEnd,
        setDateStart,
        setDateEnd,
        selectedOption: selectedFilter,
        selectOptions: selectFilterOptionsSimple,
        setSelectedOption: setSelectedFilter,
      }}
    />
  )
}

export default MemberEarningsTable
