import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ProductSmartFarm from './ProductSmartFarm'

const ProductPage: React.FC<RouteComponentProps<{ productId: string }>> = (p) => {
  const history = p.history
  const { productId } = p.match.params
  React.useEffect(() => {
    if (['cake-smart-farm', 'biswap-smart-farm', 'banana-smart-farm'].includes(productId)) return
    history.push('/')
  }, [productId, history])
  const symbol = productId === 'biswap-smart-farm' ? 'BSW' : productId === 'banana-smart-farm' ? 'BANANA' : 'CAKE'
  const label = productId === 'biswap-smart-farm' ? 'Biswap' : productId === 'banana-smart-farm' ? 'Banana' : 'Cake'
  return <ProductSmartFarm productId={productId} symbol={symbol} label={label} />
}

export default ProductPage
