import React from 'react'
import Modal from 'react-modal'
import { useInvoice } from '../../hooks/invoice'
import LoadingSpinner from '../../components/loading-spinner'

import InvoiceAwaiting from './InvoiceAwaiting.component'
import InvoiceProcessing from './InvoiceProcessing.component'
import InvoiceExecuted from './InvoiceExecuted.component'
import InvoiceFailed from './InvoiceFailed.component'
import InvoiceExpired from './InvoiceExpired.component'

interface Props {
  invoiceId?: string | null
  onClose: () => void
}

const InvoiceModal: React.FunctionComponent<Props> = props => {
  const opened = !!props.invoiceId

  const [invoice, invoiceLoading] = useInvoice(props.invoiceId)

  const loading = invoiceLoading
  const awaiting = !!invoice && invoice.status === 'AWAITING'
  const processing = !!invoice && invoice.status === 'PROCESSING'
  const executed = !!invoice && invoice.status === 'EXECUTED'
  const failed = !!invoice && invoice.status === 'FAILED'
  const expired = !!invoice && invoice.status === 'EXPIRED'

  const getModalClassName = (): string => {
    if (awaiting) return 'box-payment'
    if (processing) return 'box-payment-processing'
    if (executed) return 'box-payment-finished'
    if (failed) return 'box-payment-processing' // TODO: create a className for failed state modal
    if (expired) return 'box-payment-processing' // TODO: create a className for expired state modal
    return 'box-payment-initial'
  }

  if (!opened) return null
  if (loading || !invoice) return <LoadingSpinner />

  return (
    <Modal
      isOpen={opened}
      onRequestClose={props.onClose}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className={getModalClassName()}
    >
      {awaiting && (
        <InvoiceAwaiting
          item={invoice.item}
          depositAmount={invoice.amount}
          quoteAmount={invoice.quoteAmount}
          depositWalletAddress={invoice.walletAddress}
          expirationTime={invoice.expirationTime}
          onCloseClick={props.onClose}
        />
      )}

      {processing && (
        <InvoiceProcessing
          item={invoice.item}
          depositWalletAddress={invoice.walletAddress}
          creationTime={invoice.creationTime}
          onCloseClick={props.onClose}
        />
      )}

      {executed && (
        <InvoiceExecuted
          item={invoice.item}
          depositWalletAddress={invoice.walletAddress}
          onCloseClick={props.onClose}
        />
      )}

      {expired && (
        <InvoiceExpired
          item={invoice.item}
          depositWalletAddress={invoice.walletAddress}
          creationTime={invoice.creationTime}
          onCloseClick={props.onClose}
        />
      )}

      {failed && (
        <InvoiceFailed
          item={invoice.item}
          depositWalletAddress={invoice.walletAddress}
          creationTime={invoice.creationTime}
          onCloseClick={props.onClose}
        />
      )}
    </Modal>
  )
}

export default InvoiceModal
