import React from 'react'
import InvoiceModal from './InvoiceModal.container'

export interface InvoiceModalContextValues {
  invoiceId?: string | null
  showModal: (invoiceId: string) => void
  closeModal: () => void
}
export const InvoiceModalContext = React.createContext<InvoiceModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const InvoiceModalProvider: React.FunctionComponent = props => {
  const [showingInvoiceId, setShowingInvoiceId] = React.useState<string | null>(null)
  const showModal = (invoiceId: string) => setShowingInvoiceId(invoiceId)
  const closeModal = () => setShowingInvoiceId(null)

  return (
    <InvoiceModalContext.Provider value={{ invoiceId: showingInvoiceId, showModal, closeModal }}>
      <InvoiceModal invoiceId={showingInvoiceId} onClose={closeModal} />
      {props.children}
    </InvoiceModalContext.Provider>
  )
}

export default InvoiceModalContext
