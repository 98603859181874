import React from 'react'
import { MemberDebit } from '@allcoinwallet/inkryptus-db-schema'
import { withRouter, RouteComponentProps } from 'react-router'
import moment from 'moment'
import { t } from '../../i18n'
import { useEstimateModal } from '../../hooks/estimate'
import { useInvoiceModal, useActiveProductInvoice } from '../../hooks/invoice'
import { useActiveCompanyCustomerLicense, useActiveCompanyDistributorLicense, useActiveProductLicense, useProduct } from '../../hooks/products'
import { useUsername } from '../../hooks/current-user'
import { useProductPlansModal } from '../../hooks/products'
import { usePlatform } from '../../hooks/capacitor'
import { useMemberProductDebits } from '../../hooks/debits'
import { Button } from '../shared'
import alrt from '../../utils/alrt'
import { formatCurrency, formatTimePeriodAsDate, formatDate, formatPercent, humanizeDays } from '../../utils/format'
import { icons } from '../../assets'
import ProductStatus from './ProductStatus.component'
import { toggleLicenseAutomaticRenewal } from '../../services/api'

interface Props {
  productId: string
  available?: boolean | null
  hasVariants?: boolean | null
  freeTrialDays?: number | null
  onClose?: () => void
}

const showUnavailableAlert = () =>
  alrt.info({
    title: t('desk:products_page.tab_marketplace.country_unavailable_alert.title') as string,
    body: t('desk:products_page.tab_marketplace.country_unavailable_alert.body') as string,
  })

const MarketplaceProductActionButton: React.FunctionComponent<Props & RouteComponentProps> = props => {
  const platform = usePlatform()
  const [product, loadingProduct] = useProduct(props.productId)
  const [invoice, loadingInvoice] = useActiveProductInvoice(props.productId)
  const [license, loadingLicense] = useActiveProductLicense(props.productId)
  const [debits, loadingDebits] = useMemberProductDebits(props.productId)
  const { username } = useUsername()
  const [distributorLicense, loadingDistributorLicense] = useActiveCompanyDistributorLicense(username)
  const [customerLicense, loadingCustomerLicense] = useActiveCompanyCustomerLicense(username)

  const { showModal: showEstimateModal } = useEstimateModal()
  const { showModal: showInvoiceModal } = useInvoiceModal()
  const { showModal: showProductPlanModal } = useProductPlansModal()

  const loading = loadingProduct || loadingInvoice || loadingLicense || loadingDebits || loadingDistributorLicense || loadingCustomerLicense

  const isCustomerProduct = props.productId === 'tradesoftware_customer'
  const isDistributorProduct = props.productId === 'tradesoftware'
  const isCompanyProduct = isDistributorProduct || isCustomerProduct
  const canBuySoftwares = !!distributorLicense || !!customerLicense

  const isProfitFeeParticipant = !!product && !!product.profitFeeMargin
  const hasLicense = !!license
  const hasInvoice = !!invoice

  const {
    hasDebits,
    lastProfitFeeDebit,
    isProfitFeeOverdue,
    lastLicenseRenewalDebit,
    isLicenseRenewalOverdue,
    nearDueDate,
  } = React.useMemo(() => {
    const debitStats: {
      hasDebits?: boolean
      lastProfitFeeDebit?: MemberDebit
      isProfitFeeOverdue?: boolean
      lastLicenseRenewalDebit?: MemberDebit
      isLicenseRenewalOverdue?: boolean
      nearDueDate?: number
    } = {}
    if (!debits) return debitStats
    for (const debitId in debits) {
      const debit = debits[debitId]
      debitStats.hasDebits = true
      if (!debitStats.nearDueDate || debit.dueDateTime < debitStats.nearDueDate) debitStats.nearDueDate = debit.dueDateTime
      const isOverdue = moment().isAfter(debit.dueDateTime)
      if (debit.type === 'profit_fee' && !!debit.profitFeeDetails) {
        if (debitStats.lastProfitFeeDebit && debitStats.lastProfitFeeDebit.referencePeriod.endingTime > debit.referencePeriod.endingTime)
          continue
        debitStats.lastProfitFeeDebit = debit
        if (isOverdue) debitStats.isProfitFeeOverdue = true
      }
      if (debit.type === 'license_renewal' && !!debit.licenseDetails) {
        if (
          debitStats.lastLicenseRenewalDebit &&
          debitStats.lastLicenseRenewalDebit.referencePeriod.endingTime > debit.referencePeriod.endingTime
        )
          continue
        debitStats.lastLicenseRenewalDebit = debit
        if (isOverdue) debitStats.isLicenseRenewalOverdue = true
      }
    }
    return debitStats
  }, [debits])

  const [payingDebits, setPayingDebits] = React.useState<boolean>(false)
  const onPayDebitsClick = React.useCallback(() => {
    props.history.push('/financial?iTab=debits')
    if (props.onClose) props.onClose()
  }, [payingDebits, setPayingDebits])

  const onInvoiceClick = React.useCallback(() => {
    if (!invoice) return
    showInvoiceModal(invoice.invoiceId)
  }, [invoice, showInvoiceModal])

  const onPurchaseClick = React.useCallback(() => {
    if (!props.available) return showUnavailableAlert()
    if (props.hasVariants) return showProductPlanModal(props.productId)
    showEstimateModal(props.productId)
  }, [props.available, props.hasVariants, props.productId, showUnavailableAlert, showProductPlanModal, showEstimateModal])
  const disabledPurchase = !!license || loading || (isDistributorProduct && !customerLicense) || (!isCompanyProduct && !canBuySoftwares)

  const [togglingAutomaticRenewal, setTogglingAutomaticRenewal] = React.useState<boolean>(false)
  const onToggleAutomaticRenewal = React.useCallback(async () => {
    if (togglingAutomaticRenewal) return
    if (!license) return
    setTogglingAutomaticRenewal(true)
    try {
      await toggleLicenseAutomaticRenewal(license.licenseKey)
    } catch (error) {}
    setTogglingAutomaticRenewal(false)
  }, [togglingAutomaticRenewal, setTogglingAutomaticRenewal, license])

  return (
    <div className="signature">
      <ProductStatus
        licensePrice={product ? product.licensePrice : undefined}
        licenseActivationTime={license ? license.activationTime : undefined}
        licenseExpirationTime={license ? license.expirationTime : undefined}
        licenseRenewalTime={license ? license.renewalActivationTime : undefined}
        hasDebits={hasDebits}
        debitDueDate={nearDueDate}
        freeTrialDays={product ? product.freeTrialDays : undefined}
        enabledAutomaticRenewal={license ? license.automaticRenewal : undefined}
        loading={loading}
        invoice={invoice}
      />

      {isProfitFeeParticipant && (
        <div className="profit-fee-bar">
          <div>
            <img src={icons.profitFee} />
            <div>
              <p>Profit Fee</p>
              <p>Program</p>
            </div>
          </div>
          <div>
            <a>{t('common:know_more')}</a>
          </div>
        </div>
      )}

      {isProfitFeeParticipant && !hasLicense && (
        <div className="profit-fee-description">
          <p>
            {t('components:marketplace_product_widget.profit_fee_section.product_parcitipation_note', {
              feeMargin: formatPercent(product ? product.profitFeeMargin : null),
            })}
          </p>
          <a href="#">{t('components:marketplace_product_widget.profit_fee_section.know_more_link')}</a>
        </div>
      )}

      {((isProfitFeeParticipant && hasLicense) || lastProfitFeeDebit) && (
        <div className="profit-fee-details">
          <div>
            <p>{t('components:marketplace_product_widget.profit_fee_section.period_label')}:</p>
            <p>
              {lastProfitFeeDebit
                ? formatTimePeriodAsDate(lastProfitFeeDebit.referencePeriod)
                : product
                ? `${product.licenseDurationDays} ${t('common:days')}`
                : '-'}
            </p>
          </div>
          <div>
            <p>{t('components:marketplace_product_widget.profit_fee_section.period_profit_label')}:</p>
            <p>
              {lastProfitFeeDebit && lastProfitFeeDebit.profitFeeDetails
                ? `${formatCurrency(lastProfitFeeDebit.profitFeeDetails.profitAmount, { noSymbol: true })} ${
                    lastProfitFeeDebit.profitFeeDetails.profitCurrencyCode
                  }`
                : t('common:in_progress')}
            </p>
          </div>
          <div>
            <p>Profit Fee* ({formatPercent(product ? product.profitFeeMargin : null)}):</p>
            <p className={isProfitFeeOverdue ? 'danger' : undefined}>
              {lastProfitFeeDebit ? formatCurrency(lastProfitFeeDebit.amount) : '$$$'}
            </p>
          </div>
          <p>*{t('components:marketplace_product_widget.profit_fee_section.alert_1')}</p>
        </div>
      )}
      {lastLicenseRenewalDebit && (
        <div className="license">
          <p>{t('components:marketplace_product_widget.license_section.title')}</p>
          <div>
            <p>{t('components:marketplace_product_widget.license_section.debit_amount_label')}</p>
            <p className={isLicenseRenewalOverdue ? 'danger' : undefined}>{formatCurrency(lastLicenseRenewalDebit.amount)}</p>
          </div>
          <div>
            <p>{t('components:marketplace_product_widget.license_section.debit_due_date_label')}</p>
            <p>{formatDate(lastLicenseRenewalDebit.dueDateTime)}</p>
          </div>
        </div>
      )}

      {hasDebits && (
        <div className="alert">
          <img src={icons.info} />
          <p>{t('components:marketplace_product_widget.debits_alert_note')}</p>
        </div>
      )}
      {platform !== 'ios' && (
        <div className="button-action">
          {hasDebits ? (
            <Button
              size="medium"
              type="success"
              onClick={onPayDebitsClick}
              loading={loading || payingDebits}
              disabled={loading || payingDebits}
            >
              {t('components:marketplace_product_widget.action_button.pay_debits')}
            </Button>
          ) : hasInvoice ? (
            <Button size="medium" type="info" onClick={onInvoiceClick} loading={loading} disabled={loading}>
              {t('components:marketplace_product_widget.action_button.follow_payment')}
            </Button>
          ) : hasLicense && !!license ? (
            <a className={togglingAutomaticRenewal ? '' : license.automaticRenewal ? 'danger' : 'success'} onClick={onToggleAutomaticRenewal}>
              {togglingAutomaticRenewal
                ? t('common:loading')
                : license.automaticRenewal
                ? t('components:marketplace_product_widget.action_button.disable_automatic_renewal')
                : t('components:marketplace_product_widget.action_button.enable_automatic_renewal')}
            </a>
          ) : (
            <Button size="medium" type="success" onClick={onPurchaseClick} loading={loading} disabled={disabledPurchase}>
              {props.freeTrialDays
                ? t('components:marketplace_product_widget.action_button.activate_free_trial', {
                    freeTrialDays: humanizeDays(props.freeTrialDays),
                  })
                : isDistributorProduct
                ? t('common:upgrade')
                : t('common:buy')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default withRouter(MarketplaceProductActionButton)
