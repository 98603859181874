import React from 'react'
import { Decimal, decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { Button } from '../../components/shared'
import { getSwapTokenMetadata } from '../../utils/swap'
import { icons } from '../../assets'
import { formatDecimal } from '../../utils/format'

export type SwapProcessStep = 'CONFIRMING' | 'PROCESSING' | 'FINISHED' | 'FAILED'

export interface SwapNewProcessFormState {
  fromSymbol: string
  toSymbol: string
  activeField: 'from' | 'to'
  fromAmount: decimal
  toAmount: decimal
  swapPrice: decimal
  feeAmount: decimal
}

interface Props extends Partial<SwapNewProcessFormState> {
  step: SwapProcessStep
  onConfirmClick: () => any
  onCloseClick: () => any
}

const SwapProcess: React.FC<Props> = React.memo(
  ({ step, fromSymbol, toSymbol, fromAmount, toAmount, swapPrice, feeAmount, onConfirmClick }) => {
    const fromSymbolMeta = React.useMemo(() => getSwapTokenMetadata(fromSymbol || ''), [fromSymbol])
    const toSymbolMeta = React.useMemo(() => getSwapTokenMetadata(toSymbol || ''), [toSymbol])

    return (
      <>
        {step === 'CONFIRMING' && (
          <div className="title">
            <p>Confirm Swap</p>
          </div>
        )}
        {step === 'PROCESSING' && (
          <div className="title">
            <p>Processing...</p>
          </div>
        )}
        {step === 'FAILED' && (
          <div className="title-image">
            <img src={icons.errorSwap} alt="Failed" />
            <p>Ops, something went wrong!</p>
          </div>
        )}
        {step === 'FINISHED' && (
          <div className="title-image">
            <img src={icons.successSwap} alt="Success" />
            <p>Swap Finished!</p>
          </div>
        )}
        {step === 'CONFIRMING' && (
          <>
            <div className="amount">
              <div>
                <img src={fromSymbolMeta.icon} width={24} height={24} />
                <p>{fromSymbolMeta.name}</p>
                <p>{fromAmount}</p>
              </div>
              <img src={icons.arrowLeft} alt="Conversion" width={24} height={24} />
              <div>
                <img src={toSymbolMeta.icon} width={24} height={24} />
                <p>{toSymbolMeta.name}</p>
                <p>{toAmount}</p>
              </div>
            </div>
            <div className="obs">
              <p>
                Output is estimated. You will receive at least {formatDecimal(toAmount || Decimal(0), 8)} {toSymbol} or the transaction will
                revert.
              </p>
            </div>
          </>
        )}
        <div className="estimate">
          <div>
            <p>Price:</p>
            <p>
              {formatDecimal(swapPrice || Decimal(0), 8)} {toSymbol}/{fromSymbol}
            </p>
          </div>
          <div>
            <p>Minimum received:</p>
            <p>
              {formatDecimal(toAmount || Decimal(0), 8)} {toSymbol}
            </p>
          </div>
          {/* <div>
            <p>Fee:</p>
            <p>~0.01%</p>
          </div> */}
          <div>
            <p>Fee:</p>
            <p>
              ~{formatDecimal(feeAmount || Decimal(0), 8)} {toSymbol}
            </p>
          </div>
        </div>
        {step === 'PROCESSING' && (
          <div className="loading">
            <div className="slider">
              <div className="line"></div>
              <div className="subline inc"></div>
              <div className="subline dec"></div>
            </div>
          </div>
        )}
        {step === 'CONFIRMING' && (
          <div className="button-action">
            <Button size="large" type="primary" onClick={onConfirmClick}>
              Swap
            </Button>
          </div>
        )}
      </>
    )
  }
)

export default SwapProcess
