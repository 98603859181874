import React from 'react'
import { useMemberProfile, useMemberProfilePictureUploader, usePublicProfile } from '../../../hooks/profile'
import { useUsername } from '../../../hooks/current-user'
import ProfilePictureChanger from './ProfilePictureChanger.component'

const ProfilePicture: React.SFC = () => {
  const { username } = useUsername()
  const [profile, profileLoading] = useMemberProfile()
  const [awaiting, setAwaiting] = React.useState<boolean>(false)

  const [uploaderFn, uploading] = useMemberProfilePictureUploader()
  const loading = profileLoading || uploading || awaiting

  const [publicProfile] = usePublicProfile(username)
  const profilePictureVersion = publicProfile ? publicProfile.pictureVersion : undefined

  const onFileSelect = async (file: File) => {
    if (loading) return
    setAwaiting(true)
    try {
      await uploaderFn(file)
    } catch (error) {
      setAwaiting(false)
    }
  }

  React.useEffect(() => {
    if (uploading) return
    if (!profilePictureVersion) return
    if (!awaiting) return
    const timeoutId = setTimeout(() => setAwaiting(false), 3000)
    return () => clearTimeout(timeoutId)
  }, [awaiting, uploading, profilePictureVersion])

  return (
    <ProfilePictureChanger
      name={profile ? `${profile.name} ${profile.lastName}` : null}
      username={username}
      pictureVersion={profilePictureVersion}
      loading={loading}
      onPictureLoad={onFileSelect}
    />
  )
}

export default ProfilePicture
