import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { EstimateModalContext } from '../modals/estimate'
import { EstimateDebitsModalContext } from '../modals/estimate-debits'
import { estimateInvoiceRate } from '../services/api'

export interface UseEstimateModalHook {
  showingEstimateProductId?: string | null
  showingEstimateProductVariant?: string | null
  showModal: (productId: string, productVariant?: string) => void
  closeModal: () => void
}
export const useEstimateModal = (): UseEstimateModalHook => {
  const { showModal, closeModal, productId: showingEstimateProductId, productVariant: showingEstimateProductVariant } = React.useContext(
    EstimateModalContext
  )
  return { showModal, closeModal, showingEstimateProductId, showingEstimateProductVariant }
}

export interface UseEstimateDebitsModalHook {
  showingEstimateDebitsIds?: string[] | null
  showModal: (debitsIds: string[]) => void
  closeModal: () => void
}
export const useEstimateDebitsModal = (): UseEstimateDebitsModalHook => {
  const { showModal, closeModal, debitsIds: showingEstimateDebitsIds } = React.useContext(EstimateDebitsModalContext)
  return { showModal, closeModal, showingEstimateDebitsIds }
}

type UseEstimateAmountHook = [decimal | null, boolean, () => void]
export function useEstimateAmount(price: decimal | null): UseEstimateAmountHook {
  const [depositAmount, setDepositAmount] = React.useState<decimal | null>(null)
  const [willFetch, setWillFetch] = React.useState<boolean>(true)
  const [loading, setLoading] = React.useState<boolean>(false)

  // TODO: Move this complex case to Orbit.js lib or simplify this logic
  React.useEffect(() => {
    if (loading || !willFetch) return
    if (price === null) {
      setWillFetch(false)
      setDepositAmount(null)
      return
    }
    setLoading(true)
    ;(async () => {
      try {
        const estimatedDepositAmount = await estimateInvoiceRate(price)
        setDepositAmount(estimatedDepositAmount)
      } catch (error) {
        setDepositAmount(null)
      }
      setWillFetch(false)
      setLoading(false)
    })()
  }, [willFetch])

  React.useEffect(() => {
    if (!willFetch) setWillFetch(true)
  }, [price])

  const reFetch = () => {
    if (!willFetch) setWillFetch(true)
  }
  return [depositAmount, loading || willFetch, reFetch]
}
