import * as React from 'react'

interface Props {
  class?: string
  disabled?: Boolean
  children: React.ReactNode
  value: string
  onBlur?: () => void
  onChange: (e: any) => void
}

const Select: React.FunctionComponent<Props> = props => {
  return (
    <select
      className={props.class}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled ? true : false}
    >
      {props.children}
    </select>
  )
}

export default Select
