import { Decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import React from 'react'
import { tokens } from '../../assets'
import { DecimalFormatter } from '../../components/shared'
import { useUsername } from '../../hooks/current-user'
import { useMemberEarningsSummary } from '../../hooks/earnings'

const MemberEarningsSummary: React.FunctionComponent = () => {
  const { username } = useUsername()

  const [cakeProfitFeeSummary] = useMemberEarningsSummary(username, 'CAKE', 'profit_fee')
  const [bananaProfitFeeSummary] = useMemberEarningsSummary(username, 'BANANA', 'profit_fee')
  const [biswapProfitFeeSummary] = useMemberEarningsSummary(username, 'BSW', 'profit_fee')

  const totalProfitFee = {
    CAKE: cakeProfitFeeSummary?.total,
    BANANA: bananaProfitFeeSummary?.total,
    BSW: biswapProfitFeeSummary?.total,
  }

  const directProfitFee = {
    CAKE: cakeProfitFeeSummary?.direct,
    BANANA: bananaProfitFeeSummary?.direct,
    BSW: biswapProfitFeeSummary?.direct,
  }

  const indirect1ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level1,
    BANANA: bananaProfitFeeSummary?.level1,
    BSW: biswapProfitFeeSummary?.level1,
  }
  const indirect2ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level2,
    BANANA: bananaProfitFeeSummary?.level2,
    BSW: biswapProfitFeeSummary?.level2,
  }
  const indirect3ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level3,
    BANANA: bananaProfitFeeSummary?.level3,
    BSW: biswapProfitFeeSummary?.level3,
  }
  const indirect4ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level4,
    BANANA: bananaProfitFeeSummary?.level4,
    BSW: biswapProfitFeeSummary?.level4,
  }
  const indirect5ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level5,
    BANANA: bananaProfitFeeSummary?.level5,
    BSW: biswapProfitFeeSummary?.level5,
  }
  const indirect6ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level6,
    BANANA: bananaProfitFeeSummary?.level6,
    BSW: biswapProfitFeeSummary?.level6,
  }
  const indirect7ProfitFee = {
    CAKE: cakeProfitFeeSummary?.level7,
    BANANA: bananaProfitFeeSummary?.level7,
    BSW: biswapProfitFeeSummary?.level7,
  }

  return (
    <div className="commission-overview">
      <div className="commission-product">
        <div className="total-bar">
          <img src={tokens.cakeLogo} alt="cake" />
          <p>Cake Stake</p>
          <div>
            <p>Total</p>
            <p>
              <DecimalFormatter value={totalProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
        </div>
        <div className="referral-bar">
          <div>
            <p>Directs</p>
            <p>
              <DecimalFormatter value={directProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 1</p>
            <p>
              <DecimalFormatter value={indirect1ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 2</p>
            <p>
              <DecimalFormatter value={indirect2ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 3</p>
            <p>
              <DecimalFormatter value={indirect3ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 4</p>
            <p>
              <DecimalFormatter value={indirect4ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 5</p>
            <p>
              <DecimalFormatter value={indirect5ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 6</p>
            <p>
              <DecimalFormatter value={indirect6ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 7</p>
            <p>
              <DecimalFormatter value={indirect7ProfitFee['CAKE'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
        </div>
      </div>
      <div className="commission-product">
        <div className="total-bar">
          <img src={tokens.bananaLogo} alt="banana" />
          <p>Banana Stake</p>
          <div>
            <p>Total</p>
            <p>
              <DecimalFormatter value={totalProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
        </div>
        <div className="referral-bar">
          <div>
            <p>Directs</p>
            <p>
              <DecimalFormatter value={directProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 1</p>
            <p>
              <DecimalFormatter value={indirect1ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 2</p>
            <p>
              <DecimalFormatter value={indirect2ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 3</p>
            <p>
              <DecimalFormatter value={indirect3ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 4</p>
            <p>
              <DecimalFormatter value={indirect4ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 5</p>
            <p>
              <DecimalFormatter value={indirect5ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 6</p>
            <p>
              <DecimalFormatter value={indirect6ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 7</p>
            <p>
              <DecimalFormatter value={indirect7ProfitFee['BANANA'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
        </div>
      </div>
      <div className="commission-product">
        <div className="total-bar">
          <img src={tokens.biswapLogo} alt="biswap" />
          <p>Biswap Stake</p>
          <div>
            <p>Total</p>
            <p>
              <DecimalFormatter value={totalProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
        </div>
        <div className="referral-bar">
          <div>
            <p>Directs</p>
            <p>
              <DecimalFormatter value={directProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 1</p>
            <p>
              <DecimalFormatter value={indirect1ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 2</p>
            <p>
              <DecimalFormatter value={indirect2ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 3</p>
            <p>
              <DecimalFormatter value={indirect3ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 4</p>
            <p>
              <DecimalFormatter value={indirect4ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 5</p>
            <p>
              <DecimalFormatter value={indirect5ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 6</p>
            <p>
              <DecimalFormatter value={indirect6ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
          <div>
            <p>Level 7</p>
            <p>
              <DecimalFormatter value={indirect7ProfitFee['BSW'] || Decimal(0)} decimalPlaces={8} />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberEarningsSummary
