import { Plugins } from '@capacitor/core'
import { useState, useEffect } from 'react'

export function usePlatform(): string {
  const [platform, setPlatform] = useState('')

  async function getPlatform() {
    const { Device } = Plugins
    const info = await Device.getInfo()
    setPlatform(info.platform)
  }

  useEffect(() => {
    getPlatform()
  })

  return platform
}
