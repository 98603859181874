import * as React from 'react'
import { t } from '../../../i18n'

type SizeTypes = 'small' | 'medium' | 'large'
type ButtonTypes = 'success' | 'primary' | 'info' | 'danger' | 'disable' | 'opaque'

interface Props {
  size: SizeTypes
  type?: ButtonTypes
  disabled?: boolean
  class?: string
  children: string
  loading?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button: React.FunctionComponent<Props> = (props) => {
  const type = props.disabled ? 'disable' : props.type
  const sizeClassName = 'btn-' + props.size
  const typeClassName = type ? `btn-${type}` : 'btn-primary'
  const propsClassNames = props.class ? props.class : ''
  const onClick = props.disabled ? undefined : props.onClick
  return (
    <button onClick={onClick} className={`${sizeClassName} ${typeClassName} ${propsClassNames}`}>
      {props.loading ? t('loading') : props.children}
    </button>
  )
}

export default Button
