import React from 'react'
import Modal from 'react-modal'

import SwapDepositWallet from './SwapDepositWallet.component'

interface Props {
  symbol?: string | null
  onClose: () => void
}

const SwapDepositWalletModal: React.FunctionComponent<Props> = (props) => {
  const close = props.onClose

  return (
    <Modal
      isOpen={!!props.symbol}
      onRequestClose={close}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-swap-deposit-modal"
    >
      <SwapDepositWallet symbol={props.symbol} onCloseClick={close} />
    </Modal>
  )
}

export default SwapDepositWalletModal
