import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { InvoiceItem } from '@allcoinwallet/inkryptus-db-schema'
import QRCode from 'qrcode.react'
import { t } from '../../i18n'
import { icons } from '../../assets'
import { formatCurrency } from '../../utils/format'
import { copyToClipboard } from '../../utils/copy-to-clipboard'

import InvoiceModalMenu from './InvoiceModalMenu.component'
import { Button } from '../../components/shared'
import CountdownTimer from './CountdownTimer.component'
import InvoiceItemDetails from './InvoiceItemDetails.component'

const COPIED_TOOLTIP_HIDE_TIMEOUT = 2000

interface Props {
  item: InvoiceItem
  depositAmount: decimal
  quoteAmount: decimal
  depositWalletAddress: string
  expirationTime: number
  onCloseClick: () => void
}
const InvoiceAwaiting: React.FunctionComponent<Props> = (props) => {
  const [copiedRecently, setCopiedRecently] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (!copiedRecently) return
    const timeoutId = setTimeout(() => setCopiedRecently(false), COPIED_TOOLTIP_HIDE_TIMEOUT)
    return () => clearTimeout(timeoutId)
  }, [copiedRecently])

  const onCopy = () => {
    copyToClipboard(props.depositWalletAddress)
    setCopiedRecently(true)
  }
  return (
    <>
      <InvoiceModalMenu title={t('desk:invoice_modal.awaiting.menu_title')} />
      <div className="content">
        <div className={`info-payment ${copiedRecently ? 'showing-copy-tooltip' : ''}`}>
          <div>
            <div>
              <p>{t('desk:invoice_modal.awaiting.payment_status')}</p>
              <p>
                <CountdownTimer timestamp={props.expirationTime} />
              </p>
            </div>
            <div>
              <p className="font-small">
                {t('fields:product.licensePrice.label')}
                {': '}
                {formatCurrency(props.quoteAmount)}
              </p>
              <p className="font-large">
                <small className="font-prefix">btc</small>
                {props.depositAmount}
              </p>
            </div>
            <div>
              <p>{t('fields:invoice.walletAddress.label')}</p>
              <div className="input-large i-fill" onClick={onCopy}>
                <input type="text" value={props.depositWalletAddress} />
              </div>
            </div>
            <div>
              <p>{t('desk:invoice_modal.awaiting.copy_tooltip_success')}</p>
            </div>
          </div>
          <div>
            <QRCode
              value={props.depositWalletAddress}
              size={128}
              bgColor="#ffffff"
              fgColor="#000000"
              level="L"
              includeMargin={true}
              renderAs="svg"
            />
          </div>
        </div>
        <div className="info-product">
          <InvoiceItemDetails item={props.item} />
          <div>
            <img src={icons.info} />
            <p>{t('desk:invoice_modal.awaiting.instruction')}</p>
          </div>
          <Button size="medium" type="primary" onClick={props.onCloseClick}>
            {t('common:close')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default InvoiceAwaiting
