import React from 'react'
import { t } from '../../i18n'

interface Props {
  title: string
  onBackClick?: () => void
}

const MarketingResourcesSectionGrid: React.SFC<Props> = props => {
  return (
    <div className="section-resources">
      <div className="title">
        <p>{props.title}</p>
        {props.onBackClick && (
          <button className="btn-medium btn-danger" onClick={props.onBackClick}>
            {t('common:back')}
          </button>
        )}
      </div>
      <div className="box-marketing">{props.children}</div>
    </div>
  )
}

export default MarketingResourcesSectionGrid
