import { MemberNotification } from '@allcoinwallet/inkryptus-db-schema'
import { reverse, size, sortBy, toArray } from 'lodash'
import * as React from 'react'
import { useHistory } from 'react-router'
import { icons } from '../../../assets'
import { useMemberNotifications, useMemberUnreadNotifications, useNotificationBody } from '../../../hooks/notifications'
import { humanizeTimeFromNow } from '../../../utils/format'

export const NotificationsHeaderBadge: React.FC = () => {
  const history = useHistory()
  // const [readNotifications] = useMemberReadNotifications()
  const [readNotifications] = useMemberNotifications({ read: true, last: 3 })
  const [unreadNotifications] = useMemberUnreadNotifications()

  const unreadNotificationsCounter = React.useMemo(() => size(unreadNotifications), [unreadNotifications])

  const notifications = React.useMemo(() => {
    let list: Array<MemberNotification> = []
    if (unreadNotifications) {
      list = reverse(sortBy(toArray(unreadNotifications), 'deliveryTime'))
    }
    if (list.length < 3 && readNotifications) {
      const readList = reverse(sortBy(readNotifications, 'deliveryTime'))
      for (const notification of readList) {
        list.push(notification)
        if (list.length >= 3) break
      }
    }
    return reverse(sortBy(list, 'deliveryTime'))
  }, [readNotifications, unreadNotifications])

  const showMoreNotificationsClick = React.useCallback(() => {
    history.push('/notifications')
  }, [history])

  return (
    <div className="notification">
      <img src={icons.notification} alt="Notification" />
      {!!unreadNotificationsCounter && <span>{unreadNotificationsCounter > 9 ? '+' : unreadNotificationsCounter}</span>}
      <div className={`dropdown ${notifications.length ? '' : 'no-message'}`}>
        <div className="title">
          {!!notifications.length && (
            <p>
              {unreadNotificationsCounter} new notification{unreadNotificationsCounter === 1 ? '' : 's'}
            </p>
          )}

          {!notifications.length && <p>No new notifications</p>}
        </div>
        {!!notifications.length && (
          <>
            <div className="content">
              {notifications.map((notification) => (
                <NotificationHeaderListItem
                  key={notification.notificationId}
                  notificationId={notification.notificationId}
                  deliveryTime={notification.deliveryTime}
                />
              ))}
            </div>
            <p className="view-more" onClick={showMoreNotificationsClick}>
              View more
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const NotificationHeaderListItem: React.FC<{ notificationId: string; deliveryTime: number }> = ({ notificationId, deliveryTime }) => {
  const history = useHistory()
  const goToNotificationsPage = React.useCallback(() => {
    history.push(`/notifications?clicked_notification_id=${notificationId}`)
  }, [notificationId, history])

  const [notification] = useNotificationBody(notificationId)

  return (
    <div onClick={goToNotificationsPage}>
      <p>{notification?.title}</p>
      <p>{humanizeTimeFromNow(deliveryTime)}</p>
    </div>
  )
}
