import React from 'react'

const HomeIconSvg: React.SFC = () => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 6.99952L11 1.73952C10.45 1.24756 9.7379 0.975586 8.99997 0.975586C8.26204 0.975586 7.55 1.24756 6.99997 1.73952L0.999973 6.99952C0.682341 7.28359 0.428873 7.63207 0.256445 8.02176C0.0840165 8.41145 -0.00341463 8.8334 -2.72762e-05 9.25952V17.9995C-2.72762e-05 18.7952 0.316043 19.5582 0.878652 20.1208C1.44126 20.6834 2.20432 20.9995 2.99997 20.9995H15C15.7956 20.9995 16.5587 20.6834 17.1213 20.1208C17.6839 19.5582 18 18.7952 18 17.9995V9.24952C18.0019 8.82508 17.9138 8.40506 17.7414 8.0172C17.5691 7.62934 17.3163 7.28247 17 6.99952ZM11 18.9995H6.99997V13.9995C6.99997 13.7343 7.10533 13.4799 7.29287 13.2924C7.4804 13.1049 7.73476 12.9995 7.99997 12.9995H9.99997C10.2652 12.9995 10.5195 13.1049 10.7071 13.2924C10.8946 13.4799 11 13.7343 11 13.9995V18.9995ZM16 17.9995C16 18.2647 15.8946 18.5191 15.7071 18.7066C15.5195 18.8942 15.2652 18.9995 15 18.9995H13V13.9995C13 13.2039 12.6839 12.4408 12.1213 11.8782C11.5587 11.3156 10.7956 10.9995 9.99997 10.9995H7.99997C7.20432 10.9995 6.44126 11.3156 5.87865 11.8782C5.31604 12.4408 4.99997 13.2039 4.99997 13.9995V18.9995H2.99997C2.73476 18.9995 2.4804 18.8942 2.29287 18.7066C2.10533 18.5191 1.99997 18.2647 1.99997 17.9995V9.24952C2.00015 9.10753 2.03057 8.96721 2.08919 8.8379C2.14781 8.70858 2.2333 8.59323 2.33997 8.49952L8.33997 3.24952C8.52246 3.0892 8.75706 3.00078 8.99997 3.00078C9.24288 3.00078 9.47748 3.0892 9.65997 3.24952L15.66 8.49952C15.7666 8.59323 15.8521 8.70858 15.9108 8.8379C15.9694 8.96721 15.9998 9.10753 16 9.24952V17.9995Z" fill="#5D6579"/>
    </svg>
  )
}

export default HomeIconSvg
