import React from 'react'
import { useUid } from './current-user'
import { ShareInvitationLinkModalContext } from '../modals/share-invitation-link'
import { InviteToOurSocialNetworksModalContext } from '../modals/invite-to-our-social-networks'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'
import { InviteToOurSocialNetworksReadingStatus } from '@allcoinwallet/inkryptus-db-schema'

export interface UseShareInvitationLinkModalHook {
  showingUsername?: string | null
  showModal: (username: string) => void
  closeModal: () => void
}
export const useShareInvitationLinkModal = (): UseShareInvitationLinkModalHook => {
  const { showModal, closeModal, username: showingUsername } = React.useContext(ShareInvitationLinkModalContext)
  return { showModal, closeModal, showingUsername }
}

export interface UseInviteToOurSocialNetworksModalHook {
  showing?: boolean
  showModal: () => void
  closeModal: () => void
}
export const useInviteToOurSocialNetworksModal = (): UseInviteToOurSocialNetworksModalHook => {
  const [readingStatus, loading] = useInviteToOurSocialNetworksReadStatus()
  const { showModal, closeModal, showing } = React.useContext(InviteToOurSocialNetworksModalContext)
  return { showModal, closeModal, showing: showing === undefined ? !loading && !readingStatus : showing }
}

export const useInviteToOurSocialNetworksReadStatus = (): [InviteToOurSocialNetworksReadingStatus | null, boolean, object | undefined] => {
  const [uid, initialising] = useUid()
  const readingStatusRef = uid ? db.child('inviteToOurSocialNetworksReadingStatuses').child(uid) : undefined
  const { value: status, loading, error } = useDatabaseObject(readingStatusRef)
  return [status || null, loading || initialising, error]
}
