import React from 'react'

const ProductsIconSvg: React.SFC = () => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.47 6.37005C17.47 6.37005 17.47 6.37005 17.47 6.29005L17.41 6.14005C17.3891 6.10822 17.3657 6.07812 17.34 6.05005C17.3133 6.00772 17.2832 5.96759 17.25 5.93005L17.16 5.86005L17 5.78005L9.50002 1.15005C9.34109 1.05072 9.15744 0.998047 8.97002 0.998047C8.7826 0.998047 8.59895 1.05072 8.44002 1.15005L1.00002 5.78005L0.910023 5.86005L0.820023 5.93005C0.786874 5.96759 0.756778 6.00772 0.730023 6.05005C0.704315 6.07812 0.680906 6.10822 0.660023 6.14005L0.600023 6.29005C0.600023 6.29005 0.600023 6.29005 0.600023 6.37005C0.590194 6.45644 0.590194 6.54366 0.600023 6.63005V15.3701C0.599683 15.54 0.642659 15.7072 0.724894 15.8559C0.807129 16.0047 0.92591 16.13 1.07002 16.22L8.57002 20.85C8.61618 20.8786 8.66693 20.8989 8.72002 20.91C8.72002 20.91 8.77002 20.91 8.80002 20.91C8.9692 20.9637 9.15084 20.9637 9.32002 20.91C9.32002 20.91 9.37002 20.91 9.40002 20.91C9.45311 20.8989 9.50386 20.8786 9.55002 20.85L17 16.22C17.1441 16.13 17.2629 16.0047 17.3452 15.8559C17.4274 15.7072 17.4704 15.54 17.47 15.3701V6.63005C17.4799 6.54366 17.4799 6.45644 17.47 6.37005ZM8.00002 18.21L2.50002 14.8101V8.43005L8.00002 11.82V18.21ZM9.00002 10.09L3.40002 6.63005L9.00002 3.18005L14.6 6.63005L9.00002 10.09ZM15.5 14.8101L10 18.21V11.82L15.5 8.43005V14.8101Z" fill="#5D6579"/>
    </svg>
  )
}

export default ProductsIconSvg
