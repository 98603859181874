import React from 'react'
import { InvoiceItem } from '@allcoinwallet/inkryptus-db-schema'
import { size } from 'lodash'
import { t } from '../../i18n'
import { humanizeDays, formatUpperProductId, formatCurrency } from '../../utils/format'
import { Button } from '../../components/shared'
import { icons } from '../../assets'

interface Props {
  paid?: boolean
  item: InvoiceItem
}

const InvoiceItemDetails: React.SFC<Props> = props => {
  if (props.item.type === 'debits') {
    return (
      <>
        <div>
          <p>{t('desk:invoice_modal.invoice_item.title')}</p>
        </div>
        <div>
          <p>{t('desk:invoice_modal.invoice_item.debits_title')}</p>
          <p>
            {size(props.item.debits)} {t('common:debits')}
          </p>
        </div>
      </>
    )
  }

  if (props.item.type === 'operational_limit') {
    return (
      <>
        <div>
          <p>{t('desk:invoice_modal.invoice_item.title')}</p>
        </div>
        <div>
          <p>{t('desk:invoice_modal.invoice_item.operational_limit_title')}</p>
          <p>
            {t('common:operational_limit')}
            {': + '}
            {formatCurrency(props.item.limit)}
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      {props.paid ? (
        <img src={icons.pdfLicense} />
      ) : (
        <div>
          <p>{t('desk:invoice_modal.invoice_item.title')}</p>
        </div>
      )}
      <div>
        <p>{t('desk:invoice_modal.invoice_item.license_title', { duration: humanizeDays(props.item.licenseDurationDays) })}</p>
        <p>{formatUpperProductId(props.item.productId, props.item.productVariant)}</p>
      </div>
      {props.paid && (
        <Button size="medium" type="primary">
          {t('common:download')}
        </Button>
      )}
    </>
  )
}

export default InvoiceItemDetails
