import axios, { AxiosInstance } from 'axios'
import { get } from 'lodash'
import { Decimal, decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { SupportTicketType } from '@allcoinwallet/inkryptus-db-schema'
import config from '../config'
import { getLanguage } from '../i18n'
import { auth } from '../services/firebase'
import FormError from '../utils/form-errors'
import HumanizedError from '../utils/humanized-error'
import alrt from '../utils/alrt'

async function getApiInstance() {
  const headers: { [key: string]: string } = {}
  headers['User-Language'] = getLanguage()
  headers['Origin-App'] = 'webapp'
  if (auth.currentUser) headers['User-Id-Token'] = await auth.currentUser.getIdToken()
  const instance: AxiosInstance = axios.create({ baseURL: config.apiUrl, headers })
  return instance
}

export const urls = {
  openAcwAccount: new URL('/wallet_addresses/signup_url', config.apiUrl),
}

interface RequestOptions {
  alert?: boolean
  alertError?: boolean
}
const defaultRequestOptions: RequestOptions = {
  alert: false,
}
async function req<R = any>(
  method: 'post' | 'get' | 'patch' | 'delete',
  path: string,
  payload: any,
  options: RequestOptions = defaultRequestOptions
): Promise<R> {
  const api = await getApiInstance()
  const tz = Date.now()
  console.log(`${tz}: Request started`, { req: { method, path, payload } })
  try {
    const response = await api.request({ method, url: path, data: payload })
    const { data, status } = response
    console.log(`${tz}: Request finishes`, { req: { method, path, payload }, res: { status, data } })
    if (options.alert) {
      const alertMessage = get(data, 'humanizedMessage', null)
      alrt.success({ body: alertMessage })
    }
    return data
  } catch (e) {
    console.log(`${tz}: Request fails`, { req: { method, path, payload }, error: e })
    if ((e as any).request) {
      const { data } = (e as any).response
      if (data && data.formErrors) {
        throw new FormError(data.formErrors, data.message)
      }
      if (data && data.humanizedMessage) {
        if (options.alert) {
          alrt.error({ body: data.humanizedMessage })
        }
        throw new HumanizedError(data.humanizedMessage, data.message)
      }
      if (options.alert || options.alertError) alrt.error()
    }
    throw e
  }
}

export interface SignupCreateAccountProps {
  name: string
  lastName: string
  username: string
  sponsorUsername: string
  password: string
  email: string
  country: string
  phone: string
  birthDate: string
  gender: 'male' | 'female' | 'other' | 'undefined'
}
export async function signupCreateAccount(props: SignupCreateAccountProps): Promise<void> {
  await req('post', '/signup', props)
}

export async function signupCheckUsername(props: { username: string }): Promise<{ isSponsor: boolean; isAvailable: boolean }> {
  const data = await req('post', '/signup/check_username', props)
  return data
}

export interface AcceptTermsProps {
  termsOfUsage: boolean
  privacyPolicy: boolean
}
export async function signupAcceptTerms(props: AcceptTermsProps): Promise<void> {
  await req('post', '/signup/agreement', props)
}

export async function verifyAgreementAcceptance(props: AcceptTermsProps): Promise<void> {
  await req('post', '/agreement', props, { alert: true })
}

export async function sendConfirmationEmail(): Promise<void> {
  await req('get', '/signup/confirmation_email', {})
}

export async function changeSignupEmail(email: string): Promise<void> {
  await req('post', '/signup/email', { email })
}

export async function reportTokenRecognition(jwt: string): Promise<{ actions: { [actionName: string]: any } }> {
  const data = await req('post', '/token_recognition', { jwt })
  return data
}

export interface TestProductActivation {
  productId: string
}
export interface TestProductActivationResponse {
  licenseKey: string
  expirationTime: number
  email: string
  openingLink: string
}
export async function testProductActivation(props: TestProductActivation): Promise<TestProductActivationResponse> {
  return req<TestProductActivationResponse>('post', `/products/${props.productId}/test_activation`, props)
}

export async function attachMemberWalletAddress(walletAddress: string): Promise<void> {
  await req('post', '/wallet_addresses/member_attachment/', { walletAddress }, { alert: true })
}

export async function estimateInvoiceRate(price: decimal): Promise<decimal> {
  const estimateAmount: decimal = await req('post', '/invoices/deposit_amount_estimate/', { price })
  return estimateAmount
}

export async function activateFreeTrial(productId: string, productVariant?: string): Promise<void> {
  await req('post', `/products/${productId}/free_trial`, { productVariant }, { alertError: true })
}

export async function createInvoice(productId: string, productVariant?: string): Promise<string> {
  const response: { invoiceId: string } = await req('post', '/invoices', { productId, productVariant }, { alertError: true })
  return response.invoiceId
}

export async function createDebitsInvoice(debitsIds: string[]): Promise<string> {
  const response: { invoiceId: string } = await req('post', '/invoices/with_debits', { debitsIds }, { alertError: true })
  return response.invoiceId
}

export async function toggleLicenseAutomaticRenewal(licenseKey: string): Promise<void> {
  await req('post', `/licenses/${licenseKey}/toggle_automatic_renewal`, {}, { alertError: true })
}

export async function refreshProfileLang(lang: string): Promise<void> {
  await req('post', '/profile/lang', { lang })
}

export async function updateProfileField(fieldName: 'birthDate' | 'gender' | 'phone', fieldValue: string): Promise<void> {
  await req('patch', '/profile', { fieldName, fieldValue })
}

export async function requestAccountDeletion(): Promise<void> {
  await req('delete', '/account', {})
}

export async function requestResetPassword(usernameOrEmail: string): Promise<void> {
  await req('post', '/reset_password', { usernameOrEmail })
}

export async function resetPassword(jwt: string, password: string): Promise<void> {
  await req('post', '/password', { jwt, password })
}

export async function sendEmailInvitation(destinationName: string, destinationEmail: string): Promise<void> {
  await req('post', '/invitations/email', { destinationName, destinationEmail }, { alert: true })
}

export async function openTicket(params: { type: SupportTicketType; subject: string; description: string }): Promise<void> {
  await req('post', '/support_tickets', params, { alert: true })
}

export async function markInviteToOurSocialNetworksAsReaded(): Promise<void> {
  await req('post', '/invite_to_our_social_networks/mark_as_readed', {})
}

export async function requestWalletHarvest(symbol: string): Promise<{ transactionId: string }> {
  return await req('post', `/wallets/${symbol}/harvest/`, {})
}

export async function requestWalletCompound(symbol: string): Promise<{ transactionId: string }> {
  return await req('post', `/wallets/${symbol}/compound/`, {})
}

export async function fundProductContract(symbol: string, productId: string, grossAmount: decimal): Promise<{ productContractId: string }> {
  return await req('post', `/wallets/${symbol}/fund_product_contract/`, { productId, grossAmount })
}

export async function requestProductContractDeactivation(productId: string, contractId: string): Promise<void> {
  await req('delete', `/products/${productId}/contracts/${contractId}/`, {})
}

export async function requestWalletWithdrawal(symbol: string, address: string, grossAmount: decimal): Promise<{ transactionId: string }> {
  const data = await req('post', `/swap_wallets/${symbol}/withdrawals/`, { address, grossAmount })
  return { transactionId: data.transactionId }
}

export async function requestWalletWithdrawalResendConfirmation(symbol: string, transactionId: string): Promise<void> {
  await req('get', `/swap_wallets/${symbol}/withdrawals/${transactionId}/confirmation`, {})
}

export async function requestSwapWalletWithdrawal(symbol: string, address: string, grossAmount: decimal): Promise<{ transactionId: string }> {
  const data = await req('post', `/swap_wallets/${symbol}/withdrawals/`, { address, grossAmount })
  return { transactionId: data.transactionId }
}

export async function requestSwapWalletWithdrawalResendConfirmation(symbol: string, transactionId: string): Promise<void> {
  await req('get', `/swap_wallets/${symbol}/withdrawals/${transactionId}/confirmation`, {})
}

export async function processSwapOfSwapWallet(
  fromSymbol: string,
  toSymbol: string,
  params: { activeField: 'from' | 'to'; fromAmount: decimal; toAmount: decimal }
): Promise<{ transactionId: string }> {
  const data = await req('post', `/swap_wallets/${fromSymbol}/swap/${toSymbol}/`, params)
  return { transactionId: data.transactionId }
}

export async function calculateSwapFromInputOfSwapWallet(
  fromSymbol: string,
  toSymbol: string,
  fromAmount: decimal
): Promise<{ estimatedToAmount: decimal; toAmountSafeMargin: decimal; estimatedToAmountWithSafeMargin: decimal }> {
  const data = await req('post', `/swap_wallets/${fromSymbol}/calculate_swap_from/${toSymbol}/`, { fromAmount })
  return {
    estimatedToAmount: Decimal(data.estimatedToAmount),
    toAmountSafeMargin: Decimal(data.toAmountSafeMargin),
    estimatedToAmountWithSafeMargin: Decimal(data.estimatedToAmountWithSafeMargin),
  }
}

export async function calculateSwapFromOutputOfSwapWallet(
  fromSymbol: string,
  toSymbol: string,
  toAmount: decimal
): Promise<{ estimatedFromAmount: decimal; fromAmountSafeMargin: decimal; estimatedFromAmountWithSafeMargin: decimal }> {
  const data = await req('post', `/swap_wallets/${fromSymbol}/calculate_swap_to/${toSymbol}/`, { toAmount })
  return {
    estimatedFromAmount: Decimal(data.estimatedFromAmount),
    fromAmountSafeMargin: Decimal(data.fromAmountSafeMargin),
    estimatedFromAmountWithSafeMargin: Decimal(data.estimatedFromAmountWithSafeMargin),
  }
}

export async function markNotificationAsRead(notificationId: string): Promise<void> {
  await req('patch', `/members_notifications/${notificationId}/`, {})
}
