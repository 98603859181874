import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ProductNewGridItem from './ProductNewGridItem'
import BoxView from '../../components/box-view'
import { icons } from '../../assets'

const ProductsNewGridPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <BoxView label="Products" icon={icons.iconProduct} className="box-products-overview">
      <ProductNewGridItem label="Cake" productId="cake-smart-farm" symbol="CAKE" />
      <ProductNewGridItem label="Banana" productId="banana-smart-farm" symbol="BANANA" />
      <ProductNewGridItem label="Biswap" productId="biswap-smart-farm" symbol="BSW" />
    </BoxView>
  )
}

export default ProductsNewGridPage
