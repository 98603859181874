import React from 'react'
import { Decimal, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { filter, map, reverse, sortBy } from 'lodash'
import moment from 'moment'
import { t } from '../../i18n'
import { tokens } from '../../assets'
import { db } from '../../services/firebase'
import { useDatabaseObject, WalletTransaction } from '../../hooks/database'
import { useUid } from '../../hooks/current-user'
import { formatDate, formatHours } from '../../utils/format'

import SmartTable, { SmartTableColumn, SmartTableSelectOption, useSmartTableSort } from '../../components/smart-table-new'
import { DecimalFormatter } from '../../components/shared'
import { WalletTransactionLink } from './WalletTransactionLink'

type Row = WalletTransaction & { transactionId: string }

const selectButtonsOptions: SmartTableSelectOption[] = [
  { value: '', label: 'All' },
  { value: 'CAKE', label: 'Cake' },
  { value: 'BANANA', label: 'Banana' },
  { value: 'BSW', label: 'Biswap' },
]

const columns: SmartTableColumn<Row>[] = [
  {
    key: 'symbol',
    label: '#',
    render: (row) => {
      if (row.symbol === 'BANANA') return <img src={tokens.bananaLogo} alt="Banana" />
      if (row.symbol === 'BSW') return <img src={tokens.biswapLogo} alt="Biswap" />
      if (row.symbol === 'USDT') return <img src={tokens.usdtLogo} alt="USD Tether" />
      if (row.symbol === 'BUSD') return <img src={tokens.busdLogo} alt="BUSD" />
      if (row.symbol === 'SHIB') return <img src={tokens.shibLogo} alt="Shiba Inu" />
      return <img src={tokens.cakeLogo} alt="Cake" />
    },
  },
  {
    key: 'id',
    label: 'ID',
    render: (row) => <WalletTransactionLink transactionId={row.transactionId} transaction={row} />,
  },
  {
    key: 'timestamp',
    label: 'Time',
    sortable: true,
    render: (row) => <p>{`${formatDate(row.timestamp)} | ${formatHours(row.timestamp)}`}</p>,
  },
  {
    key: 'type',
    label: t('common:type'),
    render: (row) => <p>{t(`fields:walletTransaction.type.ofType.${row.type}`, row.type)}</p>,
  },
  {
    key: 'status',
    label: t('common:status', 'Status'),
    render: (row) => (
      <p className={row.confirmationTime ? 'success' : !row.failureReason ? 'warning' : ''}>
        {row.confirmationTime ? 'CONFIRMED' : row.failureReason ? 'FAILED' : 'PROCESSING'}
      </p>
    ),
  },
  {
    key: 'gross',
    label: 'Gross',
    sortable: true,
    render: (row) => (
      <p>
        <DecimalFormatter value={row.amount} />
      </p>
    ),
  },
  {
    key: 'fee',
    label: 'Fee',
    sortable: true,
    render: (row) => (
      <p>
        <DecimalFormatter value={row.type !== 'deposit' ? row.feeAmount : Decimal(0)} />
      </p>
    ),
  },
  {
    key: 'net',
    label: 'Net',
    sortable: true,
    render: (row) => (
      <span className={row.type === 'withdrawal' || row.type === 'product_contract_fund' ? 'danger' : 'success'}>
        <DecimalFormatter value={row.type !== 'deposit' ? sub(row.amount, row.feeAmount) : row.amount} />
      </span>
    ),
  },
]

const MemberWalletTable: React.FunctionComponent = () => {
  const [uid] = useUid()
  const [dateStart, setDateStart] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  const [dateEnd, setDateEnd] = React.useState(moment().format('YYYY-MM-DD'))

  const { activeSortColumnKey, activeSortDirection, toggleColumnSort } = useSmartTableSort({
    columnKey: 'timestamp',
    direction: 'desc',
  })
  const [symbolFilter, setSymbolFilter] = React.useState<string>('')

  const transactionsQuery = uid
    ? db
        .child('walletsTransactions')
        .orderByChild('_indexes/userId__timestamp')
        .startAt(`${uid}__${dateStart.length > 0 ? String(moment(dateStart).valueOf()) : '0000000000000'}`)
        .endAt(`${uid}__${dateEnd.length > 0 ? String(moment(dateEnd).add(1, 'day').valueOf()) : '9999999999999'}`)
    : undefined
  const transactions = useDatabaseObject(transactionsQuery)

  const transactionsList = React.useMemo(() => {
    if (!transactions.value) return []
    let list = map(transactions.value, ({ _indexes, ...t }, transactionId) => ({ ...t, transactionId }))
    if (symbolFilter) {
      list = filter(list, (row) => row.symbol === symbolFilter)
    }
    if (activeSortColumnKey === 'gross') {
      list = sortBy(list, (row) => Number(row.amount))
    } else if (activeSortColumnKey === 'fee') {
      list = sortBy(list, (row) => (row.type !== 'deposit' ? Number(row.feeAmount) : 0))
    } else if (activeSortColumnKey === 'net') {
      list = sortBy(list, (row) => Number(row.type !== 'deposit' ? sub(row.amount, row.feeAmount) : row.amount))
    } else {
      list = sortBy(list, ['timestamp', 'confirmationTime'])
    }
    return activeSortDirection === 'asc' ? list : reverse(list)
  }, [transactions.value, activeSortColumnKey, activeSortDirection, symbolFilter])

  return (
    <SmartTable
      columns={columns}
      rowKey="transactionId"
      rows={transactionsList}
      sort={{
        activeSortDirection,
        activeSortColumnKey,
        onSort: toggleColumnSort,
      }}
      filter={{
        selectButtonsOptions,
        setSelectedButtonOption: setSymbolFilter,
        selectedButtonOption: symbolFilter,
        dateStart,
        dateEnd,
        setDateStart,
        setDateEnd,
      }}
    />
  )
}

export default MemberWalletTable
