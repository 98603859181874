import React from 'react'
import { Announcement, MemberShowingAnnouncements, MemberShowingAnnouncement } from '@allcoinwallet/inkryptus-db-schema'
import { merge, first, keys } from 'lodash'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'
import { useUid, useUsername } from './current-user'
import { lang, DEFAULT_LANGUAGE } from '../i18n'

export function useAnnouncement(announcementId?: string): [Announcement | undefined, boolean, object | undefined] {
  const [uid] = useUid()
  const announcementDefaultRef =
    uid && announcementId
      ? db
          .child('announcements')
          .child(announcementId)
          .child(DEFAULT_LANGUAGE)
      : undefined
  const announcementDefault = useDatabaseObject(announcementDefaultRef)
  const announcementLocalizedRef =
    uid && announcementId && lang !== DEFAULT_LANGUAGE
      ? db
          .child('announcements')
          .child(announcementId)
          .child(lang)
      : undefined
  const announcementLocalized = useDatabaseObject(announcementLocalizedRef)
  const announcement =
    announcementDefault || announcementLocalized ? merge({}, announcementDefault.value, announcementLocalized.value) : undefined
  return [announcement, announcementDefault.loading || announcementLocalized.loading, announcementDefault.error || announcementLocalized.error]
}

export function useMemberShowingAnnouncements(): [MemberShowingAnnouncements | undefined, boolean, object | undefined] {
  const { username } = useUsername()
  const generalShowingAnnouncementsRef = username ? db.child('membersShowingAnnouncements').child('_all') : undefined
  const generalShowingAnnouncements = useDatabaseObject(generalShowingAnnouncementsRef)
  const memberShowingAnnouncementsRef = username ? db.child('membersShowingAnnouncements').child(username) : undefined
  const memberShowingAnnouncements = useDatabaseObject(memberShowingAnnouncementsRef)
  React.useDebugValue({ generalShowingAnnouncements, memberShowingAnnouncements })
  const showingAnnouncements = React.useMemo(() => {
    if (!generalShowingAnnouncements.value && !memberShowingAnnouncements.value) return undefined
    const announcements = merge({}, generalShowingAnnouncements.value, memberShowingAnnouncements.value)
    const now = Date.now()
    for (const announcementId in announcements) {
      if (announcements[announcementId].expiresIn > now) continue
      delete announcements[announcementId]
    }
    return announcements
  }, [generalShowingAnnouncements.value, memberShowingAnnouncements.value])
  return [
    showingAnnouncements,
    generalShowingAnnouncements.loading || memberShowingAnnouncements.loading,
    generalShowingAnnouncements.error || memberShowingAnnouncements.error,
  ]
}

export function useMemberShowingAnnouncement(): [
  (MemberShowingAnnouncement & { announcementId: string }) | undefined,
  boolean,
  object | undefined
] {
  const [announcements, loading, error] = useMemberShowingAnnouncements()
  const announcement = React.useMemo(() => {
    if (!announcements) return
    const announcementId = first(keys(announcements))
    if (!announcementId) return
    return { ...announcements[announcementId], announcementId }
  }, [announcements])
  return [announcement, loading, error]
}
