import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { requestResetPassword } from '../../services/api'
import FormPasswordResetRequest from './FormPasswordResetRequest.component'

const RequestPasswordReset: React.SFC<RouteComponentProps> = props => {
  const onRequest = async (usernameOrEmail: string) => {
    await requestResetPassword(usernameOrEmail)
  }
  return <FormPasswordResetRequest onRequest={onRequest} onClickBack={props.history.goBack} />
}

export default RequestPasswordReset
