import React from 'react'
import Modal from 'react-modal'

import AppModeSwitch from './AppModeSwitch.component'

interface Props {
  nextMode?: string | null
  onConfirm: () => void
  onClose: () => void
}

const AppModeSwitchModal: React.FunctionComponent<Props> = (props) => {
  const close = props.onClose
  const confirm = props.onConfirm

  React.useEffect(
    () => {
      return
      // if (transactionError) close()
    },
    [
      /* close */
    ]
  )

  const onConfirmClick = React.useCallback(async () => {
    confirm()
    close()
  }, [close, confirm])

  // if () return <LoadingSpinner />

  return (
    <Modal
      isOpen={!!props.nextMode}
      onRequestClose={close}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-switch-platform"
    >
      <AppModeSwitch nextMode={props.nextMode} onConfirmClick={onConfirmClick} onCloseClick={close} />
    </Modal>
  )
}

export default AppModeSwitchModal
