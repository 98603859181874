import React from 'react'
import { decimal, Decimal, add, mul } from '@allcoinwallet/invest-bitcoin-decimal'
import { useHistory } from 'react-router'
import { size /*, transform, isEqual, isObject */ } from 'lodash'
import PendingAgreementVerification from '../../modals/pending-agreement-verification'
import Announcement from '../../modals/announcement'
import BoxModal from './BoxModal.component'
import { tokens } from '../../assets'
import Button from '../../components/shared/button'
import { useStatuses } from '../../hooks/current-user'
import { useMemberShowingAnnouncement } from '../../hooks/announcements'
import { useMemberProductContractsRunning, useMemberProductContractsStopped } from '../../hooks/product-contracts'
import {
  useCompanyLastOverviewStats,
  useMemberCakeSmartFarmLastProfitCycleStats,
  useMemberBananaSmartFarmLastProfitCycleStats,
  useMemberBiswapSmartFarmLastProfitCycleStats,
} from '../../hooks/stats'
import { formatCurrency, formatPercent } from '../../utils/format'
import { useSwapRates } from '../../hooks/rates'
import { useMemberEarningsSummary } from '../../hooks/earnings'
import { useMemberNetwork } from '../../hooks/network'

const Home: React.FunctionComponent = () => {
  const { statuses } = useStatuses()
  const [announcement] = useMemberShowingAnnouncement()
  const pendingAgreementVerification =
    !!statuses && (!statuses.verifiedTermsOfUsageEmailAcceptance || !statuses.verifiedPrivacyPolicyEmailAcceptance)

  const history = useHistory()

  const goToCakeProduct = React.useCallback(() => {
    history.push('/products/cake-smart-farm')
  }, [history])
  const goToBananaProduct = React.useCallback(() => {
    history.push('/products/banana-smart-farm')
  }, [history])
  const goToBiswapProduct = React.useCallback(() => {
    history.push('/products/biswap-smart-farm')
  }, [history])

  const username = statuses?.username

  const [swapRates] = useSwapRates()
  const cakePrice = swapRates?.CAKE?.rates?.USDT
  const bananaPrice = swapRates?.BANANA?.rates?.USDT
  const biswapPrice = swapRates?.BSW?.rates?.USDT

  const [companyOverviewStats, companyOverviewStatsLoading] = useCompanyLastOverviewStats()
  const last7DaysCakeProfit = companyOverviewStats ? mul(companyOverviewStats.cakeSmartFarmProfit || Decimal(0), Decimal(7)) : undefined
  const last7DaysBananaProfit = companyOverviewStats ? mul(companyOverviewStats.bananaSmartFarmProfit || Decimal(0), Decimal(7)) : undefined
  const last7DaysBiswapProfit = companyOverviewStats ? mul(companyOverviewStats.biswapSmartFarmProfit || Decimal(0), Decimal(7)) : undefined

  const productsContractsCount =
    (companyOverviewStats?.cakeSmartFarmContractsCount || 0) +
    (companyOverviewStats?.biswapSmartFarmContractsCount || 0) +
    (companyOverviewStats?.bananaSmartFarmContractsCount || 0)

  const totalCakeStakingQuoteAmount =
    companyOverviewStats && cakePrice ? mul(companyOverviewStats.cakeSmartFarmTotalNetAmount || Decimal(0), cakePrice) : Decimal(0)
  const totalCakeProfitQuoteAmount =
    companyOverviewStats && cakePrice ? mul(companyOverviewStats.cakeSmartFarmTotalProfitAmount || Decimal(0), cakePrice) : Decimal(0)
  const totalBananaStakingQuoteAmount =
    companyOverviewStats && bananaPrice ? mul(companyOverviewStats.bananaSmartFarmTotalNetAmount || Decimal(0), bananaPrice) : Decimal(0)
  const totalBananaProfitQuoteAmount =
    companyOverviewStats && bananaPrice ? mul(companyOverviewStats.bananaSmartFarmTotalProfitAmount || Decimal(0), bananaPrice) : Decimal(0)
  const totalBiswapStakingQuoteAmount =
    companyOverviewStats && biswapPrice ? mul(companyOverviewStats.biswapSmartFarmTotalNetAmount || Decimal(0), biswapPrice) : Decimal(0)
  const totalBiswapProfitQuoteAmount =
    companyOverviewStats && biswapPrice ? mul(companyOverviewStats.biswapSmartFarmTotalProfitAmount || Decimal(0), biswapPrice) : Decimal(0)
  const totalStakingQuoteAmount = add(totalCakeStakingQuoteAmount, add(totalBananaStakingQuoteAmount, totalBiswapStakingQuoteAmount))
  const totalProfitQuoteAmount = add(totalCakeProfitQuoteAmount, add(totalBananaProfitQuoteAmount, totalBiswapProfitQuoteAmount))

  const [memberCakeProfitCycleStats] = useMemberCakeSmartFarmLastProfitCycleStats(username)
  const [memberBananaProfitCycleStats] = useMemberBananaSmartFarmLastProfitCycleStats(username)
  const [memberBiswapProfitCycleStats] = useMemberBiswapSmartFarmLastProfitCycleStats(username)

  // My All Earnings
  const [currentRunningCakeContracts, currentRunningCakeContractsLoading] = useMemberProductContractsRunning('cake-smart-farm')
  const [currentStoppedCakeContracts, currentStoppedCakeContractsLoading] = useMemberProductContractsStopped('cake-smart-farm')
  const [currentRunningBananaContracts, currentRunningBananaContractsLoading] = useMemberProductContractsRunning('banana-smart-farm')
  const [currentStoppedBananaContracts, currentStoppedBananaContractsLoading] = useMemberProductContractsStopped('banana-smart-farm')
  const [currentRunningBiswapContracts, currentRunningBiswapContractsLoading] = useMemberProductContractsRunning('biswap-smart-farm')
  const [currentStoppedBiswapContracts, currentStoppedBiswapContractsLoading] = useMemberProductContractsStopped('biswap-smart-farm')

  const [myAllTimeContractsCount, myAlltimeContractsProfitQuoteAmount] = React.useMemo((): [Number, decimal | undefined] => {
    if (currentRunningCakeContractsLoading || currentStoppedCakeContractsLoading) return [0, undefined]
    if (currentRunningBananaContractsLoading || currentStoppedBananaContractsLoading) return [0, undefined]
    if (currentRunningBiswapContractsLoading || currentStoppedBiswapContractsLoading) return [0, undefined]
    if (!cakePrice || !bananaPrice || !biswapPrice) return [0, undefined]
    let totalProfitQuoteAmount = Decimal(0)
    let count = 0
    const contracts = {
      ...currentRunningCakeContracts,
      ...currentStoppedCakeContracts,
      ...currentRunningBananaContracts,
      ...currentStoppedBananaContracts,
      ...currentRunningBiswapContracts,
      ...currentStoppedBiswapContracts,
    }
    for (const contractId in contracts) {
      const contract = contracts[contractId]
      count += 1
      const profitAmount = add(contract.totalProfitFeeAmount || Decimal(0), contract.totalProfitNetAmount || Decimal(0))
      const price =
        contract.productId === 'biswap-smart-farm' ? biswapPrice : contract.productId === 'banana-smart-farm' ? bananaPrice : cakePrice
      const profitQuoteAmount = mul(profitAmount, price)
      totalProfitQuoteAmount = add(totalProfitQuoteAmount, profitQuoteAmount)
    }
    return [count, totalProfitQuoteAmount]
  }, [
    currentRunningCakeContracts,
    currentRunningCakeContractsLoading,
    currentStoppedCakeContracts,
    currentStoppedCakeContractsLoading,
    currentRunningBananaContracts,
    currentRunningBananaContractsLoading,
    currentStoppedBananaContracts,
    currentStoppedBananaContractsLoading,
    currentRunningBiswapContracts,
    currentRunningBiswapContractsLoading,
    currentStoppedBiswapContracts,
    currentStoppedBiswapContractsLoading,
    cakePrice,
    bananaPrice,
    biswapPrice,
  ])

  const memberNextCycleCakeStakeAmount = memberCakeProfitCycleStats
    ? add(memberCakeProfitCycleStats.firstCycleAmount, memberCakeProfitCycleStats.startNetAmount)
    : undefined
  const memberNextCycleCakeStakeQuoteAmount =
    memberNextCycleCakeStakeAmount && cakePrice ? mul(memberNextCycleCakeStakeAmount || Decimal(0), cakePrice) : Decimal(0)
  const memberNextCycleBananaStakeAmount = memberBananaProfitCycleStats
    ? add(memberBananaProfitCycleStats.firstCycleAmount, memberBananaProfitCycleStats.startNetAmount)
    : undefined
  const memberNextCycleBananaStakeQuoteAmount =
    memberNextCycleBananaStakeAmount && bananaPrice ? mul(memberNextCycleBananaStakeAmount || Decimal(0), bananaPrice) : Decimal(0)
  const memberNextCycleBiswapStakeAmount = memberBiswapProfitCycleStats
    ? add(memberBiswapProfitCycleStats.firstCycleAmount, memberBiswapProfitCycleStats.startNetAmount)
    : undefined
  const memberNextCycleBiswapStakeQuoteAmount =
    memberNextCycleBiswapStakeAmount && biswapPrice ? mul(memberNextCycleBiswapStakeAmount || Decimal(0), biswapPrice) : Decimal(0)
  const memberNextCycleStakeQuoteAmount = add(
    memberNextCycleCakeStakeQuoteAmount,
    add(memberNextCycleBananaStakeQuoteAmount, memberNextCycleBiswapStakeQuoteAmount)
  )

  const [myAllTimeCakeProfitFeeEarnings] = useMemberEarningsSummary(username, 'CAKE', 'profit_fee')
  const [myAllTimeBananaProfitFeeEarnings] = useMemberEarningsSummary(username, 'BANANA', 'profit_fee')
  const [myAllTimeBiswapProfitFeeEarnings] = useMemberEarningsSummary(username, 'BSW', 'profit_fee')
  // React.useEffect(() => {
  //   console.log(JSON.stringify(myAllTimeProfitFeeEarningsBySymbol))
  // }, [myAllTimeProfitFeeEarningsBySymbol])
  const allTimeCakeProfitFeeQuoteAmount =
    myAllTimeCakeProfitFeeEarnings?.total && cakePrice ? mul(myAllTimeCakeProfitFeeEarnings.total, cakePrice) : Decimal(0)
  const allTimeBananaProfitFeeQuoteAmount =
    myAllTimeBananaProfitFeeEarnings?.total && bananaPrice ? mul(myAllTimeBananaProfitFeeEarnings.total, bananaPrice) : Decimal(0)
  const allTimeBiswapProfitFeeQuoteAmount =
    myAllTimeBiswapProfitFeeEarnings?.total && biswapPrice ? mul(myAllTimeBiswapProfitFeeEarnings.total, biswapPrice) : Decimal(0)
  const allTimeProfitFeeQuoteAmount = add(
    allTimeCakeProfitFeeQuoteAmount,
    add(allTimeBananaProfitFeeQuoteAmount, allTimeBiswapProfitFeeQuoteAmount)
  )

  const [memberNetwork] = useMemberNetwork(8, username)
  const membersNetworkCount = React.useMemo(() => size(memberNetwork), [memberNetwork])

  // const lastValuesRef = React.useRef<any>({})
  // React.useEffect(() => {
  //   function difference(object: any, base: any) {
  //     function changes(obj: any, b: any) {
  //       return transform(obj, function (result: any, value, key) {
  //         if (!isEqual(value, b[key])) {
  //           result[key] = isObject(value) && isObject(b[key]) ? changes(value, b[key]) : value
  //         }
  //       })
  //     }
  //     return changes(object, base)
  //   }
  //   const debugValues: any = {
  //     swapRates: { cakePrice, bananaPrice, biswapPrice },
  //     generalStats: {
  //       last7DaysCakeProfit,
  //       last7DaysBananaProfit,
  //       last7DaysBiswapProfit,
  //       productsContractsCount,
  //       companyOverviewStats,
  //       totalCakeStakingQuoteAmount,
  //       totalCakeProfitQuoteAmount,
  //       totalBananaStakingQuoteAmount,
  //       totalBananaProfitQuoteAmount,
  //       totalBiswapStakingQuoteAmount,
  //       totalBiswapProfitQuoteAmount,
  //       totalStakingQuoteAmount,
  //       totalProfitQuoteAmount,
  //     },
  //     profitCycle: {
  //       memberCakeProfitCycleStats,
  //       memberBananaProfitCycleStats,
  //       memberBiswapProfitCycleStats,
  //     },
  //     contracts: {
  //       myAllTimeContractsCount,
  //       myAlltimeContractsProfitQuoteAmount,
  //       myAllTimeCakeProfitFeeEarnings,
  //       myAllTimeBananaProfitFeeEarnings,
  //       myAllTimeBiswapProfitFeeEarnings,
  //       allTimeCakeProfitFeeQuoteAmount,
  //       allTimeBananaProfitFeeQuoteAmount,
  //       allTimeBiswapProfitFeeQuoteAmount,
  //       allTimeProfitFeeQuoteAmount,
  //     },
  //     nextCycle: {
  //       memberNextCycleCakeStakeAmount,
  //       memberNextCycleCakeStakeQuoteAmount,
  //       memberNextCycleBananaStakeAmount,
  //       memberNextCycleBananaStakeQuoteAmount,
  //       memberNextCycleBiswapStakeAmount,
  //       memberNextCycleBiswapStakeQuoteAmount,
  //       memberNextCycleStakeQuoteAmount,
  //     },
  //     network: {
  //       membersNetworkCount,
  //     },
  //   }
  //   const lastDebugValues = lastValuesRef.current
  //   const debugDiff = difference(debugValues, lastDebugValues)
  //   console.log('Debug diff', new Date().toISOString(), JSON.stringify(debugDiff, undefined, 2), debugValues)
  //   lastValuesRef.current = debugValues
  // }, [
  //   cakePrice,
  //   bananaPrice,
  //   biswapPrice,
  //   last7DaysCakeProfit,
  //   last7DaysBananaProfit,
  //   last7DaysBiswapProfit,
  //   productsContractsCount,
  //   companyOverviewStats,
  //   totalCakeStakingQuoteAmount,
  //   totalCakeProfitQuoteAmount,
  //   totalBananaStakingQuoteAmount,
  //   totalBananaProfitQuoteAmount,
  //   totalBiswapStakingQuoteAmount,
  //   totalBiswapProfitQuoteAmount,
  //   totalStakingQuoteAmount,
  //   totalProfitQuoteAmount,
  //   memberCakeProfitCycleStats,
  //   memberBananaProfitCycleStats,
  //   memberBiswapProfitCycleStats,
  //   myAllTimeContractsCount,
  //   myAlltimeContractsProfitQuoteAmount,
  //   myAllTimeCakeProfitFeeEarnings,
  //   myAllTimeBananaProfitFeeEarnings,
  //   myAllTimeBiswapProfitFeeEarnings,
  //   allTimeCakeProfitFeeQuoteAmount,
  //   allTimeBananaProfitFeeQuoteAmount,
  //   allTimeBiswapProfitFeeQuoteAmount,
  //   allTimeProfitFeeQuoteAmount,
  //   memberNextCycleCakeStakeAmount,
  //   memberNextCycleCakeStakeQuoteAmount,
  //   memberNextCycleBananaStakeAmount,
  //   memberNextCycleBananaStakeQuoteAmount,
  //   memberNextCycleBiswapStakeAmount,
  //   memberNextCycleBiswapStakeQuoteAmount,
  //   memberNextCycleStakeQuoteAmount,
  //   membersNetworkCount,
  // ])

  if (pendingAgreementVerification)
    return (
      <BoxModal>
        <PendingAgreementVerification />
      </BoxModal>
    )

  if (announcement) {
    return (
      <BoxModal>
        <Announcement announcementId={announcement.announcementId} />
      </BoxModal>
    )
  }

  if (!companyOverviewStatsLoading && !companyOverviewStats) return null

  return (
    <div className="box-home">
      <div className="products-container">
        <div className="heading">
          <p>Products</p>
          <p>Inkryptus Statistics</p>
        </div>
        <div className="cards-products">
          <div className="card">
            <img src={tokens.cakeLogo} alt="Cake" />
            <p>CAKE</p>
            <div className="profit">
              <p>No fee to join</p>
              <div>
                <p>Last 7 days profit</p>
                <p>{last7DaysCakeProfit === undefined ? '-' : formatPercent(last7DaysCakeProfit, { precision: 2 })}</p>
              </div>
            </div>
            <div className="button-action">
              <Button size="large" type="success" onClick={goToCakeProduct}>
                {currentRunningCakeContracts ? 'Open' : 'Join Now'}
              </Button>
            </div>
          </div>
          <div className="card">
            <img src={tokens.bananaLogo} alt="Banana" />
            <p>BANANA</p>
            <div className="profit">
              <p>No fee to join</p>
              <div>
                <p>Last 7 days profit</p>
                <p>{last7DaysBananaProfit === undefined ? '-' : formatPercent(last7DaysBananaProfit, { precision: 2 })}</p>
              </div>
            </div>
            <div className="button-action">
              <Button size="large" type="success" onClick={goToBananaProduct}>
                {currentRunningBananaContracts ? 'Open' : 'Join Now'}
              </Button>
            </div>
          </div>
          <div className="card">
            <img src={tokens.biswapLogo} alt="BSW" />
            <p>BSW</p>
            <div className="profit">
              <p>No fee to join</p>
              <div>
                <p>Last 7 days profit</p>
                <p>{last7DaysBiswapProfit === undefined ? '-' : formatPercent(last7DaysBiswapProfit, { precision: 2 })}</p>
              </div>
            </div>
            <div className="button-action">
              <Button size="large" type="success" onClick={goToBiswapProduct}>
                {currentRunningBiswapContracts ? 'Open' : 'Join Now'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="statistic-container">
        <div className="heading">
          <p>The staking house</p>
          <p>Inkryptus Statistics</p>
        </div>
        <div className="inkryptus-statistic">
          <div>
            <p>Total of clients</p>
            <p>{companyOverviewStats?.membersCount || '-'}</p>
          </div>
          <div>
            <p>Total of contracts</p>
            <p>{productsContractsCount || '-'}</p>
          </div>
          <div>
            <p>Total in staking (USD)</p>
            <p>{formatCurrency(totalStakingQuoteAmount)}</p>
          </div>
          <div>
            <p>Total of Rewards (USD)</p>
            <p>{formatCurrency(totalProfitQuoteAmount)}</p>
          </div>
        </div>
        <div className="user-statistic">
          <p>My Statistics</p>
          <div>
            <div>
              <p>My Contracts</p>
              <p>{myAllTimeContractsCount}</p>
            </div>
            <div>
              <p>Total in staking (USD)</p>
              <p>{formatCurrency(memberNextCycleStakeQuoteAmount)}</p>
            </div>
            <div>
              <p>Total of rewards</p>
              <p>{formatCurrency(myAlltimeContractsProfitQuoteAmount)}</p>
            </div>
            <div>
              <p>My network</p>
              <p>{membersNetworkCount}</p>
            </div>
            <div>
              <p>Network Comission</p>
              <p>{formatCurrency(allTimeProfitFeeQuoteAmount)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
