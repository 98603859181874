import * as React from 'react'

import { sendConfirmationEmail, changeSignupEmail } from '../../services/api'
import { signOut } from '../../utils/firebase-auth'
import SignupEmailVerification from '../../components/auth/signup-email-verification'

const SignupEmailVerificationPage: React.FunctionComponent = () => {
  return <SignupEmailVerification onChangeEmail={changeSignupEmail} onResend={sendConfirmationEmail} onClickExit={signOut} />
}

export default SignupEmailVerificationPage
