import React from 'react'
import { capitalize } from 'lodash'
import { useUid } from '../../hooks/current-user'
import { logo } from '../../assets'
import { useAppState } from '../../AppState'

interface Props {
  nextMode?: string | null
  onConfirmClick?: () => any
  onCloseClick?: () => any
}

const AppModeSwitch: React.FC<Props> = React.memo(({ nextMode, onConfirmClick, onCloseClick }) => {
  const [appState] = useAppState()
  const mountedRef = React.useRef(true)
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [, /* uid */ ] = useUid()

  if (!nextMode) return null

  return (
    <>
      <div className={`${nextMode} title`}>
        <img src={logo.inkryptus.regularLight} alt="Inkryptus" />
        <div/>
        <div>{nextMode}</div>
      </div>
      <p>Switching to Inkryptus {capitalize(nextMode)}?</p>
      <div className={`${nextMode} go`}onClick={onConfirmClick}> Go to {capitalize(nextMode)}</div>
      <p onClick={onCloseClick}>Continue on Inkryptus {capitalize(appState.rootMode)}</p>
    </>
  )
})

export default AppModeSwitch
