import { chain, split } from 'lodash'

export function getQueryParams(searchString: string): { [param: string]: string | undefined } {
  return chain(searchString)
    .replace('?', '')
    .split('&')
    .map((s) => split(s, '=', 2))
    .fromPairs()
    .value()
}

export function getReferralLink(platform: string, username?: string): string {
  const invitationPath = username ? `/i/${username}/` : ''
  const link = platform === 'ios' || platform == 'android' ? new URL('https://desk.inkryptus.com/') : new URL(window.location.href)
  link.pathname = invitationPath
  return link.href
}
