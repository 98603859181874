import { Decimal, decimal, mul, trunc, cmp } from '@allcoinwallet/invest-bitcoin-decimal'

export const MAX_DECIMAL_PLACES = 8
export const WITHDRAWAL_FEE = Decimal(0.01)
export const MINIMUM_WITHDRAWAL_USDT_QUOTE = Decimal(3)

export function calculateWithdrawalFee(grossAmount: decimal, usdtPrice: decimal) {
  const minimumFee = trunc(mul(usdtPrice, MINIMUM_WITHDRAWAL_USDT_QUOTE), MAX_DECIMAL_PLACES)
  const variableFee = trunc(mul(grossAmount, WITHDRAWAL_FEE), MAX_DECIMAL_PLACES)
  if (cmp(minimumFee, variableFee) > 0) {
    if (cmp(minimumFee, grossAmount) > 0) return grossAmount
    return minimumFee
  }
  return variableFee
}

export function calculateWithdrawalFeeWithoutPrice(grossAmount: decimal) {
  const variableFee = trunc(mul(grossAmount, WITHDRAWAL_FEE), MAX_DECIMAL_PLACES)
  if (cmp(variableFee, Decimal(0)) > 0) {
    return variableFee
  }
  return Decimal(0)
}
