import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useAppState } from '../AppState'

import Header from './../components/shared/header'
import Footer from './../components/shared/footer'
import Container from './../components/shared/container'

import FinancialPage from '../pages/financial'
import NetworkPage from '../pages/network'
import { ProductPage, ProductsPage } from '../pages/products'
import ProductsContractsPage from '../pages/products/ProductsContractsTablePage'
import ProfilePage from '../pages/profile'
import SupportPage from '../pages/support'
import HomePage from '../pages/home'
// import FilesPage from '../pages/files'
import MarketingPage from '../pages/marketing'
// import TutorialsPage from '../pages/tutorials'
import WalletPage from '../pages/wallet'
import NotificationsPage from '../pages/notifications'

import { InvoiceModalProvider } from '../modals/invoice'
import { EstimateModalProvider } from '../modals/estimate'
import { EstimateDebitsModalProvider } from '../modals/estimate-debits'
import { FinishProductContractModalProvider } from '../modals/finish-product-contract'
import { ProductPlansModalProvider } from '../modals/product-plans'
import { ProductOverviewModalProvider } from '../modals/product-overview'
import { ShareInvitationLinkModalProvider } from '../modals/share-invitation-link'
import { InviteToOurSocialNetworksModalProvider } from '../modals/invite-to-our-social-networks'
import { SwapHomeScreen, SwapHistoryScreen } from '../pages/swap'
import { SwapSymbolSelectorModalProvider } from '../modals/swap-symbol-selector'
import { SwapWalletWithdrawalRequestModalProvider } from '../modals/swap-wallet-withdrawal-request'
import { SwapDepositWalletModalProvider } from '../modals/swap-deposit-wallet'
import { SwapProcessModalProvider } from '../modals/swap-process'

const DeskTemplate: React.FC<{ hideFooter?: boolean }> = (props) => (
  <InvoiceModalProvider>
    <EstimateModalProvider>
      <EstimateDebitsModalProvider>
        <FinishProductContractModalProvider>
          <ProductPlansModalProvider>
            <ProductOverviewModalProvider>
              <ShareInvitationLinkModalProvider>
                <InviteToOurSocialNetworksModalProvider>
                  <Header />
                  <Container>{props.children}</Container>
                  {!props.hideFooter && <Footer />}
                </InviteToOurSocialNetworksModalProvider>
              </ShareInvitationLinkModalProvider>
            </ProductOverviewModalProvider>
          </ProductPlansModalProvider>
        </FinishProductContractModalProvider>
      </EstimateDebitsModalProvider>
    </EstimateModalProvider>
  </InvoiceModalProvider>
)

const DeskRoutes: React.FC<{ matchPath: string }> = ({ matchPath }) => {
  const [appState] = useAppState()
  const defaultStakeRoutePrefix = '/stake'
  const stakeRoutePrefix = appState.rootMode === 'stake' ? '' : defaultStakeRoutePrefix
  const defaultSwapRoutePrefix = '/swap'
  const swapRoutePrefix = appState.rootMode === 'swap' ? '' : defaultSwapRoutePrefix

  return (
    <DeskTemplate hideFooter={appState.rootMode === 'swap'}>
      <SwapDepositWalletModalProvider>
        {/* ============== STAKE ROUTES */}
        {appState.rootMode === 'stake' && (
          <Switch>
            <Route exact path={`${stakeRoutePrefix}/network`} component={NetworkPage} />
            <Route exact path={`${stakeRoutePrefix}/financial`} component={FinancialPage} />
            <Route exact path={`${stakeRoutePrefix}/marketing`} component={MarketingPage} />
            <Route exact path={`${stakeRoutePrefix}/wallet`} component={WalletPage} />
            <Route exact path={`${stakeRoutePrefix}/notifications`} component={NotificationsPage} />
            {/* <Route exact path={`${stakeRoutePrefix}/files`} component={FilesPage} /> */}
            {/* <Route exact path={`${stakeRoutePrefix}/tutorials`} component={TutorialsPage} /> */}
            <Route exact path={`${stakeRoutePrefix}/products/`} component={ProductsPage} />
            <Route exact path={`${stakeRoutePrefix}/products/contracts/`} component={ProductsContractsPage} />
            <Route exact path={`${stakeRoutePrefix}/products/:productId`} component={ProductPage} />
            <Route exact path={`${stakeRoutePrefix}/profile`} component={ProfilePage} />
            <Route exact path={`${stakeRoutePrefix}/support`} component={SupportPage} />
            <Route exact path={`${stakeRoutePrefix}/`} component={HomePage} />
            <Redirect path={defaultStakeRoutePrefix} to={matchPath.split(defaultStakeRoutePrefix)[1] || '/'} />
            <Redirect path="/" to="/" />
          </Switch>
        )}

        {/* ============== SWAP ROUTES */}
        {appState.rootMode === 'swap' && (
          <SwapSymbolSelectorModalProvider>
            <SwapWalletWithdrawalRequestModalProvider>
              <SwapProcessModalProvider>
                <Switch>
                  <Route exact path={`${swapRoutePrefix}/transactions`} component={SwapHistoryScreen} />
                  <Route exact path={`${swapRoutePrefix}/profile`} component={ProfilePage} />
                  <Route exact path={`${swapRoutePrefix}/support`} component={SupportPage} />
                  <Route exact path={`${swapRoutePrefix}/`} component={SwapHomeScreen} />
                  <Redirect path={defaultSwapRoutePrefix} to={matchPath.split(defaultSwapRoutePrefix)[1] || '/'} />
                  <Redirect path="/" to="/" />
                </Switch>
              </SwapProcessModalProvider>
            </SwapWalletWithdrawalRequestModalProvider>
          </SwapSymbolSelectorModalProvider>
        )}
      </SwapDepositWalletModalProvider>
    </DeskTemplate>
  )
}

export default DeskRoutes
