import * as React from 'react'
import { map, orderBy, slice } from 'lodash'
import { useNetworkMemberActivities } from '../../hooks/network-member-activities'
import { formatDate } from '../../utils/format'
import { t } from '../../i18n'

import Table from '../../components/table'
import CompanyMemberStatus from '../../components/company-member-status'

const LastInvitationsTable: React.FunctionComponent = () => {
  const usernameLabel = t('fields:username.label')
  const dateLabel = t('common:date')
  const statusLabel = t('common:status')
  const [networkMemberActivities] = useNetworkMemberActivities('sign_up')
  const lastMembers = React.useMemo(
    () =>
      map(slice(orderBy(networkMemberActivities, ['time'], ['desc']), 0, 8), (activity) => ({
        [usernameLabel]: '@' + activity.username,
        [dateLabel]: formatDate(activity.time),
        [statusLabel]: <CompanyMemberStatus username={activity.username} />,
      })),
    [networkMemberActivities]
  )
  return <Table columns={[usernameLabel, dateLabel, statusLabel]} rows={lastMembers} />
}

export default LastInvitationsTable
