import * as React from 'react'
import { useState } from 'react'

import { t } from '../../../i18n'
import { logo, icons } from '../../../assets/'
import { Button, FormErrorsList } from '../../shared'
import { AcceptTermsProps } from '../../../services/api'
import { useToggle } from '../../../hooks/utils'

const TERMS_OF_USAGE_LINK = 'https://www.inkryptus.com/agreement'

interface Props {
  onAccept: (params: AcceptTermsProps) => Promise<void>
}
const SignupAgreement: React.FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const [termsOfUsage, toggleTermsOfUsage] = useToggle(false)

  const onSubmit = React.useCallback(async () => {
    if (loading) return
    if (!termsOfUsage) return
    setLoading(true)
    setErrorMessages([])
    try {
      await props.onAccept({ termsOfUsage: true, privacyPolicy: true })
    } catch (error) {
      if (error.humanizedMessage) setErrorMessages([error.humanizedMessage])
    }
    setLoading(false)
  }, [termsOfUsage, loading, setLoading, setErrorMessages, props.onAccept])

  return (
    <div className="box-accept-terms">
      <div>
        <div>
          <img src={logo.inkryptus.regularDark} />
        </div>
        <div>
          <p>{t('auth:sign_up_agreement.page_title')}</p>
          <img src={icons.terms} />
          <p>
            {'To continue your registration, we need you to read and accept our '}
            <a href={TERMS_OF_USAGE_LINK} target="_blank">
              {'Agreement'}
            </a>
            {'.'}
          </p>
        </div>
        <div>
          <div>
            <input type="checkbox" id="accept-terms" checked={termsOfUsage} onChange={toggleTermsOfUsage} />
            <label htmlFor="accept-terms">{t('auth:sign_up_agreement.terms_of_usage_checkbox_label')}</label>
          </div>
        </div>
        <Button onClick={onSubmit} loading={loading} disabled={loading || !termsOfUsage} size="large" type="primary">
          {t('auth:sign_up_agreement.button_agree.label')}
        </Button>
        <FormErrorsList messages={errorMessages} itemClassName="response-signup" />
      </div>
    </div>
  )
}

export default SignupAgreement
