import * as React from 'react'
import { useHistory } from 'react-router'
import { icons } from '../../assets'
import MemberSwapWalletTable from './MemberSwapWalletTable.container'

export const SwapHistoryScreen: React.FC = () => {
  const history = useHistory()
  const goToSwapHome = React.useCallback(() => {
    history.push('/')
  }, [history])
  return (
    <div className="box-swap-history">
      <div className="back">
        <div style={{ cursor: 'pointer' }} onClick={goToSwapHome}>
          <img src={icons.arrowLeft} alt="back" />
          <p>Back</p>
        </div>
      </div>
      <div className="history-transactions">
        <MemberSwapWalletTable />
      </div>
    </div>
  )
}
