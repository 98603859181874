import { decimal, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { fill, range } from 'lodash'
import * as React from 'react'
import { parseDecimal } from '../../../utils/format'

interface Props {
  value?: number | decimal | null
  decimalPlaces?: number
}

const DEFAULT_DECIMAL_PLACES = 8

const DecimalFormatter: React.FunctionComponent<Props> = (props) => {
  const dec = parseDecimal(props.value)
  const decimalPlaces = props.decimalPlaces || DEFAULT_DECIMAL_PLACES
  const truncatedDec = dec ? trunc(dec, decimalPlaces) : undefined
  const leadingZeroesSpan = React.useMemo(() => {
    if (!truncatedDec) return null
    const [, decDigits] = truncatedDec.split('.')
    const maxLeadingZeroes = fill(range(decimalPlaces), '0').join('')
    let leadingZeroes = ''
    if (!decDigits) leadingZeroes = '.' + maxLeadingZeroes
    else if (decDigits.length < decimalPlaces) leadingZeroes = fill(range(decimalPlaces - decDigits.length), '0').join('')
    return <span className="leading-zeroes">{leadingZeroes}</span>
  }, [truncatedDec, decimalPlaces])
  if (!truncatedDec) return null
  return (
    <>
      {truncatedDec}
      {leadingZeroesSpan}
    </>
  )
}

export default DecimalFormatter
