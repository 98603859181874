import React from 'react'
import SwapWalletWithdrawalRequestModal from './SwapWalletWithdrawalRequestModal.container'

export interface SwapWalletWithdrawalRequestModalContextValues {
  symbolOrTransactionId?: string | null
  showModal: (symbolOrTransactionId: string) => void
  closeModal: () => void
}
export const SwapWalletWithdrawalRequestModalContext = React.createContext<SwapWalletWithdrawalRequestModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const SwapWalletWithdrawalRequestModalProvider: React.FunctionComponent = (props) => {
  const [showingSymbolOrTransactionId, setShowingSymbolOrTransactionId] = React.useState<string | null>(null)
  const showModal = (symbolOrTransactionId: string) => {
    setShowingSymbolOrTransactionId(symbolOrTransactionId)
  }
  const closeModal = () => {
    setShowingSymbolOrTransactionId(null)
  }

  return (
    <SwapWalletWithdrawalRequestModalContext.Provider value={{ symbolOrTransactionId: showingSymbolOrTransactionId, showModal, closeModal }}>
      <SwapWalletWithdrawalRequestModal
        symbolOrTransactionId={showingSymbolOrTransactionId}
        changeTransaction={setShowingSymbolOrTransactionId}
        onClose={closeModal}
      />
      {props.children}
    </SwapWalletWithdrawalRequestModalContext.Provider>
  )
}

export default SwapWalletWithdrawalRequestModalContext
