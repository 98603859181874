import React from 'react'
import { useUid } from '../../hooks/current-user'
import { getSwapTokenMetadata } from '../../utils/swap'

interface Props {
  onSelectClick?: (symbol: string) => any
  onCloseClick?: () => any
}

const SwapSymbolSelector: React.FC<Props> = React.memo(({ onSelectClick }) => {
  const mountedRef = React.useRef(true)
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [,/* uid */] = useUid()

  const [searchTerm, setSearchTerm] = React.useState('')
  const changeSearchTerm = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
    },
    [setSearchTerm]
  )

  const cakeMeta = React.useMemo(() => getSwapTokenMetadata('CAKE'), [])
  const showCake = React.useMemo(() => {
    if (!searchTerm) return true
    if ('CAKE'.indexOf(searchTerm) !== -1) return true
    return cakeMeta.name.indexOf(searchTerm) !== -1
  }, [searchTerm, cakeMeta])
  const selectCAKESymbol = React.useCallback(() => {
    if (onSelectClick) onSelectClick('CAKE')
  }, [onSelectClick])

  // const usdtMeta = React.useMemo(() => getSwapTokenMetadata('USDT'), [])
  // const showUsdt = React.useMemo(() => {
  //   if (!searchTerm) return true
  //   if ('USDT'.indexOf(searchTerm) !== -1) return true
  //   return usdtMeta.name.indexOf(searchTerm) !== -1
  // }, [searchTerm, usdtMeta])
  // const selectUSDTSymbol = React.useCallback(() => {
  //   if (onSelectClick) onSelectClick('USDT')
  // }, [onSelectClick])

  const busdMeta = React.useMemo(() => getSwapTokenMetadata('BUSD'), [])
  const showBusd = React.useMemo(() => {
    if (!searchTerm) return true
    if ('BUSD'.indexOf(searchTerm) !== -1) return true
    return busdMeta.name.indexOf(searchTerm) !== -1
  }, [searchTerm, busdMeta])
  const selectBUSDSymbol = React.useCallback(() => {
    if (onSelectClick) onSelectClick('BUSD')
  }, [onSelectClick])

  const bananaMeta = React.useMemo(() => getSwapTokenMetadata('BANANA'), [])
  const showBanana = React.useMemo(() => {
    if (!searchTerm) return true
    if ('BANANA'.indexOf(searchTerm) !== -1) return true
    return bananaMeta.name.indexOf(searchTerm) !== -1
  }, [searchTerm, bananaMeta])
  const selectBANANASymbol = React.useCallback(() => {
    if (onSelectClick) onSelectClick('BANANA')
  }, [onSelectClick])

  const shibMeta = React.useMemo(() => getSwapTokenMetadata('SHIB'), [])
  const showShib = React.useMemo(() => {
    if (!searchTerm) return true
    if ('SHIB'.indexOf(searchTerm) !== -1) return true
    return shibMeta.name.indexOf(searchTerm) !== -1
  }, [searchTerm, shibMeta])
  const selectSHIBSymbol = React.useCallback(() => {
    if (onSelectClick) onSelectClick('SHIB')
  }, [onSelectClick])

  const bswMeta = React.useMemo(() => getSwapTokenMetadata('BSW'), [])
  const showBsw = React.useMemo(() => {
    if (!searchTerm) return true
    if ('BSW'.indexOf(searchTerm) !== -1) return true
    return bswMeta.name.indexOf(searchTerm) !== -1
  }, [searchTerm, shibMeta])
  const selectBSWSymbol = React.useCallback(() => {
    if (onSelectClick) onSelectClick('BSW')
  }, [onSelectClick])

  return (
    <>
      <div className="title">
        <p>Select Token</p>
      </div>
      <div className="input-box">
        <input type="text" placeholder="Search token" value={searchTerm} onChange={changeSearchTerm} />
      </div>
      <div className="coins">
        {/* {showUsdt && (
          <div onClick={selectUSDTSymbol}>
            <img src={usdtMeta.icon} alt="USDT" />
            <p>{usdtMeta.name}</p>
          </div>
        )} */}
        {showCake && (
          <div onClick={selectCAKESymbol}>
            <img src={cakeMeta.icon} alt="CAKE" />
            <p>{cakeMeta.name}</p>
          </div>
        )}
        {showBusd && (
          <div onClick={selectBUSDSymbol}>
            <img src={busdMeta.icon} alt="BUSD" />
            <p>{busdMeta.name}</p>
          </div>
        )}
        {showShib && (
          <div onClick={selectSHIBSymbol}>
            <img src={shibMeta.icon} alt="SHIB" />
            <p>{shibMeta.name}</p>
          </div>
        )}
        {showBsw && (
          <div onClick={selectBSWSymbol}>
            <img src={bswMeta.icon} alt="BSW" />
            <p>{bswMeta.name}</p>
          </div>
        )}
        {showBanana && (
          <div onClick={selectBANANASymbol}>
            <img src={bananaMeta.icon} alt="BANANA" />
            <p>{bananaMeta.name}</p>
          </div>
        )}
      </div>
    </>
  )
})

export default SwapSymbolSelector
