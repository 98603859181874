import * as React from 'react'
import { map } from 'lodash'
import { FormErrors } from '../../../utils/form-errors'
import { t } from '../../../i18n'
import { icons } from '../../../assets'

interface Props {
  messages?: string[]
  formErrors?: FormErrors
  itemClassName?: string
}

/* TODO: Remove inline styles @lucas */
const FormErrorsList: React.SFC<Props> = props => (
  <div style={{ maxWidth: 226 }}>
    {map(props.messages, (message, i) => (
      <div key={i} className={props.itemClassName}>
        <img src={icons.error} />
        <p className="error">{message}</p>
      </div>
    ))}
    {map(props.formErrors, (fieldErrors, fieldName) =>
      map(fieldErrors, ({ message }, i) => (
        <div key={`${fieldName}-${i}`} className={props.itemClassName}>
          <img src={icons.error} />
          <p className="error">
            {t(`fields:${fieldName}.label`)} {message}
          </p>
        </div>
      ))
    )}
  </div>
)

export default FormErrorsList
