import { add, Decimal, sig } from '@allcoinwallet/invest-bitcoin-decimal'
import React from 'react'
import { useHistory } from 'react-router'
import { useUid } from '../../hooks/current-user'
import { useMemberWallet } from '../../hooks/wallets'
import { tokens } from '../../assets'
import { t } from '../../i18n'
import { Button, DecimalFormatter } from '../../components/shared'
import { WalletWithdrawalRequestModalContext } from '../../modals/wallet-withdrawal-request'
import notify from '../../utils/notify'
import { requestWalletCompound, requestWalletHarvest } from '../../services/api'

const MemberWalletBalance: React.FC<{ symbol: string }> = ({ symbol }) => {
  const [uid] = useUid()
  const [memberWallet] = useMemberWallet(uid, symbol)
  const history = useHistory()

  const availableBalance = memberWallet?.balance.available
  const totalBalance = memberWallet?.balance.total
  const contractLockedBalance = memberWallet?.balance.total ? memberWallet?.balance.contractLocked || {} : undefined
  const pendingTransactionBalance = memberWallet?.balance.total ? memberWallet?.balance.pendingTransaction || {} : undefined
  const harvestLockedBalance = memberWallet?.balance.total ? memberWallet?.balance.harvestLocked || {} : undefined
  const lockedBalance = React.useMemo(() => {
    if (!contractLockedBalance && !pendingTransactionBalance) return
    let totalLockedBalance = Decimal(0)
    for (const lockId in contractLockedBalance) totalLockedBalance = add(totalLockedBalance, contractLockedBalance[lockId])
    for (const lockId in pendingTransactionBalance) totalLockedBalance = add(totalLockedBalance, pendingTransactionBalance[lockId])
    return totalLockedBalance
  }, [contractLockedBalance, pendingTransactionBalance])

  const sumHarvestLockedBalance = React.useMemo(() => {
    if (!harvestLockedBalance) return
    let totalHarvestLockedBalance = Decimal(0)
    for (const lockId in harvestLockedBalance) totalHarvestLockedBalance = add(totalHarvestLockedBalance, harvestLockedBalance[lockId])
    return totalHarvestLockedBalance
  }, [harvestLockedBalance])
  const hasHarvestLockedBalance = sumHarvestLockedBalance ? sig(sumHarvestLockedBalance) > 0 : false

  const { showModal: showWalletWithdrawalRequestModal } = React.useContext(WalletWithdrawalRequestModalContext)
  const showNewWithdrawalModal = React.useCallback(() => {
    showWalletWithdrawalRequestModal('_new__' + symbol)
  }, [showWalletWithdrawalRequestModal, symbol])

  const openContractsPage = React.useCallback(() => {
    history.push('/products/contracts/')
  }, [history])

  const [requestingHarvestOrCompound, setRequestingHarvestOrCompound] = React.useState(false)
  const requestHarvest = React.useCallback(async () => {
    if (requestingHarvestOrCompound) return
    setRequestingHarvestOrCompound(true)
    try {
      await requestWalletHarvest(symbol)
    } catch (error) {
      notify('error', `Harvest request failed! ${(error as any).message}`)
    }
    setRequestingHarvestOrCompound(false)
  }, [symbol, requestingHarvestOrCompound, setRequestingHarvestOrCompound])
  const requestCompound = React.useCallback(async () => {
    if (requestingHarvestOrCompound) return
    setRequestingHarvestOrCompound(true)
    try {
      await requestWalletCompound(symbol)
    } catch (error) {
      notify('error', `Compound request failed! ${(error as any).message}`)
    }
    setRequestingHarvestOrCompound(false)
  }, [symbol, requestingHarvestOrCompound, setRequestingHarvestOrCompound])

  return (
    <>
      <div className={`wallet ${hasHarvestLockedBalance ? 'has-harvest-locked' : ''}`}>
        <div>
          <img
            src={
              symbol === 'BANANA'
                ? tokens.bananaLogo
                : symbol === 'BSW'
                ? tokens.biswapLogo
                : symbol === 'USDT'
                ? tokens.usdtLogo
                : symbol === 'BUSD'
                ? tokens.busdLogo
                : symbol === 'SHIB'
                ? tokens.shibLogo
                : tokens.cakeLogo
            }
            alt={symbol}
          />
          <div className="text-content">
            <div>
              <p>{t('desk:financial_page.tab_wallet.wallet_balance_available_title', 'Available balance')}</p>
              <p>
                <DecimalFormatter value={availableBalance} />
              </p>
            </div>
            <Button size="medium" type="danger" onClick={showNewWithdrawalModal}>
              {t('desk:financial_page.tab_wallet.wallet_withdrawal_request_button', 'Withdraw')}
            </Button>
          </div>
          <div className="text-content">
            <div>
              <p>{t('desk:financial_page.tab_wallet.wallet_balance_locked_title', 'Locked balance')}</p>
              <p>
                <DecimalFormatter value={lockedBalance} />
              </p>
            </div>
            {symbol !== 'BUSD' && symbol !== 'USDT' && symbol !== 'SHIB' && (
              <Button size="medium" type="success" onClick={openContractsPage}>
                {t('desk:financial_page.tab_wallet.open_products_contracts_button', 'Contracts')}
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className="text-content">
            <div>
              <p>{t('desk:financial_page.tab_wallet.wallet_balance_total_title', 'Total balance')}</p>
              <p>
                <DecimalFormatter value={totalBalance} />
              </p>
            </div>
          </div>
        </div>
      </div>
      {hasHarvestLockedBalance && !!sumHarvestLockedBalance && (
        <div className="wallet is-harvest-locked">
          <div>
            <div className="img-replacement"></div>
            <div className="text-content">
              <div>
                <p></p>
                <p></p>
              </div>
              <Button size="medium" type="primary" onClick={requestHarvest} loading={requestingHarvestOrCompound}>
                {t('desk:financial_page.tab_wallet.wallet_harvest_request_button', 'Harvest')}
              </Button>
            </div>
            <div className="text-content">
              <div>
                <p>{t('desk:financial_page.tab_wallet.wallet_balance_harvest_locked_title', 'Harvest Locked')}</p>
                <p>
                  <DecimalFormatter value={sumHarvestLockedBalance} />
                </p>
              </div>
              <Button size="medium" type="primary" onClick={requestCompound} loading={requestingHarvestOrCompound}>
                {t('desk:financial_page.tab_wallet.wallet_compound_request_button', 'Compound')}
              </Button>
            </div>
          </div>
          <div>
            <div className="text-content">
              <div>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MemberWalletBalance
