import * as React from 'react'
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../services/firebase'
import { useStatuses } from '../hooks/current-user'
import { useReportTokenRecognition } from '../hooks/auth'
import { useRefreshProfileLang } from '../hooks/profile'
import { lang } from '../i18n'

import DeskRoutes from './desk'

import SignupPage from './../containers/signup-page'
import SignupAgreementPage from './../containers/signup-agreement-page'
import SignupEmailVerificationPage from './../containers/signup-email-verification-page'
import SigninPage from './../containers/signin-page'
import LoadingSpinner from '../components/loading-spinner'
import RequestPasswordReset from '../pages/request-reset-password'
import ResetPassword from '../pages/reset-password'
import { TemplateRoutes } from './templates'
import { useAppState } from '../AppState'

const SingleRouteWithRedirect: React.SFC<{ path: string; component: React.FunctionComponent }> = (props) => (
  <Switch>
    <Route exact path={props.path} component={props.component} />
    <Redirect path="/" to={props.path} />
  </Switch>
)

const Routes: React.FC<RouteComponentProps> = ({ match }) => {
  const { initialising, user } = useAuthState(auth)
  const { statuses, loading: loadingStatuses } = useStatuses()
  const [appState] = useAppState()

  const tokenReportLoading = useReportTokenRecognition()

  useRefreshProfileLang()

  const fetchingStatuses = !!user && !statuses
  const loading: boolean = initialising || loadingStatuses || fetchingStatuses || tokenReportLoading || appState.rootModeTransitioning
  const authenticated: boolean = !!user
  const verifiedEmail: boolean = !!statuses && !!statuses.verifiedEmail
  const acceptedTermsAndPolicies: boolean = !!statuses && !!statuses.acceptedTermsOfUsage && !!statuses.acceptedPrivacyPolicy

  if (loading) return <LoadingSpinner />

  if (!authenticated)
    return (
      <Switch>
        <Route exact path="/sign_in" component={SigninPage} />
        <Route exact path="/sign_up" component={SignupPage} />
        <Route exact path="/reset_password" component={RequestPasswordReset} />
        <Route path="/i/:sponsorUsername/" component={SignupPage} />
        <Redirect path="/" to="/sign_in" />
      </Switch>
    )

  if (!acceptedTermsAndPolicies) return <SingleRouteWithRedirect path="/signup_agreement" component={SignupAgreementPage} />

  if (!verifiedEmail) return <SingleRouteWithRedirect path="/signup_email_verification" component={SignupEmailVerificationPage} />

  return <DeskRoutes matchPath={match.path} />
}

const EntrypointRoutes: React.FunctionComponent = () => {
  const { initialising } = useAuthState(auth)
  const loading = initialising
  React.useEffect(() => {
    document.body.classList.add(lang)
  }, [])
  if (loading) return <LoadingSpinner />
  return (
    <Switch>
      <Route path="/template" component={TemplateRoutes} />
      <Route exact path="/change_password" component={ResetPassword} />
      <Route path="/" component={Routes} />
    </Switch>
  )
}

export default EntrypointRoutes
