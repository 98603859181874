import React from 'react'
import Modal from 'react-modal'
import { useProduct } from '../../hooks/products'
import { useEstimateModal } from '../../hooks/estimate'

import LoadingSpinner from '../../components/loading-spinner'
import ProductPlans from './ProductPlans.component'

interface Props {
  productId?: string | null
  onClose: () => void
}

const ProductPlansModal: React.FunctionComponent<Props> = props => {
  const productId = props.productId || undefined
  const [product, loading, error] = useProduct(productId)
  React.useEffect(() => {
    if (!productId) return
    if (product || loading) return
    if (error) props.onClose()
  }, [productId, product, loading, error])

  const { showModal: showEstimateModal } = useEstimateModal()

  const onBuyClick = (productVariant: string) => async () => {
    if (!productId) return
    showEstimateModal(productId, productVariant)
    props.onClose()
  }

  if (!productId) return null
  if (loading || !product) return <LoadingSpinner />
  if (!product.variants) return null

  return (
    <Modal
      isOpen={!!productId}
      onRequestClose={props.onClose}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-choose-plan"
    >
      <ProductPlans
        productId={productId}
        licensePrice={product.licensePrice}
        licenseDurationDays={product.licenseDurationDays}
        variants={product.variants}
        onBuyClick={onBuyClick}
        onCloseClick={props.onClose}
      />
    </Modal>
  )
}

export default ProductPlansModal
