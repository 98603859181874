import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../services/firebase'
import { useDatabaseObject } from './database'

export function useUid(): [string | null, boolean] {
  const { user, initialising } = useAuthState(auth)
  const uid = user ? user.uid : null
  return [uid, initialising]
}

export function useStatuses() {
  const { user } = useAuthState(auth)
  const statusesRef = user ? db.child('membersStatuses').child(user.uid) : undefined
  const { value: statuses, loading, error } = useDatabaseObject(statusesRef)
  return { statuses, loading, error }
}

export function useUsername() {
  const { user } = useAuthState(auth)
  const usernameRef = user
    ? db
        .child('membersStatuses')
        .child(user.uid)
        .child('username')
    : undefined
  const { value: username, loading, error } = useDatabaseObject(usernameRef)
  return { username, loading, error }
}

export function useName() {
  const { username } = useUsername()
  const nameRef = username
    ? db
        .child('membersProfile')
        .child(username)
        .child('name')
    : undefined
  const { value: name, loading, error } = useDatabaseObject(nameRef)
  return { name, loading, error }
}
