import React from 'react'
import EmailInvitationFormWidget from './EmailInvitationFormWidget.component'
import { sendEmailInvitation } from '../../services/api'

const MemberEmailInvitationDispatcher: React.FunctionComponent = () => {
  const onSend = async (params: { email: string; name: string }) => {
    await sendEmailInvitation(params.name, params.email)
  }
  return <EmailInvitationFormWidget onSubmit={onSend} />
}

export default MemberEmailInvitationDispatcher
