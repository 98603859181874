import { MemberDebits } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'
import { useUid } from './current-user'

export function useMemberDebits(): [MemberDebits | undefined, boolean, object | undefined] {
  const [uid] = useUid()
  const query = uid ? db.child('membersDebits').child(uid) : undefined
  const { value: debits, loading, error } = useDatabaseObject(query)
  return [debits, loading, error]
}

export function useMemberProductDebits(productId?: string): [MemberDebits | undefined, boolean, object | undefined] {
  const [uid] = useUid()
  const query =
    uid && productId
      ? db
          .child('membersDebits')
          .child(uid)
          .orderByChild('productId')
          .equalTo(productId)
      : undefined
  const { value: debits, loading, error } = useDatabaseObject(query)
  return [debits, loading, error]
}
