import React from 'react'
import Modal from 'react-modal'
import { Decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'
import { MemberDebits } from '@allcoinwallet/inkryptus-db-schema'
import { useEstimateAmount } from '../../hooks/estimate'
import { useInvoiceModal } from '../../hooks/invoice'
import { createDebitsInvoice } from '../../services/api'

import LoadingSpinner from '../../components/loading-spinner'
import Estimate from './EstimateDebits.component'
import { useMemberDebits } from '../../hooks/debits'

interface Props {
  debitsIds?: string[] | null
  onClose: () => void
}

const ESTIMATE_REFRESH_SECONDS = 15

const EstimateModal: React.FunctionComponent<Props> = (props) => {
  const [memberDebits, loading, error] = useMemberDebits()
  const debits = React.useMemo(() => {
    if (!props.debitsIds || !memberDebits) return
    if (props.debitsIds.length === 0) return
    const d: MemberDebits = {}
    for (const debitId of props.debitsIds) {
      if (!memberDebits[debitId]) continue
      d[debitId] = memberDebits[debitId]
    }
    return d
  }, [props.debitsIds, memberDebits])

  React.useEffect(() => {
    if (!props.debitsIds) return
    if (memberDebits || loading) return
    if (error) props.onClose()
  }, [props.debitsIds, loading, error])

  const quoteAmount = React.useMemo(() => {
    if (!debits) return null
    let amount = Decimal(0)
    for (const debitId in debits) amount = add(amount, debits[debitId].amount)
    return amount
  }, [debits])

  const [estimateAmount, , reEstimate] = useEstimateAmount(quoteAmount)
  React.useEffect(() => {
    if (!estimateAmount) return
    const intervalId = setInterval(reEstimate, ESTIMATE_REFRESH_SECONDS * 1000)
    return () => clearInterval(intervalId)
  }, [estimateAmount, reEstimate])

  const { showModal: showInvoiceModal } = useInvoiceModal()

  const onPayClick = React.useCallback(async () => {
    if (!props.debitsIds || props.debitsIds.length === 0) return
    const invoiceId = await createDebitsInvoice(props.debitsIds)
    props.onClose()
    showInvoiceModal(invoiceId)
  }, [props.onClose, props.debitsIds])

  if (!props.debitsIds) return null
  if (loading || !debits) return <LoadingSpinner />

  return (
    <Modal
      isOpen={!!props.debitsIds && props.debitsIds.length > 0}
      onRequestClose={props.onClose}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-payment-initial"
    >
      <Estimate
        debits={debits}
        quoteAmount={quoteAmount}
        estimateAmount={estimateAmount}
        onPayClick={onPayClick}
        onCloseClick={props.onClose}
      />
    </Modal>
  )
}

export default EstimateModal
