import React from 'react'
import SwapDepositWalletModal from './SwapDepositWalletModal.container'

export interface SwapDepositWalletModalContextValues {
  symbol?: string | null
  showModal: (symbol: string) => void
  closeModal: () => void
}
export const SwapDepositWalletModalContext = React.createContext<SwapDepositWalletModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const SwapDepositWalletModalProvider: React.FunctionComponent = (props) => {
  const [showingSymbol, setShowingSymbol] = React.useState<string | null>(null)
  const showModal = React.useCallback(
    (symbol: string) => {
      setShowingSymbol(symbol)
    },
    [setShowingSymbol]
  )
  const closeModal = React.useCallback(() => {
    setShowingSymbol(null)
  }, [setShowingSymbol])

  return (
    <SwapDepositWalletModalContext.Provider value={{ symbol: showingSymbol, showModal, closeModal }}>
      <SwapDepositWalletModal symbol={showingSymbol} onClose={closeModal} />
      {props.children}
    </SwapDepositWalletModalContext.Provider>
  )
}

export default SwapDepositWalletModalContext
