import React from 'react'
import { useToggle } from '../../hooks/utils'
import { t } from '../../i18n'
import Button from '../../components/shared/button'
import { formatUpperProductId } from '../../utils/format'

interface Props {
  productId: string
  disclaimer: string
  onVerify: () => Promise<void> | void
}

const Disclaimer: React.SFC<Props> = props => {
  const [readCheckbox, toggleReadCheckbox] = useToggle(false)
  const [verifying, setVerifying] = React.useState<boolean>(false)
  const verify = React.useCallback(async () => {
    if (verifying) return
    if (!readCheckbox) return
    setVerifying(true)
    try {
      await props.onVerify()
    } catch (error) {}
    setVerifying(false)
  }, [props.onVerify, verifying, readCheckbox])

  const disclaimerParagraphs = React.useMemo(() => props.disclaimer.split('\n'), [props.disclaimer])

  return (
    <>
      <div>
        <p>{t('desk:product_prepurchase_disclaimer_modal.title')}</p>
      </div>
      <div>
        <p className="item">
          {t('desk:product_prepurchase_disclaimer_modal.title')} - {formatUpperProductId(props.productId)}
        </p>
        {disclaimerParagraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <div>
        <label>
          <input type="checkbox" name="checkbox" checked={readCheckbox} onChange={toggleReadCheckbox} value="value" />
          {t('desk:product_prepurchase_disclaimer_modal.read_checkbox.label')}
        </label>
        <Button size="medium" type="primary" onClick={verify} disabled={!readCheckbox || verifying} loading={verifying}>
          {t('desk:product_prepurchase_disclaimer_modal.agree_button.label')}
        </Button>
      </div>
    </>
  )
}

export default Disclaimer
