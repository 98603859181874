interface ConfigVars {
  firebase: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
  }
  stackdriver: {
    apiKey: string
  }
  apiUrl: string
}
const configVars: ConfigVars = (window as any).configVars

if (process.env.REACT_APP_USE_LOCAL_API) {
  configVars.apiUrl = 'http://localhost:8002/'
}

export default configVars
