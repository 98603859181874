import React from 'react'
import Modal from 'react-modal'
import { Decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { useProduct } from '../../hooks/products'
import { useEstimateAmount } from '../../hooks/estimate'
import { useInvoiceModal } from '../../hooks/invoice'
import { createInvoice, activateFreeTrial } from '../../services/api'
import alrt from '../../utils/alrt'
import { t } from '../../i18n'

import LoadingSpinner from '../../components/loading-spinner'
import Estimate from './Estimate.component'
import Disclaimer from './Disclaimer.component'

interface Props {
  productId?: string | null
  productVariant?: string | null
  onClose: () => void
}

const ESTIMATE_REFRESH_SECONDS = 15

const EstimateModal: React.FunctionComponent<Props> = (props) => {
  const productId = props.productId || undefined
  const productVariant = props.productVariant || undefined
  const [verifyingDisclaimer, setVerifyingDisclaimer] = React.useState<boolean>(false)
  const [disclaimerAccepted, setDisclaimerAccepted] = React.useState<boolean>(false)
  const [product, loading, error] = useProduct(productId)
  React.useEffect(() => {
    if (!productId) return
    if (product || loading) return
    if (error) props.onClose()
  }, [productId, product, loading, error])

  let price = product ? product.licensePrice : null
  if (product && product.variants && productVariant) {
    const variant = product.variants[productVariant]
    if (variant) {
      price = variant.licensePrice
    }
  }
  const [estimateAmount, , reEstimate] = useEstimateAmount(price)
  React.useEffect(() => {
    if (!estimateAmount) return
    const intervalId = setInterval(reEstimate, ESTIMATE_REFRESH_SECONDS * 1000)
    return () => clearInterval(intervalId)
  }, [estimateAmount, reEstimate])

  const { showModal: showInvoiceModal } = useInvoiceModal()

  const onPayClick = React.useCallback(async () => {
    console.log({ productId, product, disclaimerAccepted })
    if (!productId || !product) return
    if (product.prepurchaseDisclaimer && !disclaimerAccepted) return setVerifyingDisclaimer(true)
    if (product.freeTrialDays) {
      await activateFreeTrial(productId, productVariant)
      props.onClose()
      alrt.success({
        title: t('alrt:free_trial_activated.title') as string,
        body: t('alrt:free_trial_activated.body') as string,
      })
    }
    const invoiceId = await createInvoice(productId, productVariant)
    props.onClose()
    showInvoiceModal(invoiceId)
  }, [props.onClose, productId, product, productVariant, disclaimerAccepted])

  const onVerify = React.useCallback(async () => {
    setDisclaimerAccepted(true)
    await onPayClick()
  }, [onPayClick])

  if (!productId) return null
  if (loading || !product) return <LoadingSpinner />

  return (
    <Modal
      isOpen={!!productId}
      onRequestClose={props.onClose}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className={verifyingDisclaimer ? 'pending-agreement' : 'box-payment-initial'}
    >
      {verifyingDisclaimer && product.prepurchaseDisclaimer ? (
        <Disclaimer productId={productId} disclaimer={product.prepurchaseDisclaimer} onVerify={onVerify} />
      ) : (
        <Estimate
          productId={productId}
          productVariant={productVariant}
          estimateAmount={estimateAmount}
          licensePrice={price || Decimal(0)}
          licenseDurationDays={product.licenseDurationDays}
          freeTrialDays={product.freeTrialDays}
          onPayClick={onPayClick}
          onCloseClick={props.onClose}
        />
      )}
    </Modal>
  )
}

export default EstimateModal
