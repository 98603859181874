import React from 'react'
import WalletWithdrawalRequestModal from './WalletWithdrawalRequestModal.container'

export interface WalletWithdrawalRequestModalContextValues {
  transactionId?: string | null
  showModal: (transactionId: string) => void
  closeModal: () => void
}
export const WalletWithdrawalRequestModalContext = React.createContext<WalletWithdrawalRequestModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const WalletWithdrawalRequestModalProvider: React.FunctionComponent = (props) => {
  const [showingTransactionId, setShowingTransactionId] = React.useState<string | null>(null)
  const showModal = (transactionId: string) => {
    setShowingTransactionId(transactionId)
  }
  const closeModal = () => {
    setShowingTransactionId(null)
  }

  return (
    <WalletWithdrawalRequestModalContext.Provider value={{ transactionId: showingTransactionId, showModal, closeModal }}>
      <WalletWithdrawalRequestModal transactionId={showingTransactionId} changeTransaction={setShowingTransactionId} onClose={closeModal} />
      {props.children}
    </WalletWithdrawalRequestModalContext.Provider>
  )
}

export default WalletWithdrawalRequestModalContext
