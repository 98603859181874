import React from 'react'
import { map, split, trim } from 'lodash'
import { t } from '../../i18n'
import { useAnnouncement } from '../../hooks/announcements'

interface Props {
  announcementId: string
}

const AnnouncementModal: React.FunctionComponent<Props> = props => {
  const [announcement] = useAnnouncement(props.announcementId)

  const paragraphsAndLines = React.useMemo(() => {
    if (!announcement) return null
    if (!announcement.bodyText || !trim(announcement.bodyText)) return null
    return map(split(announcement.bodyText, /\n\n/), paragraph => split(paragraph, /\n/))
  }, [announcement])

  if (!paragraphsAndLines) return null
  return (
    <div className="pending-debits">
      <div className="menu">
        <p>{t('desk:announcement_modal.title')}</p>
      </div>
      {/* <div className="alert-suspended">
        <p>{t('desk:announcement_modal.alert.title')}</p>
        <p>{t('desk:announcement_modal.alert.description')}</p>
      </div> */}
      <div className="debits">
        {map(paragraphsAndLines, (paragraphLines, pIndex) => (
          <p key={pIndex}>
            {map(paragraphLines, (line, lIndex) => (
              <>
                {lIndex > 0 && <br />}
                {line}
              </>
            ))}
          </p>
        ))}
      </div>
    </div>
  )
}

export default AnnouncementModal
