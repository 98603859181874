import { WalletTransaction } from '@allcoinwallet/inkryptus-db-schema'
import { sub } from '@allcoinwallet/invest-bitcoin-decimal'
import React from 'react'
import { tokens, icons } from '../../assets'
import { DecimalFormatter } from '../../components/shared'
import Button from '../../components/shared/button'

interface Props {
  transactionId: string
  transaction: WalletTransaction
  onResendClick?: () => any
  onCloseClick?: () => any
}

const WalletWihdrawalConfirmation: React.FC<Props> = ({ transaction, onResendClick, onCloseClick }) => {
  const mountedRef = React.useRef(true)
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const address = transaction.externalAddress
  const grossAmount = transaction.amount
  const feeAmount = transaction.feeAmount
  const netAmount = sub(grossAmount, feeAmount)

  const [resending, setResending] = React.useState(false)
  const resendConfirmationEmail = React.useCallback(async () => {
    if (resending) return
    setResending(true)
    try {
      if (onResendClick) await onResendClick()
      if (!mountedRef.current) return
    } catch (error) {}
    setResending(false)
  }, [resending, setResending])

  const close = React.useCallback(() => {
    if (onCloseClick) onCloseClick()
  }, [onCloseClick])

  return (
    <div className="modal-confirm-withdraw">
      <div className="title">
        <img
          src={
            transaction.symbol === 'BANANA'
              ? tokens.bananaLogo
              : transaction.symbol === 'BSW'
              ? tokens.biswapLogo
              : transaction.symbol === 'USDT'
              ? tokens.usdtLogo
              : transaction.symbol === 'BUSD'
              ? tokens.busdLogo
              : transaction.symbol === 'SHIB'
              ? tokens.shibLogo
              : tokens.cakeLogo
          }
          alt={transaction.symbol}
        />
        <p>Withdraw</p>
      </div>
      <div className="subtitle">
        <img src={transaction.authorizationTime ? icons.successPrimary : icons.message} alt="Message" />
        {!transaction.authorizationTime && <p>Withdrawal confirmation required. Please check your email.</p>}
        {transaction.authorizationTime && <p>Your withdrawal has been successfully authorized and will be processed soon.</p>}
      </div>
      <div className="content">
        <div>
          <p>Destination address</p>
          <p>{address}</p>
        </div>
        <div>
          <p>Network</p>
          <p>Binance Smart Chain (BEP20)</p>
        </div>
        <div>
          <p>Amount</p>
          <p>
            <DecimalFormatter value={grossAmount} /> {transaction.symbol}
          </p>
        </div>
        <div>
          <p>Withdrawal fee: (1% or $3)</p>
          <p>
            <DecimalFormatter value={feeAmount} /> {transaction.symbol}
          </p>
        </div>
        <div>
          <p>You receive:</p>
          <p>
            <DecimalFormatter value={netAmount} /> {transaction.symbol}
          </p>
        </div>
      </div>
      <div className="input-box">
        <p>Status</p>
        <input
          type="text"
          disabled
          value={
            transaction.confirmationTime
              ? 'Confirmed on the network Binance Smart Chain'
              : transaction.authorizationTime
              ? 'Authorized and awaiting processing'
              : 'Awaiting your authorization by email'
          }
        />
      </div>
      <div className="button-action">
        <Button size="medium" type={transaction.authorizationTime ? 'primary' : 'opaque'} onClick={close}>
          Close
        </Button>
        {!transaction.authorizationTime && (
          <Button size="medium" type="primary" onClick={resendConfirmationEmail} loading={resending}>
            Resend confirmation email
          </Button>
        )}
      </div>
    </div>
  )
}

export default WalletWihdrawalConfirmation
