import React from 'react'
import Modal from 'react-modal'
import { requestProductContractDeactivation } from '../../services/api'

import LoadingSpinner from '../../components/loading-spinner'
import FinishProductContract from './FinishProductContract.component'
import { useMemberProductContract } from '../../hooks/product-contracts'

interface Props {
  contractId?: string | null
  onClose: () => void
}

const FinishProductContractModal: React.FunctionComponent<Props> = (props) => {
  const contractId = props.contractId || undefined
  const [contract, contractLoading, contractError] = useMemberProductContract(contractId)
  const productId = contract?.productId

  const close = props.onClose

  React.useEffect(() => {
    if (!contractId) return
    if (contract || contractLoading) return
    if (contractError) close()
  }, [contractId, contract, contractLoading, contractError, close])

  const onFinishClick = React.useCallback(async () => {
    if (!contractId) return
    if (!productId) return
    await requestProductContractDeactivation(productId, contractId)
  }, [contractId, productId])

  if (!contractId) return null
  if (contractLoading || !contract) return <LoadingSpinner />

  return (
    <Modal
      isOpen={!!contractId}
      onRequestClose={close}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-payment-initial"
    >
      <FinishProductContract contractId={contractId} contract={contract} onFinishClick={onFinishClick} onCloseClick={close} />
    </Modal>
  )
}

export default FinishProductContractModal
