import React from 'react'
import { useToggle } from '../../hooks/utils'
import { t } from '../../i18n'
import Button from '../../components/shared/button'

interface Props {
  name: string
  onVerify: (params: { termsOfUsage: boolean; privacyPolicy: boolean }) => Promise<void> | void
}

const PendingAgreementVerification: React.SFC<Props> = (props) => {
  const [termsOfUsage, toggleTermsOfUsage] = useToggle(false)
  const [verifying, setVerifying] = React.useState<boolean>(false)
  const verify = async () => {
    if (verifying) return
    setVerifying(true)
    try {
      await props.onVerify({ termsOfUsage: true, privacyPolicy: true })
    } catch (error) {}
    setVerifying(false)
  }
  return (
    <div className="pending-agreement">
      <div>
        <p>{t('desk:agreement_modal.title')}</p>
      </div>
      <div>
        <p className="item">Inkryptus, Inc.</p>
        <p className="item">Acceptance of Terms of Use:</p>
        <p>
          The Terms of Use represent the entire agreement between Inkryptus, Inc. (“TSI”) and its customers, superseding all prior or
          contemporaneous agreements, representations, warranties, and understandings with respect to the Site, the content, product, or
          services provided by or through the Site, and subject to these Terms and Conditions. Acceptance of such terms is evidenced by using,
          visiting, or browsing the Site, without limitation or qualification.
        </p>
        <p className="item">Changes to the Terms of Use:</p>
        <p>
          TSI has the right to make changes, modifications, alterations or deletions of any content of these terms at any given time. Due to
          timely updates, clients are expected to occasionally visit this page to identify revisions to which they are bound. Use of the Site
          before and after changes made, signifies acceptance, agreement and assent of the Terms of Use and Terms and Conditions and are
          therefore bound to the existing rules.
        </p>
        <p className="item">Copyright and Trademark Notice:</p>
        <p>
          Unless otherwise specified, all materials appearing on this site, including the text, site design, logos, graphics, icons, and images,
          as well as the selection, assembly and arrangement thereof, are the sole property of TSI. You may use the content of this site only
          for the purpose of shopping on this site or placing an order on this site and for no other purpose. No materials from this site may be
          copied, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any form or by any means without our prior
          written permission. All rights not expressly granted herein are reserved. Any unauthorized use of the materials appearing on this site
          may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.
        </p>
        <p className="item">Links:</p>
        <p>
          This site may contain links to other sites on the Internet that are owned and operated by third parties. You acknowledge that we’re
          not responsible for the operation of or content located on or through any such site.
        </p>
        <p className="item">Order Acceptance Policy:</p>
        <p>
          Your receipt of an electronic or other form of order confirmation does not signify TSI’s acceptance of your order, nor does it
          constitute confirmation of our offer to sell. TSI reserves the right at any time after receipt of your order to accept or decline your
          order for any reason.
        </p>
        <p className="item">Delivery:</p>
        <p>
          Orders will be delivered within as per each third-party merchant policies. Time for delivery shall not be of the essence of the
          contract and TSI shall not be liable for any loss, costs, damages, charges or expenses caused directly or indirectly by any delay in
          the delivery.
        </p>
        <p className="item">Privacy:</p>
        <p>
          We keep your personal information private and secure. When you make a purchase from our site, you provide your name, email address,
          address, phone number, password, ID number and crypto wallet. We use this information to process your orders, to keep you updated on
          your orders and to personalize your shopping experience. Our secure servers protect your information using advanced encryption
          techniques and firewall technology
        </p>
        <p>
          To keep you informed about our latest offers, we may notify you of current promotions, specials and new additions to
          www.inkryptus.com. You may unsubscribe from our newsletters by following the unsubscribe instructions in any email you receive from
          us.
        </p>
        <p>
          We use “cookies” to keep track of your current shopping session to personalize your experience and so that you may retrieve your
          shopping cart at any time.
        </p>
        <p className="item">Refund Policy:</p>
        <p>
          Customers purchasing any product or services purchased through the TSI platform should refer to each third-party seller’s refund
          policy in the event Customer wishes to request a refund. TSI shall not be liable for any refund policies of any third-party sellers.
        </p>
        <p className="item">Subscription Cancellation Policy:</p>
        <p>
          If a Customer chooses to purchase the Monthly Subscription, Customer may cancel this subscription at any time by sending a written
          request for cancellation to customerservice@inkryptus.com. Customer must send request to cancel and request must be received at
          minimum of three (3) days prior to date of monthly recurring payment in order for payment to be properly cancelled. Otherwise, payment
          for the following month will be processed.
        </p>
        <p>
          If Customer chooses to purchase the Yearly Subscription, Customers may cancel this subscription by sending a request to cancel at
          least thirty (30) days prior to intended cancellation date.
        </p>
        <p>
          If Customer chooses to cancel a subscription with a third-party seller, Customer must do so through the third party seller by
          contacting the third party seller directly. TSI shall have no involvement with the cancellation policies of any third-party sellers.
        </p>
        <p className="item">Typographical Errors:</p>
        <p>
          In the event a service is listed at an incorrect price due to typographical error or error in pricing information received from our
          suppliers, TSI shall have the right to refuse or cancel any orders placed for service listed at the incorrect price. TSI shall have
          the right to refuse or cancel any such orders whether or not the order has been confirmed and you have been charged.{' '}
        </p>
        <p className="item">Site User Conduct:</p>
        <p>
          All users of the Site shall be 18 years of age or older. In connection to the use of the Site and content, users are not allowed to:
        </p>
        <p>Upload, post, e-mail, or otherwise transmit any:</p>
        <p>
          - content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, pornographic, libelous,
          invasive of anyone’s privacy, hateful, or racially, ethnically, or otherwise illegal or objectionable;
        </p>
        <p>
          - content that does not give any right to users to transmit under any law or under contractual or fiduciary relationships (such as
          inside information, proprietary, and confidential information learned or disclosed as part of employment relationships or under
          non-disclosure agreements);
        </p>
        <p>- content that infringes any patent, trademark, trade secret, copyright, or other intellectual property right of any party;</p>
        <p>
          - unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any
          other form of solicitation;
        </p>
        <p>
          - material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the
          functionality of any computer software or hardware or telecommunications equipment
        </p>
        <p>- Use the Site for any unlawful purpose;</p>
        <p>- Interfere with or disrupt the services and content of the Site,</p>
        <p>- Intentionally or unintentionally, violate any applicable local, state, national, or international law.</p>
        <p>
          Termination of access to the Site and use of its products and services may be performed by TSI subject to the compliance to the Terms
          of Use. False, inaccurate or incomplete information provided; engagement to any conduct that would otherwise harm any of TSI’s rights
          or interests in its Site, products, services, or other property; or for any or no reason, can all be grounds for termination without
          prior notice.
        </p>
        <p className="item">Limitation of Liability:</p>
        <p>
          TSI, its officers, managers, members, employees, attorneys or agents shall be liable (jointly or severally) under no circumstances,
          including but not limited to negligence for any direct, indirect, special, incidental or consequential damages of any kind. This
          statement includes but not limited to the use of services offered by TSI, data and profit loss on any theory of liability which may
          arise in or out of the connection with the ability or inability of the services offered by TSI or the materials on the Site. The fact
          remains true despite the advice given to TSI or any of its representatives of the possibility of such damages. Limitation and
          exclusion of incidental or consequential damages may not be applicable to everyone since some states do not allow such. Liability
          shall be limited to the fullest extent permitted by applicable law if any authority holds any portion of this section to be not
          enforceable.
        </p>
        <p className="item">Disclaimer of Warranty:</p>
        <p>
          TSI disclaims all warranties, expressed or implied, including but not limited to, warranties of merchantability, fitness for a
          particular purpose, or other violations of rights with the fullest extent permissible pursuant to applicable law. No liability or
          responsibility is assumed for any errors or omissions of the contents in the Site; any corrected defects; any failures, delays,
          malfunctions, or interruptions in the delivery of the any content on the Site; any losses or damages; or any conduct by users of the
          site, either online or offline. TSI does not warrant or represent the use or results of the use of the services or materials on the
          Site in terms of correctness, accuracy, reliability, or otherwise. The users/clients shall assume the entire cost of servicing, repair
          or correction.
        </p>
      </div>
      <div>
        <label>
          <input type="checkbox" name="checkbox" checked={termsOfUsage} onChange={toggleTermsOfUsage} value="value" />
          {t('desk:agreement_modal.read_checkbox.label')}
        </label>
        <Button size="medium" type="primary" onClick={verify} disabled={!termsOfUsage} loading={verifying}>
          {t('desk:agreement_modal.agree_button.label')}
        </Button>
      </div>
    </div>
  )
}

export default PendingAgreementVerification
