import * as React from 'react'

export interface BoxViewTabProps {
  tabKey: string
  label: string
}

export interface BoxViewTabsState {
  activeTabKey: string
  tabs: { [tabKey: string]: Omit<BoxViewTabProps, 'tabKey'> }
}

export interface BoxViewTabsContextValue extends BoxViewTabsState {
  setTabsState: React.Dispatch<React.SetStateAction<BoxViewTabsState>>
}

export const BoxViewTabsContext = React.createContext<BoxViewTabsContextValue>({
  activeTabKey: '',
  tabs: {},
  setTabsState: () => {
    console.warn('set tabs state called before initialization')
  },
})

export const BoxViewTabsProvider: React.FC = ({ children }) => {
  const [tabsState, setTabsState] = React.useState<BoxViewTabsState>({ activeTabKey: '', tabs: {} })
  const { activeTabKey, tabs } = tabsState
  return <BoxViewTabsContext.Provider value={{ activeTabKey, tabs, setTabsState }}>{children}</BoxViewTabsContext.Provider>
}

export const BoxViewTab: React.FC<BoxViewTabProps> = ({ tabKey, label, children }) => {
  const { activeTabKey, setTabsState } = React.useContext(BoxViewTabsContext)

  React.useEffect(() => {
    setTabsState((current) => {
      const next: BoxViewTabsState = { ...current, tabs: { ...current.tabs } }
      next.tabs[tabKey] = { label }
      if (!next.activeTabKey) next.activeTabKey = tabKey
      return next
    })
    // return () => {
    //   setTabsState((current) => {
    //     const next: BoxViewTabsState = { ...current, tabs: { ...current.tabs } }
    //     delete next.tabs[tabKey]
    //     return next
    //   })
    // }
  }, [setTabsState, tabKey, label])

  if (tabKey !== activeTabKey) return null
  return <>{children}</>
}

export const BoxViewTabButton: React.FC<BoxViewTabProps & { onClick: (tabKey: string) => any }> = React.memo(({ tabKey, label, onClick }) => {
  const { activeTabKey } = React.useContext(BoxViewTabsContext)
  const active = tabKey === activeTabKey
  const selectTab = React.useCallback(() => {
    onClick(tabKey)
  }, [onClick, tabKey])
  return (
    <p className={active ? 'active' : ''} onClick={selectTab}>
      {label}
    </p>
  )
})
