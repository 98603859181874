import { useMemo } from 'react'
import { merge } from 'lodash'
import { db } from '../services/firebase'
import { getLanguage, DEFAULT_LANGUAGE } from '../i18n'
import {
  useDatabaseObject,
  MarketingResources,
  FileResources,
  TutorialResources,
  MarketingResource,
  FileResource,
  TutorialResource,
} from './database'
import { ResourceCategories } from '@allcoinwallet/inkryptus-db-schema'

type Section = 'marketingResources' | 'fileResources' | 'tutorialResources'
type UseResourceHook<T = MarketingResources | FileResources | TutorialResources> = [T | null, boolean, object | undefined]
export function useLocalizedResources(section: Section): UseResourceHook {
  // observes resources
  const globalRef = db.child(section).child(DEFAULT_LANGUAGE)
  const global = useDatabaseObject(globalRef)

  // loads user language
  const lang = getLanguage()

  // observes localized version of resources
  const localizedRef = lang !== DEFAULT_LANGUAGE ? db.child(section).child(lang) : undefined
  const localized = useDatabaseObject(localizedRef)

  // merge global resources with the localized version
  const resources = useMemo(() => merge({}, global.value, localized.value), [global.value, localized.value])

  // merge loading and error statuses of each observable
  const loading = global.loading || localized.loading
  const error = global.error || localized.error

  return [resources, loading, error]
}

type UseSingleResourceHook<T = MarketingResource | FileResource | TutorialResource> = [T | null, boolean, object | undefined]
export function useSingleLocalizedResource(section: Section, resourceId?: string): UseSingleResourceHook {
  // observes resources
  const globalRef = resourceId
    ? db
        .child(section)
        .child(DEFAULT_LANGUAGE)
        .child(resourceId)
    : undefined
  const global = useDatabaseObject(globalRef)

  // loads user language
  const lang = getLanguage()

  // observes localized version of resources
  const localizedRef =
    lang !== DEFAULT_LANGUAGE && resourceId
      ? db
          .child(section)
          .child(lang)
          .child(resourceId)
      : undefined
  const localized = useDatabaseObject(localizedRef)

  // merge global resources with the localized version
  const resource = useMemo(() => {
    if (!global.value) return null
    if (!localized.value) return global.value
    return merge({}, global.value, localized.value)
  }, [global.value, localized.value])

  // merge loading and error statuses of each observable
  const loading = global.loading || localized.loading
  const error = global.error || localized.error

  return [resource, loading, error]
}

export function useLocalizedMarketingResources() {
  return useLocalizedResources('marketingResources') as UseResourceHook<MarketingResources>
}
export function useLocalizedFileResources() {
  return useLocalizedResources('fileResources') as UseResourceHook<FileResources>
}
export function useLocalizedTutorialResources() {
  return useLocalizedResources('tutorialResources') as UseResourceHook<TutorialResources>
}

export function useMarketingResource(resourceId?: string) {
  return useSingleLocalizedResource('marketingResources', resourceId) as UseSingleResourceHook<MarketingResource>
}
export function useFileResource(resourceId?: string) {
  return useSingleLocalizedResource('fileResources', resourceId) as UseSingleResourceHook<FileResource>
}
export function useTutorialResource(resourceId?: string) {
  return useSingleLocalizedResource('tutorialResources', resourceId) as UseSingleResourceHook<TutorialResource>
}

export function useResourceCategories(): [ResourceCategories | null, boolean, object | undefined] {
  const globalRef = db.child('resourceCategories').child(DEFAULT_LANGUAGE)
  const global = useDatabaseObject(globalRef)

  const lang = getLanguage()
  const localizedRef = lang !== DEFAULT_LANGUAGE ? db.child('resourceCategories').child(lang) : undefined
  const localized = useDatabaseObject(localizedRef)

  const categories = useMemo(() => (!!global.value || !!localized.value ? merge({}, global.value, localized.value) : null), [
    global.value,
    localized.value,
  ])

  const loading = global.loading || localized.loading
  const error = global.error || localized.error
  return [categories, loading, error]
}
