import React from 'react'
import { minBy, values } from 'lodash'
import { decimal, Decimal, add } from '@allcoinwallet/invest-bitcoin-decimal'
import moment from 'moment'
import * as SvgIcons from '../../../components/shared/svg-icons'
import { useMemberDebits } from '../../../hooks/debits'
import { formatCurrency, formatDatePlain } from '../../../utils/format'
import { t } from '../../../i18n'
import { Link } from 'react-router-dom'

interface Props {}

const DebitsAlertBar: React.FunctionComponent<Props> = () => {
  const [debits] = useMemberDebits()

  const minDueDebit = React.useMemo(() => minBy(values(debits), 'dueDateTime'), [debits])
  const totalAmount = React.useMemo(() => {
    if (!debits) return null
    let total: decimal = Decimal(0)
    for (const debitId in debits) {
      const debit = debits[debitId]
      total = add(total, debit.amount)
    }
    return total
  }, [debits])

  const isOverdue = React.useMemo(() => !!minDueDebit && moment(minDueDebit.dueDateTime).isBefore(moment()), [minDueDebit])
  const exceedsTolerance = React.useMemo(() => !!minDueDebit && moment(minDueDebit.dueDateTime).isBefore(moment()), [minDueDebit])

  if (!debits || !minDueDebit) return null

  const barStatus = isOverdue ? (exceedsTolerance ? 'account_cancelation' : 'overdue') : 'due'

  return (
    <div className={`has-debits ${isOverdue || exceedsTolerance ? 'expired' : ''}`}>
      <div className="title">
        <div>
          <SvgIcons.AlertIcon />
        </div>
        <div>
          <p>{t(`components:debits_alert_bar.${barStatus}.title`)}</p>
        </div>
      </div>
      <div className="description">
        {barStatus !== 'due' && <p className="important">{t(`components:debits_alert_bar.${barStatus}.note`)}</p>}
        <p>{t(`components:debits_alert_bar.${barStatus}.description`)}</p>
      </div>
      <div className="details">
        <div>
          <p>{t('fields:debits.status.label')}</p>
          <p>{t(`fields:debits.status.ofType.${minDueDebit.status}`)}</p>
        </div>
        <div>
          <p>{t('components:debits_alert_bar.total_amount_label')}</p>
          <p>{formatCurrency(totalAmount)}</p>
        </div>
        <div>
          <p>{t('fields:debits.dueDateTime.label')}</p>
          <p>{formatDatePlain(minDueDebit.dueDateTime)}</p>
        </div>
      </div>
      <div className="action-button">
        <Link to="/financial?iTab=debits">{t('components:debits_alert_bar.pay_button.label')}</Link>
      </div>
    </div>
  )
}

export default DebitsAlertBar
