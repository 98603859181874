import { toast } from 'react-toastify'

type ToastType = 'success' | 'info' | 'warn' | 'error'

export const notify = (type: ToastType, msg: string) =>
  toast.call(type, msg, {
    position: toast.POSITION.BOTTOM_LEFT,
  })

export default notify
