import React from 'react'
import { Input, Button } from '../../components/shared'
import { t } from '../../i18n'
import { copyToClipboard } from '../../utils/copy-to-clipboard'

interface Props {
  link: string
  onShare: () => void
}

const COPY_TOOLTIP_HIDE_TIMEOUT = 3000

const ReferralLinkWidget: React.FunctionComponent<Props> = props => {
  const [showingCopyTooltip, setShowingCopyTooltip] = React.useState<boolean>(false)
  const onCopy = () => {
    copyToClipboard(props.link)
    setShowingCopyTooltip(true)
  }

  React.useEffect(() => {
    if (!showingCopyTooltip) return
    const timeoutId = setTimeout(() => {
      setShowingCopyTooltip(false)
    }, COPY_TOOLTIP_HIDE_TIMEOUT)
    return () => clearTimeout(timeoutId)
  }, [showingCopyTooltip])

  return (
    <div className={`link-referral ${showingCopyTooltip ? 'showing-copy-tooltip' : ''}`}>
      <div>
        <p>Invitation link</p>
      </div>
      <div className="input-large i-disable">
        <Input value={props.link} />
      </div>
      <div>
        <Button onClick={onCopy} size="medium" type="primary">
          {t('desk:network_page.tab_overview.referral_link_widget.copy_button.label')}
        </Button>
        <Button onClick={props.onShare} size="medium" type="primary">
          {t('desk:network_page.tab_overview.referral_link_widget.share_button.label')}
        </Button>
      </div>
      <div>
        <p>{t('desk:network_page.tab_overview.referral_link_widget.copy_button.tooltip_success')}</p>
      </div>
    </div>
  )
}

export default ReferralLinkWidget
