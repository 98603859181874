import React from 'react'
import { db, storage } from '../services/firebase'
import { refreshProfileLang } from '../services/api'
import { lang } from '../i18n'
import { useUsername, useUid } from './current-user'
import { useDatabaseObject, Profile } from './database'
import { MemberPublicProfile } from '@allcoinwallet/inkryptus-db-schema'

let alreadyRefreshed: boolean = false

export function useRefreshProfileLang(): void {
  const { username } = useUsername()
  const langRef = !!username
    ? db
        .child('membersProfile')
        .child(username)
        .child('lang')
    : undefined
  const profileLang = useDatabaseObject(langRef)

  React.useEffect(() => {
    if (alreadyRefreshed) return
    if (!username) return
    if (!!username && !profileLang.value) return
    if (lang === profileLang.value) return
    refreshProfileLang(lang)
    alreadyRefreshed = true
  }, [username, profileLang])
}

export function useMemberProfile(targetUsername?: string | null): [Profile | null, boolean, object | undefined] {
  const { username } = useUsername()
  let profileRef = !!username ? db.child('membersProfile').child(username) : undefined
  if (targetUsername) profileRef = db.child('membersProfile').child(targetUsername)
  if (targetUsername === null) profileRef = undefined
  const profile = useDatabaseObject(profileRef)
  return [profile.value || null, profile.loading, profile.error]
}

export function useMemberProfilePictureUploader(): [(file: File) => Promise<void>, boolean, object | undefined] {
  const [uid] = useUid()
  const [uploading, setUploading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<object | undefined>(undefined)
  const ref = uid
    ? storage
        .ref()
        .child('public')
        .child('profile_pictures')
        .child(uid)
        .child('face')
    : undefined

  const uploadFn = async (file: File) => {
    if (uploading) return
    if (!ref) return
    setUploading(true)
    try {
      await ref.put(file)
    } catch (error) {
      setError(error)
    }
    setUploading(false)
  }

  return [uploadFn, uploading, error]
}

export function usePublicProfile(username?: string): [MemberPublicProfile | null, boolean, object | undefined] {
  const profileRef = username ? db.child('membersPublicProfile').child(username) : undefined
  const publicProfile = useDatabaseObject(profileRef)
  return [publicProfile.value || null, publicProfile.loading, publicProfile.error]
}
