import { trunc, cmp, decimal, Decimal, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { get } from 'lodash'
import React from 'react'
import { tokens, icons } from '../../assets'
import { DecimalFormatter } from '../../components/shared'
import Button from '../../components/shared/button'
import { useUid } from '../../hooks/current-user'
import { useSwapRates } from '../../hooks/rates'
import { useMemberWallet } from '../../hooks/wallets'
import { t } from '../../i18n'
import { isSimpleEthAddress } from '../../utils/blockchain'
import { calculateWithdrawalFee, MAX_DECIMAL_PLACES } from '../../utils/fee'
import { parseDecimal } from '../../utils/format'

interface Props {
  symbol: string
  onCreateClick?: (address: string, grossAmount: decimal) => any
  onCloseClick?: () => any
}

const WalletWihdrawalRequest: React.FC<Props> = ({ symbol, onCreateClick, onCloseClick }) => {
  const mountedRef = React.useRef(true)
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [uid, uidLoading] = useUid()
  const [wallet, walletLoading] = useMemberWallet(uid, symbol)
  const availableBalance = wallet?.balance.available

  const [swapRates, swapRatesLoading] = useSwapRates()
  const price = Decimal(get(swapRates, `USDT.rates.${symbol}`, 1) as any)

  const loading = uidLoading || walletLoading || swapRatesLoading

  const [agreementChecked, setAgreementChecked] = React.useState(false)
  const onChangeAgreementChecked = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreementChecked(event.target.checked)
    },
    [setAgreementChecked]
  )

  const [addressError, setAddressError] = React.useState('')
  const [address, setAddress] = React.useState('')
  const changeAddress = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddress(event.target.value)
    },
    [setAddress]
  )
  const validateAddress = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!event.target.value) setAddressError(t('wallet:withdrawal_request_modal.empty_address_error', 'Required address'))
      else if (!isSimpleEthAddress(event.target.value))
        setAddressError(t('wallet:withdrawal_request_modal.invalid_address_error', 'Invalid address'))
      else setAddressError('')
    },
    [setAddressError]
  )

  const [grossAmountError, setGrossAmountError] = React.useState('')
  const [grossAmountText, setGrossAmountText] = React.useState('')
  const changeGrossAmountText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGrossAmountText(event.target.value)
    },
    [setGrossAmountText]
  )
  const validateGrossAmount = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        setGrossAmountError(t('wallet:withdrawal_request_modal.empty_gross_amount_error', 'Required amount'))
        return
      }

      const parsedValue = parseDecimal(event.target.value)
      if (!parsedValue) {
        setGrossAmountError(t('wallet:withdrawal_request_modal.invalid_gross_amount_error', 'Invalid amount'))
        setGrossAmountText('')
      } else if (cmp(parsedValue, Decimal(1)) < 0) {
        setGrossAmountError(`1 ${symbol} minimum withdrawal required`)
      } else if (!availableBalance || cmp(parsedValue, availableBalance) > 0) {
        setGrossAmountError(t('wallet:withdrawal_request_modal.gross_amount_exceeds_available_balance_error', 'Balance not available'))
      } else {
        setGrossAmountError('')
        setGrossAmountText(String(Number(trunc(parsedValue, MAX_DECIMAL_PLACES))))
      }
    },
    [setGrossAmountError, setGrossAmountText, availableBalance, symbol]
  )
  const grossAmount = React.useMemo(() => parseDecimal(grossAmountText), [grossAmountText])
  const validGrossAmount = React.useMemo(
    () => !!grossAmount && !!availableBalance && cmp(grossAmount, availableBalance) <= 0,
    [grossAmount, availableBalance]
  )
  const [feeAmount, netAmount] = React.useMemo((): [decimal | undefined, decimal | undefined] => {
    if (!grossAmount) return [undefined, undefined]
    if (!price) return [undefined, undefined]
    const fee = calculateWithdrawalFee(grossAmount, price)
    const net = sub(grossAmount, fee)
    return [fee, net]
  }, [grossAmount, price])
  const greaterThanMinAmount = !!grossAmount && cmp(grossAmount, Decimal(1)) >= 0 && !!netAmount && cmp(netAmount, Decimal(0)) > 0

  const fillGrossAmountWithAvailableBalance = React.useCallback(() => {
    if (availableBalance) setGrossAmountText(trunc(availableBalance, MAX_DECIMAL_PLACES))
  }, [setGrossAmountText, availableBalance])

  const [creating, setCreating] = React.useState(false)
  const createTransaction = React.useCallback(async () => {
    if (creating) return
    if (!grossAmount || !address) return
    if (!validGrossAmount) return
    setCreating(true)
    try {
      if (onCreateClick) await onCreateClick(address, grossAmount)
      if (!mountedRef.current) return
      setAgreementChecked(false)
      setAddress('')
      setGrossAmountText('')
    } catch (error) {}
    setCreating(false)
  }, [creating, setCreating, onCreateClick, setAgreementChecked, setAddress, setGrossAmountText, grossAmount, address, validGrossAmount])

  const close = React.useCallback(() => {
    setAgreementChecked(false)
    setAddress('')
    setGrossAmountText('')
    setAddressError('')
    setGrossAmountError('')
    if (onCloseClick) onCloseClick()
  }, [onCloseClick, setAgreementChecked, setAddress, setGrossAmountText, setAddressError, setGrossAmountError])

  return (
    <div className={`modal-withdraw ${loading ? 'loading' : ''}`}>
      <div className="title">
        <img
          src={
            symbol === 'BANANA'
              ? tokens.bananaLogo
              : symbol === 'BSW'
              ? tokens.biswapLogo
              : symbol === 'USDT'
              ? tokens.usdtLogo
              : symbol === 'BUSD'
              ? tokens.busdLogo
              : symbol === 'SHIB'
              ? tokens.shibLogo
              : tokens.cakeLogo
          }
          alt={symbol}
        />
        <p>Withdraw</p>
      </div>
      <div className="input-box">
        <p>Address</p>
        <input type="text" placeholder="Send address" value={address} onChange={changeAddress} onBlur={validateAddress} />
        {addressError && <p className="error">{addressError}</p>}
      </div>
      <div className="input-box disabled">
        <p>Network</p>
        <input type="text" placeholder="Binance Smart Chain (BEP20)" disabled></input>
      </div>
      <div className="alert">
        <img src={icons.warning} alt="alert" />
        <p>Make sure you are using the network Binance Smart Chain(BEP 20). Deposits made trough other network could be lost.</p>
      </div>
      <div className="input-box amount">
        <p>Amount</p>
        <div>
          <input
            type="text"
            placeholder={`Minimum 1 ${symbol}`}
            value={grossAmountText}
            onChange={changeGrossAmountText}
            onBlur={validateGrossAmount}
          />
          <p onClick={fillGrossAmountWithAvailableBalance}>MAX</p>
        </div>
        <p className={grossAmount && !validGrossAmount ? 'danger' : ''}>
          Available: {availableBalance ? trunc(availableBalance, MAX_DECIMAL_PLACES) : '-'}
        </p>
        {grossAmountError && <p className="error">{grossAmountError}</p>}
      </div>
      <div className="amount-received">
        <p>You receive</p>
        <p className={`${!!netAmount && cmp(netAmount, Decimal(0)) <= 0 ? 'danger' : ''}`}>
          {netAmount ? <DecimalFormatter value={netAmount} /> : '0.000000'}
        </p>
        <p>Withdrawal fee (1% or $3): {feeAmount ? <DecimalFormatter value={feeAmount} /> : '0.000000'}</p>
      </div>
      <div className="confirm-term">
        <input id="agree" type="checkbox" checked={agreementChecked} onChange={onChangeAgreementChecked} />
        <label htmlFor="agree">I am aware that the receiving address is from the network Binance Smart Chain (BEP20)</label>
      </div>
      <div className="button-action">
        <Button size="medium" type="disable" onClick={close}>
          Close
        </Button>
        <Button
          size="medium"
          type="primary"
          onClick={createTransaction}
          loading={creating}
          disabled={!grossAmount || !address || !validGrossAmount || !agreementChecked || !greaterThanMinAmount}
        >
          Withdraw
        </Button>
      </div>
    </div>
  )
}

export default WalletWihdrawalRequest
