import React from 'react'
import { InvoiceItem } from '@allcoinwallet/inkryptus-db-schema'
import { t } from '../../i18n'
import { formatDate, formatHours } from '../../utils/format'
import { getWalletAddressExplorerUrl } from '../../utils/testnet'

import InvoiceModalMenu from './InvoiceModalMenu.component'
import { Button } from '../../components/shared'
import InvoiceItemDetails from './InvoiceItemDetails.component';

interface Props {
  item: InvoiceItem
  depositWalletAddress: string
  creationTime: number
  onCloseClick: () => void
}
const InvoiceExpired: React.FunctionComponent<Props> = props => {
  return (
    <>
      <InvoiceModalMenu title={t('desk:invoice_modal.expired.menu_title')} />
      <div className="content">
        <div className="info-product">
          <InvoiceItemDetails item={props.item} />
        </div>
        <div className="info-transaction">
          <div>
            <p>{t('desk:invoice_modal.expired.transactions_table_title')}</p>
          </div>
          <div>
            <div>
              <div>
                <p>{t('common:date')}</p>
              </div>
              <div>
                <p>{t('fields:invoice.walletAddress')}</p>
              </div>
              <div>
                <p>{t('common:status')}</p>
              </div>
            </div>
            <div>
              <div>
                <p>{formatDate(props.creationTime)}</p>
                <p>{formatHours(props.creationTime)}</p>
              </div>
              <div>
                <a href={getWalletAddressExplorerUrl(props.depositWalletAddress)} target="_blank">
                  {props.depositWalletAddress}
                </a>
              </div>
              <div>
                <p>{t('desk:invoice_modal.expired.payment_status')}</p>
              </div>
            </div>
          </div>
        </div>
        <Button size="medium" type="primary" onClick={props.onCloseClick}>
          {t('common:close')}
        </Button>
      </div>
    </>
  )
}

export default InvoiceExpired
