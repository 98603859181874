import * as React from 'react'
import { AppStateRootMode } from '../../../AppState'
import { useSwapCryptoNodeStatus } from '../../../hooks/swap-wallets'

export const MaintenanceBar: React.FC<{ rootMode: AppStateRootMode }> = React.memo(({ rootMode }) => {
  const [nowTicker, setNowTicker] = React.useState(Date.now())
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setNowTicker(Date.now())
    }, 10000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [setNowTicker])

  const [swapNodeStatus] = useSwapCryptoNodeStatus()

  const ago10Minutes = nowTicker - 10 * 60 * 1000

  const isSwapNotSynchronized = swapNodeStatus?.syncTime ? swapNodeStatus.syncTime <= ago10Minutes : false

  const isMaintenance = isSwapNotSynchronized

  if (!isMaintenance) return null
  return (
    <div className="maintenance-bar">
      <p>BEP20 Network Maintenance! Contracts and Deposits will be updated soon.</p>
    </div>
  )
})
