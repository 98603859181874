import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useName } from '../../hooks/current-user'
import PendingAgreementVerification from './PendingAgreementVerification.component'
import { verifyAgreementAcceptance } from '../../services/api'

const MemberPendingAgreementVerification: React.FunctionComponent<RouteComponentProps> = props => {
  const { name } = useName()
  if (!name) return null

  return <PendingAgreementVerification name={name} onVerify={verifyAgreementAcceptance} />
}

export default withRouter(MemberPendingAgreementVerification)
