import React from 'react'
import { add } from '@allcoinwallet/invest-bitcoin-decimal'
import NetworkGraph, { TreeItem } from './NetworkGraph.component'
import AffiliateOverviewModal from '../../modals/affiliate-overview'
import { useMemberNetwork, useMemberNetworkTree, useMemberNetworkSummary } from '../../hooks/network'
import { useUsername } from '../../hooks/current-user'
import { useMemberNetworkCakeSmartFarmLastProfitCycleStats } from '../../hooks/stats'

const MemberNetworkGraph: React.FunctionComponent = () => {
  const { username } = useUsername()
  const [memberNetwork] = useMemberNetwork(8, username)
  const tree = useMemberNetworkTree(username, memberNetwork)
  const networkSummary = useMemberNetworkSummary(username, memberNetwork)
  const [networkLastProfitCycle] = useMemberNetworkCakeSmartFarmLastProfitCycleStats(username)
  const stakedAmount = React.useMemo(
    () => (networkLastProfitCycle ? add(networkLastProfitCycle.firstCycleAmount, networkLastProfitCycle.startNetAmount) : null),
    [networkLastProfitCycle]
  )
  const [selectedAffiliateUsername, setSelectedAffiliateUsername] = React.useState<string | null>(null)
  const onClickNode = (item: TreeItem) => {
    const affiliateUsername = item.name
    setSelectedAffiliateUsername(affiliateUsername)
  }
  const onCloseAffiliateOverviewModal = () => {
    setSelectedAffiliateUsername(null)
  }
  return (
    <>
      {tree && (
        <NetworkGraph
          tree={tree}
          onClickNode={onClickNode}
          directReferralsCount={networkSummary && networkSummary.directsCount}
          indirectReferralsCount={networkSummary && networkSummary.indirectsCount}
          stakedAmount={stakedAmount}
        />
      )}
      {selectedAffiliateUsername && <AffiliateOverviewModal username={selectedAffiliateUsername} onClose={onCloseAffiliateOverviewModal} />}
    </>
  )
}

export default MemberNetworkGraph
