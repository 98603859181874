import React from 'react'
import { InvoiceItem } from '@allcoinwallet/inkryptus-db-schema'
import { get } from 'lodash'
import { t, lang } from '../../i18n'
import { icons } from '../../assets'
import { Button } from '../../components/shared'

interface Props {
  item: InvoiceItem
}

const InvoiceItemPostPaymentInstruction: React.SFC<Props> = props => {
  if (props.item.type === 'debits') return null
  if (props.item.type === 'operational_limit') return null
  const productIdAlias = props.item.productId === 'tradesoftware_customer' ? 'tradesoftware' : props.item.productId
  return (
    <>
      <div>
        <p>{t(`desk:invoice_modal.executed.notice.${productIdAlias}.title`)}</p>
        <p>{t(`desk:invoice_modal.executed.notice.${productIdAlias}.description`)}</p>
      </div>
      <div>
        <div>
          <img src={icons.play} onClick={getVideoTutorialLink(productIdAlias)} />
        </div>
        <div>
          <div>
            <p>{t(`desk:invoice_modal.executed.notice.${productIdAlias}.recommended_step.title`)}</p>
            <p>{t(`desk:invoice_modal.executed.notice.${productIdAlias}.recommended_step.instruction`)}</p>
          </div>
          <Button size="medium" type="primary" onClick={goToProductFollowLink(productIdAlias)}>
            {t(`desk:invoice_modal.executed.notice.${productIdAlias}.follow_button.label`)}
          </Button>
        </div>
      </div>
    </>
  )
}

export default InvoiceItemPostPaymentInstruction

const getVideoTutorialLink = (productId: string) => () => {
  const url = get(
    {
      fxchrome: {
        'en-US': 'http://tutorials.tradesoftware.com/university/fx-chrome-registration-tutorial/',
        'es-ES': 'http://tutorials.tradesoftware.com/university/es/tutorial-de-catastro-en-fx-chrome/',
        'pt-BR': 'http://tutorials.tradesoftware.com/university/pt/tutorial-de-cadastro-na-fx-chrome/',
        'ru-RU':
          'http://tutorials.tradesoftware.com/university/ru/%d1%80%d1%83%d0%ba%d0%be%d0%b2%d0%be%d0%b4%d1%81%d1%82%d0%b2%d0%be-%d0%bf%d0%be-%d1%80%d0%b5%d0%b3%d0%b8%d1%81%d1%82%d1%80%d0%b0%d1%86%d0%b8%d0%b8-fx-chrome/',
      },
      mvts: {
        'en-US': 'http://tutorials.tradesoftware.com/university/compra-mvts-3/',
        'es-ES': 'http://tutorials.tradesoftware.com/university/pt/compra-mvts/',
        'pt-BR': 'http://tutorials.tradesoftware.com/university/pt/compra-mvts/',
        'ru-RU': 'http://tutorials.tradesoftware.com/university/ru/compra-e-configuracao-mvts/',
      },
      ignisight: {
        'en-US': '',
        'es-ES': '',
        'pt-BR': '',
        'ru-RU': '',
      },
      darwrna: {
        'en-US': 'http://tutorials.tradesoftware.com/university/binance-registration-tutorial/',
        'es-ES': 'http://tutorials.tradesoftware.com/university/es/tutorial-de-registro-binance/',
        'pt-BR': 'http://tutorials.tradesoftware.com/university/pt/tutorial-cadastro-binance/',
        'ru-RU':
          'http://tutorials.tradesoftware.com/university/ru/%d1%83%d1%87%d0%b5%d0%b1%d0%bd%d0%be%d0%b5-%d0%bf%d0%be%d1%81%d0%be%d0%b1%d0%b8%d0%b5-%d0%bf%d0%be-%d1%80%d0%b5%d0%b3%d0%b8%d1%81%d1%82%d1%80%d0%b0%d1%86%d0%b8%d0%b8/',
      },
      tradecoyote: {
        'en-US': '',
        'es-ES': '',
        'pt-BR': '',
        'ru-RU': '',
      },
      tradesoftware: {
        'en-US': 'http://tutorials.tradesoftware.com/university/trade-software-purchase-tutorial/',
        'es-ES': 'http://tutorials.tradesoftware.com/university/es/tutorial-compra-de-producto-trade-software/',
        'pt-BR': 'http://tutorials.tradesoftware.com/university/pt/tutorial-compra-de-produto-trade-software/',
        'ru-RU':
          'http://tutorials.tradesoftware.com/university/ru/%d1%80%d1%83%d0%ba%d0%be%d0%b2%d0%be%d0%b4%d1%81%d1%82%d0%b2%d0%be-%d0%bf%d0%be-%d0%bf%d0%be%d0%ba%d1%83%d0%bf%d0%ba%d0%b5-trade-software-2/',
      },
    },
    `${productId}.${lang}`
  )
  if (typeof url === 'string') window.open(url, '_blank')
}

const goToProductFollowLink = (productId: string) => () => {
  const url = get(
    {
      fxchrome: 'https://www.fxchrome.com',
      mvts: 'https://mvts-654ca.web.app/',
      ignisight: 'https://app.ignisight.com',
      darwrna: 'https://app.darwrna.com',
      tradecoyote: 'https://app.tradecoyote.com',
      tradesoftware: 'https://invictusdraft.com/Tutorials/trade-software-purchase-tutorial/',
    },
    productId
  )
  if (typeof url === 'string') window.open(url, '_blank')
}
