import React from 'react'
import CloudImage, { Transformation } from '../../../components/cloud-image'
import alrt from '../../../utils/alrt'
import { icons } from '../../../assets'

interface Props {
  username?: string | null
  name?: string | null
  loading?: boolean
  pictureVersion?: string
  onPictureLoad?: (file: File) => void | Promise<void>
}

const ProfilePictureChanger: React.SFC<Props> = props => {
  const uploadInputRef = React.useRef<HTMLInputElement | null>(null)
  const onPictureClick = () => {
    if (!uploadInputRef.current) return
    uploadInputRef.current.click()
  }
  const onPictureLoad = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.onPictureLoad) return
    const files = e.target.files
    if (!files || files.length === 0) return
    const file = files[0]
    try {
      await props.onPictureLoad(file)
    } catch (error) {
      if (error.humanizedMessage) alrt.error({ body: error.humanizedMessage })
      else alrt.error()
    }
    if (!uploadInputRef.current) return
    uploadInputRef.current.files = null
  }
  const disabled = !props.onPictureLoad || props.loading
  const loading = props.loading || false
  return (
    <>
      <div>
        <div>
          <div className={loading ? 'loading' : undefined} onClick={disabled ? undefined : onPictureClick}>
            <div>
              <img src={icons.editPhoto} />
              <p>Editar foto</p>
            </div>
          </div>
          {loading ? (
            <CloudImage spinner>
              <Transformation crop="fill" width="480" height="480" />
            </CloudImage>
          ) : (
            <CloudImage
              name={props.username ? `usernames_pictures/${props.username}` : undefined}
              fallbackAvatar
              version={props.pictureVersion}
            >
              <Transformation crop="thumb" gravity="face" width="480" height="480" />
            </CloudImage>
          )}
          <input
            type="file"
            style={{ display: 'none' }}
            ref={uploadInputRef}
            accept="image/.*"
            multiple={false}
            onChange={disabled ? undefined : onPictureLoad}
          />
        </div>
      </div>
      <div>
        <p>{props.name}</p>
      </div>
    </>
  )
}

export default ProfilePictureChanger
