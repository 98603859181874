import React from 'react'
import TutorialCardItem from './TutorialCard.component'
import { useTutorialResource } from '../../hooks/resources'

interface Props {
  resourceId: string
}

const TutorialCard: React.FunctionComponent<Props> = props => {
  const [tutorial] = useTutorialResource(props.resourceId)

  if (!tutorial) return null
  return (
    <TutorialCardItem
      title={tutorial.title}
      description={tutorial.description}
      thumbnailUrl={tutorial.thumbnailUrl}
      openingLink={tutorial.openingLink}
      videoLink={undefined}
    />
  )
}

export default TutorialCard
