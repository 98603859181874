import React from 'react'
import { Decimal, sub } from '@allcoinwallet/invest-bitcoin-decimal'
import { flatMap, map, reverse, sortBy, upperCase } from 'lodash'
import moment from 'moment'
import { t } from '../../i18n'
import { db } from '../../services/firebase'
import { useDatabaseObject, WalletTransaction } from '../../hooks/database'
import { useUid } from '../../hooks/current-user'
import { formatDate, formatHours } from '../../utils/format'
import { getBscTransactionExplorerUrl } from '../../utils/testnet'
import { SwapWalletWithdrawalRequestModalContext } from '../../modals/swap-wallet-withdrawal-request'

import SmartTable from '../../components/smart-table'
import { DecimalFormatter } from '../../components/shared'

const MemberSwapWalletTable: React.FunctionComponent = () => {
  const { showModal: showSwapWalletWithdrawalRequestModal } = React.useContext(SwapWalletWithdrawalRequestModalContext)

  const [uid] = useUid()
  const [dateStart, setDateStart] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  const [dateEnd, setDateEnd] = React.useState(moment().format('YYYY-MM-DD'))
  const transactionsQuery = uid
    ? db
        .child('walletsTransactions')
        .orderByChild('_indexes/userId__timestamp')
        .startAt(`${uid}__${dateStart.length > 0 ? String(moment(dateStart).valueOf()) : '0000000000000'}`)
        .endAt(`${uid}__${dateEnd.length > 0 ? String(moment(dateEnd).add(1, 'day').valueOf()) : '9999999999999'}`)
    : undefined
  const transactions = useDatabaseObject(transactionsQuery)

  const transactionsList = React.useMemo(() => {
    if (!transactions.value) return []
    const orderedTransactions = reverse(
      sortBy(
        flatMap(transactions.value, (t, transactionId): [WalletTransaction & { swapIn?: boolean }, string][] =>
          t.type === 'swap'
            ? [
                [{ ...t, swapIn: true }, transactionId + '_in'],
                [t, transactionId],
              ]
            : [[t, transactionId]]
        ),
        ['0.timestamp', '0.confirmationTime']
      )
    )
    return map(orderedTransactions, ([transaction, transactionId]) => [
      transaction.type === 'deposit' || transaction.confirmationTransactionHash ? (
        <a
          key={`${transactionId}_url`}
          href={getBscTransactionExplorerUrl(transaction.confirmationTransactionHash || transactionId)}
          target="_blank"
        >
          {transaction.confirmationTransactionHash || transactionId}
        </a>
      ) : transaction.type === 'withdrawal' ? (
        <a key={`${transactionId}_url`} onClick={() => showSwapWalletWithdrawalRequestModal(transactionId)}>
          {transactionId}
        </a>
      ) : (
        transactionId
      ),
      `${formatDate(transaction.timestamp)} | ${formatHours(transaction.timestamp)}`,
      transaction.swapIn ? transaction.inSymbol || '-' : transaction.symbol,
      t(`fields:walletTransaction.type.ofType.${transaction.type}`, upperCase(transaction.type)),
      <span
        key={`${transactionId}_status`}
        className={transaction.confirmationTime ? 'confirmed' : !transaction.failureReason ? 'waiting' : ''}
      >
        {transaction.confirmationTime ? 'CONFIRMED' : transaction.failureReason ? 'FAILED' : 'PROCESSING'}
      </span>,
      <DecimalFormatter value={transaction.swapIn ? transaction.inAmount || Decimal(0) : transaction.amount} />,
      <DecimalFormatter value={transaction.type !== 'deposit' && !transaction.swapIn ? transaction.feeAmount : Decimal(0)} />,
      <span className={transaction.type === 'withdrawal' || transaction.swapIn ? 'danger' : 'success'}>
        <DecimalFormatter
          value={
            transaction.type !== 'deposit' && !transaction.swapIn
              ? sub(transaction.amount, transaction.feeAmount)
              : transaction.swapIn
              ? transaction.inAmount || Decimal(0)
              : transaction.amount
          }
        />
      </span>,
    ])
  }, [transactions.value, showSwapWalletWithdrawalRequestModal])

  return (
    <SmartTable
      tableTitle={t('desk:financial_page.tab_swap_wallet.transactions_history_widget.title', 'Transaction History')}
      columns={[
        t('fields:walletTransaction.id.label', 'ID'),
        t('fields:walletTransaction.timestamp.label', 'Time'),
        t('common:walletTransaction.symbol.label', 'Currency'),
        t('common:type'),
        t('common:status', 'Status'),
        t('fields:walletTransaction.amount.label', 'Gross'),
        t('fields:walletTransaction.feeAmount.label', 'Fee'),
        t('fields:walletTransaction.totalAmount.label', 'Net'),
      ]}
      rows={transactionsList}
      filter={{
        dateStart,
        dateEnd,
        setDateStart,
        setDateEnd,
      }}
    />
  )
}

export default MemberSwapWalletTable
