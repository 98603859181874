import { MemberWallet, WalletTransaction } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'

function getWalletId(userId: string, currency: string): string {
  return `${userId}__${currency}`
}

export function useMemberWallet(userId?: string | null, currency?: string): [MemberWallet | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersWallets').child(walletId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useMemberWalletAddress(userId?: string | null, currency?: string): [string | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersWallets').child(walletId).child('address') : undefined)
  return [v.value, v.loading, v.error]
}

export function useWalletTransaction(transactionId?: string | null): [WalletTransaction | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(transactionId ? db.child('walletsTransactions').child(transactionId) : undefined)
  return [v.value, v.loading, v.error]
}
