import React from 'react'
import { padStart } from 'lodash'
import moment from 'moment'

interface Props {
  timestamp: number
}

const initialTimer = '--:--'
const zeroedTimer = '00:00'

const CountdownTimer: React.FunctionComponent<Props> = props => {
  const [timer, setTimer] = React.useState<string>(initialTimer)

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const diff = moment(props.timestamp).diff(Date.now(), 'minutes', true)
      if (diff <= 0) {
        if (timer === zeroedTimer) return
        return setTimer(zeroedTimer)
      }
      const hours = Math.floor(diff)
      const seconds = Math.floor((diff - hours) * 60)
      setTimer(`${hours}:${padStart(String(seconds), 2, '0')}`)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [props.timestamp, timer === zeroedTimer])

  let className: string | undefined = undefined
  if (timer === zeroedTimer) className = 'timer-finished'
  else if (timer === initialTimer) className = 'timer-stopped'
  else className = 'timer-running'

  return <span className={className}>{timer}</span>
}

export default CountdownTimer
