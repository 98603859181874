import React from 'react'
const CloseIcon: React.SFC<{ className?: string }> = props => {
  return (
    <svg className={props.className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17.5" stroke="white" />
      <path d="M26.6777 9.70711L25.9706 9L8.99999 25.9706L9.70709 26.6777L26.6777 9.70711Z" fill="white" />
      <path d="M25.9705 26.6778L26.6776 25.9707L9.70708 9.00014L8.99997 9.70725L25.9705 26.6778Z" fill="white" />
    </svg>
  )
}

export default CloseIcon
