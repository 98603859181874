import React from 'react'
import { logo } from '../../assets'

interface Props {
  title: string
}

const EstimateModalMenu: React.FunctionComponent<Props> = props => {
  return (
    <div className="menu">
      <div>
        <p>{props.title}</p>
      </div>
      <div>
        <img src={logo.allcoinwallet.regular} />
      </div>
    </div>
  )
}

export default EstimateModalMenu
