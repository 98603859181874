import React from 'react'
import EstimateDebitsModal from './EstimateDebitsModal.container'

export interface EstimateDebitsModalContextValues {
  debitsIds?: string[] | null
  showModal: (debitsIds: string[]) => void
  closeModal: () => void
}
export const EstimateDebitsModalContext = React.createContext<EstimateDebitsModalContextValues>({
  showModal: () => { },
  closeModal: () => { },
})

export const EstimateDebitsModalProvider: React.FunctionComponent = props => {
  const [showingEstimateDebitsIds, setShowingEstimateDebitsIds] = React.useState<string[] | null>(null)
  const showModal = (debitsIds: string[]) => {
    setShowingEstimateDebitsIds(debitsIds)
  }
  const closeModal = () => {
    setShowingEstimateDebitsIds(null)
  }

  return (
    <EstimateDebitsModalContext.Provider
      value={{ debitsIds: showingEstimateDebitsIds, showModal, closeModal }}
    >
      <EstimateDebitsModal debitsIds={showingEstimateDebitsIds} onClose={closeModal} />
      {props.children}
    </EstimateDebitsModalContext.Provider>
  )
}

export default EstimateDebitsModalContext
