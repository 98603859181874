import * as React from 'react'
import { Reference, Query } from '../../hooks/database'
import { icons } from '../../assets'
import { t } from '../../i18n'

export function useSmartTableSort(initialState: {
  columnKey: string
  direction: 'asc' | 'desc'
}): {
  activeSortColumnKey: string
  activeSortDirection: 'asc' | 'desc'
  toggleColumnSort: (columnKey: string) => any
} {
  const [sortState, setSortState] = React.useState<{ columnKey: string; direction: 'asc' | 'desc' }>(initialState)
  const activeSortColumnKey = sortState.columnKey
  const activeSortDirection = sortState.direction
  const toggleColumnSort = React.useCallback(
    (columnKey: string) => {
      setSortState((s) => {
        if (s.columnKey === columnKey) {
          return { columnKey, direction: s.direction === 'asc' ? 'desc' : 'asc' }
        }
        return { columnKey, direction: 'desc' }
      })
    },
    [setSortState]
  )

  return { activeSortColumnKey, activeSortDirection, toggleColumnSort }
}

export interface SelectSmartTableFilter<T = any> {
  functions: {
    ChangeSelectFilter: (e: any) => void
  }
  fields: {
    name: string
    onQuery: (ref: Reference<T>, uid: string, dateStartFilter: string, dateEndFilter: string) => Reference<T> | Query<T>
    onFilter: (v: T) => T
  }[]
}

export interface SmartTableColumn<T = any> {
  label: string
  key: string
  render: (row: T) => any
  sortable?: boolean
}

export interface SmartTableSelectOption {
  value: string
  label?: string
}

export interface SmartTableProps<T = any> {
  columns: SmartTableColumn<T>[]
  rowKey: string
  rows: T[]
  rowClassName?: (obj: T) => string | undefined
  sort?: {
    activeSortColumnKey?: string
    activeSortDirection?: 'asc' | 'desc'
    onSort?: (columnKey: string) => any
  }
  filter?: {
    selectButtonsOptions?: SmartTableSelectOption[]
    selectedButtonOption?: string
    setSelectedButtonOption?: React.Dispatch<React.SetStateAction<string>>
    dateStart?: string
    dateEnd?: string
    setDateStart?: React.Dispatch<React.SetStateAction<string>>
    setDateEnd?: React.Dispatch<React.SetStateAction<string>>
    selectLabel?: string
    selectOptions?: SmartTableSelectOption[]
    selectedOption?: string
    setSelectedOption?: React.Dispatch<React.SetStateAction<string>>
  }
}

const SmartTableDataCell: React.FC<{ row: any; column: SmartTableColumn; columnKey: string | number }> = React.memo(
  ({ row, column, columnKey }) => {
    return <td key={columnKey}>{column.render(row)}</td>
  }
)

const SmartTableHeaderCell: React.FC<{
  column: SmartTableColumn
  activeSortDirection?: 'asc' | 'desc'
  onClick?: (columnKey: string) => {}
}> = ({ column, onClick, activeSortDirection }) => {
  const columnKey = column.key
  const propagateClick = React.useCallback(() => {
    if (onClick) onClick(columnKey)
  }, [columnKey, onClick])
  return (
    <th onClick={propagateClick} style={{ cursor: activeSortDirection || column.sortable ? 'pointer' : 'default' }}>
      {column.label}
      {(activeSortDirection || column.sortable) && (
        <>
          {activeSortDirection === undefined && <img src={icons.arrowTable} />}
          {activeSortDirection === 'asc' && <img src={icons.arrowTable} style={{ transform: 'rotate(40deg)' }} />}
          {activeSortDirection === 'desc' && <img src={icons.arrowTable} style={{ transform: 'rotate(-40deg)' }} />}
        </>
      )}
    </th>
  )
}

const SmartTableSelectButtonOption: React.FC<{
  value: string
  label?: string
  active?: boolean
  onSelect: (value: string) => any
}> = React.memo(({ active, value, label, onSelect }) => {
  const propagateSelectedOption = React.useCallback(() => {
    onSelect(value)
  }, [value, onSelect])
  return (
    <div className={active ? 'active' : ''} onClick={propagateSelectedOption}>
      <p>{label || value}</p>
    </div>
  )
})

const SmartTable: React.FunctionComponent<SmartTableProps> = (props) => {
  const {
    selectButtonsOptions,
    selectedButtonOption,
    setSelectedButtonOption,
    dateStart,
    setDateStart,
    dateEnd,
    setDateEnd,
    selectLabel,
    selectOptions,
    selectedOption,
    setSelectedOption,
  } = props.filter || {}
  const changeDateStart = React.useCallback(
    (e) => {
      if (setDateStart) setDateStart(e.target.value)
    },
    [setDateStart]
  )
  const changeDateEnd = React.useCallback(
    (e) => {
      if (setDateEnd) setDateEnd(e.target.value)
    },
    [setDateEnd]
  )
  const changeSelectOption = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (setSelectedOption) setSelectedOption(event.target.value)
    },
    [setSelectedOption]
  )

  const { activeSortColumnKey, activeSortDirection, onSort } = props.sort || {}

  return (
    <div className="table">
      <div className="options">
        <div className="left-side">
          {selectButtonsOptions !== undefined && selectedButtonOption !== undefined && setSelectedButtonOption !== undefined && (
            <div className="select-buttons">
              {selectButtonsOptions.map((option) => (
                <SmartTableSelectButtonOption
                  key={option.value}
                  {...option}
                  active={option.value === selectedButtonOption}
                  onSelect={setSelectedButtonOption}
                />
              ))}
            </div>
          )}
        </div>
        <div className="right-side">
          {selectOptions !== undefined && selectedOption !== undefined && setSelectedOption !== undefined && (
            <div className="select">
              {selectLabel && (<label>{selectLabel}</label>)}
              <select onChange={changeSelectOption}>
                {selectOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label || option.value}
                  </option>
                ))}
              </select>
            </div>
          )}
          {dateStart !== undefined && setDateStart !== undefined && dateEnd !== undefined && setDateEnd !== undefined && (
            <div className="date">
              <div>
                <p>{t('components:smart_table.date_filter.from_label')}:</p>
                <input value={dateStart} type="date" onChange={changeDateStart} />
              </div>
              <div>
                <p>{t('components:smart_table.date_filter.to_label')}:</p>
                <input value={dateEnd} type="date" onChange={changeDateEnd} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {props.columns.map((column) => (
                <SmartTableHeaderCell
                  key={column.key}
                  column={column}
                  activeSortDirection={activeSortColumnKey === column.key ? activeSortDirection : undefined}
                  onClick={onSort}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {props.rows.map((row, rowIndex) => (
              <tr key={row[props.rowKey] || rowIndex} className={props.rowClassName ? props.rowClassName(row) : undefined}>
                {props.columns.map((column, columnIndex) => (
                  <SmartTableDataCell key={column.key || columnIndex} columnKey={column.key || columnIndex} column={column} row={row} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SmartTable
