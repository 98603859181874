import React from 'react'
import moment from 'moment'
import { map, range, keys, orderBy } from 'lodash'
import { BarChart, Bar, Tooltip, XAxis, YAxis } from 'recharts'
import { t } from '../../i18n'
import { useNetworkMemberActivities } from '../../hooks/network-member-activities'

const MemberNetworkActivityChart: React.FunctionComponent = () => {
  const [networkMemberActivities] = useNetworkMemberActivities('sign_up')
  const data = React.useMemo(() => {
    if (!networkMemberActivities) return []
    const now = moment()
    const monthly: { [month: string]: { directs: number; indirects: number; total: number } } = {}
    for (const activityKey of keys(networkMemberActivities)) {
      const activity = networkMemberActivities[activityKey]
      const monthKey = getMonthKey(moment(activity.time))
      if (!monthly[monthKey]) monthly[monthKey] = { directs: 0, indirects: 0, total: 0 }
      const isIndirect = !!activity.details && activity.details.connectionLevel && Number(activity.details.connectionLevel) > 0
      monthly[monthKey].total += 1
      if (isIndirect) {
        monthly[monthKey].indirects += 1
        continue
      }
      monthly[monthKey].directs += 1
    }
    for (const daysOffset of range(30, 0)) {
      const monthKey = getMonthKey(moment(now).subtract(daysOffset, 'days'))
      if (monthly[monthKey]) continue
      monthly[monthKey] = { directs: 0, indirects: 0, total: 0 }
    }
    return orderBy(map(monthly, (data, month) => ({ ...data, month, monthLabel: moment(month).format('MMM D') })), ['month'])
    function getMonthKey(m: moment.Moment) {
      return m.format('YYYY-MM-DD')
    }
  }, [networkMemberActivities])
  return (
    <>
      <div>
        <p>{t('desk:network_page.tab_overview.activity_chart_widget.title')}</p>
      </div>
      <div>
        <BarChart width={400} height={210} data={data} margin={{ top: 10, right: 25, bottom: 10, left: 25 }}>
          <Tooltip cursor={false} />
          <XAxis dataKey="monthLabel" />
          <YAxis orientation="left" allowDecimals={false} label={<div /> as any} hide={false} width={10} />
          <Bar dataKey="total" stackId="invitations" fill="#82ca9d" />
        </BarChart>
      </div>
    </>
  )
}

export default MemberNetworkActivityChart
