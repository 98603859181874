import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import { logo } from '../../../assets/'
import { t } from '../../../i18n'
import { useFormErrors } from '../../../hooks/form-errors'

import Input from '../../shared/input'
import Button from '../../shared/button'
import FormErrorsList from '../../shared/form-errors-list'
import { Link } from 'react-router-dom'

const withTargetValue = (setFn: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => setFn(e.target.value)
const withKeyEnterPress = (callback: () => void) => (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') callback()
}

interface Props {
  onSubmit: (data: { username: string; password: string }) => Promise<void>
}
const SigninForm: React.FunctionComponent<Props> = props => {
  const [username, setUsername] = useState<string>('')
  const usernameEl = useRef<HTMLInputElement>(null)
  const [password, setPassword] = useState<string>('')
  const passwordEl = useRef<HTMLInputElement>(null)

  const [formErrors, setFormErrors, solveFormError] = useFormErrors()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    if (loading) return
    setLoading(true)
    try {
      await props.onSubmit({
        username,
        password,
      })
    } catch (error) {
      if (error.formErrors) setFormErrors(error.formErrors)
    }
    setLoading(false)
  }

  const onBlur = (fieldName: string) => () => {
    solveFormError(fieldName)
  }

  const focusOnUsername = () => {
    if (!usernameEl.current) return
    usernameEl.current.focus()
  }
  useEffect(focusOnUsername, [])
  const focusOnPassword = () => {
    if (!passwordEl.current) return
    passwordEl.current.focus()
  }

  return (
    <div className="box-login">
        <div className="login">
          <div>
            <a href="https://www.inkryptus.com">
              <img src={logo.inkryptus.regularDark} />
            </a>
          </div>
          <div>
            <p>{t('auth:sign_in.page_title')}</p>
            <div className={`input-large i-stroke ${formErrors['username'] ? 'i-wrong' : undefined}`}>
              <Input
                size="medium"
                inputRef={usernameEl}
                placeholder={t('fields:username.label')}
                value={username}
                onChange={withTargetValue(setUsername)}
                onBlur={onBlur('username')}
                onKeyPress={withKeyEnterPress(focusOnPassword)}
              />
            </div>
            <div className={`input-large i-stroke ${formErrors['password'] ? 'i-wrong' : undefined}`}>
              <Input
                size="medium"
                inputRef={passwordEl}
                style={formErrors['password'] ? 'wrong' : undefined}
                placeholder={t('fields:password.label')}
                type="password"
                value={password}
                onChange={withTargetValue(setPassword)}
                onBlur={onBlur('password')}
                onKeyPress={withKeyEnterPress(onSubmit)}
              />
            </div>
            <Button size="medium" type="primary" disabled={loading} loading={loading} onClick={onSubmit}>
              {t('auth:sign_in.button_sign_in.label')}
            </Button>
            <Link to="/reset_password">{t('auth:sign_in.link_forgot_password')}</Link>
            <FormErrorsList formErrors={formErrors} itemClassName="response-signup" />
          </div>
        </div>

    </div>
  )
}

export default SigninForm
