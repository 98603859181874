import React from 'react'
import { Link } from 'react-router-dom'
import { t } from '../../i18n'
import { logo, icons } from '../../assets'

const FinishedPasswordReset: React.SFC = () => {
  return (
    <div className="password-recovery-finished">
      <div>
        <img src={logo.inkryptus.regularDark} />
      </div>
      <div>
        <p>{t('auth:reset_password.page_title')}</p>
        <div className="response-signup">
          <img src={icons.success} />
          <p className="sucess">{t('auth:reset_password.finished_message')}</p>
        </div>
        <Link to="/">{t('auth:reset_password.go_to_app_link.label')}</Link>
      </div>
    </div>
  )
}

export default FinishedPasswordReset
