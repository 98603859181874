import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './styles/main.scss'
import './i18n'

import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { AppStateProvider } from './AppState'
import { WalletWithdrawalRequestModalProvider } from './modals/wallet-withdrawal-request'
import { AppModeSwitchModalProvider } from './modals/app-mode-switch'

const rootElement = document.getElementById('root') as HTMLElement

Modal.defaultStyles = { overlay: {}, content: {} }
Modal.setAppElement(rootElement)

ReactDOM.render(
  <>
    <ToastContainer />
    <BrowserRouter>
      <AppStateProvider>
        <AppModeSwitchModalProvider>
          <WalletWithdrawalRequestModalProvider>
            <Routes />
          </WalletWithdrawalRequestModalProvider>
        </AppModeSwitchModalProvider>
      </AppStateProvider>
    </BrowserRouter>
  </>,
  rootElement
)

serviceWorker.unregister()
