import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { t } from '../../i18n'
import { icons } from '../../assets'
import { humanizeDays, formatUpperProductId, formatCurrency, toUpper } from '../../utils/format'

import InvoiceModalMenu from './EstimateModalMenu.component'
import { Button } from '../../components/shared'
import { Trans } from 'react-i18next'

interface Props {
  estimateAmount?: decimal | null
  licensePrice: decimal
  licenseDurationDays: number
  productId: string
  productVariant?: string | null
  freeTrialDays?: number
  onPayClick: () => void | Promise<void>
  onCloseClick: () => void
}
const Estimate: React.FunctionComponent<Props> = (props) => {
  const [paying, setPaying] = React.useState<boolean>(false)

  const onPayClick = async () => {
    if (paying) return
    setPaying(true)
    try {
      await props.onPayClick()
    } catch (error) {}
    setPaying(false)
  }

  return (
    <>
      <InvoiceModalMenu title={t('desk:estimate_modal.menu_title')} />
      <div className="content">
        <div className="info-payment">
          <div>
            <div>
              <p>{t('desk:estimate_modal.payment_status')}</p>
              <p>--:--</p>
            </div>
            <div>
              <p className="font-small">
                <Trans i18nKey="fields:invoice.estimateAmount.description" values={{ estimateAmount: props.estimateAmount || '-' }}>
                  {'Estimate Amount: '}
                  <span className={props.freeTrialDays ? 'font-line-through' : undefined}>{'-'}</span>
                  {' BTC'}
                </Trans>
              </p>
              <p className="font-large" style={props.freeTrialDays ? { textDecoration: 'line-through' } : undefined}>
                {formatCurrency(props.licensePrice)}
              </p>
            </div>
            <div>
              <p>{t('fields:invoice.walletAddress.label')}</p>
              <div className="input-large i-fill">
                <Button size="large" type="success" onClick={onPayClick} loading={paying} disabled={paying}>
                  {props.freeTrialDays ? t('desk:estimate_modal.pay_button.label_free_trial') : t('desk:estimate_modal.pay_button.label')}
                </Button>
              </div>
            </div>
          </div>
          <div>
            <img src={icons.qrWalletAcwPayment} />
          </div>
        </div>
        <div className="info-product">
          <div>
            <p>{t('desk:invoice_modal.invoice_item.title')}</p>
          </div>
          <div>
            <p>
              {t('desk:invoice_modal.invoice_item.license_title', { duration: humanizeDays(props.freeTrialDays || props.licenseDurationDays) })}
            </p>
            <p>
              {formatUpperProductId(props.productId, props.productVariant)}
              {props.freeTrialDays && ' - ' + toUpper(t('common:free_trial'))}
            </p>
          </div>
          <div>
            <img src={icons.info} />
            <p>{props.freeTrialDays ? t('desk:estimate_modal.instruction_free_trial') : t('desk:estimate_modal.instruction')}</p>
          </div>
          <Button size="medium" type="primary" onClick={props.onCloseClick}>
            {t('common:close')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Estimate
