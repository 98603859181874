import * as React from 'react'
import { t } from '../../i18n'
import Button from '../../components/shared/button'
import Input from '../../components/shared/input'
import alrt from '../../utils/alrt'

interface Props {
  onSubmit: (params: { email: string; name: string }) => Promise<void> | void
}

interface State {
  invitationEmail: string
  invitationName: string
  loading?: boolean
}

class EmailInvitationFormWidget extends React.Component<Props, State> {
  public state: State = {
    invitationEmail: '',
    invitationName: '',
  }

  onClick = async () => {
    if (this.state.loading) return
    this.setState({ loading: true })
    const email = this.state.invitationEmail
    const name = this.state.invitationName
    try {
      await this.props.onSubmit({ email, name })
      alrt.success()
    } catch (error) {
      if (error.humanizedMessage) {
        alrt.error({ body: error.humanizedMessage })
      } else {
        alrt.error()
      }
    }
    this.setState({ loading: false })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    })
  }

  render() {
    return (
      <div className="email-referral">
        <div>
          <p>{t('desk:network_page.tab_overview.email_invitation_dispatcher_widget.title')}</p>
        </div>
        <div className="input-large i-stroke">
          <Input
            id="invitationEmail"
            placeholder={t('desk:network_page.tab_overview.email_invitation_dispatcher_widget.form.email_placeholder')}
            onChange={this.handleChange}
          />
        </div>
        <div className="input-large i-stroke">
            <Input
              id="invitationName"
              placeholder={t('desk:network_page.tab_overview.email_invitation_dispatcher_widget.form.name_placeholder')}
              onChange={this.handleChange}
            />
        </div>
        <Button
            onClick={this.onClick}
            size="medium"
            type="primary"
            loading={this.state.loading}
            disabled={this.state.loading || !this.state.invitationEmail || !this.state.invitationName}
          >
            Send email invitation
          </Button>

      </div>
    )
  }
}

export default EmailInvitationFormWidget
