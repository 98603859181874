import React from 'react'
import { map } from 'lodash'
import { t } from '../../i18n'
import TutorialCard from '../../components/tutorial-card'

interface Props {
  tutorialsIds: { [key: string]: string }
}

const UsageLearningTutorials: React.SFC<Props> = props => {
  return (
    <div className="box-tutorials-product">
      <p>{t('desk:product_overview_modal.usage_learning_tutorials.title')}</p>
      <div className="tutorials-products">
        {map(props.tutorialsIds, tutorialId => (
          <TutorialCard key={tutorialId} resourceId={tutorialId} />
        ))}
      </div>
    </div>
  )
}

export default UsageLearningTutorials
