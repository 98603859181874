import React from 'react'
import { useMemberProfile } from '../../hooks/profile'

const MemberContacts: React.FunctionComponent = () => {
  const [profile] = useMemberProfile()
  if (!profile) return <div />
  return (
    <div>
      <p>{`${profile.name} ${profile.lastName}`}</p>
      <p>{profile.email}</p>
      <p>{profile.phone}</p>
    </div>
  )
}

export default MemberContacts
