import React from 'react'
import Modal from 'react-modal'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { requestSwapWalletWithdrawal, requestSwapWalletWithdrawalResendConfirmation } from '../../services/api'

import LoadingSpinner from '../../components/loading-spinner'
import SwapWalletWithdrawalRequest from './SwapWalletWithdrawalRequest.component'
import SwapWalletWithdrawalConfirmation from './SwapWalletWithdrawalConfirmation.component'
import { useSwapWalletTransaction } from '../../hooks/swap-wallets'
import { isValidSwapToken } from '../../utils/swap'

interface Props {
  symbolOrTransactionId?: string | null
  changeTransaction: (symbolOrTransactionId: string) => any
  onClose: () => void
}

const SwapWalletWithdrawalRequestModal: React.FunctionComponent<Props> = (props) => {
  const symbolOrTransactionId = props.symbolOrTransactionId || undefined
  const newWithdrawalSymbol = isValidSwapToken(symbolOrTransactionId) ? symbolOrTransactionId : undefined
  const transactionId = newWithdrawalSymbol ? undefined : symbolOrTransactionId
  const [transaction, transactionLoading, transactionError] = useSwapWalletTransaction(transactionId)
  const symbol = transaction?.symbol || newWithdrawalSymbol

  const close = props.onClose

  React.useEffect(() => {
    if (!transactionId) return
    if (transaction || transactionLoading) return
    if (transactionError) close()
  }, [transactionId, transaction, transactionLoading, transactionError, close])

  const changeTransactionId = props.changeTransaction
  const onCreateClick = React.useCallback(
    async (address: string, grossAmount: decimal) => {
      if (!address || !grossAmount) return
      if (!symbol) return
      const { transactionId: newTransactionId } = await requestSwapWalletWithdrawal(symbol, address, grossAmount)
      changeTransactionId(newTransactionId)
    },
    [transactionId, changeTransactionId, symbol]
  )

  const onResendClick = React.useCallback(async () => {
    if (!transactionId) return
    if (!symbol) return
    await requestSwapWalletWithdrawalResendConfirmation(symbol, transactionId)
  }, [transactionId, symbol])

  if (!transactionId && !symbol) return null
  if ((transactionLoading || !transaction) && transactionId) return <LoadingSpinner />

  return (
    <Modal
      isOpen={!!symbolOrTransactionId}
      onRequestClose={close}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-payment-initial"
    >
      {!transactionId && <SwapWalletWithdrawalRequest symbol={symbol} onCreateClick={onCreateClick} onCloseClick={close} />}
      {transactionId && transaction && (
        <SwapWalletWithdrawalConfirmation
          transactionId={transactionId}
          transaction={transaction}
          onResendClick={onResendClick}
          onCloseClick={close}
        />
      )}
    </Modal>
  )
}

export default SwapWalletWithdrawalRequestModal
