import React from 'react'
import { useMemberProfile } from '../../../hooks/profile'
import { useUsername } from '../../../hooks/current-user'
import { t } from '../../../i18n'
import ProfileInput from './ProfileInput.component'
import { updateProfileField } from '../../../services/api'

const ProfileInfoForm: React.FunctionComponent = () => {
  const { username } = useUsername()
  const [profile] = useMemberProfile()
  const onEdit = (field: string) => async (value: string) => {
    if (!['birthDate', 'gender', 'phone'].includes(field)) return
    const fieldName: 'birthDate' | 'gender' | 'phone' = field as any
    await updateProfileField(fieldName, value)
  }
  return (
    <>
      <ProfileInput label={t(`fields:username.label`)} value={username || undefined} />
      <ProfileInput label={t(`fields:email.label`)} value={profile ? profile.email : undefined} />
      <ProfileInput label={t(`fields:country.label`)} value={profile ? profile.country : undefined} type="country" />
      <ProfileInput label={t(`fields:phone.label`)} value={profile ? profile.phone : undefined} onEdit={onEdit('phone')} type="phone" />
      <ProfileInput label={t(`fields:birthDate.label`)} value={profile ? profile.birthDate : undefined} type="date" />
      <ProfileInput label={t(`fields:gender.label`)} value={profile ? profile.gender : undefined} onEdit={onEdit('gender')} type="gender" />
    </>
  )
}

export default ProfileInfoForm
