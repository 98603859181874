import React from 'react'

const MarketingIconSvg: React.SFC = () => {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.89 8.55029C15.8069 8.38537 15.6797 8.24669 15.5226 8.14961C15.3656 8.05253 15.1847 8.00085 15 8.00029H9.99998V2.00029C10.0107 1.78097 9.94896 1.56422 9.82426 1.38349C9.69955 1.20276 9.51883 1.06809 9.30998 1.00029C9.10921 0.93423 8.89267 0.933488 8.69145 0.998167C8.49022 1.06285 8.31467 1.18962 8.18998 1.36029L0.189984 12.3603C0.0897493 12.5052 0.0295623 12.6739 0.0155141 12.8495C0.00146601 13.0251 0.0340555 13.2013 0.109984 13.3603C0.179906 13.542 0.301372 13.6994 0.459473 13.8131C0.617575 13.9268 0.805431 13.9919 0.999984 14.0003H5.99998V20.0003C6.00014 20.2112 6.06695 20.4166 6.19088 20.5872C6.3148 20.7578 6.48949 20.8849 6.68998 20.9503C6.79046 20.9814 6.89481 20.9983 6.99998 21.0003C7.15777 21.0007 7.31341 20.9638 7.45419 20.8925C7.59497 20.8212 7.71689 20.7177 7.80998 20.5903L15.81 9.59029C15.9177 9.44108 15.9822 9.26504 15.9963 9.08154C16.0104 8.89805 15.9736 8.71421 15.89 8.55029ZM7.99998 16.9203V13.0003C7.99998 12.7351 7.89463 12.4807 7.70709 12.2932C7.51955 12.1056 7.2652 12.0003 6.99998 12.0003H2.99998L7.99998 5.08029V9.00029C7.99998 9.2655 8.10534 9.51986 8.29288 9.70739C8.48041 9.89493 8.73477 10.0003 8.99998 10.0003H13L7.99998 16.9203Z" fill="#FAA61A"/>
    </svg>
  )
}

export default MarketingIconSvg
