import { CryptoNode, MemberWallet, WalletTransaction } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useUid } from './current-user'
import { useDatabaseObject } from './database'

function getSwapWalletId(userId: string, currency: string): string {
  return `${userId}__${currency}`
}

export function useMemberSwapWallet(userId?: string | null, currency?: string): [MemberWallet | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getSwapWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersWallets').child(walletId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useMemberSwapWalletAddress(userId?: string | null, currency?: string): [string | undefined, boolean, object | undefined] {
  const walletId = userId && currency ? getSwapWalletId(userId, currency) : undefined
  const v = useDatabaseObject(walletId ? db.child('membersWallets').child(walletId).child('address') : undefined)
  return [v.value, v.loading, v.error]
}

export function useSwapWalletTransaction(transactionId?: string | null): [WalletTransaction | undefined, boolean, object | undefined] {
  const v = useDatabaseObject(transactionId ? db.child('walletsTransactions').child(transactionId) : undefined)
  return [v.value, v.loading, v.error]
}

export function useSwapCryptoNodeStatus(): [CryptoNode | undefined, boolean, object | undefined] {
  const [uid] = useUid()
  const v = useDatabaseObject(uid ? db.child('cryptoNodes').child('swap-bsc-dapp') : undefined)
  return [v.value, v.loading, v.error]
}
