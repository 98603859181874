import * as React from 'react'
import { icons} from '../assets'
export const SwapHistoryScreen: React.FC = () => {
  return (
    <div className="container">
      <div className="box-swap-history">
        <div className="back">
          <div>
            <img src={icons.arrowLeft} alt="back" />
            <p>Back</p>
          </div>
        </div>
        <div className="table">Neilor aqui voce copia a tabela de wallet</div>
      </div>
    </div>
  )
}
