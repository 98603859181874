import * as React from 'react'
import { cmp, Decimal, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { get } from 'lodash'

import { useUid } from '../../hooks/current-user'
import { useMemberWallet } from '../../hooks/wallets'
import { Button, Input } from '../../components/shared'
import { formatDecimal, parseDecimal } from '../../utils/format'
import SwapDepositWalletModalContext from '../../modals/swap-deposit-wallet'
import { fundProductContract } from '../../services/api'

export interface ProductCreateContractWidgetProps {
  symbol: string
  productId: string
}

export const ProductCreateContractWidget: React.FC<ProductCreateContractWidgetProps> = ({ symbol, productId }) => {
  const [uid, uidLoading] = useUid()
  const [wallet, walletLoading] = useMemberWallet(uid, symbol)
  const availableBalance = wallet?.balance.available ? trunc(wallet.balance.available) : undefined

  const { showModal: showSwapDepositWalletModal } = React.useContext(SwapDepositWalletModalContext)
  const openDepositModal = React.useCallback(() => {
    showSwapDepositWalletModal(symbol)
  }, [showSwapDepositWalletModal, symbol])

  const [startAmountError, setStartAmountError] = React.useState('')
  const [startAmountText, setStartAmountText] = React.useState('')
  const changeStartAmountText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStartAmountText(event.target.value)
    },
    [setStartAmountText]
  )
  const formatStartAmountText = React.useCallback(() => {
    setStartAmountText((t) => {
      const d = parseDecimal(t)
      if (d) return d
      return t
    })
  }, [setStartAmountText])
  const startAmount = parseDecimal(startAmountText)

  const fillStartAmountWithAvailableBalance = React.useCallback(() => {
    if (!availableBalance) return
    setStartAmountText(availableBalance)
  }, [availableBalance, setStartAmountText])

  const [createdContract, setCreatedContract] = React.useState(false)
  const [creatingContract, setCreatingContract] = React.useState(false)
  const createContract = React.useCallback(async () => {
    if (creatingContract) return
    if (!startAmount) return
    if (cmp(startAmount, Decimal(1)) < 0) {
      setStartAmountError('Less than minimum amount')
      return
    }
    setStartAmountError('')
    setCreatingContract(true)
    try {
      await fundProductContract(symbol, productId, startAmount)
      setCreatedContract(true)
    } catch (error) {
      console.error('Failed to create contract', error)
      const humanizedMessage = get(error, 'response.data.humanizedMessage', 'Failed to create contract! Please, try again...')
      setStartAmountError(humanizedMessage)
    }
    setCreatingContract(false)
  }, [symbol, productId, startAmount, creatingContract, setCreatingContract, setStartAmountError, setCreatedContract])

  React.useEffect(() => {
    if (!createdContract) return
    const timeoutId = setTimeout(() => {
      setCreatedContract(false)
    }, 4000)
    return () => clearTimeout(timeoutId)
  }, [createdContract, setCreatedContract])

  const loading = uidLoading || walletLoading || creatingContract
  console.log('loading', loading)

  return (
    <div className="payment">
      <p>Create a New Contract</p>

      <div className="input-large i-stroke">
        <Input id="startAmount" placeholder="0" value={startAmountText} onChange={changeStartAmountText} onBlur={formatStartAmountText} />
        <p onClick={fillStartAmountWithAvailableBalance}>MAX</p>
        {startAmountError && <p>{startAmountError}</p>}
      </div>
      <div className="balance">
        <div>
          <p>Available Balance:</p>
        </div>
        <div onClick={fillStartAmountWithAvailableBalance}>
          <p>{availableBalance ? formatDecimal(availableBalance, 8) : '-'}</p>
        </div>
      </div>
      <Button onClick={createContract} size="medium" type="success" loading={creatingContract} disabled={loading}>
        Create contract
      </Button>
      <p className="separator">or</p>
      <Button onClick={openDepositModal} size="medium" type="primary">
        Deposit funds
      </Button>

      {createdContract && (
        <div className="success-tooltip">
          <p>New contract created successfully!</p>
        </div>
      )}
    </div>
  )
  // return (
  //     <div className="email-referral">
  //       <div>
  //         <p>{t('desk:network_page.tab_overview.email_invitation_dispatcher_widget.title')}</p>
  //       </div>
  //       <div className="">
  //             <p style={{ cursor: 'pointer' }} onClick={fillFromWithAvailableBalance}>
  //               Balance: {fromAvailableBalance ? formatDecimal(fromAvailableBalance, 8) : '-'}
  //             </p>
  //       </div>
  //       <div className="input-large i-stroke">
  //           <Input
  //             id="invitationName"
  //             placeholder={t('desk:network_page.tab_overview.email_invitation_dispatcher_widget.form.name_placeholder')}
  //             onChange={this.handleChange}
  //           />
  //           <p onClick={fillFromWithAvailableBalance}>MAX</p>
  //       </div>
  //       <Button
  //           onClick={this.onClick}
  //           size="medium"
  //           type="success"
  //           loading={this.state.loading}
  //           disabled={this.state.loading || !this.state.invitationEmail || !this.state.invitationName}
  //         >
  //           Create a new contract
  //         </Button>
  //     </div>
  // )
}
