import { useMemo } from 'react'
import { merge, find, map } from 'lodash'
import { db } from '../services/firebase'
import { getLanguage, DEFAULT_LANGUAGE } from '../i18n'
import { useDatabaseObject, Product, Products, ProductLicenses, ProductLicense, ProductDetails } from './database'
import { useUsername } from './current-user'
import React from 'react'
import ProductPlansModalContext from '../modals/product-plans'
import ProductOverviewModalContext from '../modals/product-overview'

type ProductHook = [Product | null, boolean, object | undefined]
export function useProduct(productId?: string): ProductHook {
  const productRef = productId ? db.child('products').child(productId) : undefined
  const product = useDatabaseObject(productRef)
  const lang = getLanguage()
  const localizedProductRef = productId
    ? db
        .child('productsLabels')
        .child(lang)
        .child(productId)
    : undefined
  const localizedProduct = useDatabaseObject(localizedProductRef)
  const value: Product | null = useMemo(() => (product.value ? merge({}, product.value, localizedProduct.value) : null), [
    product.value,
    localizedProduct.value,
  ])
  return [value, product.loading, product.error]
}

type ProductsHook = [Products | null, boolean, object | undefined]
export function useLocalizedProducts(): ProductsHook {
  const productsRef = db.child('products')
  const products = useDatabaseObject(productsRef)
  const lang = getLanguage()
  const localizedRef = db.child('productsLabels').child(lang)
  const localized = useDatabaseObject(localizedRef)
  const localizedProducts = useMemo(() => merge({}, products.value, localized.value), [products.value, localized.value])
  const loading = products.loading || localized.loading
  const error = products.error || localized.error
  return [localizedProducts, loading, error]
}

type ProductDetailsHook = [ProductDetails | null, boolean, object | undefined]
export function useProductDetails(productId?: string): ProductDetailsHook {
  const productRef = productId
    ? db
        .child('productsDetails')
        .child(DEFAULT_LANGUAGE)
        .child(productId)
    : undefined
  const product = useDatabaseObject(productRef)
  const lang = getLanguage()
  const localizedProductRef =
    productId && lang !== DEFAULT_LANGUAGE
      ? db
          .child('productsDetails')
          .child(lang)
          .child(productId)
      : undefined
  const localizedProduct = useDatabaseObject(localizedProductRef)
  const value: ProductDetails | null = useMemo(() => (product.value ? merge({}, product.value, localizedProduct.value) : null), [
    product.value,
    localizedProduct.value,
  ])
  return [value, product.loading, product.error]
}

type ProductLicensesHook = [ProductLicenses | undefined, boolean, object | undefined]
export function useActiveLicenses(username?: string | null): ProductLicensesHook {
  const licensesQuery = !username
    ? null
    : db
        .child('productLicenses')
        .orderByChild('_indexes/username__status')
        .equalTo(`${username}__ACTIVE`)
  const licenses = useDatabaseObject(licensesQuery)
  return [licenses.value, licenses.loading, licenses.error]
}
export function useMemberActiveLicenses(): ProductLicensesHook {
  const { username, loading: loadingUsername, error: errorUsername } = useUsername()
  const [licenses, licensesLoading, licensesError] = useActiveLicenses(username)
  const loading = loadingUsername || licensesLoading
  const error = errorUsername || licensesError
  return [licenses, loading, error]
}

function useActiveCompanyLicense(productId: 'tradesoftware_customer' | 'tradesoftware', username?: string | null): ActiveProductLicenseHook {
  const [licenses, licensesLoading, licensesError] = useActiveLicenses(username)
  const companyLicense = useMemo(
    () =>
      find(
        map(licenses, (license, licenseKey): ProductLicense & { licenseKey: string } => ({ ...license, licenseKey })),
        p => p.productId === productId
      ),
    [licenses]
  )
  return [companyLicense || null, licensesLoading, licensesError]
}
export function useActiveCompanyDistributorLicense(username?: string | null): ActiveProductLicenseHook {
  return useActiveCompanyLicense('tradesoftware', username)
}
export function useActiveCompanyCustomerLicense(username?: string | null): ActiveProductLicenseHook {
  return useActiveCompanyLicense('tradesoftware_customer', username)
}

export function useMemberLicenses(): ProductLicensesHook {
  const { username, loading: loadingUsername, error: errorUsername } = useUsername()
  const licensesQuery = !username
    ? null
    : db
        .child('productLicenses')
        .orderByChild('username')
        .equalTo(username)
  const licenses = useDatabaseObject(licensesQuery)
  const loading = loadingUsername || licenses.loading
  const error = errorUsername || licenses.error
  return [licenses.value, loading, error]
}

type ActiveProductLicenseHook = [(ProductLicense & { licenseKey: string }) | null, boolean, object | undefined]
export function useActiveProductLicense(productId?: string): ActiveProductLicenseHook {
  const { username, loading: loadingUsername, error: errorUsername } = useUsername()
  const licensesQuery =
    !username || !productId
      ? null
      : db
          .child('productLicenses')
          .orderByChild('_indexes/productId__username__status')
          .equalTo(`${productId}__${username}__ACTIVE`)
  const licenses = useDatabaseObject(licensesQuery)
  const loading = loadingUsername || licenses.loading
  const error = errorUsername || licenses.error
  if (!licenses.value) return [null, loading, error]
  const licenseKey = Object.keys(licenses.value)[0]
  const license = { ...licenses.value[licenseKey], licenseKey }
  return [license, loading, error]
}

export interface UseProductPlansModalHook {
  showModal: (productId: string) => void
  closeModal: () => void
}
export const useProductPlansModal = (): UseProductPlansModalHook => {
  const { showModal, closeModal } = React.useContext(ProductPlansModalContext)
  return { showModal, closeModal }
}

export interface UseProductOverviewModalHook {
  showModal: (productId: string) => void
  closeModal: () => void
}
export const useProductOverviewModal = (): UseProductOverviewModalHook => {
  const { showModal, closeModal } = React.useContext(ProductOverviewModalContext)
  return { showModal, closeModal }
}
