import * as React from 'react'
import { Route, Switch } from 'react-router'
import { SwapSymbolSelectorModalProvider } from '../modals/swap-symbol-selector'
import { SwapHistoryScreen } from '../templates/SwapHistoryScreen'
import { SwapHomeScreen } from '../templates/SwapHomeScreen'

const NotFoundScreen: React.FC = () => {
  return (
    <div
      className="not-found-screen"
      style={{ position: 'absolute', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <h1>NOT FOUND</h1>
    </div>
  )
}

export const TemplateRoutes: React.FC = () => {
  return (
    <SwapSymbolSelectorModalProvider>
      <Switch>
        <Route path="/template/history" exact component={SwapHistoryScreen} />
        <Route path="/template/" exact component={SwapHomeScreen} />
        <Route path="/template/" component={NotFoundScreen} />
      </Switch>
    </SwapSymbolSelectorModalProvider>
  )
}
