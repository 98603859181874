import React from 'react'
import { Decimal, mul } from '@allcoinwallet/invest-bitcoin-decimal'
import { useHistory } from 'react-router'

import { tokens } from '../../assets'
import Button from '../../components/shared/button'
import { useCompanyLastOverviewStats } from '../../hooks/stats'
import { formatPercent } from '../../utils/format'
import { useMemberProductContractsRunning } from '../../hooks/product-contracts'

const ProductNewGridItem: React.FC<{ productId: string; symbol: string; label: string }> = ({ productId, symbol, label }) => {
  const history = useHistory()

  const [companyOverviewStats] = useCompanyLastOverviewStats()
  const lastProfit = companyOverviewStats
    ? (symbol === 'BANANA'
        ? companyOverviewStats?.bananaSmartFarmProfit
        : symbol === 'BSW'
        ? companyOverviewStats?.biswapSmartFarmProfit
        : companyOverviewStats?.cakeSmartFarmProfit) || Decimal(0)
    : undefined
  const last7DaysProfit = lastProfit ? mul(lastProfit, Decimal(7)) : undefined
  const last30DaysProfit = lastProfit ? mul(lastProfit, Decimal(30)) : undefined

  const [contractsRunning] = useMemberProductContractsRunning(productId)

  const goToSinglePage = React.useCallback(() => {
    history.push(`/products/${productId}/`)
  }, [history, productId])

  return (
    <>
      <div className="product">
        {symbol === 'CAKE' && <img className="logo" src={tokens.cakeLogo} alt="Logo cake" />}
        {symbol === 'BSW' && <img className="logo" src={tokens.biswapLogo} alt="Logo biswap" />}
        {symbol === 'BANANA' && <img className="logo" src={tokens.bananaLogo} alt="Logo banana" />}
        <div className="description">
          <p>{label}</p>
          <div>
            <p>No fee to join</p>
          </div>
        </div>
        <div className="profit">
          <div>
            <p>Last 7 days Profit</p>
            <p>{formatPercent(last7DaysProfit, { precision: 1 })}</p>
          </div>
          <div>
            <p>Last Month Profit</p>
            <p>{formatPercent(last30DaysProfit, { precision: 1 })}</p>
          </div>
          <div>
            <p>Fee</p>
            <p>25%</p>
          </div>
        </div>
        <div className="join-now">
          <Button size="large" type="success" onClick={goToSinglePage}>
            {contractsRunning ? 'Open' : 'Join Now'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ProductNewGridItem
