import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { icons } from '../../assets'
import BoxView from '../../components/box-view'
import ProductsContractsTable from './ProductsContractsTable'

const ProductsContractsTablePage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <BoxView label="Contracts" icon={icons.iconContract} className="box-contracts-overview">
      <ProductsContractsTable />
    </BoxView>
  )
}

export default ProductsContractsTablePage
