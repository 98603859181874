import React from 'react'
import { t } from '../../i18n'
import { logo, icons } from '../../assets'

interface Props {
  onClickBack: () => void
}
const SentPasswordResetLink: React.FunctionComponent<Props> = props => {
  return (
    <div className="password-recovery-sent">
      <div>
        <img src={logo.inkryptus.regularDark} />
      </div>
      <div>
        <p>{t('auth:request_reset_password.page_title')}</p>
        <p>
          <img src={icons.emailSend} />
          {t('auth:request_reset_password.sent_message')}
        </p>
        <a onClick={props.onClickBack} href="javascript:void(0)">
          {t('auth:request_reset_password.back_link.label')}
        </a>
      </div>
    </div>
  )
}

export default SentPasswordResetLink
