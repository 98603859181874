import React from 'react'
import AppModeSwitchModal from './AppModeSwitchModal.container'

export interface AppModeSwitchModalContextValues {
  nextMode?: string | null
  showModal: (nextMode: string, onConfirm: () => void) => void
  closeModal: () => void
}
export const AppModeSwitchModalContext = React.createContext<AppModeSwitchModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const AppModeSwitchModalProvider: React.FunctionComponent = (props) => {
  const [showingNextMode, setShowingNextMode] = React.useState<string | null>(null)
  const [onConfirmCallback, setOnConfirmCallback] = React.useState<{ cb: () => void }>({
    cb: () => {
      console.warn('initial empty onConfirm callback')
    },
  })
  const showModal = React.useCallback(
    (nextMode: string, onConfirm: () => void) => {
      setShowingNextMode(nextMode)
      setOnConfirmCallback({ cb: onConfirm })
    },
    [setShowingNextMode, setOnConfirmCallback]
  )
  const closeModal = React.useCallback(() => {
    setShowingNextMode(null)
    setOnConfirmCallback({
      cb: () => {
        console.warn('empty onConfirm callback')
      },
    })
  }, [setShowingNextMode, setOnConfirmCallback])

  return (
    <AppModeSwitchModalContext.Provider value={{ nextMode: showingNextMode, showModal, closeModal }}>
      <AppModeSwitchModal nextMode={showingNextMode} onClose={closeModal} onConfirm={onConfirmCallback.cb} />
      {props.children}
    </AppModeSwitchModalContext.Provider>
  )
}

export default AppModeSwitchModalContext
