import React from 'react'
import { add, decimal, Decimal, mul, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { compact, filter, reverse, sortBy } from 'lodash'
import { tokens } from '../../assets'
import { ProductContract } from '../../hooks/database'
import { formatDate, formatHours, formatPercent } from '../../utils/format'

import SmartTable, { SmartTableColumn, SmartTableSelectOption, useSmartTableSort } from '../../components/smart-table-new'
import { Button, DecimalFormatter } from '../../components/shared'
import { useMemberProductContractsRunning, useMemberProductContractsStopped } from '../../hooks/product-contracts'
import FinishProductContractModalContext from '../../modals/finish-product-contract'

type Row = ProductContract & { contractId: string; onFinishContractClick?: () => any }

const selectButtonsOptions: SmartTableSelectOption[] = [
  { value: '', label: 'All' },
  { value: 'CAKE', label: 'Cake' },
  { value: 'BANANA', label: 'Banana' },
  { value: 'BSW', label: 'Biswap' },
]

function nonZeroOrEmpty<T extends decimal | number | undefined>(f: T): f is NonNullable<T> {
  if (!f) return false
  if (f === Decimal('0')) return false
  return true
}

const columns: SmartTableColumn<Row>[] = [
  {
    key: 'status',
    label: 'Contracts/Status',
    render: (row) => (
      <div>
        {row.symbol === 'BANANA' ? (
          <img src={tokens.bananaLogo} alt="Banana" />
        ) : row.symbol === 'BSW' ? (
          <img src={tokens.biswapLogo} alt="Biswap" />
        ) : (
          <img src={tokens.cakeLogo} alt="Cake" />
        )}
        <div>
          <p className="text-overflow-ellipsis" style={{ maxWidth: 100 }}>
            {row.contractId}
          </p>
          {row.status === 'PROCESSING' && <p className="warning">Confirming payment</p>}
          {row.status === 'PENDING_ACTIVATION' && <p className="warning">Pending</p>}
          {row.status === 'ACTIVE' && (row.status !== 'ACTIVE' || row.lastProfit === undefined) && (
            <p className="warning">Waiting activation</p>
          )}
          {row.status === 'ACTIVE' && row.status === 'ACTIVE' && row.lastProfit !== undefined && <p className="success">Active</p>}
          {row.status === 'PENDING_DEACTIVATION' && <p className="danger">Waiting termination</p>}
          {row.status === 'INACTIVE' && <p className="danger">Finished</p>}
        </div>
      </div>
    ),
  },
  {
    key: 'paymentStartTime',
    label: 'Date',
    sortable: true,
    render: (row) => (
      <div>
        <p>{formatDate(row.paymentStartTime)}</p>
        <p>{formatHours(row.paymentStartTime)}</p>
      </div>
    ),
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    render: (row) => (
      <p>
        <DecimalFormatter value={add(row.startNetAmount, row.startFeeAmount)} decimalPlaces={8} />
      </p>
    ),
  },
  {
    key: 'duration',
    label: 'Active days',
    sortable: true,
    render: (row) => <p>{row.profitReportsCounter || 0} days</p>,
  },
  {
    key: 'profit',
    label: 'Total Profit/Fee',
    sortable: true,
    render: (row) => (
      <div>
        <p>
          {nonZeroOrEmpty(row.totalProfit) && (
            <p>
              {!!row.totalProfitNetAmount && !!row.totalProfitFeeAmount
                ? trunc(add(row.totalProfitNetAmount, row.totalProfitFeeAmount), 8)
                : '-'}{' '}
              ({formatPercent(row.totalProfit, { precision: 2 })})
            </p>
          )}
          {!nonZeroOrEmpty(row.totalProfit) && <p>0</p>}
        </p>
        {nonZeroOrEmpty(row.totalProfitFeeAmount) && <p>{trunc(row.totalProfitFeeAmount, 8)}</p>}
        {!nonZeroOrEmpty(row.totalProfitFeeAmount) && <p>0</p>}
      </div>
    ),
  },
  {
    key: 'net_amount',
    label: 'Total Net Profit',
    sortable: true,
    render: (row) => {
      const totalNetProfit = row.totalProfit ? mul(Decimal(row.totalProfit), Decimal(0.75)) : undefined
      return (
        <p>
          {nonZeroOrEmpty(totalNetProfit) && nonZeroOrEmpty(row.totalProfitNetAmount) && trunc(row.totalProfitNetAmount, 8)}
          {(!nonZeroOrEmpty(totalNetProfit) || !nonZeroOrEmpty(row.totalProfitNetAmount)) && '0'}
        </p>
      )
    },
  },
  {
    key: 'actions',
    label: 'Actions',
    render: (row) => (
      <div>
        <Button
          size="medium"
          type="danger"
          disabled={row.status !== 'ACTIVE' || row.lastProfit === undefined}
          onClick={row.onFinishContractClick}
        >
          Cancel
        </Button>
      </div>
    ),
  },
]

const statusFilterOptions = [
  { value: '', label: 'Filter...' },
  { value: 'pending', label: 'Pending' },
  { value: 'active', label: 'Active' },
  { value: 'finished', label: 'Finished' },
]

const ProductContractsTable: React.FunctionComponent = () => {
  const { showModal: showFinishProductContractModal } = React.useContext(FinishProductContractModalContext)

  const { activeSortColumnKey, activeSortDirection, toggleColumnSort } = useSmartTableSort({
    columnKey: 'paymentStartTime',
    direction: 'desc',
  })
  const [symbolFilter, setSymbolFilter] = React.useState<string>('')
  const [statusFilter, setStatusFilter] = React.useState('')

  const [cakeContracts] = useMemberProductContractsRunning('cake-smart-farm')
  const [bananaContracts] = useMemberProductContractsRunning('banana-smart-farm')
  const [biswapContracts] = useMemberProductContractsRunning('biswap-smart-farm')

  const [cakeStoppedContracts] = useMemberProductContractsStopped('cake-smart-farm')
  const [bananaStoppedContracts] = useMemberProductContractsStopped('banana-smart-farm')
  const [biswapStoppedContracts] = useMemberProductContractsStopped('biswap-smart-farm')

  const contractsList = React.useMemo(() => {
    let list: Row[] = []
    for (const collection of compact([
      cakeContracts,
      bananaContracts,
      biswapContracts,
      cakeStoppedContracts,
      bananaStoppedContracts,
      biswapStoppedContracts,
    ])) {
      for (const contractId in collection) {
        const contract = collection[contractId]
        list.push({ ...contract, contractId, onFinishContractClick: () => showFinishProductContractModal(contractId) })
      }
    }
    if (symbolFilter) {
      list = filter(list, (row) => row.symbol === symbolFilter)
    }
    if (statusFilter) {
      list = filter(list, (row) =>
        statusFilter === 'finished'
          ? row.status === 'INACTIVE'
          : statusFilter === 'active'
          ? row.status === 'ACTIVE' && row.lastProfit !== undefined
          : row.status !== 'INACTIVE' && !(row.status === 'ACTIVE' && row.lastProfit !== undefined)
      )
    }
    if (activeSortColumnKey === 'amount') {
      list = sortBy(list, (row) => Number(add(row.startNetAmount, row.startFeeAmount)))
    } else if (activeSortColumnKey === 'profit') {
      list = sortBy(list, (row) =>
        !!row.totalProfitNetAmount && !!row.totalProfitFeeAmount ? Number(trunc(add(row.totalProfitNetAmount, row.totalProfitFeeAmount), 8)) : 0
      )
    } else if (activeSortColumnKey === 'net_amount') {
      list = sortBy(list, (row) => {
        const totalNetProfit = row.totalProfit ? mul(Decimal(row.totalProfit), Decimal(0.75)) : undefined
        return nonZeroOrEmpty(totalNetProfit) && nonZeroOrEmpty(row.totalProfitNetAmount) ? Number(trunc(row.totalProfitNetAmount, 8)) : 0
      })
    } else if (activeSortColumnKey === 'duration') {
      list = sortBy(list, (row) => row.profitReportsCounter || 0)
    } else {
      list = sortBy(list, 'paymentStartTime')
    }
    return activeSortDirection === 'asc' ? list : reverse(list)
  }, [
    cakeContracts,
    bananaContracts,
    biswapContracts,
    cakeStoppedContracts,
    bananaStoppedContracts,
    biswapStoppedContracts,
    showFinishProductContractModal,
    activeSortColumnKey,
    activeSortDirection,
    symbolFilter,
    statusFilter,
  ])

  return (
    <SmartTable
      columns={columns}
      rowKey="contractId"
      rows={contractsList}
      sort={{
        activeSortDirection,
        activeSortColumnKey,
        onSort: toggleColumnSort,
      }}
      filter={{
        selectButtonsOptions,
        setSelectedButtonOption: setSymbolFilter,
        selectedButtonOption: symbolFilter,
        selectOptions: statusFilterOptions,
        selectedOption: statusFilter,
        setSelectedOption: setStatusFilter,
      }}
    />
  )
}

export default ProductContractsTable
