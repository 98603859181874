import * as React from 'react'
import { toArray, first } from 'lodash'
import { SmartFarmProfitCycleStats, SmartFarmProfitCycleGroupStats, CompanyOverviewStats } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'

export function useCompanyCakeSmartFarmLastProfitCycleStats(): [SmartFarmProfitCycleGroupStats | undefined, boolean, object | undefined] {
  const { value, loading, error } = useDatabaseObject(db.child('stats').child('companyCakeSmartFarmProfitCycles').limitToLast(1))
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useMemberCakeSmartFarmLastProfitCycleStats(
  username?: string
): [SmartFarmProfitCycleStats | undefined, boolean, object | undefined] {
  const dbQuery = username ? db.child('stats').child('memberCakeSmartFarmProfitCycles').child(username).limitToLast(1) : undefined
  const { value, loading, error } = useDatabaseObject(dbQuery)
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useMemberNetworkCakeSmartFarmLastProfitCycleStats(
  username?: string
): [SmartFarmProfitCycleGroupStats | undefined, boolean, object | undefined] {
  const dbQuery = username ? db.child('stats').child('memberNetworkCakeSmartFarmProfitCycles').child(username).limitToLast(1) : undefined
  const { value, loading, error } = useDatabaseObject(dbQuery)
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useCompanyBananaSmartFarmLastProfitCycleStats(): [SmartFarmProfitCycleGroupStats | undefined, boolean, object | undefined] {
  const { value, loading, error } = useDatabaseObject(db.child('stats').child('companyBananaSmartFarmProfitCycles').limitToLast(1))
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useMemberBananaSmartFarmLastProfitCycleStats(
  username?: string
): [SmartFarmProfitCycleStats | undefined, boolean, object | undefined] {
  const dbQuery = username ? db.child('stats').child('memberBananaSmartFarmProfitCycles').child(username).limitToLast(1) : undefined
  const { value, loading, error } = useDatabaseObject(dbQuery)
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useMemberNetworkBananaSmartFarmLastProfitCycleStats(
  username?: string
): [SmartFarmProfitCycleGroupStats | undefined, boolean, object | undefined] {
  const dbQuery = username ? db.child('stats').child('memberNetworkBananaSmartFarmProfitCycles').child(username).limitToLast(1) : undefined
  const { value, loading, error } = useDatabaseObject(dbQuery)
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useCompanyBiswapSmartFarmLastProfitCycleStats(): [SmartFarmProfitCycleGroupStats | undefined, boolean, object | undefined] {
  const { value, loading, error } = useDatabaseObject(db.child('stats').child('companyBiswapSmartFarmProfitCycles').limitToLast(1))
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useMemberBiswapSmartFarmLastProfitCycleStats(
  username?: string
): [SmartFarmProfitCycleStats | undefined, boolean, object | undefined] {
  const dbQuery = username ? db.child('stats').child('memberBiswapSmartFarmProfitCycles').child(username).limitToLast(1) : undefined
  const { value, loading, error } = useDatabaseObject(dbQuery)
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useMemberNetworkBiswapSmartFarmLastProfitCycleStats(
  username?: string
): [SmartFarmProfitCycleGroupStats | undefined, boolean, object | undefined] {
  const dbQuery = username ? db.child('stats').child('memberNetworkBiswapSmartFarmProfitCycles').child(username).limitToLast(1) : undefined
  const { value, loading, error } = useDatabaseObject(dbQuery)
  const lastCycleStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastCycleStats, loading, error]
}

export function useCompanyLastOverviewStats(): [CompanyOverviewStats | undefined, boolean, object | undefined] {
  const { value, loading, error } = useDatabaseObject(db.child('stats').child('companyOverview').limitToLast(1))
  const lastStats = React.useMemo(() => (value ? first(toArray(value)) : undefined), [value])
  return [lastStats || undefined, loading, error]
}
