import React from 'react'
import Modal from 'react-modal'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { requestWalletWithdrawal, requestWalletWithdrawalResendConfirmation } from '../../services/api'

import LoadingSpinner from '../../components/loading-spinner'
import WalletWithdrawalRequest from './WalletWithdrawalRequest.component'
import WalletWithdrawalConfirmation from './WalletWithdrawalConfirmation.component'
import { useWalletTransaction } from '../../hooks/wallets'

interface Props {
  transactionId?: string | null
  changeTransaction: (transactionId: string) => any
  onClose: () => void
}

const WalletWithdrawalRequestModal: React.FunctionComponent<Props> = (props) => {
  const transactionId = props.transactionId || undefined
  const isNew = React.useMemo(() => (transactionId ? transactionId.startsWith('_new') : false), [transactionId])
  const [transaction, transactionLoading, transactionError] = useWalletTransaction(transactionId !== '_new' ? transactionId : undefined)
  const transactionSymbol = transaction?.symbol
  const symbol = React.useMemo(() => {
    if (!transactionId) return
    if (transactionId.startsWith('_new')) {
      const paramSymbol = transactionId.replace('_new__', '')
      return paramSymbol || undefined
    }
    return transactionSymbol
  }, [transactionId, transactionSymbol])

  const close = props.onClose

  React.useEffect(() => {
    if (!transactionId) return
    if (transactionId.startsWith('_new')) return
    if (transaction || transactionLoading) return
    if (transactionError) close()
  }, [transactionId, transaction, transactionLoading, transactionError, close])

  const changeTransactionId = props.changeTransaction
  const onCreateClick = React.useCallback(
    async (address: string, grossAmount: decimal) => {
      if (!address || !grossAmount || !symbol) return
      const { transactionId: newTransactionId } = await requestWalletWithdrawal(symbol, address, grossAmount)
      changeTransactionId(newTransactionId)
    },
    [transactionId, changeTransactionId, symbol]
  )

  const onResendClick = React.useCallback(async () => {
    if (!transactionId) return
    if (!symbol) return
    await requestWalletWithdrawalResendConfirmation(symbol, transactionId)
  }, [transactionId, symbol])

  if (!transactionId) return null
  if ((transactionLoading || !transaction) && !isNew) return <LoadingSpinner />

  return (
    <Modal
      isOpen={!!transactionId}
      onRequestClose={close}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-payment-initial"
    >
      {isNew && symbol && <WalletWithdrawalRequest symbol={symbol} onCreateClick={onCreateClick} onCloseClick={close} />}
      {!isNew && transaction && (
        <WalletWithdrawalConfirmation
          transactionId={transactionId}
          transaction={transaction}
          onResendClick={onResendClick}
          onCloseClick={close}
        />
      )}
    </Modal>
  )
}

export default WalletWithdrawalRequestModal
