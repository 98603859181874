import React from 'react'
import { ProductContract } from '@allcoinwallet/inkryptus-db-schema'
import { trunc, add, mul, Decimal, decimal } from '@allcoinwallet/invest-bitcoin-decimal'

import Button from '../../components/shared/button'
import { FinishProductContractModalContext } from '../../modals/finish-product-contract'
import { tokens } from '../../assets'
import { formatDate, formatHours, formatPercent } from '../../utils/format'

export interface ProductContractListItemProps {
  contractId: string
  contract: ProductContract
}

const MAX_DECIMALS = 6

export const ProductContractListItem: React.FC<ProductContractListItemProps> = ({ contractId, contract }) => {
  const profitable = contract.status === 'ACTIVE' && contract.lastProfit !== undefined
  const profitReportsCounter = contract.profitReportsCounter

  const lastProfitAmount =
    !!contract.lastProfitNetAmount && !!contract.lastProfitFeeAmount
      ? trunc(add(contract.lastProfitNetAmount, contract.lastProfitFeeAmount), MAX_DECIMALS)
      : '-'
  const totalProfitAmount =
    !!contract.totalProfitNetAmount && !!contract.totalProfitFeeAmount
      ? trunc(add(contract.totalProfitNetAmount, contract.totalProfitFeeAmount), MAX_DECIMALS)
      : '-'

  const lastNetProfit = contract.lastProfit ? mul(Decimal(contract.lastProfit), Decimal(0.75)) : undefined
  const totalNetProfit = contract.totalProfit ? mul(Decimal(contract.totalProfit), Decimal(0.75)) : undefined
  // const lastFeeProfit = contract.lastProfit ? mul(Decimal(contract.lastProfit), Decimal(0.25)) : undefined
  // const totalFeeProfit = contract.totalProfit ? mul(Decimal(contract.totalProfit), Decimal(0.25)) : undefined

  const { showModal: showFinishProductContractModal } = React.useContext(FinishProductContractModalContext)
  const openFinishProductContractModal = React.useCallback(() => {
    showFinishProductContractModal(contractId)
  }, [showFinishProductContractModal, contractId])

  function nonZeroOrEmpty<T extends decimal | number | undefined>(f: T): f is NonNullable<T> {
    if (!f) return false
    if (f === Decimal('0')) return false
    return true
  }

  return (
    <div className="contract">
      <div className="logo">
        {contract.symbol === 'BSW' && <img src={tokens.biswapLogo} alt="Biswap" />}
        {contract.symbol === 'BANANA' && <img src={tokens.bananaLogo} alt="Banana" />}
        {contract.symbol === 'CAKE' && <img src={tokens.cakeLogo} alt="Cake" />}
      </div>
      <div className="info">
        <div>
          <p>ID</p>
          <p>{contractId}</p>
        </div>
        <div>
          <p>Status</p>
          {contract.status === 'PROCESSING' && <p className="warning">Confirming payment</p>}
          {contract.status === 'PENDING_ACTIVATION' && <p className="warning">Pending</p>}
          {contract.status === 'ACTIVE' && !profitable && <p className="warning">Waiting activation</p>}
          {contract.status === 'ACTIVE' && profitable && <p className="success">Active</p>}
          {contract.status === 'PENDING_DEACTIVATION' && <p className="danger">Waiting termination</p>}
          {contract.status === 'INACTIVE' && <p className="danger">Finished</p>}
        </div>
        <div>
          <p>Date</p>
          <p>
            {formatDate(contract.paymentStartTime)} | {formatHours(contract.paymentStartTime)}
          </p>
        </div>
      </div>
      <div className="amount">
        <p>Staking</p>
        <p>{trunc(contract.netAmount, MAX_DECIMALS)}</p>
      </div>
      <div className="day-period">
        <div>
          <p>Last Cycle</p>
          <p>{contract.lastProfitTime ? formatDate(contract.lastProfitTime) : '-'}</p>
        </div>
        <div>
          <p>Total Cycle</p>
          <p>{profitReportsCounter || 0}</p>
        </div>
      </div>
      <div className="profit">
        <div>
          <p>Gross Cycle Profit</p>
          {nonZeroOrEmpty(contract.lastProfit) && (
            <p>
              {lastProfitAmount} ({formatPercent(contract.lastProfit, { precision: 2 })})
            </p>
          )}
          {!nonZeroOrEmpty(contract.lastProfit) && <p>0</p>}
        </div>
        <div>
          <p>Gross Total Profit</p>
          {nonZeroOrEmpty(contract.totalProfit) && (
            <p>
              {totalProfitAmount} ({formatPercent(contract.totalProfit, { precision: 2 })})
            </p>
          )}
          {!nonZeroOrEmpty(contract.totalProfit) && <p>0</p>}
        </div>
      </div>
      <div className="fee">
        <div>
          <p>Cycle Fee (25%)</p>
          {nonZeroOrEmpty(contract.lastProfitFeeAmount) && (
            <p>
              {trunc(contract.lastProfitFeeAmount, MAX_DECIMALS)}
              {/*  ({formatPercent(lastFeeProfit, { precision: 2 })}) */}
            </p>
          )}
          {!nonZeroOrEmpty(contract.lastProfitFeeAmount) && <p>0</p>}
        </div>
        <div>
          <p>Total Fee (25%)</p>
          {nonZeroOrEmpty(contract.totalProfitFeeAmount) && (
            <p>
              {trunc(contract.totalProfitFeeAmount, MAX_DECIMALS)}
              {/* ({formatPercent(totalFeeProfit, { precision: 2 })}) */}
            </p>
          )}
          {!nonZeroOrEmpty(contract.totalProfitFeeAmount) && <p>0</p>}
        </div>
      </div>
      <div className="profit-liquid">
        <div>
          <p>Net Cycle Profit (75%)</p>
          {nonZeroOrEmpty(lastNetProfit) && nonZeroOrEmpty(contract.lastProfitNetAmount) && (
            <p>
              {trunc(contract.lastProfitNetAmount, MAX_DECIMALS)}
              {/* ({formatPercent(lastNetProfit, { precision: 2 })}) */}
            </p>
          )}
          {(!nonZeroOrEmpty(lastNetProfit) || !nonZeroOrEmpty(contract.lastProfitNetAmount)) && <p>0</p>}
        </div>
        <div>
          <p>Net Total Profit (75%)</p>
          {nonZeroOrEmpty(totalNetProfit) && nonZeroOrEmpty(contract.totalProfitNetAmount) && (
            <p>
              {trunc(contract.totalProfitNetAmount, MAX_DECIMALS)}
              {/* ({formatPercent(totalNetProfit, { precision: 2 })}) */}
            </p>
          )}
          {(!nonZeroOrEmpty(totalNetProfit) || !nonZeroOrEmpty(contract.totalProfitNetAmount)) && <p>0</p>}
        </div>
      </div>
      <div className="button-action">
        <Button size="medium" type="danger" disabled={!profitable} onClick={openFinishProductContractModal}>
          Cancel Contract
        </Button>
      </div>
    </div>
  )
}
