import React from 'react'
import { RouteComponentProps } from 'react-router'
// import Tabs, { TabContent } from '../../components/tabs'
import { icons } from '../../assets'
// import { t } from '../../i18n'
import ProfileInfoTab from './info/ProfileInfoTab.component'
// import PasswordTab from './password/ProfilePasswordTab.component'
// import ProfileWalletTab from './wallet/WalletTab.component'
// // import { getQueryParams } from '../../utils/url'
// import BoxTabs from '../../components/box-tabs'
import BoxView from '../../components/box-view'

const Profile: React.SFC<RouteComponentProps> = (props) => {
  // const { iTab } = getQueryParams(props.location.search)
  return (
    <BoxView label="Profile" icon={icons.iconProfile} className="box-profile">
      <ProfileInfoTab />
    </BoxView>

  )
}

export default Profile
