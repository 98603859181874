import * as React from 'react'
import { WalletTransaction } from '@allcoinwallet/inkryptus-db-schema'
import { getBscTransactionExplorerUrl } from '../../utils/testnet'
import WalletWithdrawalRequestModalContext from '../../modals/wallet-withdrawal-request'

export const WalletTransactionLink: React.FC<{ transactionId: string; transaction: WalletTransaction }> = React.memo(
  ({ transactionId, transaction }) => {
    const { showModal: showWalletWithdrawalRequestModal } = React.useContext(WalletWithdrawalRequestModalContext)
    const showWithdrawalModal = React.useCallback(() => showWalletWithdrawalRequestModal(transactionId), [
      showWalletWithdrawalRequestModal,
      transactionId,
    ])

    if (transaction.type === 'deposit' || transaction.confirmationTransactionHash) {
      const bscUrl = getBscTransactionExplorerUrl(transaction.confirmationTransactionHash || transactionId)
      return (
        <a className="text-overflow-ellipsis" href={bscUrl} target="_blank" style={{ maxWidth: 100 }}>
          {transaction.confirmationTransactionHash || transactionId}
        </a>
      )
    }
    if (transaction.type === 'withdrawal')
      return (
        <a className="text-overflow-ellipsis" onClick={showWithdrawalModal} style={{ maxWidth: 100 }}>
          {transactionId}
        </a>
      )
    return (
      <span className="text-overflow-ellipsis" style={{ maxWidth: 100 }}>
        {transactionId}
      </span>
    )
  }
)
