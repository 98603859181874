import * as React from 'react'
import { add, cmp, decimal, Decimal, mul, round, sub, trunc } from '@allcoinwallet/invest-bitcoin-decimal'

import { icons } from '../../assets'
import { processSwapOfSwapWallet, calculateSwapFromInputOfSwapWallet, calculateSwapFromOutputOfSwapWallet } from '../../services/api'
import Button from '../../components/shared/button'
import SwapSymbolSelectorModalContext from '../../modals/swap-symbol-selector'
import SwapWalletWithdrawalModalContext from '../../modals/swap-wallet-withdrawal-request'
import SwapDepositWalletModalContext from '../../modals/swap-deposit-wallet'
import SwapProcessModalContext from '../../modals/swap-process'
import { useMemberSwapWallet } from '../../hooks/swap-wallets'
import { useUid } from '../../hooks/current-user'
import { useSwapRates } from '../../hooks/rates'
import { formatDecimal, parseDecimal } from '../../utils/format'
import { getSwapTokenMetadata } from '../../utils/swap'

interface SwapFormInputState {
  symbol: string
  inputText: string
}
interface SwapFormState {
  lastChangedInput: 'from' | 'to'
  lastChangeTime: number
  swapPrice?: decimal
  from: SwapFormInputState
  to: SwapFormInputState
}
const initialFormState: SwapFormState = {
  lastChangedInput: 'from',
  lastChangeTime: 0,
  from: { symbol: 'USDT', inputText: '' },
  to: { symbol: 'CAKE', inputText: '' },
}

interface SwapFormStatus {
  fromAmount: decimal | ''
  toAmount: decimal | ''
  feeAmount: decimal | ''
  errorText: string
}

export const SwapHomeScreen: React.FC = () => {
  const [uid] = useUid()
  const [cakeSwapWallet] = useMemberSwapWallet(uid, 'CAKE')
  const cakeAvailableBalance = cakeSwapWallet?.balance.available
  const cakeTokenMeta = getSwapTokenMetadata('CAKE')
  const [usdtSwapWallet] = useMemberSwapWallet(uid, 'USDT')
  const usdtAvailableBalance = usdtSwapWallet?.balance.available
  const usdtTokenMeta = getSwapTokenMetadata('USDT')
  const [busdSwapWallet] = useMemberSwapWallet(uid, 'BUSD')
  const busdAvailableBalance = busdSwapWallet?.balance.available
  const busdTokenMeta = getSwapTokenMetadata('BUSD')
  const [bananaSwapWallet] = useMemberSwapWallet(uid, 'BANANA')
  const bananaAvailableBalance = bananaSwapWallet?.balance.available
  const bananaTokenMeta = getSwapTokenMetadata('BANANA')
  const [shibSwapWallet] = useMemberSwapWallet(uid, 'SHIB')
  const shibAvailableBalance = shibSwapWallet?.balance.available
  const shibTokenMeta = getSwapTokenMetadata('SHIB')
  const [bswSwapWallet] = useMemberSwapWallet(uid, 'BSW')
  const bswAvailableBalance = bswSwapWallet?.balance.available
  const bswTokenMeta = getSwapTokenMetadata('BSW')

  const [swapRates, swapRatesLoading] = useSwapRates()

  const [formState, setFormState] = React.useState<SwapFormState>(initialFormState)

  const fromSymbol = formState.from.symbol
  const fromInputText = formState.from.inputText
  const fromSwapRates = swapRates && swapRates[fromSymbol]
  const fromMetadata = getSwapTokenMetadata(fromSymbol)
  const toSymbol = formState.to.symbol
  const toInputText = formState.to.inputText
  const toMetadata = getSwapTokenMetadata(toSymbol)
  const preSwapPrice = fromSwapRates && fromSwapRates.rates[toSymbol]
  const swapPrice = fromInputText || toInputText ? formState.swapPrice : fromSwapRates && fromSwapRates.rates[toSymbol]
  const lastChangeTime = formState.lastChangeTime || undefined
  const activeInput = formState.lastChangedInput

  const usdtRates = swapRates?.USDT?.rates || {}
  const fromSymbolUsdtRate = usdtRates ? usdtRates[fromSymbol] : Decimal(0)
  const toSymbolUsdtRate = usdtRates ? usdtRates[toSymbol] : Decimal(0)

  React.useEffect(() => {
    if (!lastChangeTime) return
    if (!fromInputText && !toInputText) return

    fetchSwapPrice().catch((error) => {
      console.error('Failed to fetch swap price', error)
    })
    async function fetchSwapPrice() {
      const currentUpdateTime = lastChangeTime

      if (activeInput === 'from') {
        const fromAmount = parseDecimal(fromInputText)
        if (!fromAmount) return
        const { estimatedToAmount } = await calculateSwapFromInputOfSwapWallet(fromSymbol, toSymbol, fromAmount)
        const estimatedPrice = trunc(Decimal(Number(estimatedToAmount) / Number(fromAmount)), 18)
        setFormState((state) => {
          if (state.lastChangeTime !== currentUpdateTime) return state
          return {
            ...state,
            swapPrice: estimatedPrice,
          }
        })
      }

      if (activeInput === 'to') {
        const toAmount = parseDecimal(toInputText)
        if (!toAmount) return
        const { estimatedFromAmount } = await calculateSwapFromOutputOfSwapWallet(fromSymbol, toSymbol, toAmount)
        const estimatedPrice = trunc(Decimal(Number(toAmount) / Number(estimatedFromAmount)), 18)
        setFormState((state) => {
          if (state.lastChangeTime !== currentUpdateTime) return state
          return {
            ...state,
            swapPrice: estimatedPrice,
          }
        })
      }
    }
  }, [activeInput, lastChangeTime, fromSymbol, toSymbol, fromInputText, toInputText, setFormState])

  const fromAvailableBalance =
    fromSymbol === 'CAKE'
      ? cakeAvailableBalance
      : fromSymbol === 'USDT'
      ? usdtAvailableBalance
      : fromSymbol === 'BUSD'
      ? busdAvailableBalance
      : fromSymbol === 'BANANA'
      ? bananaAvailableBalance
      : fromSymbol === 'SHIB'
      ? shibAvailableBalance
      : fromSymbol === 'BSW'
      ? bswAvailableBalance
      : undefined
  const toAvailableBalance =
    toSymbol === 'CAKE'
      ? cakeAvailableBalance
      : toSymbol === 'USDT'
      ? usdtAvailableBalance
      : toSymbol === 'BUSD'
      ? busdAvailableBalance
      : toSymbol === 'BANANA'
      ? bananaAvailableBalance
      : toSymbol === 'SHIB'
      ? shibAvailableBalance
      : toSymbol === 'BSW'
      ? bswAvailableBalance
      : undefined
  const availableBalance = fromAvailableBalance

  const status = React.useMemo((): SwapFormStatus => {
    let fromAmount: decimal | '' = ''
    let toAmount: decimal | '' = ''
    let feeAmount: decimal | '' = ''
    let errorText = ''

    if (!swapPrice) {
      // errorText = 'Swap price cannot be obtained'
      return { fromAmount, toAmount, errorText, feeAmount }
    } else {
      if (activeInput === 'from') {
        fromAmount = parseDecimal(fromInputText) || ''
        if (!fromAmount) {
          errorText = 'Invalid "from" amount'
        } else {
          const estimatedToAmount = trunc(mul(fromAmount, swapPrice), 8)
          const estimatedSafeMargin = round(mul(estimatedToAmount, Decimal(0.01)), 8)

          feeAmount = estimatedSafeMargin

          const toFixedFeeAmount = toSymbol === 'USDT' ? Decimal(3) : mul(toSymbolUsdtRate, Decimal(3))
          if (estimatedSafeMargin && toFixedFeeAmount) feeAmount = add(estimatedSafeMargin, toFixedFeeAmount)

          toAmount = sub(estimatedToAmount, feeAmount)
          if (cmp(toAmount, Decimal(0)) < 0) toAmount = Decimal(0)
        }
      }
      if (activeInput === 'to') {
        toAmount = parseDecimal(toInputText) || ''
        if (!toAmount) {
          errorText = 'Invalid "to" amount'
        } else {
          const estimatedFromAmount = trunc(Decimal(Number(toAmount) / Number(swapPrice)), 8)
          const estimatedSafeMargin = round(mul(estimatedFromAmount, Decimal(0.01)), 8)

          const fromFixedFeeAmount = fromSymbol === 'USDT' ? Decimal(3) : mul(fromSymbolUsdtRate, Decimal(3))
          const fromFeeAmount = add(fromFixedFeeAmount, estimatedSafeMargin)

          fromAmount = add(estimatedFromAmount, fromFeeAmount)

          feeAmount = mul(fromFeeAmount, swapPrice)
        }
      }
    }

    if (!errorText) {
      if (!fromAmount) errorText = 'Required "from" amount'
      else if (!toAmount) errorText = 'Required "to" amount'
      else if (cmp(fromAmount, Decimal('0.00000001')) < 0) errorText = 'Minimum 0.00000001 "from" amount required'
      else if (cmp(toAmount, Decimal('0.00000001')) < 0) errorText = 'Minimum 0.00000001 "to" amount required'
      else if (!availableBalance || cmp(fromAmount, availableBalance) > 0) errorText = 'Balance not available'
    }

    return { fromAmount, toAmount, errorText, feeAmount }
  }, [fromInputText, toInputText, swapPrice, activeInput, availableBalance, fromSymbolUsdtRate, toSymbolUsdtRate])
  const { fromAmount, toAmount, errorText, feeAmount } = status
  // if (errorText && (fromInputText || toInputText)) console.warn('errorText', errorText)

  const fillFromWithAvailableBalance = React.useCallback(() => {
    setFormState((v) => ({
      ...v,
      lastChangedInput: 'from',
      lastChangeTime: Date.now(),
      swapPrice: undefined,
      from: {
        symbol: v.from.symbol,
        inputText: fromAvailableBalance ? formatDecimal(fromAvailableBalance, 8) : v.from.inputText,
      },
    }))
  }, [setFormState, fromAvailableBalance])

  const changeFromInputText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist()
      setFormState((v) => ({
        ...v,
        lastChangedInput: 'from',
        lastChangeTime: Date.now(),
        swapPrice: undefined,
        from: {
          symbol: v.from.symbol,
          inputText: event?.target?.value || '',
        },
      }))
    },
    [setFormState]
  )

  const changeToInputText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist()
      setFormState((v) => ({
        ...v,
        lastChangedInput: 'to',
        lastChangeTime: Date.now(),
        swapPrice: undefined,
        to: {
          symbol: v.to.symbol,
          inputText: event?.target?.value || '',
        },
      }))
    },
    [setFormState]
  )

  const alternateSymbols = React.useCallback(() => {
    setFormState((s) => ({
      lastChangedInput: s.lastChangedInput === 'from' ? 'to' : 'from',
      lastChangeTime: Date.now(),
      from: { ...s.to },
      to: { ...s.from },
    }))
  }, [setFormState])

  const { showModal: showSwapDepositWalletModal } = React.useContext(SwapDepositWalletModalContext)
  const showUSDTSwapDepositWallet = React.useCallback(() => {
    showSwapDepositWalletModal('USDT')
  }, [showSwapDepositWalletModal])
  const showCAKESwapDepositWallet = React.useCallback(() => {
    showSwapDepositWalletModal('CAKE')
  }, [showSwapDepositWalletModal])
  const showBUSDSwapDepositWallet = React.useCallback(() => {
    showSwapDepositWalletModal('BUSD')
  }, [showSwapDepositWalletModal])
  const showSHIBSwapDepositWallet = React.useCallback(() => {
    showSwapDepositWalletModal('SHIB')
  }, [showSwapDepositWalletModal])
  const showBSWSwapDepositWallet = React.useCallback(() => {
    showSwapDepositWalletModal('BSW')
  }, [showSwapDepositWalletModal])
  const showBANANASwapDepositWallet = React.useCallback(() => {
    showSwapDepositWalletModal('BANANA')
  }, [showSwapDepositWalletModal])

  const { showModal: showSwapSymbolSelector } = React.useContext(SwapSymbolSelectorModalContext)
  const openFromSwapSymbolSelector = React.useCallback(() => {
    showSwapSymbolSelector(fromSymbol, (symbol) => {
      setFormState((s) => ({
        lastChangedInput: 'from',
        lastChangeTime: Date.now(),
        from: {
          symbol,
          inputText: s.from.inputText,
        },
        to: {
          symbol: s.to.symbol !== symbol ? s.to.symbol : symbol === 'CAKE' ? 'USDT' : 'CAKE',
          inputText: s.to.inputText,
        },
      }))
    })
  }, [showSwapSymbolSelector, fromSymbol, setFormState])
  const openToSwapSymbolSelector = React.useCallback(() => {
    showSwapSymbolSelector(toSymbol, (symbol) => {
      setFormState((s) => ({
        lastChangedInput: 'to',
        lastChangeTime: Date.now(),
        to: {
          symbol,
          inputText: s.to.inputText,
        },
        from: {
          symbol: s.from.symbol !== symbol ? s.from.symbol : symbol === 'CAKE' ? 'USDT' : 'CAKE',
          inputText: s.from.inputText,
        },
      }))
    })
  }, [showSwapSymbolSelector, toSymbol, setFormState])

  const { showModal: showSwapWalletWithdrawal } = React.useContext(SwapWalletWithdrawalModalContext)
  const newCAKESwapWalletWithdrawal = React.useCallback(() => {
    showSwapWalletWithdrawal('CAKE')
  }, [showSwapWalletWithdrawal])
  const newUSDTSwapWalletWithdrawal = React.useCallback(() => {
    showSwapWalletWithdrawal('USDT')
  }, [showSwapWalletWithdrawal])
  const newBUSDSwapWalletWithdrawal = React.useCallback(() => {
    showSwapWalletWithdrawal('BUSD')
  }, [showSwapWalletWithdrawal])
  const newBANANASwapWalletWithdrawal = React.useCallback(() => {
    showSwapWalletWithdrawal('BANANA')
  }, [showSwapWalletWithdrawal])
  const newSHIBSwapWalletWithdrawal = React.useCallback(() => {
    showSwapWalletWithdrawal('SHIB')
  }, [showSwapWalletWithdrawal])
  const newBSWSwapWalletWithdrawal = React.useCallback(() => {
    showSwapWalletWithdrawal('BSW')
  }, [showSwapWalletWithdrawal])

  const { showNewProcessModal: showSwapNewProcessModal } = React.useContext(SwapProcessModalContext)
  const openSwapProcessModal = React.useCallback(() => {
    if (!fromSymbol || !toSymbol) return
    if (!fromAmount || !toAmount) return
    if (!swapPrice) return
    if (!feeAmount) return
    showSwapNewProcessModal({ fromSymbol, toSymbol, activeField: activeInput, fromAmount, toAmount, swapPrice, feeAmount })
  }, [showSwapNewProcessModal, fromSymbol, toSymbol, fromAmount, toAmount, activeInput, swapPrice, feeAmount])

  const [processingSwap, setProcessingSwap] = React.useState(false)
  const processSwap = React.useCallback(async () => {
    if (!fromSymbol || !toSymbol) return
    if (!fromAmount || !toAmount) return
    if (processingSwap) return
    if ((() => true)()) {
      openSwapProcessModal()
      return
    }
    setProcessingSwap(true)
    try {
      const { transactionId } = await processSwapOfSwapWallet(fromSymbol, toSymbol, { activeField: activeInput, fromAmount, toAmount })
      console.log('transaction of swap', transactionId)
      setFormState(initialFormState)
    } catch (error) {
      console.error('failed to process swap', error)
    }
    setProcessingSwap(false)
  }, [fromSymbol, toSymbol, fromAmount, toAmount, activeInput, processingSwap, setProcessingSwap, setFormState, openSwapProcessModal])

  const fromInputValue = activeInput !== 'from' ? (fromAmount ? formatDecimal(fromAmount, 8) : '') : fromInputText
  const toInputValue = activeInput !== 'to' ? (toAmount ? formatDecimal(toAmount, 8) : '') : toInputText

  const loading = swapRatesLoading

  return (
    <div className={`box-swap ${loading ? 'loading' : ''}`}>
      <div className="wallets">
        <div className="assets-container">
          <p>Wallet</p>
          <div className="assets">
            <div>
              <img src={usdtTokenMeta.icon} alt="USDT" />
              <p>{usdtTokenMeta.name}</p>
              <p>
                Balance: <br />
                {usdtAvailableBalance ? formatDecimal(usdtAvailableBalance, 8) : '-'}
              </p>
              <Button size="small" type="success" onClick={showUSDTSwapDepositWallet}>
                Deposit
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={newUSDTSwapWalletWithdrawal}
                disabled={!usdtAvailableBalance || cmp(trunc(usdtAvailableBalance, 8), Decimal(0)) <= 0}
              >
                Withdraw
              </Button>
            </div>
            <div>
              <img src={cakeTokenMeta.icon} alt="CAKE" />
              <p>{cakeTokenMeta.name}</p>
              <p>
                Balance: <br /> {cakeAvailableBalance ? formatDecimal(cakeAvailableBalance, 8) : '-'}
              </p>
              <Button size="small" type="success" onClick={showCAKESwapDepositWallet}>
                Deposit
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={newCAKESwapWalletWithdrawal}
                disabled={!cakeAvailableBalance || cmp(trunc(cakeAvailableBalance, 8), Decimal(0)) <= 0}
              >
                Withdraw
              </Button>
            </div>
            <div>
              <img src={busdTokenMeta.icon} alt="BUSD" />
              <p>{busdTokenMeta.name}</p>
              <p>
                Balance: <br /> {busdAvailableBalance ? formatDecimal(busdAvailableBalance, 8) : '-'}
              </p>
              <Button size="small" type="success" onClick={showBUSDSwapDepositWallet}>
                Deposit
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={newBUSDSwapWalletWithdrawal}
                disabled={!busdAvailableBalance || cmp(trunc(busdAvailableBalance, 8), Decimal(0)) <= 0}
              >
                Withdraw
              </Button>
            </div>
            <div>
              <img src={shibTokenMeta.icon} alt="SHIB" />
              <p>{shibTokenMeta.name}</p>
              <p>
                Balance: <br /> {shibAvailableBalance ? formatDecimal(shibAvailableBalance, 8) : '-'}
              </p>
              <Button size="small" type="success" onClick={showSHIBSwapDepositWallet}>
                Deposit
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={newSHIBSwapWalletWithdrawal}
                disabled={!shibAvailableBalance || cmp(trunc(shibAvailableBalance, 8), Decimal(0)) <= 0}
              >
                Withdraw
              </Button>
            </div>
            <div>
              <img src={bswTokenMeta.icon} alt="BSW" />
              <p>{bswTokenMeta.name}</p>
              <p>
                Balance: <br /> {bswAvailableBalance ? formatDecimal(bswAvailableBalance, 8) : '-'}
              </p>
              <Button size="small" type="success" onClick={showBSWSwapDepositWallet}>
                Deposit
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={newBSWSwapWalletWithdrawal}
                disabled={!bswAvailableBalance || cmp(trunc(bswAvailableBalance, 8), Decimal(0)) <= 0}
              >
                Withdraw
              </Button>
            </div>
            <div>
              <img src={bananaTokenMeta.icon} alt="BANANA" />
              <p>{bananaTokenMeta.name}</p>
              <p>
                Balance: <br /> {bananaAvailableBalance ? formatDecimal(bananaAvailableBalance, 8) : '-'}
              </p>
              <Button size="small" type="success" onClick={showBANANASwapDepositWallet}>
                Deposit
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={newBANANASwapWalletWithdrawal}
                disabled={!bananaAvailableBalance || cmp(trunc(bananaAvailableBalance, 8), Decimal(0)) <= 0}
              >
                Withdraw
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="swap">
        <p className="title">Swap</p>
        <div className="swap-container">
          <div className="asset-swap">
            <div>
              <p>{activeInput === 'from' ? 'From' : 'From (estimated)'}</p>
              <p style={{ cursor: 'pointer' }} onClick={fillFromWithAvailableBalance}>
                Balance: {fromAvailableBalance ? formatDecimal(fromAvailableBalance, 8) : '-'}
              </p>
            </div>
            <div>
              <div
                onClick={fromSymbol === 'USDT' ? undefined : openFromSwapSymbolSelector}
                style={{ cursor: fromSymbol === 'USDT' ? 'default' : 'pointer' }}
              >
                <img src={fromMetadata.icon} alt={fromSymbol} />
                <p>{fromMetadata.name}</p>
                {fromSymbol !== 'USDT' && <img src={icons.arrowDown} alt="Arrow Down" />}
              </div>
              <input type="text" placeholder="0" value={fromInputValue} onChange={changeFromInputText} />
              <p onClick={fillFromWithAvailableBalance}>MAX</p>
            </div>
          </div>
          <div className="change-swap">
            <div onClick={alternateSymbols}>
              <img src={icons.changeSwap} alt="Change Swap" />
            </div>
          </div>
          <div className="asset-swap">
            <div>
              <p>{activeInput === 'to' ? 'To' : 'To (estimated)'}</p>
              <p>Balance: {toAvailableBalance ? formatDecimal(toAvailableBalance, 8) : '-'}</p>
            </div>
            <div>
              <div
                onClick={toSymbol === 'USDT' ? undefined : openToSwapSymbolSelector}
                style={{ cursor: toSymbol === 'USDT' ? 'default' : 'pointer' }}
              >
                <img src={toMetadata.icon} alt={toSymbol} />
                <p>{toMetadata.name}</p>
                {toSymbol !== 'USDT' && <img src={icons.arrowDown} alt="Arrow Down" />}
              </div>
              <input type="text" placeholder="0" value={toInputValue} onChange={changeToInputText} />
              <p onClick={fillFromWithAvailableBalance}>MAX</p>
            </div>
          </div>
          <div className="estimate-price">
            <p>Price:</p>
            {!swapRatesLoading && !preSwapPrice && <p className="is-warning">This market is currently unavailable</p>}
            {(swapRatesLoading || preSwapPrice || swapPrice) && (
              <p>{swapPrice ? `${formatDecimal(swapPrice, 8)} ${toSymbol} per ${fromSymbol}` : preSwapPrice ? '...' : '-'}</p>
            )}
          </div>
          <div className="estimate-fee">
            <p>Fee:</p>
            <p>{feeAmount && (swapRatesLoading || preSwapPrice || swapPrice) ? `~${formatDecimal(feeAmount, 8)} ${toSymbol}` : '-'}</p>
          </div>
          <Button size="large" type="primary" onClick={processSwap} disabled={!!errorText} loading={processingSwap}>
            Swap
          </Button>
        </div>
      </div>
    </div>
  )
}
