import React from 'react'
import { t } from '../../i18n'
import { icons } from '../../assets'

interface Props {
  title: string
  description: string
  openingLink: string
  thumbnailUrl: string
  videoLink?: string
}

const openExternalLink = (link: string) => () => window.open(link, '_blank')

const cutText = (text: string, size: number) => (text.length > size ? text.substr(0, size - 3) + '...' : text)

const TutorialCardItem: React.FunctionComponent<Props> = props => {
  const onOpenClick = React.useCallback(openExternalLink(props.openingLink), [props.openingLink])
  return (
    <div className="item">
      <div style={{ backgroundImage: `url("${props.thumbnailUrl}")` }} />
      <div>
        <div>
          <p>{props.title}</p>
        </div>
        <div>
          <p>{cutText(props.description, 110)} </p>
        </div>
        <div>
          <p onClick={onOpenClick}>
            <img src={icons.download} /> {t('common:open')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TutorialCardItem
