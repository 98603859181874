import React from 'react'
import { find, map, orderBy, filter, debounce } from 'lodash'
import { MarketingResource, ResourceCategory, ResourceCategories } from '@allcoinwallet/inkryptus-db-schema'
import Fuse from 'fuse.js'
import { icons } from '../../assets'
import { t } from '../../i18n'
import MarketingResourcesSectionGrid from './MarketingResourcesSectionGrid.component'
import MarketingResourcesExpandedSectionGrid from './MarketingResourcesExpandedSectionGrid.component'
import MarketingResourceItem from './MarketingResourceItem.component'
import { useLocalizedMarketingResources, useResourceCategories } from '../../hooks/resources'
import BoxView from '../../components/box-view'

type Resource = MarketingResource & { resourceId: string }
type Resources = Array<Resource>
type CategoryAndResources = ResourceCategory & { categoryId: string; resources: Resources }
type CategoriesAndResources = Array<CategoryAndResources>
const fuseOptions: Fuse.IFuseOptions<Resource> = {
  shouldSort: true,
  threshold: 0.45,
  minMatchCharLength: 3,
  keys: [
    { name: 'title', weight: 0.7 },
    { name: 'description', weight: 0.3 },
  ],
}

const MarketingPage: React.FunctionComponent = () => {
  const [marketingResources] = useLocalizedMarketingResources()
  const [resourceCategories] = useResourceCategories()

  const [selectedCategoryId, setSelectedCategoryId] = React.useState<string>('')
  // const onSelectCategory = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => setSelectedCategoryId(e.target.value), [])
  const handleOpenCategory = React.useCallback((categoryId: string) => () => setSelectedCategoryId(categoryId), [])
  const onCancelCategorySelection = React.useCallback(() => setSelectedCategoryId(''), [])

  const [orderedCategoriesList, categoriesList, resources] = React.useMemo((): [CategoriesAndResources, CategoriesAndResources, Resources] => {
    if (!marketingResources) return [[], [], []]
    const categories: ResourceCategories = { uncategorized: { title: t('common:uncategorized') }, ...resourceCategories }
    const resourcesList = map(marketingResources, (r, resourceId) => ({ ...r, resourceId, order: r.order === undefined ? 99999999 : r.order }))
    const categoriesList = filter(
      map(categories, (c, categoryId) => ({
        ...c,
        categoryId,
        order: c.order === undefined ? 99999999 : c.order,
        resources: orderBy(
          filter(resourcesList, (r) => {
            if (categoryId === 'uncategorized') return !r.categories
            if (r.categories) return !!r.categories[categoryId]
            return false
          }),
          ['order', 'title'],
          ['asc', 'asc']
        ),
      })),
      (c) => c.resources.length > 0
    )
    const nameOrderedCategoriesList = orderBy(categoriesList, ['title'], ['asc'])
    const orderedCategoriesList = orderBy(categoriesList, ['order', 'title'], ['asc', 'asc'])
    return [orderedCategoriesList, nameOrderedCategoriesList, resourcesList]
  }, [marketingResources, resourceCategories])

  const selectedCategory: CategoryAndResources | null = React.useMemo(() => {
    if (!selectedCategoryId) return null
    return find(categoriesList, (category) => category.categoryId === selectedCategoryId) || null
  }, [selectedCategoryId, categoriesList])

  const [searchResults, setSearchResults] = React.useState<Resources | null>(null)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  // const onSearch = React.useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     const queryValue = e.target.value
  //     setSearchQuery(queryValue)
  //     if (searchResults && !queryValue) return setSearchResults(null)
  //     if (searchResults && queryValue.length < 3) return setSearchResults([])
  //     if (!searchResults && queryValue.length > 0) return setSearchResults([])
  //   },
  //   [searchResults]
  // )
  const onBackSearch = React.useCallback(() => {
    setSearchQuery('')
    setSearchResults(null)
  }, [])

  React.useEffect(() => {
    const debouncedFn = debounce((query: string) => {
      const fuse = new Fuse(resources, fuseOptions)
      const results = map(fuse.search(query), (r) => r.item)
      setSearchResults(results)
    }, 300)
    if (searchQuery) debouncedFn(searchQuery)
    return debouncedFn.cancel
  }, [searchQuery])

  return (
    <BoxView label="Marketing" icon={icons.iconMarketing} className="box-marketing">
      <div className="marketing-container">
      {searchResults ? (
        <MarketingResourcesExpandedSectionGrid title={`${t('common:search_results')} (${searchResults.length})`} onBackClick={onBackSearch}>
          {searchResults.map((r) => (
            <MarketingResourceItem
              key={r.resourceId}
              title={r.title}
              description={r.description}
              thumbnailUrl={r.thumbnailUrl}
              downloadLink={r.downloadLink}
            />
          ))}
        </MarketingResourcesExpandedSectionGrid>
      ) : selectedCategory ? (
        <MarketingResourcesExpandedSectionGrid title={selectedCategory.title} onBackClick={onCancelCategorySelection}>
          {selectedCategory.resources.map((r) => (
            <MarketingResourceItem
              key={r.resourceId}
              title={r.title}
              description={r.description}
              thumbnailUrl={r.thumbnailUrl}
              downloadLink={r.downloadLink}
            />
          ))}
        </MarketingResourcesExpandedSectionGrid>
      ) : (
        orderedCategoriesList.map((category) => (
          <MarketingResourcesSectionGrid
            key={category.categoryId}
            title={category.title}
            onSeeAllClick={category.resources.length > 4 ? handleOpenCategory(category.categoryId) : undefined}
          >
            {(category.resources.length > 4 ? category.resources.slice(0, 4) : category.resources).map((r) => (
              <MarketingResourceItem
                key={r.resourceId}
                title={r.title}
                description={r.description}
                thumbnailUrl={r.thumbnailUrl}
                downloadLink={r.downloadLink}
              />
            ))}
          </MarketingResourcesSectionGrid>
        ))
      )}
      </div>
    </BoxView>

  )
}

export default MarketingPage
