import moment from 'moment'
import { toUpper, memoize, filter, uniq } from 'lodash'
import { decimal, Decimal, mul, round, trunc } from '@allcoinwallet/invest-bitcoin-decimal'
import { TimePeriod } from '@allcoinwallet/inkryptus-db-schema'
import { t } from '../i18n'

export { toUpper }

export function humanizeDays(durationDays: number): string {
  return moment.duration(durationDays, 'days').humanize()
}

export function humanizeMs(durationMs: number): string {
  return moment.duration(durationMs, 'milliseconds').humanize()
}

export function humanizeTimeFromNow(time: number | Date | moment.Moment | null | undefined): string {
  if (!time) return '--:--'
  return moment(time).fromNow()
}

export function formatMsToDays(durationMs: number): string {
  return `${Math.floor(moment.duration(durationMs).asDays())} ${t('common:days')}`
}

export function formatDate(date: Date | number): string {
  return moment(date).format('L')
}
export function formatDateUtc(date: Date | number): string {
  return moment.utc(date).format('L')
}
export function formatHours(date: Date | number): string {
  return moment(date).format('LTS')
}
export function formatDateAndHours(date: Date | number): string {
  return `${formatDate(date)} ${formatHours(date)}`
}

export function formatDatePlain(date: Date | number): string {
  return moment(date).format('LL')
}

export function formatTimePeriodAsDate(period?: TimePeriod | null | undefined): string {
  if (!period) return '-'
  return `${moment(period.startingTime).format('l')} ${t('common:to')} ${moment(period.endingTime).format('l')}`
}

export function formatNumber(number: number | string | null | undefined): string {
  if (number === null || number === undefined) return '-'
  // TODO: implement
  if (typeof number === 'number') return number.toLocaleString()
  return number
}

export function formatCurrency(number: number | decimal | null | undefined, options: { noSymbol?: boolean } = {}): string {
  if (number === null || number === undefined) return '-'
  const symbol = options.noSymbol ? '' : '$'
  try {
    const dec = Decimal(number)
    return symbol + Number(dec).toFixed(2)
  } catch (err) {
    return symbol + String(number)
  }
}

export function parseDecimal(input: any): decimal | undefined {
  if (input === undefined || input === null) return undefined
  try {
    if (typeof input === 'number') return Decimal(input)
    if (typeof input !== 'string') return undefined
    const localeDecimalSeparator = getLocaleDecimalSeparator()
    if (!localeDecimalSeparator) return Decimal(input)
    const nonDigitsChars = input.match(/[^\d]/g)
    if (!nonDigitsChars) return Decimal(input)
    if (nonDigitsChars.length === 1) {
      if (nonDigitsChars[0] === '.') return Decimal(input)
      if (localeDecimalSeparator === nonDigitsChars[0]) return Decimal(input.split(localeDecimalSeparator).join('.'))
      return Decimal(input.split(nonDigitsChars[0]).join(''))
    }
    const nonDecimalSeparatorChars = uniq(filter(nonDigitsChars, (char) => char !== localeDecimalSeparator))
    let cleanedInput = input
    for (const char of nonDecimalSeparatorChars) cleanedInput = cleanedInput.split(char).join('')
    if (localeDecimalSeparator !== '.') cleanedInput = cleanedInput.split(localeDecimalSeparator).join('.')
    return Decimal(cleanedInput)
  } catch (err) {
    return undefined
  }
}

export function formatDecimal(input: decimal, prec: number = 2, fixMode?: 'trunc' | 'round') {
  const num = Number(fixMode === 'round' ? round(input, prec) : trunc(input, prec))
  return num.toLocaleString(undefined, {
    minimumFractionDigits: prec,
    maximumFractionDigits: prec,
  })
}

export const getLocaleDecimalSeparator = memoize(() => {
  const matches = Number(1.1).toLocaleString().match(/[^\d]/)
  if (!matches) return
  const [decimalSeparator] = matches
  return decimalSeparator
})

export function formatPercent(number: number | decimal | null | undefined, options: { noSymbol?: boolean; precision?: number } = {}): string {
  const symbol = options.noSymbol ? '' : '%'
  if (number === null || number === undefined) return '**' + symbol
  const precision = options.precision || 0
  try {
    const dec = Decimal(number)
    const percent = trunc(mul(dec, Decimal(100)), precision)
    return Number(percent) + symbol
  } catch (err) {
    return '##' + symbol
  }
}

export function formatUpperProductId(productId?: string | null, productVariant?: string | null): string {
  if (!productId) return ''
  if (productId === 'tradesoftware_customer') return 'TRADESOFTWARE'
  if (productId === 'tradesoftware') return 'DISTRIBUTOR UPGRADE'
  if (productId === 'sevencapital_high') return 'SEVENCAPITAL XM'
  if (productId === 'sevencapital_low') return 'SEVENCAPITAL FBS'
  if (productId === 'sevencapital_world') return 'SEVENCAPITAL WORLD'
  let text = toUpper(productId)
  if (productVariant) text = `${text} [${toUpper(productVariant)}]`
  return text
}
