import * as React from 'react'
import { useHistory } from 'react-router'
import { Location } from 'history'
import { MemberNotification } from '@allcoinwallet/inkryptus-db-schema'
import { reverse, sortBy } from 'lodash'
import BoxView from '../../components/box-view'
import { icons } from '../../assets'
import { useMemberNotifications, useMemberUnreadNotifications } from '../../hooks/notifications'
import { NotificationListItem } from './NotificationListItem'

const getClickedNotificationIdParam = (location: Location<unknown>) => {
  try {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get('clicked_notification_id')
  } catch (error) {
    console.error('Failed to get clicked notificationId from params', error)
    return
  }
}

const NotificationsPage: React.FC = () => {
  const history = useHistory()

  // const [readNotifications] = useMemberReadNotifications()
  const [readNotifications] = useMemberNotifications({ read: true })
  const [unreadNotifications] = useMemberUnreadNotifications()

  const notifications = React.useMemo(() => {
    const list: Array<MemberNotification & { id: string }> = []
    if (readNotifications)
      for (const notificationId in readNotifications) list.push({ ...readNotifications[notificationId], id: notificationId })
    if (unreadNotifications)
      for (const notificationId in unreadNotifications) list.push({ ...unreadNotifications[notificationId], id: notificationId })
    return reverse(sortBy(list, 'deliveryTime'))
  }, [readNotifications, unreadNotifications])

  const [expandedNotificationId, setExpandedNotificationId] = React.useState(() => getClickedNotificationIdParam(history.location) || '')
  const toggleExpandedNotificationId = React.useCallback(
    (notificationId) => {
      setExpandedNotificationId((currentId) => (currentId === notificationId ? '' : notificationId))
    },
    [setExpandedNotificationId]
  )

  return (
    <BoxView label="Notifications" icon={icons.iconNotification} className="box-notifications">
      <div className="notifications-container">
        {notifications.map((notification) => (
          <NotificationListItem
            key={notification.notificationId}
            notificationId={notification.notificationId}
            memberNotificationId={notification.id}
            expanded={expandedNotificationId === notification.notificationId}
            read={!!notification.readingTime}
            onClick={toggleExpandedNotificationId}
          />
        ))}
      </div>
    </BoxView>
  )
}

export default NotificationsPage
