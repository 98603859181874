import * as React from 'react'

interface Props {
  children: string
  value: string
  hidden?: boolean
}

const SelectItem: React.FunctionComponent<Props> = props => {
  return (
    <option hidden={props.hidden} value={props.value}>
      {props.children}
    </option>
  )
}

export default SelectItem
