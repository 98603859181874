import { map, size } from 'lodash'
import React from 'react'
import { BoxViewTabButton, BoxViewTabsContext, BoxViewTabsProvider, BoxViewTab } from './BoxViewTabs'

interface BoxViewContentProps {
  icon?: string
  label?: string
  className?: string
}

const BoxViewContent: React.FC<BoxViewContentProps> = ({ icon, label, className, children }) => {
  const { tabs, setTabsState } = React.useContext(BoxViewTabsContext)
  const tabsSize = React.useMemo(() => size(tabs), [tabs])

  const tabSelector = React.useCallback(
    (tabKey: string) => {
      setTabsState((current) => ({ ...current, activeTabKey: tabKey }))
    },
    [setTabsState]
  )

  return (
    <div className={`box-view ${className || ''}`}>
      <div className="heading-tab">
        <img src={icon} alt={label} />
        <p>{label}</p>
        {!!tabsSize && (
          <div className="tab-panel">
            {map(tabs, ({ label }, tabKey) => (
              <BoxViewTabButton key={tabKey} tabKey={tabKey} label={label} onClick={tabSelector} />
            ))}
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export interface BoxViewProps extends BoxViewContentProps {
  renderMenu?: () => React.ReactNode
}

export const BoxView: React.FC<BoxViewProps> = (props) => {
  if (!props.className)
    return (
      <div className="box-view">
        <div className="menu">
          <div>
            {props.renderMenu ? (
              props.renderMenu()
            ) : (
              <>
                <img src={props.icon} /> {props.label}
              </>
            )}
          </div>
        </div>
        <div className="content">{props.children}</div>
      </div>
    )

  return (
    <BoxViewTabsProvider>
      <BoxViewContent icon={props.icon} label={props.label} className={props.className}>
        {props.children}
      </BoxViewContent>
    </BoxViewTabsProvider>
  )
}

export { BoxViewTab }
