import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { get } from 'lodash'
import { logo } from '../../assets'
import { Button } from '../../components/shared'
import { ProductVariants } from '../../hooks/database'
import { t } from '../../i18n'

interface Props {
  licensePrice: decimal
  licenseDurationDays: number
  productId: string
  variants: ProductVariants
  onBuyClick: (productVariant: string) => () => void | Promise<void>
  onCloseClick: () => void
}
const ProductPlans: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <div className="content">
        <div>
          <p>{t('desk:product_plans_modal.menu_title')}</p>
        </div>
        <div>
          <img src={logo.ignisight.regular} />
        </div>
        <div>
          <div className="plan-description">
            <div>
              <img src={logo.ignisight.regular} />
              <div>
                <p>{get(props.variants, 'lite.name')}</p>
                <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.lite.p1')}</p>
                <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.lite.p2')}</p>
              </div>
            </div>
            <div>
              <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.lite.license_field_1_title')}</p>
              <p>{get(props.variants, 'lite.licensePrice')}</p>
              <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.lite.license_field_2_title')}</p>
              <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.lite.license_field_2_value')}</p>
            </div>
            <div>
              <p>{t('desk:product_plans_modal.description_title')}</p>
              <p>{get(props.variants, 'lite.description')}</p>
            </div>
            <Button size="small" type="primary" onClick={props.onBuyClick('lite')}>
              {t('common:buy')}
            </Button>
          </div>
          <div className="plan-description">
            <div>
              <img src={logo.ignisight.regular} />
              <div>
                <p>{get(props.variants, 'pro.name')}</p>
                <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.pro.p1')}</p>
                <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.pro.p2')}</p>
              </div>
            </div>
            <div>
              <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.pro.license_field_1_title')}</p>
              <p>{get(props.variants, 'pro.licensePrice')}</p>
              <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.pro.license_field_2_title')}</p>
              <p>{t('desk:product_plans_modal.temp_notes.mockingjaybot.pro.license_field_2_value')}</p>
            </div>
            <div>
              <p>{t('desk:product_plans_modal.description_title')}</p>
              <p>{get(props.variants, 'pro.description')}</p>
            </div>
            <Button size="small" type="primary" onClick={props.onBuyClick('pro')}>
              {t('common:buy')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductPlans
