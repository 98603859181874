import React from 'react'
import { map, orderBy } from 'lodash'
import moment from 'moment'
import { t } from '../../i18n'
import { icons } from '../../assets'
import { useMemberSupportTickets } from '../../hooks/support-ticket'
import SmartTable from '../../components/smart-table'
import BoxView from '../../components/box-view'
import OpeningTicketForm from './OpeningTicketForm.component'
import { formatDate } from '../../utils/format'
import alrt from '../../utils/alrt'
import { copyToClipboard } from '../../utils/copy-to-clipboard'

const SUPPORT_EMAIL = 'support@inkryptus.zendesk.com'
const showFollowingInfo = () => {
  copyToClipboard(SUPPORT_EMAIL)
  alrt.info({
    title: t('desk:support_page.tickets_history_table.follow_instruction_alert.title') as string,
    body: t('desk:support_page.tickets_history_table.follow_instruction_alert.body', { supportEmail: SUPPORT_EMAIL }) as string,
  })
}

const SupportPage: React.FunctionComponent = () => {
  const [dateStart, setDateStart] = React.useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
  const [dateEnd, setDateEnd] = React.useState(moment().format('YYYY-MM-DD'))
  const [tickets] = useMemberSupportTickets({ dateStart, dateEnd: moment(dateEnd).add(1, 'day').format('YYYY-MM-DD') })
  const ticketRows = React.useMemo(() => {
    const list = orderBy(
      map(tickets, (ticket, ticketId) => ({ ...ticket, ticketId })),
      ['openingTime'],
      ['desc']
    )
    return map(list, (ticket) => [
      ticket.ticketId.substr(ticket.ticketId.length - 9),
      formatDate(ticket.openingTime),
      t(`fields:ticket.type.options.${ticket.type}`),
      ticket.description,
      <span key={`${ticket.ticketId}_status`} className="opened">
        {t('fields:ticket.status.options.opened')}
      </span>,
      <a key={`${ticket.ticketId}_link`} onClick={showFollowingInfo}>
        {t('common:follow')}
      </a>,
    ])
  }, [tickets])
  return (
    <BoxView icon={icons.iconSupport} label={t('desk:support_page.menu_title')} className="box-support">
        <OpeningTicketForm />
        <div className="tickets-history">
          <SmartTable
            tableTitle=""
            columns={[
              t('fields:ticket.id.label'),
              t('fields:ticket.openingTime.label'),
              t('fields:ticket.type.label'),
              t('fields:ticket.subject.label'),
              t('fields:ticket.status.label'),
              t('fields:ticket.followingLink.label'),
            ]}
            rows={ticketRows}
            filter={{
              dateStart,
              dateEnd,
              setDateStart,
              setDateEnd,
            }}
          />
        </div>
    </BoxView>
  )
}

export default SupportPage
