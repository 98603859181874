import React from 'react'
import { t } from '../../i18n'

interface Props {
  status?: 'CLIENT' | 'DISTRIBUTOR' | 'ACTIVE' | 'INACTIVE' | null
  loading?: boolean
}
const CompanyStatus: React.SFC<Props> = props => {
  if (props.loading) return <span className="loading">...</span>
  if (!props.status || props.status === 'INACTIVE') return <span className="inactive">{t('fields:productMember.status.INACTIVE')}</span>
  return (
    <span className="active">
      {props.status === 'ACTIVE'
        ? t('fields:productMember.status.ACTIVE')
        : props.status === 'DISTRIBUTOR'
        ? t('common:distributor')
        : t('common:customer')}
    </span>
  )
}

export default CompanyStatus
