import React from 'react'
import Modal from 'react-modal'
import { replace } from 'lodash'
import * as SvgIcons from '../../components/shared/svg-icons'
import { Button } from '../../components/shared'
import { getReferralLink } from '../../utils/url'
import { usePlatform } from '../../hooks/capacitor'
import { t } from '../../i18n'

interface Props {
  username: string
  onClose: () => void
}

type SocialNetwork = 'facebook' | 'whatsapp' | 'twitter'
function getShareLink(socialNetwork: SocialNetwork, platform: string, username?: string): string {
  let url = getReferralLink(platform, username)
  if (socialNetwork === 'twitter') {
    const twitterUrl = new URL('https://twitter.com/intent/tweet')
    twitterUrl.searchParams.set('text', t('desk:share_invitation_link_modal.share_message_text', { invitationUrl: url }))
    url = twitterUrl.href
  }
  if (socialNetwork === 'facebook') {
    const facebookUrl = new URL('https://www.facebook.com/dialog/share')
    facebookUrl.searchParams.set('app_id', '809276846634203')
    facebookUrl.searchParams.set('display', 'popup')
    facebookUrl.searchParams.set('href', url)
    facebookUrl.searchParams.set('redirect_uri', url)
    url = facebookUrl.href
  }
  if (socialNetwork === 'whatsapp') {
    const whatsappUrl = new URL('https://api.whatsapp.com/send')
    whatsappUrl.searchParams.set('text', t('desk:share_invitation_link_modal.share_message_text', { invitationUrl: url }))
    url = replace(whatsappUrl.href, /\+/g, '%20')
  }
  return url
}

const ShareInvitationLink: React.FunctionComponent<Props> = (props) => {
  const platform = usePlatform()
  const handleShareClick = React.useCallback(
    (socialNetwork: SocialNetwork) => (e: any) => {
      e.preventDefault()
      const url = getShareLink(socialNetwork, platform, props.username)
      window.open(url, '_blank')
    },
    [props.username, platform]
  )

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.onClose}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-invitation-link"
    >
      <div className="title">
        <p>{t('desk:share_invitation_link_modal.description')}</p>
      </div>
      <div className="social-media">
        <div onClick={handleShareClick('facebook')}>
          <SvgIcons.FacebookIcon />
          <p>Facebook</p>
          <a href={getShareLink('facebook', platform, props.username)}>{t('common:share')}</a>
        </div>
        <div onClick={handleShareClick('whatsapp')}>
          <SvgIcons.WhatsappIcon />
          <p>Whatsapp</p>
          <a href={getShareLink('whatsapp', platform, props.username)}>{t('common:share')}</a>
        </div>
        <div onClick={handleShareClick('twitter')} style={{ display: 'none' }}>
          <SvgIcons.TwitterIcon />
          <p>Twitter</p>
          <a href={getShareLink('twitter', platform, props.username)}>{t('common:share')}</a>
        </div>
      </div>
      <div className="footer">
        <Button size="medium" type="primary" onClick={props.onClose}>
          {t('common:close')}
        </Button>
      </div>
    </Modal>
  )
}

export default ShareInvitationLink
