import React from 'react'
const WhatsappIcon: React.SFC<{ className?: string }> = props => {
  return (
    <svg className={props.className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5579 0C7.99672 0 0.231762 7.76489 0.231762 17.2682C0.231762 20.2815 1.04303 23.1788 2.54966 25.8444L0 35L9.32944 32.5083C12.0529 33.957 14.8924 34.7682 17.5579 34.7682C27.0033 34.7682 35 26.7714 35 17.2682C35 7.76489 27.1771 0 17.5579 0ZM17.5579 33.6093C15.0083 33.6093 12.2848 32.798 9.67712 31.3494L9.44536 31.2335L1.62254 33.3775L3.76653 25.6706L3.65061 25.4388C2.20191 22.9471 1.39071 20.1077 1.39071 17.2682C1.39071 8.40232 8.63408 1.15895 17.5579 1.15895C26.5397 1.15895 33.8411 8.40232 33.8411 17.2682C33.8411 26.1342 26.3659 33.6093 17.5579 33.6093Z"
        fill="white"
      />
      <path
        d="M17.5579 2.89746C9.61919 2.89746 3.1291 9.32957 3.1291 17.2683C3.1291 20.1077 3.94037 22.7733 5.389 25.1491L5.62076 25.4389L4.05628 30.9438L9.73511 29.4951L10.0249 29.669C12.4007 31.1177 15.0083 31.871 17.558 31.871C25.5547 31.871 32.1027 25.3229 32.1027 17.2683C32.1027 9.32957 25.5546 2.89746 17.5579 2.89746ZM17.5 30.77C15.1821 30.77 12.8063 30.0167 10.6043 28.6839L9.90894 28.2203L5.67882 29.2634L6.83777 25.2071L6.37418 24.4538C4.98347 22.3098 4.28811 19.818 4.28811 17.2684C4.28811 10.025 10.2566 4.05648 17.558 4.05648C24.9173 4.05648 30.9438 10.025 30.9438 17.2684C30.9437 24.6856 24.9172 30.712 17.5 30.77Z"
        fill="white"
      />
      <path
        d="M26.25 20.4552L22.5993 18.7748C22.0778 18.543 21.5563 18.7169 21.2086 19.1225L19.8179 20.9188C18.7749 20.5132 15.8775 19.2384 14.1971 16.1092L15.4719 14.6026C15.7617 14.2549 15.8196 13.7913 15.6458 13.3857L14.0812 9.67711C13.9074 9.27151 13.5597 8.98175 13.0961 8.98175L11.9951 8.92383C11.5315 8.92383 11.01 9.03974 10.6623 9.38742C10.1408 9.85102 9.15566 10.8361 8.86591 12.1689C8.46031 14.0811 9.09767 16.3411 10.7202 18.5431C11.7053 19.9338 14.7186 24.0481 20.5132 25.6706C21.0927 25.8444 21.6722 25.9023 22.1937 25.9023C23.1788 25.9023 24.1059 25.6126 24.9172 25.149C25.8444 24.5696 26.4818 23.5845 26.7136 22.5414L26.8874 21.7301C27.0033 21.2085 26.7136 20.687 26.25 20.4552ZM25.4966 22.2516C25.3228 23.0048 24.8592 23.7003 24.2218 24.1059C23.2367 24.7433 22.0777 24.8591 20.8029 24.5115C15.4139 23.0048 12.5744 19.1224 11.6473 17.8475C10.2566 15.9353 9.67711 13.9651 10.0248 12.4005C10.2565 11.3575 11.0099 10.6042 11.4735 10.1986C11.5894 10.0826 11.7632 10.0247 11.9371 10.0247L13.096 10.1406L14.6606 13.7913L13.2699 15.3559C13.0381 15.6456 12.9801 16.1091 13.154 16.4569C15.0662 20.1655 18.543 21.6142 19.5861 21.9619C19.9917 22.0778 20.3973 21.9619 20.6871 21.6142L22.0778 19.7599L25.7285 21.4404L25.4966 22.2516Z"
        fill="white"
      />
    </svg>
  )
}

export default WhatsappIcon
