import React from 'react'
import { t } from '../../i18n'
import { useMarketingResource } from '../../hooks/resources'

interface Props {
  resourceId: string
}

const openExternalLink = (link: string) => () => window.open(link, '_blank')

const PresentationDownload: React.FunctionComponent<Props> = props => {
  const [resource] = useMarketingResource(props.resourceId)
  const onDownloadClick = React.useCallback(resource ? openExternalLink(resource.downloadLink) : () => {}, [resource])
  if (!resource) return null
  return (
    <div className="presentation">
      <p>{t('desk:product_overview_modal.presentation_download.title')}</p>
      <img src={resource.thumbnailUrl} onClick={onDownloadClick} />
    </div>
  )
}

export default PresentationDownload
