export const formatDate = (input: string): string => {
  let datePart: RegExpMatchArray | null
  datePart = input.match(/\d+/g)
  if (datePart) {
    let year = datePart[0].substring(4) // get only two digits
    const month = datePart[1],
      day = datePart[2]
    return day + '/' + month + '/' + year
  }
  console.error('Format Date cannot be null')
  return input
}

export default formatDate
