import * as React from 'react'
import { icons } from '../../../assets'
import alrt from '../../../utils/alrt'
import { t } from '../../../i18n'
import { countries } from '../../../utils/countries'

import Input from '../../../components/shared/input'
import Button from '../../../components/shared/button'
import { Select, SelectItem } from '../../../components/shared'

interface Props {
  label: string
  value?: string
  onEdit?: (changedValue: string) => void | Promise<any>
  type?: 'text' | 'date' | 'phone' | 'country' | 'gender'
}

const ProfileInput: React.FunctionComponent<Props> = props => {
  const defaultValue = props.value || ''
  const [changingValue, setChangingValue] = React.useState<string>(defaultValue)
  const [editMode, setEditMode] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const value = editMode ? changingValue : defaultValue

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChangingValue(e.target.value)
  }

  const handleSave = async () => {
    if (!props.onEdit) return
    setLoading(true)
    try {
      await props.onEdit(changingValue)
    } catch (error) {
      if (error.humanizedMessage) alrt.error({ body: error.humanizedMessage })
      else alrt.error()
    }
    setEditMode(false)
    setLoading(false)
  }

  const enableEditMode = () => {
    if (!props.onEdit) return
    setEditMode(true)
  }

  React.useEffect(() => {
    setChangingValue(defaultValue)
  }, [editMode, defaultValue])

  return (
    <div>
      <div>
        <p>{props.label}</p>
      </div>
      <div className="input-large-icon i-fill">
        <DynamicInput type={props.type} disabled={!editMode} onChange={handleChange} value={value} />
        {props.onEdit && <img src={icons.edit} onClick={enableEditMode} />}
        {editMode && (
          <Button class="profile__btn--save" size="medium" type="primary" onClick={handleSave}>
            {loading ? t('common:loading') : t('common:save')}
          </Button>
        )}
      </div>
    </div>
  )
}

interface DynamicInputProps {
  type?: 'text' | 'date' | 'phone' | 'country' | 'gender'
  value?: string
  disabled?: boolean
  onChange: (e: React.ChangeEvent<any>) => void
}
const DynamicInput: React.SFC<DynamicInputProps> = props => {
  // if (props.type === 'country')
  //   return (
  //     <Select value={props.value || ''} onChange={props.onChange}>
  //       <SelectItem value="" hidden>
  //         {''}
  //       </SelectItem>
  //       {countries.map(({ code, name }) => (
  //         <SelectItem key={code} value={code}>
  //           {name}
  //         </SelectItem>
  //       ))}
  //     </Select>
  //   )

  if (props.type === 'gender')
    return (
      <Select value={props.value || ''} onChange={props.onChange}>
        <SelectItem value="" hidden>
          {''}
        </SelectItem>
        {['undefined', 'female', 'male', 'other'].map(k => (
          <SelectItem key={k} value={k}>
            {t('fields:gender.options.' + k)}
          </SelectItem>
        ))}
      </Select>
    )
  return (
    <Input
      type={props.type}
      disabled={props.disabled}
      onChange={props.onChange}
      value={props.type === 'country' ? (props.value && countries[props.value]) || '' : props.value}
    />
  )
}

export default ProfileInput
