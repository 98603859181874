import * as React from 'react'
import { signInWithUsernameAndPassword } from '../../utils/firebase-auth'

import SigninForm from '../../components/auth/signin'

const SigninPage: React.FunctionComponent = () => {
  const onSubmit = async (props: { username: string; password: string }): Promise<void> => {
    await signInWithUsernameAndPassword(props.username, props.password)
  }
  return <SigninForm onSubmit={onSubmit} />
}

export default SigninPage
