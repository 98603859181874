import React from 'react'
import QRCode from 'qrcode.react'
import { icons } from '../../assets'
import { useUid } from '../../hooks/current-user'
import { useMemberSwapWallet } from '../../hooks/swap-wallets'
import { copyToClipboard } from '../../utils/copy-to-clipboard'
import { getSwapTokenMetadata } from '../../utils/swap'
import { formatDecimal } from '../../utils/format'
import { Button } from '../../components/shared'

interface Props {
  symbol?: string | null
  onCloseClick?: () => any
}

const SwapDepositWallet: React.FC<Props> = React.memo(({ symbol, onCloseClick }) => {
  const [uid] = useUid()

  const [swapWallet] = useMemberSwapWallet(uid, symbol || undefined)
  const walletAddress = swapWallet?.address
  const availableBalance = swapWallet?.balance.available

  const [agreed, setAgreed] = React.useState(false)
  const [agreementChecked, setAgreementChecked] = React.useState(false)
  React.useEffect(() => {
    if (!!symbol) {
      setAgreementChecked(false)
      setAgreed(false)
    }
  }, [symbol, setAgreed, setAgreementChecked])
  const toggleAgreementCheck = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreementChecked(!!event.target.checked)
    },
    [setAgreementChecked]
  )
  const agree = React.useCallback(() => {
    setAgreed(true)
  }, [setAgreed])

  const mountedRef = React.useRef(true)
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [showingCopyTooltip, setShowingCopyTooltip] = React.useState<boolean>(false)
  const onCopy = React.useCallback(() => {
    if (!walletAddress) return
    copyToClipboard(walletAddress)
    setShowingCopyTooltip(true)
  }, [walletAddress])

  React.useEffect(() => {
    if (!showingCopyTooltip) return
    const timeoutId = setTimeout(() => {
      setShowingCopyTooltip(false)
    }, 3000)
    return () => clearTimeout(timeoutId)
  }, [showingCopyTooltip])

  if (!symbol) return null

  const symbolMeta = getSwapTokenMetadata(symbol)

  if (!agreed)
    return (
      <>
        <div className="close-alert">
          <img src={icons.menuClose} alt="close" onClick={onCloseClick} />
        </div>
        <div className="content-alert">
          <img src={icons.attentionRed} alt="attention" />
          <div>
            <p>Attention!</p>
            <p>Before making any deposit make sure you are using BEP20 Network. Funds sent from other network will be lost.</p>
          </div>
        </div>
        <div className="agree-alert">
          <label htmlFor="agree">
            I agree
            <input type="checkbox" id="agree" checked={agreementChecked} onChange={toggleAgreementCheck} />
            <div className="selector-area">
              <span className="circle"></span>
            </div>
          </label>
        </div>
        <div className="button-alert">
          <Button size="large" type="primary" disabled={!agreementChecked} onClick={agree}>
            Deposit
          </Button>
        </div>
      </>
    )

  return (
    <>
      <div className="title">
        <p>{symbolMeta.name} - Wallet</p>
        <img src={icons.menuClose} alt="Menu Close" onClick={onCloseClick} />
      </div>
      <div className="balance">
        <img src={symbolMeta.icon} alt={symbol} width={80} height={80} />
        <p>{symbolMeta.name}</p>
        <p>
          <span> Balance</span> <br />
          {availableBalance ? formatDecimal(availableBalance, 8) : '-'}
        </p>
      </div>
      <div className="adress" onClick={onCopy}>
        <div className="qr-code">
          {walletAddress && (
            <QRCode value={walletAddress} size={80} bgColor="#ffffff" fgColor="#000000" level="L" includeMargin={true} renderAs="svg" />
          )}
          {!walletAddress && <img src={icons.qrCode} alt="Qr-code" />}
        </div>
        <div className="content">
          <div>
            <p>Wallet Address</p>
            <p>{walletAddress || '...'}</p>
          </div>
          <div>
            <p>Network</p>
            <p>
              <img src={icons.bscNetwork} alt="Binance Smart Chain" />
              Binance Smart chain (BEP 20)
            </p>
          </div>
        </div>
        {showingCopyTooltip && (
          <div className="copy-tooltip">
            <p>Copied to clipboard!</p>
          </div>
        )}
      </div>
    </>
  )
})

export default SwapDepositWallet
