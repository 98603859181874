import React from 'react'
import { decimal } from '@allcoinwallet/invest-bitcoin-decimal'
import { MemberDebits } from '@allcoinwallet/inkryptus-db-schema'
import { map } from 'lodash'
import { t } from '../../i18n'
import { icons } from '../../assets'
import { formatUpperProductId, formatCurrency } from '../../utils/format'

import InvoiceModalMenu from './EstimateDebitsModalMenu.component'
import { Button } from '../../components/shared'

interface Props {
  estimateAmount?: decimal | null
  quoteAmount?: decimal | null
  debits?: MemberDebits | null
  onPayClick: () => void | Promise<void>
  onCloseClick: () => void
}
const Estimate: React.FunctionComponent<Props> = (props) => {
  const [paying, setPaying] = React.useState<boolean>(false)

  const onPayClick = async () => {
    if (paying) return
    setPaying(true)
    try {
      await props.onPayClick()
    } catch (error) {}
    setPaying(false)
  }

  return (
    <>
      <InvoiceModalMenu title={t('desk:estimate_modal.menu_title')} />
      <div className="content">
        <div className="info-payment">
          <div>
            <div>
              <p>{t('desk:estimate_modal.payment_status')}</p>
              <p>--:--</p>
            </div>
            <div>
              <p className="font-small">{t('fields:invoice.estimateAmount.description', { estimateAmount: props.estimateAmount || '-' })}</p>
              <p className="font-large">{formatCurrency(props.quoteAmount)}</p>
            </div>
            <div>
              <p>{t('fields:invoice.walletAddress.label')}</p>
              <div className="input-large i-fill">
                <Button size="large" type="success" onClick={onPayClick} loading={paying} disabled={paying}>
                  {t('desk:estimate_modal.pay_button.label')}
                </Button>
              </div>
            </div>
          </div>
          <div>
            <img src={icons.qrWalletAcwPayment} />
          </div>
        </div>
        <div className="info-product">
          <div>
            <p>{t('desk:invoice_modal.invoice_item.title')}</p>
          </div>
          <div>
            <p>{t('desk:invoice_modal.invoice_item.debits_title')}</p>
            {map(props.debits, (debit, debitId) => (
              <p key={debitId}>
                {formatUpperProductId(debit.productId)}
                <small>({t(`fields:debits.type.ofType.${debit.type}`)})</small>
              </p>
            ))}
          </div>
          <div>
            <img src={icons.info} />
            <p>{t('desk:estimate_modal.instruction')}</p>
          </div>
          <Button size="medium" type="primary" onClick={props.onCloseClick}>
            {t('common:close')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Estimate
