import * as React from 'react'

export type AppStateRootMode = 'stake' | 'swap'

export interface AppState {
  rootMode: AppStateRootMode
  rootModeTransitioning: boolean
}

function getInitialRootModeFromUrl(): AppStateRootMode {
  const url = new URL(window.location.href)
  if (url.host.indexOf('swap.') !== -1) return 'swap'
  if (url.pathname.startsWith('/swap')) return 'swap'
  return 'stake'
}

const initialState: AppState = {
  rootMode: getInitialRootModeFromUrl(),
  rootModeTransitioning: false,
}

const AppStateContext = React.createContext<[AppState, React.Dispatch<React.SetStateAction<AppState>>]>([initialState, (c) => c])

export const useAppState = () => React.useContext(AppStateContext)

export const AppStateProvider: React.FC = ({ children }) => {
  const [appState, setAppState] = React.useState(initialState)

  const rootMode = appState.rootMode
  React.useEffect(() => {
    if (rootMode === 'swap') document.body.classList.add('swap')
    else document.body.classList.remove('swap')
  }, [rootMode])

  return <AppStateContext.Provider value={[appState, setAppState]}>{children}</AppStateContext.Provider>
}
