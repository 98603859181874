import React from 'react'
import EstimateModal from './EstimateModal.container'

export interface EstimateModalContextValues {
  productId?: string | null
  productVariant?: string | null
  showModal: (productId: string, variant?: string) => void
  closeModal: () => void
}
export const EstimateModalContext = React.createContext<EstimateModalContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const EstimateModalProvider: React.FunctionComponent = props => {
  const [showingEstimateProductId, setShowingEstimateProductId] = React.useState<string | null>(null)
  const [showingEstimateProductVariant, setShowingEstimateProductVariant] = React.useState<string | null>(null)
  const showModal = (productId: string, variant?: string) => {
    setShowingEstimateProductId(productId)
    setShowingEstimateProductVariant(variant || null)
  }
  const closeModal = () => {
    setShowingEstimateProductId(null)
    setShowingEstimateProductVariant(null)
  }

  return (
    <EstimateModalContext.Provider
      value={{ productId: showingEstimateProductId, productVariant: showingEstimateProductVariant, showModal, closeModal }}
    >
      <EstimateModal productId={showingEstimateProductId} productVariant={showingEstimateProductVariant} onClose={closeModal} />
      {props.children}
    </EstimateModalContext.Provider>
  )
}

export default EstimateModalContext
