import React from 'react'

export interface InviteToOurSocialNetworksContextValues {
  showing?: boolean
  showModal: () => void
  closeModal: () => void
}
export const InviteToOurSocialNetworksModalContext = React.createContext<InviteToOurSocialNetworksContextValues>({
  showModal: () => {},
  closeModal: () => {},
})

export const InviteToOurSocialNetworksModalProvider: React.FunctionComponent = props => {
  const [showing, setShowing] = React.useState<boolean | undefined>()
  const showModal = React.useCallback(() => setShowing(true), [showing, setShowing])
  const closeModal = React.useCallback(() => setShowing(false), [showing, setShowing])

  return (
    <InviteToOurSocialNetworksModalContext.Provider value={{ showing, showModal, closeModal }}>
      {props.children}
    </InviteToOurSocialNetworksModalContext.Provider>
  )
}

export default InviteToOurSocialNetworksModalProvider
