import React from 'react'
import { size, keys } from 'lodash'
import { NetworkMembers } from '@allcoinwallet/inkryptus-db-schema'
import { db } from '../services/firebase'
import { useDatabaseObject } from './database'

type UseMemberNetwork = [NetworkMembers | null, boolean, object | undefined]
export function useMemberNetwork(levels: number, username?: string | null): UseMemberNetwork {
  // TODO: Refactor to optimize this query
  const networkMembersRef = username ? db.child('networkMembers') : undefined
  const networkMembers = useDatabaseObject(networkMembersRef)

  const memberNetwork: null | NetworkMembers = React.useMemo(() => {
    if (!networkMembers.value || !username) return null
    const memberNetworkObj: NetworkMembers = {}
    fillObjWithChildrens(networkMembers.value, 0, [username])
    return memberNetworkObj
    function fillObjWithChildrens(members: NetworkMembers, level: number, usernames: string[]): void {
      if (level > levels) return
      if (usernames.length === 0) return
      let nextLevelUsernames: string[] = []
      for (const levelUsername of usernames) {
        const member = members[levelUsername]
        if (!member) continue
        memberNetworkObj[levelUsername] = member
        nextLevelUsernames = nextLevelUsernames.concat(keys(member.sponsored))
      }
      fillObjWithChildrens(members, level + 1, nextLevelUsernames)
    }
  }, [networkMembers.value, levels, username])

  return [memberNetwork, networkMembers.loading, networkMembers.error]
}

type UseMemberNetworkSummary = { directsCount: number; indirectsCount: number } | null
export function useMemberNetworkSummary(username?: string | null, network?: NetworkMembers | null): UseMemberNetworkSummary {
  return React.useMemo(() => {
    if (!network || !username) return null
    const summary = { directsCount: 0, indirectsCount: 0 }
    const networkSize = size(network) - 1
    if (networkSize > 0) {
      const member = network[username]
      if (!member) return null
      summary.directsCount = size(member.sponsored)
      summary.indirectsCount = networkSize - summary.directsCount
    }
    return summary
  }, [network, username])
}

interface TreeItem {
  name: string
  children: TreeItem[]
}
type UseNetworkTree = TreeItem | null
export function useMemberNetworkTree(username?: string | null, networkMembers?: NetworkMembers | null): UseNetworkTree {
  const tree: TreeItem | null = React.useMemo(() => {
    if (!username) return null
    if (!networkMembers) return null
    const treeObj: TreeItem = { name: fmt(username), children: toTreeChildren(7, keys((networkMembers[username] || {}).sponsored)) }
    return treeObj
    function toTreeChildren(level: number, usernames: string[]): TreeItem[] {
      if (level === 0) return []
      if (usernames.length === 0) return []
      if (!networkMembers) return []
      return usernames.map(un => ({ name: fmt(un), children: toTreeChildren(level - 1, keys((networkMembers[un] || {}).sponsored)) }))
    }
    function fmt(username: string) {
      return `${username}`
    }
  }, [username, networkMembers])
  return tree
}
