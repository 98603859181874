import React from 'react'
const AlertIcon: React.SFC<{ className?: string }> = props => {
  return (
    <svg className={props.className} width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9999 16.5675C12.6127 16.5675 13.1095 16.0848 13.1095 15.4893C13.1095 14.8939 12.6127 14.4111 11.9999 14.4111C11.3871 14.4111 10.8904 14.8939 10.8904 15.4893C10.8904 16.0848 11.3871 16.5675 11.9999 16.5675Z"
        fill="white"
      />
      <path
        d="M11.8507 12.6916C12.188 12.6916 12.4617 12.4256 12.4617 12.0979V6.4575C12.4617 6.12976 12.188 5.86377 11.8507 5.86377C11.5135 5.86377 11.2397 6.12976 11.2397 6.4575V12.0979C11.2397 12.4256 11.5135 12.6916 11.8507 12.6916Z"
        fill="white"
      />
      <path
        d="M14.2269 1.76545L14.1991 1.72359C13.6333 0.876341 13.0486 -6.22845e-08 11.9998 -6.22845e-08C10.9068 -6.22845e-08 10.3349 0.88881 9.78195 1.74882C9.7792 1.75298 9.7621 1.7797 9.75935 1.78415L0.0849276 17.7141C0.0293275 17.8056 0 17.8534 0 17.9594C0 19.1159 0.957727 20 2.1348 20H21.8652C23.0426 20 24 19.1156 24 17.9594C24 17.8534 23.9707 17.7771 23.9154 17.6859L14.2269 1.76545ZM21.8652 18.8125H2.1348C1.68847 18.8125 1.30477 18.5302 1.23359 18.1019L10.8182 2.34997C11.2929 1.61167 11.6039 1.1735 11.9998 1.1735C12.3851 1.1735 12.7505 1.72864 13.1739 2.36274L22.7661 18.0986C22.6952 18.5267 22.3115 18.8125 21.8652 18.8125Z"
        fill="white"
      />
    </svg>
  )
}

export default AlertIcon
