import React from 'react'
import Modal from 'react-modal'
import { isString } from 'lodash'

import SwapSymbolSelector from './SwapSymbolSelector.component'

interface Props {
  currentSymbol?: string | null
  onSelect: (symbol: string) => void
  onClose: () => void
}

const SwapSymbolSelectorModal: React.FunctionComponent<Props> = (props) => {
  const currentSymbol = isString(props.currentSymbol) ? props.currentSymbol : undefined

  const close = props.onClose
  const select = props.onSelect

  React.useEffect(
    () => {
      return
      // if (transactionError) close()
    },
    [
      /* close */
    ]
  )

  const onSelectClick = React.useCallback(
    async (symbol: string) => {
      console.log('modal container select click', symbol)
      select(symbol)
      close()
    },
    [close, select]
  )

  // if () return <LoadingSpinner />

  return (
    <Modal
      isOpen={currentSymbol !== undefined}
      onRequestClose={close}
      portalClassName="modal-portal"
      overlayClassName="overlay"
      className="box-swap-symbol-selector"
    >
      <SwapSymbolSelector onSelectClick={onSelectClick} onCloseClick={close} />
    </Modal>
  )
}

export default SwapSymbolSelectorModal
