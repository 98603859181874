import React from 'react'
import moment from 'moment'
import { useUsername } from './current-user'
import { useDatabaseObject, NetworkMemberActivityType, NetworkMemberActivities } from './database'
import { db } from '../services/firebase'

type UseNetworkActivitiesHook = [NetworkMemberActivities | null, boolean, object | undefined]
type Filters = { periodStart?: number; periodEnd?: number }
export function useNetworkMemberActivities(type?: NetworkMemberActivityType | null, filters?: Filters): UseNetworkActivitiesHook {
  const { username, loading: usernameLoading, error: usernameError } = useUsername()

  const [periodStart, periodEnd] = React.useMemo(
    () => [
      (filters && filters.periodStart) ||
        moment()
          .subtract(30, 'days')
          .valueOf(),
      (filters && filters.periodEnd) || 999999999999999,
    ],
    [filters]
  )
  const activitiesQuery =
    username && type
      ? db
          .child('networkMembersActivities')
          .child(username)
          .orderByChild('_indexes/type__time')
          .startAt(`${type}__${periodStart}`)
          .endAt(`${type}__${periodEnd}`)
      : undefined
  const activities = useDatabaseObject(activitiesQuery)

  const loading = usernameLoading || activities.loading
  const error = usernameError || activities.error
  return [activities.value || null, loading, error]
}
